import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, DropdownMenu, DropdownToggle, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown, Input } from 'reactstrap';
import { ConversionClient, EmployeeClient, EmployeeModel, EmployeeRefModel } from '../../../../../client/ApiClient';
import { DataCell, DataRow, DataTable, DataTableButton } from '../../../../basic/DataTable';
import StageActionToolbar from '../generic/StageActionToolbar';
import { RangePaginationState, RangePagination, paginate } from 'components/basic/Pagination';
import { stagingPageUpdate, stagingPageSetPrimary, stagingPageCopyMappings, StagingPageCopyMappingsItem } from '../generic/Abstractions'
import { RowStatus, AssignRowStatus } from '../generic';
import { regex } from '../../Constants';

import '../../../../../styles/Icons.scss';
import './EmployeeStage.scss';
import DataListInput from '../../../../basic/DataListInput';

interface EmployeeStagingPageArgs {
    conversionId: string,
}

type EmployeeStagingPageProps = RouteComponentProps<EmployeeStagingPageArgs>

interface EmployeeStagingPageState {
    items: (EmployeeModel
        & StagingPageCopyMappingsItem)[];
    itemsByDestinationCodes: EmployeeModel[];
    filter: string;
    destinationCodes: EmployeeRefModel[];
    sort: boolean;
    sortColumn: string;
    mapped: boolean;
    notMapped: boolean;
    dropped: boolean;
    modal: boolean;
    repeatedCode: string;
    canCancel: boolean;
    lastValue: string;
    zero: boolean;
    primaryList: {
        destinationCode?: string,
        pItems?: EmployeeModel[]
    }[];
    pagination: RangePaginationState;
    buttonLock: boolean;
    includeAddToMain: boolean;
}

export class EmployeeStagingPage extends React.Component<EmployeeStagingPageProps, EmployeeStagingPageState>{
    private client: EmployeeClient = new EmployeeClient()
    private conversionClient: ConversionClient = new ConversionClient();

    private readonly copyMappings = stagingPageCopyMappings(this);

    private statuses = [
        {
            id: 'A',
            value: 'Active'
        },
        {
            id: 'R',
            value: 'Retired'
        },
        {
            id: 'I',
            value: 'Inactive'
        }
    ]

    constructor(props: EmployeeStagingPageProps) {
        super(props)
        this.state = {
            items: [],
            itemsByDestinationCodes: [],
            filter: '',
            destinationCodes: [],
            sort: true,
            sortColumn: '',
            mapped: true,
            notMapped: true,
            dropped: true,
            modal: false,
            repeatedCode: '',
            canCancel: false,
            lastValue: '',
            zero: false,
            primaryList: [],
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            buttonLock: false,
            includeAddToMain: false,
        }
        this.getEmployees = this.getEmployees.bind(this)
    }

    componentDidMount() {
        this.getEmployees();
        this.UIController();
        this.client.getRef(this.props.match.params.conversionId)
            .then(response => {
                if (response != null) {
                    this.setState({
                        destinationCodes: response,
                    })
                }
            })
            .catch(error => { })
    }

    setDefaults = (entity: EmployeeModel) => new EmployeeModel({
        ...entity,
        destinationCode: entity.destinationCode || '',
        status: entity.status || this.statuses[0].id,
        isRep: entity.isRep || false,
        isProd: entity.isProd || false,
        isTeleMarketer: entity.isTeleMarketer || false,
        isOther: entity.isOther || false,
    });

    getEmployees() {
        this.client.getAll(this.props.match.params.conversionId, '')
            .then((response) => {
                const items = response!
                this.updatePrimaryList(items)

                this.setState({
                    items: items.map(this.setDefaults).sort((a: any, b: any) => {
                        let x = a[this.state.sortColumn]
                        let y = b[this.state.sortColumn]
                        if (this.state.sortColumn === 'isPrimary') {
                            y = this.state.primaryList.some(p => p.destinationCode === a.destinationCode) && a.isPrimary && a.isMapped && !a.isDrop ? 3 : !a.isPrimary && a.isMapped && !a.isDrop ? 2 : 1;
                            x = this.state.primaryList.some(p => p.destinationCode === b.destinationCode) && b.isPrimary && b.isMapped && !b.isDrop ? 3 : !b.isPrimary && b.isMapped && !b.isDrop ? 2 : 1;
                        }
                        if (typeof y === "string") {
                            y = y.toLowerCase().trim()
                            x = x.toLowerCase().trim()
                        }
                        if (x === y) {
                            return 0;
                        }
                        else if (x === null) {
                            return 1;
                        }
                        else if (y === null) {
                            return -1;
                        }
                        else if (!this.state.sort) {
                            return x < y ? -1 : 1;
                        }
                        else {
                            return x < y ? 1 : -1;
                        }
                    }),
                })
            })
            .catch(error => { })
    }

    updatePrimaryList(items: any[]) {
        let primaryList:
            {
                destinationCode?: string,
                pItems: EmployeeModel[]
            }[] = [];

        items.forEach((item: EmployeeModel) => {
            if (item.destinationCode) {
                let primaryObject = primaryList.find(x => x.destinationCode!.trim() === item.destinationCode!.trim());
                if (!primaryObject) {
                    primaryObject = { destinationCode: item.destinationCode, pItems: [] }
                    primaryList.push(primaryObject);
                }
                if (!item.isDrop) primaryObject.pItems.push(item);
            }
        });

        primaryList = primaryList.filter(x => x.pItems!.length > 1 && x.pItems.some(p => p.isPrimary));
        this.setState({ primaryList })
    }

    put(entity: EmployeeModel) {
        entity.isPrimary = true;
        stagingPageUpdate(this, this.client)
            .withSource('Employee', entity.sourceCode!)
            .update(entity)
            .then(() => this.getEmployees())
            .catch(error => {
                const response = JSON.parse(error.response)
                console.log(response)
            })
    }

    getByDestinationCodes = (destinationCode: string) => {
        this.client.getByDestinationCode(this.props.match.params.conversionId, destinationCode)
            .then(response => {
                if (response != null) {
                    this.setState({ itemsByDestinationCodes: response })
                }
            })
            .catch(error => { })
    }

    handleSearch = (filter: string) => {
        this.setState({ filter })
    }

    handleChange = (e: any) => {
        const dc = e.target.value;
        const id = Number(e.target.name);

        const items = this.state.items;
        const i = this.state.items.findIndex(v => v.id === id);
        items[i].destinationCode = dc.toUpperCase().replace(regex.alphaNumeric, '');

        this.setState({ items }, () => {
            if (items[i].destinationCode !== this.state.lastValue && this.state.lastValue.includes(this.state.filter) && this.state.filter !== '') this.put(items[i])
        });
    }

    handleCheckboxChange = (id: number, e: { target: { name: any, checked: any } }) => {
        const items = this.state.items
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === id) {
                items[i] = new EmployeeModel({
                    ...items[i],
                    [e.target.name]: e.target.checked,
                })
                if (e.target.name === "isDrop" && items[i].isDrop && (items[i].lobCount > 0 || items[i].clientCount > 0)) {
                    const policy = items[i].lobCount > 1 ? 'policies' : 'policy'
                    const client = items[i].clientCount > 1 ? 'clients' : 'client'
                    const pString = items[i].lobCount > 0 ? `${items[i].lobCount} ${policy} will be dropped` : ''
                    const cString = items[i].clientCount > 0 ? `${items[i].clientCount} ${client} will be dropped` : ''
                    const and = items[i].lobCount > 0 && items[i].clientCount > 0 ? '\n' : ''
                    alert(`${pString}${and}${cString}`)
                }
            }
        }
        this.setState({ items });
        this.put(items.find(v => v.id === id)!)
    }

    handleValueChange = (id: number, e: { target: { name: any, value: any } }) => {
        const items = this.state.items.map(
            v => v.id === id
                ? new EmployeeModel({
                    ...v,
                    [e.target.name]: e.target.value,
                })
                : v)
        this.setState({ items });
        this.put(items.find(v => v.id === id)!)
    }

    sort(column: string) {
        const items = this.state.items.sort((a: any, b: any) => {

            let x = a[column]
            let y = b[column]
            if (column === 'isPrimary') {
                y = this.state.primaryList.some(p => p.destinationCode === a.destinationCode) && a.isPrimary && a.isMapped && !a.isDrop ? 3 : !a.isPrimary && a.isMapped && !a.isDrop ? 2 : 1;
                x = this.state.primaryList.some(p => p.destinationCode === b.destinationCode) && b.isPrimary && b.isMapped && !b.isDrop ? 3 : !b.isPrimary && b.isMapped && !b.isDrop  ? 2 : 1;
            }
            if (typeof y === "string") {
                y = y.toLowerCase().trim()
                x = x.toLowerCase().trim()
            }
            if (x === y) {
                return 0;
            }
            else if (x === null) {
                return 1;
            }
            else if (y === null) {
                return -1;
            }
            else if (this.state.sort) {
                return x < y ? -1 : 1;
            }
            else {
                return x < y ? 1 : -1;
            }
        })
        this.setState({ sort: !this.state.sort, sortColumn: column, items });
    }

    mappedCheck(check: string) {
        if (check === 'notMapped') {
            const notMapped = !this.state.notMapped
            this.setState({ notMapped })
        } else if (check === 'mapped') {
            const mapped = !this.state.mapped
            this.setState({ mapped })
        } else if (check === 'dropped') {
            const dropped = !this.state.dropped
            this.setState({ dropped })
        }
    }

    primarySelectionModalShowIfHasCode = (repeatedCode: string | undefined, canCancel: boolean) => {
        if (!repeatedCode) {
            alert('Please specify a destination code in order to set a primary record.');
        } else {
            this.primarySelectionModalShow(repeatedCode, canCancel);
        }
    }

    primarySelectionModalShow = (repeatedCode: string, canCancel: boolean) => {
        this.setState({
            modal: true,
            canCancel: canCancel,
            repeatedCode,
        })
        this.getByDestinationCodes(repeatedCode)
    }

    primarySelectionModalHide = () => {
        this.setState({ modal: false })
    }

    handleSelectDestination = (i: number) => {
        const itemsByDestinationCodes = this.state.itemsByDestinationCodes
        itemsByDestinationCodes.map((item, index) => {
            if (index === i) {
                item.isPrimary = true
            } else {
                item.isPrimary = false
            }
            return item
        })
        this.setState({ itemsByDestinationCodes })
    }

    putPrimary = () => {
        const codes = this.state.itemsByDestinationCodes
        let num = 0
        codes.forEach(c => c.isPrimary ? num += 1 : null)
        if (num === 1) {
            codes.forEach(code => code.isPrimary ? this.updatePrimary(code.id) : null)
            this.primarySelectionModalHide();
        } else {
            alert('Please only have one Source Code set to primary')
        }
    }

    updatePrimary(id: number) {
        const item = this.state.itemsByDestinationCodes.find(item => item.id === id)!;
        stagingPageSetPrimary(this, this.client)
            .withSource(item.sourceCode!)
            .setPrimary(id)
            .catch(() => { });
    }

    employeePrimary = (item: EmployeeModel) => {
        let string = `${item.sourceCode} - ${item.sourceDescription}`;
        if (item.isProd || item.isRep || item.isTeleMarketer || item.isOther) { string = string.concat(" - Type: ") }
        if (item.isProd) {
            string = string.concat("Producer");
            if (item.isRep) {
                string = string.concat("/CSR");
            }
            if (item.isTeleMarketer) {
                string = string.concat("/Sales Rep");
            }
            if (item.isOther) {
                string = string.concat("/Other");
            }
        } else if (item.isRep) {
            string = string.concat("CSR");
            if (item.isTeleMarketer) {
                string = string.concat("/Sales Rep");
            }
            if (item.isOther) {
                string = string.concat("/Other");
            }
        } else if (item.isTeleMarketer) {
            string = string.concat("Sales Rep");
            if (item.isOther) {
                string = string.concat("/Other");
            }
        } else if (item.isOther) {
            string = string.concat("Other");
        }
        if (item.email) string = string.concat(` - e: ${item.email}`);
        if (item.email2) string = string.concat(` - e: ${item.email2}`);
        if (item.businessNumber) string = string.concat(` - B#: ${item.businessNumber}`);
        if (item.mobileNumber) string = string.concat(` - M#: ${item.mobileNumber}`);
        if (item.residenceNumber) string = string.concat(` - H#: ${item.residenceNumber}`);
        if (item.address1) string = string.concat(` - ${item.address1}`);
        if (item.address2) string = string.concat(` ${item.address2}`);
        if (item.address3) string = string.concat(` ${item.address3}`);
        if (item.city) string = string.concat(` ${item.city}, ${item.state} ${item.postalCode}`);
        return string
    }

    zero = () => {
        this.setState({ zero: !this.state.zero })
    }

    lockButton = (lock: boolean) => {
        this.setState({ buttonLock: lock })
    }

    DestinationCodes = (): any[] => {
        const arr = [];
        const dc = this.state.destinationCodes;
        if (dc.length > 0) {
            for (let i = 0; i < dc.length; i++) {
                const name = dc[i].firstName === null ? dc[i].lastName : `${dc[i].firstName} ${dc[i].lastName}`
                arr.push(<option
                    key={i}
                    data-value={dc[i].sourceCode?.toUpperCase()}
                >
                    {`${dc[i].sourceCode!.trim()} - ${name}`}
                </option>)
            }
        } else {
            arr.push(<option data-value={'readOnly'}> No Employees Available in Target</option>)

        }
        return arr;
    }

    handleChangeDropDown = (item: EmployeeModel, e: any) => {
        const i = this.state.items.findIndex(v => v.id === item.id);
        const items = this.state.items.map(
            v => v.id === item.id
                ? new EmployeeModel({
                    ...v,
                    destinationCode: e.value
                })
                : v
        )
        this.setState({ items });
        this.put(items[i])
    }

    UIController = () => {
        this.conversionClient.getUIController()
            .then((response) => {
                if (response) this.setState({ includeAddToMain: response!.addToMainEpic })
            })
            .catch(err => console.log(err))
    }

    showDropDown = (employee: EmployeeModel) => {
        const items = this.state.items.map(item => {
            return employee.id === item.id
                ? new EmployeeModel({
                    ...item,
                    isAddToMain: false,
                    destinationCode: '',
                })
                : item;
        })
        this.setState({ items })
    }

    addToMain = (employee: EmployeeModel) => {
        const items = this.state.items.map(item => {
            return employee.id === item.id
                ? new EmployeeModel({
                    ...item,
                    isAddToMain: true,
                    destinationCode: '',
                })
                : item;
        })
        this.setState({ items })
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;
        const zero = this.state.zero ? "Show All" : "Hide 0 Rows"
        const items = this.state.items
            .map(AssignRowStatus)
            .filter(i => (i.sourceCode && i.sourceCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.sourceDescription && i.sourceDescription!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.destinationCode && i.destinationCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())))
            .filter(item =>
                (item.mapped === RowStatus.NotMapped && this.state.notMapped)
                || (item.mapped === RowStatus.Mapped && this.state.mapped)
                || (item.mapped === RowStatus.Dropped && this.state.dropped))
            .filter(item => item.clientCount > 0 || item.lobCount > 0 || !this.state.zero || item.sourceCode!.includes('Default'));

        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='employees'
                    handleSearch={this.handleSearch}
                    primaryList={this.state.primaryList}
                    getItems={() => this.getEmployees()}
                    handleCopyMapping={this.copyMappings.submitMappings}
                    copyMap={true}
                    autoMap={true}
                    filter={this.state.filter}
                    lockButton={this.lockButton}
                />
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th onClick={() => this.sort('sourceCode')} className='employeeRow'>Source Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('sourceDescription')} className='employeeRow'>Description<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isDrop')} className='checkbox-column employeeRow'>Exclude<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('lobCount')} className='checkbox-column employeeRow'>Policy Count<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('clientCount')} className='checkbox-column employeeRow'>Client Count<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('destinationCode')} className='destination employeeRow'>Destination Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isRep')} className='checkbox-col employeeRow'>CSR<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isProd')} className='checkbox-col employeeRow'>Producer<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isTeleMarketer')} className='checkbox-column employeeRow'>Sales Rep<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isOther')} className='checkbox-column employeeRow'>Other<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('status')} className='checkbox-column employeeRow'>Status<i className="fa fa-sort"></i></th>
                            {this.state.includeAddToMain && <th onClick={() => this.sort('isPrimary')} className='checkbox-column employeeRow'>Primary<i className="fa fa-sort"></i></th>}
                            <th className='checkbox-column'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Mapped
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={!this.state.notMapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('notMapped')}>&#x274C; Not Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.mapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('mapped')}>&#x2705; Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.dropped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('dropped')}>&#x2796; Excluded</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                            <th className='merge-name-toggle'><label className="switch"><input type="checkbox" readOnly checked={this.state.zero} onClick={this.zero} /> <span className="slider round"></span></label>{zero}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginate(items, this.state.pagination, (item, i) => (
                                <DataRow id={item.id.toString()} key={item.id}>
                                    <DataCell>{item.sourceCode}</DataCell>
                                    <DataCell>{item.sourceDescription}</DataCell>
                                    <DataCell className='checkbox-column'>
                                        <input className='custom-checkbox'
                                            type='checkbox'
                                            name='isDrop'
                                            checked={item.isDrop}
                                            onChange={e => this.handleCheckboxChange(item.id, e)}
                                            disabled={this.state.buttonLock}
                                        />
                                    </DataCell>
                                    <DataCell className='center-aligning'>{!item.sourceCode!.includes('Default') && item.lobCount}</DataCell>
                                    <DataCell className='center-aligning'>{!item.sourceCode!.includes('Default') && item.clientCount}</DataCell>
                                    <DataCell className='addToMainContainer destination'>
                                        {this.state.includeAddToMain ?
                                            <>
                                                {!item.isAddToMain ?
                                                    <div className={"addToMainEpic"}>
                                                        <DataListInput
                                                            id={`referenceId - ${i}`}
                                                            name={item.id.toString()}
                                                            value={item.destinationCode ? item.destinationCode.toUpperCase() : undefined}
                                                            onValueChange={event => this.handleChangeDropDown(item, event)}
                                                            disabled={this.state.buttonLock}
                                                        >
                                                            <option data-value='' />
                                                            {this.DestinationCodes()}
                                                        </DataListInput>
                                                    </div>
                                                    :
                                                    <Input className="addToMainEpic"
                                                        value={item.destinationCode}
                                                        name={item.id.toString()}
                                                        onChange={this.handleChange}
                                                        onFocus={(e) => (this.setState({ lastValue: e.target.value.trim() }))}
                                                        onBlur={() => { if (item.destinationCode !== this.state.lastValue) this.put(item) }}
                                                        maxLength={6}
                                                        autoComplete="off"
                                                        placeholder={"Add to Main - 6 max"}
                                                        disabled={this.state.buttonLock} />}
                                                {item.sourceCode !== 'Default' &&
                                                    <DataTableButton
                                                        icon={item.isAddToMain ? 'list' : 'edit'}
                                                        id={"addToMain"}
                                                        title={item.isAddToMain ? 'Dropdown List' : 'Add To Main'}
                                                        action={() => item.isAddToMain ? this.showDropDown(item) : this.addToMain(item)}
                                                    />}
                                            </>
                                            :
                                            <DataListInput
                                                id={`referenceId - ${i}`}
                                                name={item.id.toString()}
                                                value={item.destinationCode ? item.destinationCode.toUpperCase() : undefined}
                                                onValueChange={event => this.handleChangeDropDown(item, event)}
                                                disabled={this.state.buttonLock}
                                            >
                                                <option data-value='' />
                                                {this.DestinationCodes()}
                                            </DataListInput>}
                                    </DataCell>
                                    <DataCell className='checkbox-column'>
                                        <input className='custom-checkbox'
                                            type='checkbox'
                                            name='isRep'
                                            checked={item.isRep || false}
                                            onChange={e => this.handleCheckboxChange(item.id, e)}
                                            disabled={this.state.buttonLock}
                                        />
                                    </DataCell>
                                    <DataCell className='checkbox-column'>
                                        <input className='custom-checkbox'
                                            type='checkbox'
                                            name='isProd'
                                            checked={item.isProd || false}
                                            onChange={e => this.handleCheckboxChange(item.id, e)}
                                            disabled={this.state.buttonLock}
                                        />
                                    </DataCell>
                                    <DataCell className='checkbox-column'>
                                        <input className='custom-checkbox'
                                            type='checkbox'
                                            name='isTeleMarketer'
                                            checked={item.isTeleMarketer || false}
                                            onChange={e => this.handleCheckboxChange(item.id, e)}
                                            disabled={this.state.buttonLock}
                                        />
                                    </DataCell>
                                    <DataCell className='checkbox-column'>
                                        <input className='custom-checkbox'
                                            type='checkbox'
                                            name='isOther'
                                            checked={item.isOther || false}
                                            onChange={e => this.handleCheckboxChange(item.id, e)}
                                            disabled={this.state.buttonLock}
                                        />
                                    </DataCell>
                                    <DataCell>
                                        <select
                                            className="custom-select"
                                            name='status'
                                            value={item.status || this.statuses[0].id}
                                            onChange={e => this.handleValueChange(item.id, e)}
                                            disabled={this.state.buttonLock}
                                        >
                                            {
                                                this.statuses.map(status => (
                                                    <option
                                                        key={status.id}
                                                        value={status.id}
                                                    >
                                                        {status.value}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </DataCell>
                                    {this.state.includeAddToMain && <DataCell className='checkbox-column' itemProp={this.state.primaryList.some(p => p.destinationCode!.toUpperCase() === item.destinationCode!.toUpperCase()) && !item.isDrop
                                        ? item.isPrimary && item.isMapped
                                            ? 'primary'
                                            : 'non-primary' : ''}>
                                        <DataTableButton
                                            id={item.id + 'primary'}
                                            disabled={this.state.buttonLock}
                                            action={() => this.primarySelectionModalShowIfHasCode(item.destinationCode, true)}
                                            title='Click to set a primary record'
                                        >
                                            {item.isAddToMain ? this.state.primaryList.some(p => p.destinationCode!.toUpperCase() === item.destinationCode!.toUpperCase()) && !item.isDrop
                                                ? item.isPrimary && item.isMapped
                                                    ? <span className='mapped' itemProp='primary'>&#x2705;</span>
                                                    : <span className='mapped' itemProp='non-primary'>&#x274C;</span> : null: null}
                                        </DataTableButton>
                                    </DataCell>}
                                    <DataCell className='checkbox-column' itemProp={item.mapped === RowStatus.NotMapped ? 'unmapped' : item.mapped === RowStatus.Mapped ? 'mapped' : 'dropped'}>
                                        {item.mapped === RowStatus.NotMapped ? <span className='mapped' itemProp='unmapped'>&#x274C;</span> : item.mapped === RowStatus.Mapped ? <span className='mapped' itemProp='mapped'>&#x2705;</span> : <span className='mapped' itemProp='dropped'>&#x2796;</span>}
                                    </DataCell>
                                    <DataCell className='merge-name-toggle'>
                                    </DataCell>
                                </DataRow>
                            ))
                        }
                    </tbody>
                </DataTable>
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
                <div>
                    <Modal isOpen={this.state.modal}>
                        <ModalHeader>Please Set Primary of Destination: {this.state.repeatedCode}</ModalHeader>
                        <ModalBody>
                            <select
                                name='isPrimary'
                                className="custom-select"
                                onChange={(e) => this.handleSelectDestination(Number(e.target.value))}
                                id={this.state.repeatedCode.toString()}
                                key={this.state.repeatedCode}
                            >
                                {this.state.itemsByDestinationCodes.map((item, index) => (
                                    <option
                                        value={index}
                                        key={item.id}
                                        selected={item.isPrimary}
                                    >
                                        {this.employeePrimary(item)}
                                    </option>
                                ))}
                            </select>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.canCancel && <Button color="secondary" onClick={() => this.primarySelectionModalHide()}>Close</Button>}
                            <Button color="primary" onClick={this.putPrimary}>Submit</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}
