import * as Authentication from './Authentication';
import * as Notifications from './Notifications';
import * as Requests from './Requests'
import * as Boolean from './Boolean';
import * as Select from './Select';


// The top-level state object
export interface ApplicationState {
    authentication: Authentication.AuthenticationState | undefined,
    notifications: Notifications.NotificationsState,
    requests: Requests.RequestsState,
    boolean: Boolean.BooleanState,
    boolAction: Boolean.UpdateBooleanAction,
    select: Select.SelectState,
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    authentication: Authentication.reducer,
    notifications: Notifications.reducer,
    requests: Requests.reducer,
    boolean: Boolean.reducer,
    select : Select.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
