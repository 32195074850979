/*
    Expose Parent Conversion IDs used for Mini Conversions
        SELECT DISTINCT [ParentConversionId]
        FROM [MCU].[Conversions]
        WHERE [ConversionTypeId] = (
            SELECT [ConversionTypeId] FROM [MCU].[ConversionTypes] WHERE [Description] LIKE 'Mini%'
        )
*/
export const parentConversionId_CustFileOnly = '83EE38A0-125B-4D86-BA36-597D3A758C47';
export const parentConversionId_CustAndPolFiles = '2D492FFA-2EC2-4524-851A-DD326C963C74';

/*
    Expose Conversion Options IDs used for Mini Conversions
        SELECT [Id]
        FROM [MCU].[ConversionsOptions]
        WHERE [ConversionTypeId] = (
            SELECT [ConversionTypeId] FROM [MCU].[ConversionTypes] WHERE [Description] LIKE 'Mini%'
        )
*/
export const conversionOption_CustFileSelectedId = 'CBF8062B-1AC6-4A6C-BBC4-FFAA84C8E9E3';   // Customers File Selected for Mini Conversion
export const conversionOption_CustFileUploadedId = '64CA8357-FE35-41D3-9D72-C1D62FC65706';   // Customers File Uploaded for Mini Conversion
export const conversionOption_CustFileValidatedId = '41F4E7B5-C04C-40C5-BBB1-5986127654E9';  // Customers File Validated for Mini Conversion
export const conversionOption_CustFileQueuedId = '09969F04-45AE-48D0-8C3D-72B6FB43C728';     // Customers File Queued (submitted for processing) for Mini Conversion
export const conversionOption_PolFileSelectedId = 'D0DB3133-B494-43EE-A358-7016DFCF1A09';    // Policies File Selected for Mini Conversion
export const conversionOption_PolFileUploadedId = 'D3EF83EC-E2E3-411F-85B3-0D420D6FDFF3';    // Policies File Uploaded for Mini Conversion
export const conversionOption_PolFileValidatedId = 'CF903A63-5DA5-46CF-92E5-C0E66824FE3D';   // Policies File Validated for Mini Conversion
export const conversionOption_PolFileQueuedId = '40F9A9AF-0BB1-4A94-8827-F857D372FFF9';      // Policies File Queued (submitted for processing) for Mini Conversion
