import * as React from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import * as Clients from 'client/ApiClient';

import { MaterialIcon } from 'components/basic/Icon';
import { RangePagination } from 'components/basic/Pagination';

import DynamicDataTable from 'components/basic/DynamicDataTable';
import Toolbar from "components/basic/Toolbar";
import * as SourceSystem from 'constants/SourceSystem';

type DynamicDataTableState = {
    records: Clients.DynamicEntityListModel;
    sourceRecords: Clients.DynamicEntityListModel;
    users: Clients.UserModel[];
    ref: Clients.MergeMapRefModel[];
    conversion: Clients.ConversionModel | undefined;
    conversionType: string;
    isError: boolean;

    pageSize: number;
    pageNumber: number;

    sortColumn: number | undefined;
    sortDirection: Clients.SortDirection;

    filter: string;
};

export type DynamicDataTableProps = {
    conversionId: string;
    sectionId: string;
    source: 'stage';
    columnTake: number;
    children?: React.ReactNode;
};

export default class ADMTable extends React.Component<DynamicDataTableProps, DynamicDataTableState> {
    private readonly client = new Clients.GenericClient();
    private readonly users = new Clients.UserClient();
    private readonly merge = new Clients.MergeMapClient();
    private conversion = new Clients.ConversionClient();
    private readonly mergeNames =
        [{ mergeName: 'divisionmerge', entity: 'Divisions' },
        { mergeName: 'branchmerge', entity: 'Branches' },
        { mergeName: 'departmentmerge', entity: 'Departments' },
        { mergeName: 'groupmerge', entity: 'Groups' },
        { mergeName: 'brokersmerge', entity: 'Brokers' },
        { mergeName: 'companymerge', entity: 'Companies' },
        { mergeName: 'employeemerge', entity: 'Employees' },
        { mergeName: 'kindoflossmerge', entity: 'KindsOfLoss' },
        { mergeName: 'lineofbusinessmerge', entity: 'LineOfBusiness' },
        { mergeName: 'profilequestions', entity: 'ProfileQuestion' }];

    constructor(props: Readonly<DynamicDataTableProps>) {
        super(props);
        this.state = {
            records: new Clients.DynamicEntityListModel({ count: 0 }),
            sourceRecords: new Clients.DynamicEntityListModel({ count: 0 }),
            users: [],
            ref: [],
            conversion: undefined,
            conversionType: '',
            isError: false,

            pageSize: 50,
            pageNumber: 0,

            sortColumn: undefined,
            sortDirection: Clients.SortDirection.None,

            filter: '',
        }
    }

    componentDidMount() {
        this.currentConversion();
    }

    componentDidUpdate(prevProps: DynamicDataTableProps, prevState: DynamicDataTableState) {
        if (this.props.sectionId !== prevProps.sectionId
            && (this.state.sortColumn !== undefined
                || this.state.filter !== '')) {
            // Setting defaults.
            this.setState({
                sortColumn: undefined,
                filter: '',
            })
        } else if (this.props.sectionId !== prevProps.sectionId
            || this.props.source !== prevProps.source
            || this.props.conversionId !== prevProps.conversionId
            || this.state.pageNumber !== prevState.pageNumber
            || this.state.pageSize !== prevState.pageSize
            || this.state.sortColumn !== prevState.sortColumn
            || this.state.sortDirection !== prevState.sortDirection
            || this.state.filter !== prevState.filter
        ) {
            if (this.state.conversionType === 'merge') {
                this.getMergeRef();
            } else {
                this.fetchRecords();
            }
        }
    }

    currentConversion = () => {
        this.conversion.get(this.props.conversionId)
            .then(response => {
                const conversionType = response!.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id ? 'merge' : 'epic'
                this.setState({ conversion: response!, conversionType }, () => {
                    this.getUsers();
                })
            })
            .catch(error => { })
    }

    getUsers = () => {
        this.users.getAll()
            .then(users =>
                users && this.setState({ users }, () => {
                    if (this.state.conversionType === 'merge') {
                        this.getMergeRef();
                    } else {
                        this.fetchRecords();
                    }
                }))
            .catch(err => console.log(err))
    }

    getMergeRef = () => {
        const params = this.props
        const filterName = this.mergeNames.filter(m => m.entity === params.sectionId)
        const routeName = filterName.length > 0 ? filterName[0].mergeName : params.sectionId.toLowerCase()
        this.merge.getRef(params.conversionId, routeName)
            .then((response) => {
                let ref = response!;
                this.setState({ ref }, () => {
                    this.fetchRecords();
                })
            })
            .catch(error => { })
    }

    fetchRecords = () => {
        const take = this.state.pageSize;
        const offset = this.state.pageSize * this.state.pageNumber;
        const sortColumn = (this.state.sortColumn !== undefined
            && this.state.records.schema!.columns![this.state.sortColumn].id) || '';
        this.client.getStageAll(
            this.props.conversionId,
            `[ADM].[${this.props.sectionId}View]`,
            this.props.columnTake,
            take,
            offset,
            sortColumn,
            this.state.sortDirection,
            this.state.filter)
            .then(r => {
                if (r) {
                    this.setState({
                        records: r,
                        isError: false,
                    })
                }
            })
            .catch(error => {
                if (error instanceof Clients.ApiException && error.status === 404) {
                    this.setState({
                        // A stored procedure for the entity has not been registered.
                        isError: true,
                    })
                } else {
                    this.setState({ records: new Clients.DynamicEntityListModel() })
                    console.log({
                        message: `error getting dynamic records`,
                        source: this.props.source,
                        conversionId: this.props.conversionId,
                        sectionId: this.props.sectionId,
                        error: error,
                    })
                }
            });
    }

    render() {
        if (this.state.isError) {
            return <MaterialIcon className='dynamic-data-table-not-found'>block</MaterialIcon>;
        }

        const arr: (string | undefined)[] = [];
        if (this.props.source === 'stage' && this.state.records.schema) {
            this.state.records.schema!.columns!.forEach((v, i: number) => {
                if (v.name !== "Stage Guid Pk") {
                    arr.push(v.id)
                }
            })
        }
        const columns = arr;

        const count = this.state.sourceRecords.count > this.state.records.count ? this.state.sourceRecords.count : this.state.records.count;

        return (
            <>
                <Toolbar>
                    <InputGroup>
                        <React.Fragment>
                            <Input placeholder='Search'
                                name='filter'
                                onChange={e => this.setState({ filter: e.target.value })}
                                value={this.state.filter}
                            />
                            <InputGroupAddon addonType='append' className='material-icon'>
                                <Button onClick={() => this.setState({ filter: '' })} > close</Button>
                            </InputGroupAddon>
                        </React.Fragment>
                    </InputGroup>
                    {this.props.children}
                </Toolbar>
                <h5 className='mapping-body-header'>[ADM].[{this.props.sectionId}] Table </h5>
                <DynamicDataTable
                    records={this.state.records}
                    columns={columns}
                    sorting={{
                        sortColumn: this.state.sortColumn,
                        sortDirection: this.state.sortDirection,
                        onSort: (sortColumn, sortDirection) => this.setState({
                            sortColumn: sortColumn,
                            sortDirection: sortDirection,
                        })
                    }}
                    sectionId={this.props.sectionId}
                    ref="table"
                />
                <RangePagination
                    pageNumber={this.state.pageNumber}
                    pageSize={this.state.pageSize}
                    recordsCount={count}
                    onChangeIndex={index => this.setState({ pageNumber: index })}
                />
            </>
        )
    }
}