import * as _ from 'lodash';

export const handleCheckedChange = (id: any, event: { target: {name: any, checked: any}}) => {
    const name = event.target.name
    const value = event.target.checked

    return (state: any) => {
        return { items: changeArrayItem(state, id, name, value) }
    }
}

export const handleValueChange = (id: any, event: { target: { name: any, value: any } }) => {
    const name = event.target.name
    const value = event.target.value

    return (state: any) => {
        return { items: changeArrayItem(state, id, name, value) }
    }
}

function changeArrayItem(state: any, id: any, name: string, value: any): any {
    const index = state.items.findIndex((i: { id: any; }) => i.id === id);
    const newItem = _.cloneDeep(state.items[index])
    _.set(newItem, name, value)

    let newItems = _.clone(state.items)
    newItems[index] = newItem

    return newItems
}