import { paginate, RangePagination, RangePaginationState } from 'components/basic/Pagination';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PolicyDetailsEquipmentFloaterClient, PolicyDetailsEquipmentFloaterModel } from '../../../../../client/ApiClient';
import '../../../../../styles/Icons.scss';
import { DataCell, DataRow, DataTable, DataTableButton } from '../../../../basic/DataTable';
import { MaterialIcon } from '../../../../basic/Icon';
import { handleValueChange } from '../generic/Handlers';
import StageActionToolbar from '../generic/StageActionToolbar';


interface PolicyDetailsEquipmentFloaterStagingPageArgs {
    conversionId: string,
}

type PolicyDetailsEquipmentFloaterStagingPageProps = RouteComponentProps<PolicyDetailsEquipmentFloaterStagingPageArgs>;

interface PolicyDetailsEquipmentFloaterStagingPageState {
    items: PolicyDetailsEquipmentFloaterModel[];
    destinationCodes: any;
    filter: string;

    pagination: RangePaginationState;
}

export class PolicyDetailsEquipmentFloaterStagingPage extends React.Component<PolicyDetailsEquipmentFloaterStagingPageProps, PolicyDetailsEquipmentFloaterStagingPageState>{
    private client: PolicyDetailsEquipmentFloaterClient = new PolicyDetailsEquipmentFloaterClient()

    constructor(props: PolicyDetailsEquipmentFloaterStagingPageProps) {
        super(props)
        this.state = {
            items: [],
            destinationCodes: [],
            filter: '',

            pagination: {
                pageSize: 25,
                pageNumber: 0,
            }
        }
    }

    componentDidMount() {
        //this.client.get(this.props.match.params.conversionId, '')
        //    .then((response) => {
        //        this.setState({
        //            items: response!
        //        })
        //    })
        //    .catch(error => { })
    }

    put(id: number) {
        const entity = this.state.items.find(item => item.id === id)

        this.client.put(this.props.match.params.conversionId, id, entity!)
            .then(() => {
            })
            .catch(error => { })
    }

    handleSearch = (filterString: string) => {
        this.setState({
            filter: filterString
        })
        this.client.get(this.props.match.params.conversionId, filterString)
            .then(response => {
                this.setState({
                    items: response!
                })
            })
            .catch(error => { })
    }

    dataTable = (items: PolicyDetailsEquipmentFloaterModel[]) => {
        return < DataTable >
            <thead>
                <tr className='nonFullWidthRow'>
                    <th>Source Code</th>
                    <th>Source Description</th>
                    <th>Destination Code</th>
                    <th className='checkbox-column'>Is Mapped</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    paginate(items, this.state.pagination, (item, index) => (
                        <DataRow id={item.id.toString()} key={item.id}>
                            <DataCell>{item.sourceCode}</DataCell>
                            <DataCell>{item.sourceDescription}</DataCell>
                            <DataCell>
                                <select
                                    className="custom-select"
                                    name='isCompany'
                                    value={item.destinationCode}
                                    onChange={event => { this.setState(handleValueChange(item.id, event)) }}
                                >
                                    {
                                        this.state.destinationCodes.map((code: any) => (
                                            <option
                                                key={code.id}
                                                value={code.id}
                                            >
                                                {code.value}
                                            </option>
                                        ))
                                    }
                                </select>
                            </DataCell>
                            <DataCell className='checkbox-column'>
                                {item.destinationCode &&
                                    <MaterialIcon className='is-mapped-icon'>done</MaterialIcon>
                                }
                            </DataCell>
                            <DataCell>
                                <DataTableButton icon='save' id={item.id.toString() + 'button'} title='Save'
                                    action={id => this.put(item.id)} />
                            </DataCell>
                        </DataRow>
                    ))
                }
            </tbody>
                </DataTable >

        
    }

    pagination = (items: PolicyDetailsEquipmentFloaterModel[]) => {
        return <RangePagination
            pageNumber={this.state.pagination.pageNumber}
            pageSize={this.state.pagination.pageSize}
            recordsCount={items.length}
            onChangeIndex={index => this.setState({
                pagination: {
                    ...this.state.pagination,
                    pageNumber: index,
                }
            })}
        />
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;
        //const items = this.state.items;
        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='equipmentfloater'
                    tierGate='tier4'
                    noMapping={true}
                    filter={this.state.filter}
                />
                {
                    //this.dataTable(items)
                    //this.pagination(items)
                }
                
                
            </>
        );
    }
}