import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, Form, Label, Container, Row, Col, Button, Collapse, CardBody, Card } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { DataTableButton } from 'components/basic/DataTable';

import * as _ from 'lodash';

import * as Routes from 'services/Routes';
import { promptAgainstState } from 'helpers/ChangeTracker';

import * as Clients from '../../../client/ApiClient';
import * as Controls from '../../basic/Form';
import ConfirmationModalOptions from 'components/basic/modals/ConfirmationModalOptions';
import { toolTips } from './OptionTooltips';
import '../../../styles/Forms.scss';
import './Common.scss';
import { store } from "AppContext"
import { enqueueNotification } from "../../../store/Notifications"
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import * as SourceSystem from 'constants/SourceSystem';

import { ConversionEditAndOptionsPageProps } from '../../../helpers/ChangeTracker';

//interface OptionsPageProps extends RouteComponentProps<{
//    conversionId: string,
//}> {
//    refreshPage?: boolean,
//}

class Options {
    values: Clients.ConversionOptionEnumModel[] | undefined;
    id!: string;
    name: string | undefined;
    defaultValue: string | undefined;
    type: Clients.ConversionOptionType | undefined;
    isHidden: boolean | undefined;
    isRequired: boolean | undefined;
    conversionTypeId: string | undefined;
    order!: number;
    tooltip?: string[] | undefined;
}
type OptionsPageState = {
    options: Clients.ConversionOptionModel[]
    originals: { [key: string]: Clients.ConversionOptionValueModel }
    values: DynamicValues
    // Initial values to compare with current to determine if
    // values have been changed.
    initial: {
        values: DynamicValues,
    }
    validations: Controls.ValidationResultList,
    conversion: Clients.ConversionModel,
    customizations: Clients.OptionCustomizationModel[],
    confirmationModal: boolean,
    isOpen: string,
    buttonLock: boolean,
    includeBranch: string,
    includeGroup:string,
};

type DynamicValues = { [key: string]: string | undefined };

class OptionsPage extends React.Component<ConversionEditAndOptionsPageProps, OptionsPageState>{
    private readonly client: Clients.ConversionOptionsClient = new Clients.ConversionOptionsClient();
    private conversionClient: Clients.ConversionClient = new Clients.ConversionClient();
    private executions: Clients.ExecutionsClient = new Clients.ExecutionsClient();
    private target: Clients.GenericClient = new Clients.GenericClient();

    constructor(props: Readonly<ConversionEditAndOptionsPageProps>) {
        super(props);
        this.state = {
            options: [],
            originals: {},
            values: {},
            initial: {
                values: {},
            },
            validations: new Controls.ValidationResultList(),
            conversion: new Clients.ConversionModel(),
            customizations: [],
            confirmationModal: false,
            isOpen: '',
            buttonLock: true,
            includeBranch: '',
            includeGroup: '',
        }
    }
    componentDidMount() {
        this.getOptions();
        this.buttonLock();
        this.getAgency();
        this.getClients();
    }

    componentDidUpdate(prevProps: ConversionEditAndOptionsPageProps) {
        if (this.props.refreshPage) {
            this.getOptions();
        }
        if (this.props.location !== prevProps.location) {
            this.componentDidMount()
        }
    }

    getOptions = () => {
        const conversionId = this.props.match.params.conversionId;
        const options = this.client.getSchema(conversionId)
            .then(options => {
                this.setState({
                    options: options!,
                });
            });
        const values = this.client.get(conversionId)
            .then(values => {
                const originals = values!.reduce(
                    (result, current) => ({ ...result, [current.id!]: current, }), {});
                this.setState({
                    originals: originals,
                });
            });
        const customizations = this.client.getCustomizations(conversionId);
        Promise.all([options, values, customizations])
            .then(([optionsResult, valuesResult, customizationsResult]) => {
                // Store only these values, which will be displayed on the UI, so
                // the conversion won't be PATCHed with values, which user doesn't see.
                const values = this.state.options.reduce(
                    (v, cur) => {
                        const original = this.state.originals[cur.id];
                        return original ? { ...v, [cur.id]: original.value, } : v;
                    }, {});

                this.setState({
                    values,
                    initial: {
                        values: _.cloneDeep(values),
                    },
                    customizations: customizationsResult!,
                }, () => {
                    if (Object.keys(this.state.values).length > 0) {
                        const values = this.fillMissingFields(this.state.options, this.state.values);
                        const validations = this.validate(this.state.options, values)
                        this.setState({ validations, values });
                    }
                });
            })
            .catch(error => { })
    }

    getClients = () => {
        const conversionId = this.props.match.params.conversionId;
        this.conversionClient.get(conversionId)
            .then(results => {
                if (results !== null) this.setState({ conversion: results })
            })
            .catch(error => {
                console.log(error)
            })
    }

    deriveState = (id: string, value: any) => {
        let values = _.clone(this.state.values);
        values[id] = value;
        this.setState({
            values: values,
            validations: this.validate(this.state.options, values),
        });
    }

    private applyCustomizations = (option: Clients.IConversionOptionModel, values: DynamicValues) => {
        for (let i = 0; i < this.state.customizations.length; i++) {
            const customization = this.state.customizations[i];
            switch (customization.kind) {
                case Clients.OptionCustomizationKind.MakeNotRequiredIfZero:
                    if (option.id === customization.details.UpdateOptionId) {
                        const valueOptionId = customization.details.ValueOptionId;
                        if (valueOptionId in values && values[valueOptionId] === '0') {
                            return {
                                ...option,
                                isRequired: false,
                            }
                        }
                    }
                    break;
                default:
                    throw new Error(`Unexpected customization kind ${customization.kind}.`);
            }
        }
        return option;
    }

    validate = (options: Clients.ConversionOptionModel[], values: DynamicValues): Controls.ValidationResultList => {
        const results: Controls.ValidationResultList = new Controls.ValidationResultList();
        for (let i = 0; i < options.length; i++) {
            const option = this.applyCustomizations(options[i], values);
            if (!option.isRequired) {
                // Validation is not required.
                continue;
            }
            let value: string | undefined;
            if (option.id in values) {
                value = values[option.id]
            } else {
                // Don't validate a field, which hasn't been touched.
                continue;
            }
            if (value === null || value === undefined || value === '') {
                results.add(option.id, { message: 'The field is required' });
            }

            if (option.name === "Translate Group By" && ((this.state.includeGroup === 'Y' && value === 'N') || (this.state.includeGroup === 'N' && value !== 'N'))) {
                results.add(option.id, { message: 'Business Unit Option needs to match target' });
            }

            if (option.name === "Translate Branch By" && ((this.state.includeBranch === 'Y' && value === 'N') || (this.state.includeBranch === 'N' && value !== 'N'))) {
                results.add(option.id, { message: 'Business Unit Option needs to match target' });
            }
        }
        return results;
    }

    fillMissingFields(options: Clients.ConversionOptionModel[], values: DynamicValues): DynamicValues {
        const result: DynamicValues = _.clone(values);
        for (let i = 0; i < options.length; i++) {
            const option = options[i];
            if (!(option.id in result)
                && !option.isHidden
                && option.conversionTypeId === this.state.conversion.conversionTypeId) {
                result[option.id] = undefined;
            }
        }
        return result;
    }

    submit = () => {
        const values = this.fillMissingFields(this.state.options, this.state.values);
        const validations = this.validate(this.state.options, values)

        this.setState({
            validations: validations,
            values: values,
        });

        if (!validations.isValid()) {
            return;
        }

        const models = _.map(values, (v, k) => {
            let rowVersion: string | undefined;
            if (k in this.state.originals) {

                rowVersion = this.state.originals[k].rowVersion;
            }
            return Clients.ConversionOptionValueModel.fromJS({
                id: k,
                conversionId: this.props.match.params.conversionId,
                value: v,
                rowVersion: rowVersion,
            });
        });

        this.client.update(this.props.match.params.conversionId, models)
            .then(() => this.setState({
                initial: {
                    values: _.cloneDeep(this.state.values),
                },
                confirmationModal: true,
            }))
            .then(() => store.dispatch(enqueueNotification({ type: 'success', message: 'Project ' + this.state.conversion.description + ' was saved.' })))
            .catch(() => store.dispatch(enqueueNotification({ type: 'error', message: 'Project ' + this.state.conversion.description + ' was not saved.' })))
    }

    removeConfirmationModal = () => {
        const confirmationModal = false;
        this.setState({ confirmationModal });
    }

    getAgency = () => {
        this.target.getTargetAll(this.props.match.params.conversionId, 'agency', 0, 0, 0, "", 0, "")
            .then(response => {
                if (response) {
                    const columns = response.schema!.columns
                    let groupI = 0;
                    let branchI = 0;
                    for (let i = 0; i < columns!.length; i++) {
                        if (columns![i].id === "IncludeGroup") {
                            groupI = i
                        } else if (columns![i].id === "IncludeBranch") {
                            branchI = i
                        }
                    }
                    const includeBranch = response!.entities![0].values![branchI]
                    const includeGroup = response!.entities![0].values![groupI]
                    this.setState({ includeBranch,includeGroup})
                }
            })
            .catch(err => console.log(err))
    }

    getOptionValue = (id: string) => this.state.values[id] || '';

    createEditorControl = (option: Options): React.ReactNode => {
        let node: React.ReactNode;
        let orginial = (this.state.originals as any)[option.id]
        let locked = orginial ? orginial.isLocked : false;
        locked = this.state.buttonLock
        switch (option.type) {
            case Clients.ConversionOptionType.Text:
                node = <Controls.TrackedInputDataControl
                    id={option.id}
                    tag={Input}
                    value={this.getOptionValue(option.id)}
                    onCommit={this.deriveState}
                    disabled={locked}
                />
                break;
            case Clients.ConversionOptionType.List:
                node = <Controls.InputDataControl
                    id={option.id}
                    tag={Input}
                    type='select'
                    className="custom-select"
                    value={this.getOptionValue(option.id)}
                    onCommit={this.deriveState}
                    disabled={locked}
                >
                    {!_.some(option.values, v => v.value === '') && <option id=''></option>}
                    {option.values && option.values.map(value => {
                        return <option
                            key={value.value}
                            value={value.value}
                        >{value.name}</option>
                    })}
                </Controls.InputDataControl>
                break;
            case Clients.ConversionOptionType.Date:
                node = <Controls.TrackedDateDataControl
                    id={option.id}
                    tag={DatePicker}
                    value={this.getOptionValue(option.id)}
                    onCommit={this.deriveState}
                    disabled={locked}
                />
                break;
            case Clients.ConversionOptionType.Radio:
                node = <React.Fragment>
                    {option.values && option.values.map(value => (
                        <Label className='form-check form-check-inline' key={value.value}>
                            <Controls.InputDataControl
                                id={option.id}
                                name={option.id}
                                tag={Input}
                                type='radio'
                                checked={this.state.values[option.id] === value.value}
                                value={value.value || ''}
                                onCommit={this.deriveState}
                                disabled={locked}
                            />
                            {value.name}
                        </Label>
                    ))}
                </React.Fragment>
                break;
            default:
                throw new Error(`option type ${option.type} is not supported`);
        }
        return (
            <Controls.Validation
                targetId={option.id}
                validations={this.state.validations}>
                {node}
            </Controls.Validation>
        );
    }

    toggle(id: string) {
        if (id !== this.state.isOpen) {
            this.setState({ isOpen: id })
        } else {
            this.setState({ isOpen: '' })
        }
    }

    buttonLock = () => {
        this.executions.listExecutionsAllTierBoolean(this.props.match.params.conversionId)
            .then((response) => {
                if (response) {
                    this.setState({ buttonLock: response.isLocked })
                }
            })
            .catch(error => { })
    }

    public render() {
        const merge = this.state.conversion && this.state.conversion!.conversionTypeId! && this.state.conversion!.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id ? true : false;
        const options: Options[] = this.state.options.map((o) => {
            const tool = toolTips.filter(t => t.name === o.name)[0]
            if (tool) {
                return {
                    values: o.values,
                    id: o.id,
                    name: o.name,
                    defaultValue: o.defaultValue,
                    type: o.type,
                    isHidden: o.isHidden,
                    isRequired: o.isRequired,
                    conversionTypeId: o.conversionTypeId,
                    order: o.order,
                    tooltip: tool.tooltip,
                }
            } else {
                return {
                    values: o.values,
                    id: o.id,
                    name: o.name,
                    defaultValue: o.defaultValue,
                    type: o.type,
                    isHidden: o.isHidden,
                    isRequired: o.isRequired,
                    conversionTypeId: o.conversionTypeId,
                    order: o.order,
                    tooltip: undefined,
                }
            }
        })
        return (
            <React.Fragment>
                <h3 className='mapping-body-header'>Conversion Options - {this.state.conversion.description} </h3>
                <Container>
                    <Form>{
                        options
                            .filter(option => !option.isHidden)
                            .filter(option => option.conversionTypeId === this.state.conversion.conversionTypeId)
                            .sort((a: Options, b: Options) => {
                                const x = a.order
                                const y = b.order
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0
                            })
                            .map(option => (
                                <React.Fragment key={option.id}>
                                    <Row>
                                        <Label md={5} className='text-md-right'>
                                            {option.tooltip &&
                                                <DataTableButton
                                                    onClick={() => { if (option.tooltip !== undefined) this.toggle(option.id) }}
                                                    icon={this.state.isOpen === option.id ? 'expand_less' : 'expand_more'}
                                                    id={option.name+"tooltip"}
                                                    title={"Tooltip: "+option.name} />}
                                            {option.name}</Label>
                                        <Col md={6}>{this.createEditorControl(option)}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={{ size: 8, offset: 3 }}>
                                        <Collapse isOpen={this.state.isOpen === option.id}>
                                                <Card className='cardBackground'>
                                                    <CardBody>
                                                {option.tooltip &&
                                                    option.tooltip.join(" ")}
                                                    </CardBody>
                                        </Card>
                                        <br />
                                            </Collapse>
                                            </Col>
                                    </Row>
                                </React.Fragment>
                            ))
                    }</Form>
                    <Row>
                        <Col md={{ size: 6, offset: 4 }} className="action-col">
                            {
                                this.state.conversion && this.state.conversion.refreshRan && <Button
                                    className='submit-button-orange float-right'
                                    onClick={() => {
                                        merge ?
                                            this.props.history.push(Routes.getMergeMappingLocation(this.props.match.params.conversionId)) :
                                            this.props.history.push(Routes.getMappingLocation(this.props.match.params.conversionId))
                                    }}
                                >Mappings</Button>
                            }
                            <Button
                                className='submit-button-orange float-right'
                                onClick={this.submit}
                                disabled={this.state.buttonLock}
                            >Save Options</Button>

                        </Col>
                    </Row>
                </Container>
                <ConfirmationModalOptions
                    isOpen={this.state.confirmationModal}
                    message={this.state.conversion.description + ' was saved.'}
                    conversionId={this.props.match.params.conversionId}
                    onSubmit={this.removeConfirmationModal}
                />
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(
        (state: ApplicationState) => {
            return {
                currentUser: state.authentication && state.authentication.currentUser,
                currentTabName: state.select && state.select.tabName,
            }
        }
    )(promptAgainstState<
            ConversionEditAndOptionsPageProps,
            OptionsPageState,
            { values: DynamicValues }
        >(
            OptionsPage,
            x => x.values,
            "options",
            "Changes you made with the conversion options will be lost. Do you really want to leave the page?"
        )
    )
);
