import * as React from 'react';
import { Container, Form, FormGroup, Label, Input, Row, Col, Button, InputGroup, InputGroupAddon } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as Roles from 'constants/Roles';

import _ from 'lodash';
import memoize from 'memoize-one';

import * as Clients from 'client/ApiClient';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';

import { DataTable, DataRow, DataCell, DataTableButton } from 'components/basic/DataTable';
import Toolbar from 'components/basic/Toolbar';
import { Validation, ValidationResultList } from 'components/basic/Form';
import { sendNotification } from '../../services/Notifications';
import { authentication } from '../../services/Authentication';
import { SecureContainer } from '../basic/Authentication';
import DeleteConfirmationModal from '../basic/modals/DeleteConfirmationModal';

import 'styles/Forms.scss'

interface IUserEditPageProps extends RouteComponentProps<{ userId?: string }> {
    isEditing: boolean;
    isCustomerAdministrator: boolean | undefined;
    isAdministrator: boolean | undefined;
    currentUser: Clients.IUserLoginDetailsModel | undefined,
};

interface UserEditPageState {
    roles: Clients.IRoleModel[];
    clients: {
        client: Clients.IConversionClientModel,
        assigned: boolean,
        initialAssigned: boolean
    }[];
    conversions: {
        conversion: Clients.IConversionModel,
        assigned: boolean,
        initialAssigned: boolean
    }[];
    filteredConversions: {
        conversion: Clients.IConversionModel,
        assigned: boolean,
        initialAssigned: boolean
    }[];
    clientsFilter: string;
    conversionsFilter: string;
    user: Clients.UserModel;
    validations: ValidationResultList;
    isEditing: boolean;
    isCurrentUser: boolean;
    tabId: 'clients' | 'conversions';
    modal: boolean;
    passwordPlaceholder: boolean;
    selectedClient: string;
}

class UserEditPage extends React.Component<IUserEditPageProps, UserEditPageState> {
    private readonly userClient: Clients.UserClient = new Clients.UserClient();

    private readonly roleClient: Clients.RoleClient = new Clients.RoleClient();

    private readonly clientClient: Clients.ClientClient = new Clients.ClientClient();

    private readonly userToClientClient: Clients.UserToClientClient = new Clients.UserToClientClient();

    private readonly conversionClient: Clients.ConversionClient = new Clients.ConversionClient();

    private readonly userToConversionClient: Clients.UserToConversionClient = new Clients.UserToConversionClient();

    private readonly clientsFilterTerms = memoize(UserEditPage.extractFilterTerms);

    private readonly conversionsFilterTerms = memoize(UserEditPage.extractFilterTerms);

    private static extractFilterTerms = (f: string) => {
        return f.split(' ')
            .map(s => s.trim().toLowerCase())
            .filter(s => s.length > 0);
    }

    private requiredFields = ['firstName', 'lastName', 'emailAddress', 'role.id']
    private rolesRequiredClientAccess = ['1b660917-7a0d-ea11-9dd7-3c528246d348', '287a7ada-5f32-ea11-9ddd-3c528246d348'];

    constructor(props: IUserEditPageProps) {
        super(props)
        this.state = {
            roles: [],
            clients: [],
            conversions: [],
            filteredConversions: [],
            clientsFilter: "",
            conversionsFilter: "",
            user: new Clients.UserModel(),
            validations: new ValidationResultList(),
            isEditing: !!this.props.match.params.userId,
            isCurrentUser: false,
            tabId: this.props.isCustomerAdministrator ? 'conversions' : 'clients',
            modal: false,
            passwordPlaceholder: false,
            selectedClient: ''
        }
    }

    componentDidMount() {
        this.roleClient.getAll()
            .then((response) => {
                if (response !== null) {
                    this.setState({
                        roles: response
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })

        if (this.state.isEditing) {
            this.userClient.get(this.props.match.params.userId!)
                .then(response => {
                    if (response) {
                        let isCurrentUser = false;

                        const currentUser = authentication.getCurrentUser();
                        if (response.id === currentUser!.id) {
                            isCurrentUser = true;
                        }
                        this.setState({
                            user: response,
                            passwordPlaceholder: true,
                            isCurrentUser: isCurrentUser
                        });
                    }
                })
                .catch(() => { })
        } else {
            const user = this.state.user
            user.isActive = true;
            this.setState({ user })
        }
        Promise.all([
            this.updateClients(),
            this.updateConversions(),
        ])
            .catch(() => { });
    }

    componentDidUpdate(prevProps: IUserEditPageProps) {
        if (prevProps !== this.props) {
            this.componentDidMount();
        }
    }

    private updateClients = () => {
        const clients = this.clientClient.getAll();
        const clientsMapping = this.state.isEditing
            ? this.userToClientClient.get(this.props.match.params.userId!)
            : Promise.resolve([]);

        return Promise.all([clients, clientsMapping])
            .then(response => {
                const [clients, clientsMapping] = response;
                this.setState({
                    // TODO: review performance
                    clients: clients!.map(client => {
                        const assigned = clientsMapping!.findIndex(m => m.id === client.id) !== -1;
                        return { client, assigned, initialAssigned: assigned }
                    })
                }, () => {
                    for (let i = 0; i < this.state.clients.length; i++) {
                        if (this.state.clients[i].assigned) {
                            const selectedClient = this.state.clients[i].client.description || '';
                            this.setState({ selectedClient });
                            break;
                        }
                    }
                }
                );
            });
    }

    private updateConversions = () => {
        const conversions = this.conversionClient.getAll();
        const conversionsMapping = this.state.isEditing
            ? this.userToConversionClient.get(this.props.match.params.userId!)
            : Promise.resolve([]);

        return Promise.all([conversions, conversionsMapping])
            .then(response => {
                const [conversions, conversionsMapping] = response;

                this.setState({
                    // TODO: review performance
                    conversions: conversions!.map(conversion => {
                        const assigned = conversionsMapping!.findIndex(m => m.id === conversion.id) !== -1;
                        return { conversion, assigned, initialAssigned: assigned }
                    })
                }, () => this.processClientAccess());
            });
    }

    validate = (): ValidationResultList => {
        const result: ValidationResultList = new ValidationResultList();
        const user = this.state.user
        const requiredFields = this.requiredFields

        for (let i = 0; i < requiredFields.length; i++) {
            let property = requiredFields[i]
            let value = _.get(user, property)

            if (value === null || value === '' || value === undefined) {

                result.add(property, { message: 'The field is required' })
            }
        }

        this.validatePassword(result, user!.password || '')
        this.validateEmail(result, user.emailAddress)

        this.setState({
            validations: result
        })

        return result
    }

    validateEmail = (validations: ValidationResultList, email: string | undefined) => {
        if (email) {
            const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                validations.add('emailAddress', { message: 'Invalid email format' });
            }

        }
    }

    validatePassword = (validations: ValidationResultList, password: string | null) => {
        const property = 'password'
        if (password || (password === '' && !this.state.isEditing)) {
            if (password.length < 15 || password === null) {
                validations.add(property, { message: 'Must be at least 15 characters' })
            }
            if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
                validations.add(property, { message: 'Must contain at least 1 uppercase and 1 lowercase letter' })
            }
            if (!/\d/.test(password)) {
                validations.add(property, { message: 'Must contain at least 1 number' })
            }
            if (!/[ !"#$%&'()*+,-./: ;<=>?@[\]^ _`{|}~"]/.test(password)) {
                validations.add(property, { message: 'Must contain at least 1 special character' })
            }
        }
    }

    handleSave = (event: { preventDefault: () => void }) => {
        event.preventDefault();

        const promise = this.createUpsertUserPromise();
        promise
            .then(response => {
                this.setState({
                    user: response!,
                    isEditing: true,
                    passwordPlaceholder: true,
                }, () => {
                    sendNotification('success', 'User ' + this.state.user.firstName + ' ' + this.state.user.lastName + ' was saved.');
                });
            })
            .catch(error => { });
    }

    handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        const promise = this.createUpsertUserPromise();
        promise
            .then(() => this.props.history.push('/user'))
            .catch(error => { });
    }

    validateClientAccess(): boolean {
        const roleId = this.state.user!.role!.id ? this.state.user!.role!.id : ''
        const role = this.rolesRequiredClientAccess.includes(roleId);
        const accessedClientIds = this.state.clients.some(cl => cl.assigned);

        if (role && !accessedClientIds) {
            alert("Please provide access to a client for this user")
            return false;
        }
        return true;
    }

    private createUpsertUserPromise = (): Promise<Clients.UserModel | null> => {
        const validationResult = this.validate()
        if (!validationResult.isValid()) {
            return Promise.reject();
        }
        const clientValiationResult = this.validateClientAccess();
        if (!clientValiationResult) {
            return Promise.reject();
        }

        const user = this.state.user;
        // HACK: shut up the message a message deserializer complaining about the dates format.
        (user.role as any).createdDate = undefined;
        (user.role as any).lastEditedDate = undefined;

        if (this.state.user.role && Roles.noAccessRoleId === this.state.user.role!.id) user.isActive = false;

        const result = this.state.isEditing
            ? this.userClient.put(user.id!, user)
            : this.userClient.post(user);

        return result
            .then(response => {
                return Promise.all([
                    this.submitConversions(response!),
                    this.submitClients(response!),
                ]).then(() => response)
            });
    }

    private submitConversions = (user: Clients.UserModel) => {
        const conversions = this.state.conversions;
        const removedAssignedConversions = conversions
            .filter(x => x.initialAssigned)
            .filter(x => !x.assigned)

        if (conversions.length > 0 || removedAssignedConversions.length > 0) {
            const request = new Clients.UpdateUserToConversionRequestModel();

            request.insert = conversions
                .filter(x => x.assigned)
                .map(x => new Clients.UserToConversionModel({
                    userId: user!.id!,
                    conversionId: x.conversion.id!,
                }));

            request.remove = conversions
                .filter(x => !x.assigned)
                .map(x => new Clients.UserToConversionModel({
                    userId: user!.id!,
                    conversionId: x.conversion.id!,
                }));

            request.remove = removedAssignedConversions
                .map(x => new Clients.UserToConversionModel({
                    userId: user!.id!,
                    conversionId: x.conversion.id!,
                }));
            conversions.map(c => {
                if (c.assigned && c.conversion.client!.description!.trim() !== this.state.selectedClient.trim()) {
                    c.assigned = false
                }
                return c;
            })
            this.setState({ conversions })

            return this.userToConversionClient.patch(user!.id!, request);
        } else {
            return Promise.resolve(null);
        }
    }

    private submitClients = (user: Clients.UserModel) => {
        const updatedClients = this.state.clients.filter(x => x.assigned !== x.initialAssigned);

        if (updatedClients.length > 0) {
            const request = new Clients.UpdateUserToClientRequestModel();

            request.insert = updatedClients
                .filter(x => x.assigned)
                .map(x => new Clients.UserToConversionClientModel({
                    userId: user.id!,
                    clientId: x.client.id!,
                }));

            request.remove = updatedClients
                .filter(x => !x.assigned)
                .map(x => new Clients.UserToConversionClientModel({
                    userId: user.id!,
                    clientId: x.client.id!,
                }));

            return this.userToClientClient.patch(user.id!, request);
        } else {
            return Promise.resolve(null);
        }
    }

    private filterClient = (c: Clients.IConversionClientModel): boolean => {
        const terms = this.clientsFilterTerms(this.state.clientsFilter);
        return UserEditPage.filterDescriptionByTerms(c.description || '', terms);
    }

    private filterConversion = (c: Clients.IConversionModel): boolean => {
        const terms = this.conversionsFilterTerms(this.state.conversionsFilter);
        return UserEditPage.filterDescriptionByTerms(c.description || '', terms);
    }

    private static filterDescriptionByTerms = (description: string, terms: string[]): boolean => {
        const descriptionLower = description.toLowerCase();
        return _(terms).every(t => descriptionLower.indexOf(t) !== -1);
    }

    deleteModal = () => {
        const modal = !this.state.modal
        this.setState({ modal });
    }

    userDelete = () => {
        const id = this.state.user.id ? this.state.user.id : ''
        this.userClient.delete(id)
            .then(() => {
                this.deleteModal();
                this.props.history.push('/user')
            })
            .catch(error => {
                this.deleteModal();
                this.props.history.push('/user')
                console.log(error)
            });
    }

    processClientAccess() {
        const accessedClientIds = this.state.clients.filter(cl => cl.assigned).map(cl => cl.client.id);
        const filteredConversions = this.state.conversions.filter(con => accessedClientIds.includes(con.conversion.client!.id));
        this.setState({ filteredConversions: filteredConversions });
    }

    public render() {
        return (
            <Container>
                <Form>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            <div>
                                {this.props.isEditing ?
                                    <h2>Edit User</h2> :
                                    <h2>New User</h2>
                                }
                                <h5>Please provide the following information:</h5>
                                <br />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 5, offset: 1 }}>
                            <FormGroup>
                                <Label for="firstName">First Name</Label>
                                <Validation
                                    targetId='firstName'
                                    validations={this.state.validations}
                                >
                                    <Input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        value={this.state.user.firstName || ''}
                                        maxLength={50}
                                        onChange={(event) => this.setState({
                                            user: Clients.UserModel.fromJS({
                                                ...this.state.user,
                                                firstName: event.target.value.replace(/[^a-z]/gi, ''),
                                            })
                                        })}
                                    />
                                </Validation>
                            </FormGroup>
                        </Col>
                        <Col md={{ size: 5 }}>
                            <FormGroup>
                                <Label for="lastName">Last Name</Label>
                                <Validation
                                    targetId='lastName'
                                    validations={this.state.validations}
                                >
                                    <Input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        value={this.state.user.lastName || ''}
                                        maxLength={50}
                                        onChange={(event) => this.setState({
                                            user: Clients.UserModel.fromJS({
                                                ...this.state.user,
                                                lastName: event.target.value.replace(/[^a-z]/gi, ''),
                                            })
                                        })}
                                    />
                                </Validation>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 5, offset: 1 }}>
                            <FormGroup>
                                <Label for="emailAddress">Email Address</Label>
                                <Validation
                                    targetId='emailAddress'
                                    validations={this.state.validations}
                                >
                                    <Input
                                        type="email"
                                        name="emailAddress"
                                        id="emailAddress"
                                        maxLength={255}
                                        disabled={this.state.isCurrentUser && !this.props.isAdministrator}
                                        value={this.state.user.emailAddress || ''}
                                        onChange={(event) => this.setState({
                                            user: Clients.UserModel.fromJS({
                                                ...this.state.user,
                                                emailAddress: event.target.value.replace(/[^A-Za-z0-9_ @./\\$():#&+-]/gi, ''),
                                            })
                                        })}
                                    />
                                </Validation>
                            </FormGroup>
                        </Col>
                        <Col md={{ size: 3 }}>
                            <FormGroup>
                                <Label for="password">Password

                                </Label>

                                <Validation
                                    targetId='password'
                                    validations={this.state.validations}
                                >
                                    {this.props.isEditing && this.state.passwordPlaceholder ?
                                        <Input
                                            type="password"
                                            id="passwordPlaceholder"
                                            value='Password1!@#$%^'
                                            disabled
                                        /> :
                                        <Input
                                            type="password"
                                            name="password"
                                            id="password"
                                            value={this.state.user.password || ''}
                                            maxLength={255}
                                            onBlur={() => { if (this.state.user.password && this.state.user.password!.length === 0 || this.state.user.password === null) this.setState({ passwordPlaceholder: true }) }}
                                            onChange={(event) => this.setState({
                                                user: Clients.UserModel.fromJS({
                                                    ...this.state.user,
                                                    password: event.target.value === '' ? null : event.target.value,
                                                })
                                            })}
                                        />}
                                </Validation>
                            </FormGroup>
                        </Col>
                        <Col md={{ size: 2 }}>
                            {this.props.isEditing &&
                                <FormGroup>
                                    <Label className="switch">
                                        <input
                                            type="checkbox"
                                            readOnly
                                            checked={!this.state.passwordPlaceholder}
                                            onClick={() => this.setState({ passwordPlaceholder: !this.state.passwordPlaceholder })}
                                        />
                                        <span className="slider round">
                                        </span>
                                    </Label>
                                    Reset Password
                                </FormGroup>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 5, offset: 1 }}>
                            <FormGroup>
                                <Label for="role.id">Role</Label>
                                <Validation
                                    targetId='role.id'
                                    validations={this.state.validations}
                                >
                                    <select
                                        className="custom-select"
                                        id="role.id"
                                        disabled={this.state.isCurrentUser}
                                        value={this.state.user.role && this.state.user.role.id}
                                        onChange={event => this.setState({
                                            user: Clients.UserModel.fromJS({
                                                ...this.state.user,
                                                role: Clients.RoleModel.fromJS({
                                                    ...this.state.user.role!,
                                                    id: event.target.value
                                                })
                                            })
                                        })}
                                    >
                                        <option value='' />
                                        {this.state.roles
                                            .filter(role => {
                                                return this.props.currentUser!.role === Roles.developerAdministrationRoleId ||
                                                    (role.id !== Roles.developerAdministrationRoleId && role.id !== Roles.noAccessRoleId)
                                            })
                                            .map((role) => (
                                                <option
                                                    key={role.id}
                                                    value={role.id}
                                                >
                                                    {role.description}
                                                </option>
                                            ))}
                                    </select>
                                </Validation>
                            </FormGroup>
                        </Col>
                        {this.state.user.role === undefined || (this.state.user.role && Roles.noAccessRoleId !== this.state.user.role!.id) ?
                            <Col md={{ size: 4 }}>
                                <FormGroup>
                                    <Label check>User Is Active </Label>
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        disabled={this.state.isCurrentUser}
                                        checked={this.state.user.isActive || false}
                                        onChange={event => this.setState({
                                            user: Clients.UserModel.fromJS({
                                                ...this.state.user,
                                                isActive: event.target.checked
                                            })
                                        })}
                                    />
                                </FormGroup>
                            </Col> : null}
                        <Col md={{ size: 1 }}>
                            <SecureContainer roles={[
                                Roles.developerAdministrationRoleId
                            ]}>
                                <FormGroup>
                                    {this.props.isEditing &&
                                        <Button title='Delete User' id={this.state.user.id!} onClick={this.deleteModal} > <i className="fa fa-trash"></i></Button>}
                                </FormGroup>
                            </SecureContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}><hr /></Col>
                    </Row>
                    {this.state.user.role && (Roles.developerAdministrationRoleId === this.state.user.role!.id || Roles.administrationRoleId === this.state.user.role!.id || Roles.vertaforeInternalRoleId === this.state.user.role!.id || Roles.noAccessRoleId === this.state.user.role!.id) ?
                        null :
                        (!this.state.isCurrentUser || this.props.isAdministrator) &&
                        <SecureContainer roles={[
                            Roles.vertaforeInternalRoleId,
                            Roles.administrationRoleId,
                            Roles.developerAdministrationRoleId,
                            Roles.customerAdministratorRoleId
                        ]}>
                            <Row>
                                <Col md={{ size: 5, offset: 1 }}>
                                    <FormGroup>
                                        <Label for="role.id">Client</Label>
                                        <select
                                            className="custom-select"
                                            id="client.id"
                                            value={this.state.selectedClient}
                                            onChange={(event) => {
                                                const selectedClient = event.target.value ? event.target.value : '';
                                                this.setState({
                                                    selectedClient, clients: this.state.clients
                                                        .map(c => {
                                                            return c.client.description === selectedClient
                                                                ? {
                                                                    ...c,
                                                                    assigned: true,
                                                                }
                                                                : {
                                                                    ...c,
                                                                    assigned: false,
                                                                };
                                                        })
                                                }, () => this.processClientAccess())
                                            }}
                                        >
                                            <option value='' >Please Select Client </option>
                                            {this.state.clients && this.state.clients.map((c) => (
                                                <option
                                                    key={c.client.id}
                                                    value={c.client.description || ''}
                                                >
                                                    {c.client.description}
                                                </option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md={{ size: 10, offset: 1 }}>
                                    <Toolbar>
                                        <InputGroup>
                                            <Input
                                                value={this.state.conversionsFilter}
                                                placeholder='Search by description'
                                                onChange={event => this.setState({
                                                    conversionsFilter: event.target.value,
                                                })}
                                            />
                                            <InputGroupAddon addonType='append' className='material-icon'>
                                                <Button
                                                    onClick={() => this.setState({
                                                        conversionsFilter: ""
                                                    })}
                                                >clear</Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Toolbar>
                                    <DataTable>
                                        <thead>
                                            <tr>
                                                <th>Conversion</th>
                                                <th className='controls-column'>Access</th>
                                                <th>Client</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.filteredConversions.length === 0 ?
                                                <tr className="customMessage">
                                                    <td>
                                                        Please select access to a Client.
                                                    </td>
                                                </tr> :
                                                this.state.filteredConversions
                                                    .filter(c => this.filterConversion(c.conversion))
                                                    .map(c => (
                                                        <DataRow key={c.conversion.id} id={c.conversion.id!}>
                                                            <DataCell>{c.conversion.description}</DataCell>
                                                            <DataCell className='controls-column'>
                                                                <DataTableButton
                                                                    id={"toggle-" + c.conversion.id!}
                                                                    action={() => {
                                                                        const id = c.conversion.id;
                                                                        this.setState({
                                                                            filteredConversions: this.state.filteredConversions
                                                                                .map(c => {
                                                                                    return c.conversion.id !== id
                                                                                        ? c
                                                                                        : {
                                                                                            ...c,
                                                                                            assigned: !c.assigned,
                                                                                        };
                                                                                }),
                                                                            conversions: this.state.conversions
                                                                                .map(c => {
                                                                                    return c.conversion.id !== id
                                                                                        ? c
                                                                                        : {
                                                                                            ...c,
                                                                                            assigned: !c.assigned,
                                                                                        };
                                                                                })
                                                                        });
                                                                    }}
                                                                    icon={c.assigned ? 'done' : 'clear'}
                                                                ></DataTableButton>
                                                            </DataCell>
                                                            <DataCell>{c.conversion.client!.description}</DataCell>
                                                        </DataRow>
                                                    ))}
                                        </tbody>
                                    </DataTable>
                                </Col>
                            </Row>
                        </SecureContainer>
                    }
                    <Row>
                        <Col md={{ size: 10, offset: 1 }} className="buttons-panel">
                            <Button className="submit-button-orange"
                                type="button"
                                onClick={this.handleSubmit}
                            >Submit</Button>
                            {this.state.isEditing && < Button className="submit-button-orange"
                                type="button"
                                onClick={this.handleSave}
                            >Save</Button>}

                        </Col>
                    </Row>
                </Form>
                <DeleteConfirmationModal
                    isOpen={this.state.modal}
                    title={'Delete User ' + this.state.user.emailAddress}
                    message={'Please confirm to delete ' + this.state.user.emailAddress}
                    onSubmit={this.userDelete}
                    onClose={this.deleteModal}
                />
            </Container>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            isCustomerAdministrator: state.authentication
                && state.authentication.currentUser
                && state.authentication.currentUser.role === Roles.customerAdministratorRoleId,
            isAdministrator: state.authentication
                && state.authentication.currentUser
                && (state.authentication.currentUser.role === Roles.administrationRoleId
                    || state.authentication.currentUser.role === Roles.developerAdministrationRoleId),
            currentUser: state.authentication
                && state.authentication.currentUser
        }
    },
)(withRouter(UserEditPage));