import * as React from 'react';
import { ModalHeader, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

interface IClearConfirmationModalProps {
    isOpen: boolean;
    message: string;
    onSubmit: () => void;
    onClose: () => void;
}

export default class ClearConfirmationModal extends React.Component<IClearConfirmationModalProps, {}>{
    public render() {
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader>Clear Mappings</ModalHeader>
                <ModalBody>{this.props.message}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.onSubmit}>Clear</Button>
                    <Button color="secondary" onClick={this.props.onClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}