import { RangePaginationState, RangePagination, paginate } from 'components/basic/Pagination';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { RouteComponentProps } from 'react-router-dom';
import { ActionTypeModel, ActionTypeRefModel, ActionTypesClient, GenericClient, ExecutionsClient, ExecutionsModel, ExecutionsTierBooleanModel, IUserLoginDetailsModel, ConversionReportsClient, ConversionClient, ConversionModel, ConversionOptionsClient, ConversionOptionValueModel } from '../../../../../client/ApiClient';
import { DataCell, DataRow, DataTable, DataTableButton } from '../../../../basic/DataTable';
import * as Roles from 'constants/Roles';
import StageToolbar from '../generic/StageToolbar';
import { Button, DropdownMenu, DropdownToggle, DropdownItem, UncontrolledDropdown, Badge } from 'reactstrap';
import { SecureContainer } from 'components/basic/Authentication';
import { stagingPageUpdate, stagingPageCopyMappings, StagingPageCopyMappingsItem } from '../generic/Abstractions'
import { RowStatus, AssignRowStatus } from '../generic';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import ClearConfirmationModal from 'components/basic/modals/ClearConfirmationModal';
import DataListInput from 'components/basic/DataListInput';
import { enqueueNotification } from "../../../../../store/Notifications"
import { store } from "AppContext"
import { saveAs } from 'file-saver';
import '../../../../../styles/Icons.scss';
import './ActionTypesStage.scss';

interface ActionTypesStagingPageArgs {
    conversionId: string,
}

type ActionTypesStagingPageProps = RouteComponentProps<ActionTypesStagingPageArgs> & {
    currentUser: IUserLoginDetailsModel | undefined,
};

interface ActionTypesStagingPageState {
    items: (ActionTypeModel
        & StagingPageCopyMappingsItem)[]
    destinationCodes: ActionTypeRefModel[];
    executions: ExecutionsModel[];
    tierBooleans: ExecutionsTierBooleanModel | undefined;
    filter: string;
    sort: boolean;
    sortColumn: string;
    mapped: boolean;
    notMapped: boolean;
    dropped: boolean;
    clearModal: boolean;
    copyDisable: boolean;
    buttonLock: boolean;
    timeout: any;
    options: { [key: string]: ConversionOptionValueModel } | undefined;
    disabled: {
        images: boolean,
        activities: boolean,
        suspense: boolean,
    };
    lastExecutions: {
        images: ExecutionsModel | undefined,
        activities: ExecutionsModel | undefined,
        suspense: ExecutionsModel | undefined,
    };
    hubConnection: HubConnection | null;
    currentConversion: ConversionModel | undefined;
    pagination: RangePaginationState;
}

class ActionTypesStagingPage extends React.Component<ActionTypesStagingPageProps, ActionTypesStagingPageState>{
    private client: ActionTypesClient = new ActionTypesClient();
    private genericClient: GenericClient = new GenericClient();
    private executions: ExecutionsClient = new ExecutionsClient();
    private report: ConversionReportsClient = new ConversionReportsClient();
    private conversion: ConversionClient = new ConversionClient();
    private options: ConversionOptionsClient = new ConversionOptionsClient();

    private migrateEntities = [
        {
            sectionId: 'activities',
            packageName: '04-23-00-02-Activity-Extract-Load.dtsx'
        },
        {
            sectionId: 'images',
            packageName: '04-24-00-02-Image-Extract-Load.dtsx'
        },
        {
            sectionId: 'suspense',
            packageName: '04-26-00-02-Suspense-Extract-Load.dtsx'
        },
    ]

    private readonly copyMappings = stagingPageCopyMappings(this);

    constructor(props: ActionTypesStagingPageProps) {
        super(props)
        this.state = {
            items: [],
            destinationCodes: [],
            executions: [],
            tierBooleans: undefined,
            filter: '',
            sort: true,
            sortColumn: '',
            mapped: true,
            notMapped: true,
            dropped: true,
            hubConnection: null,
            clearModal: false,
            copyDisable: false,
            buttonLock: true,
            timeout: null,
            currentConversion: undefined,
            options: undefined,
            disabled: {
                images: false,
                activities: false,
                suspense: false,
            },
            lastExecutions: {
                images: undefined,
                activities: undefined,
                suspense: undefined,
            },
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            }
        }
    }

    componentDidMount() {
        this.getItems();
        this.client.getRef(this.props.match.params.conversionId)
            .then(response => {
                if (response != null) {
                    this.setState({
                        destinationCodes: response,
                    })
                }
            })
            .catch(error => { })
        this.getExecutions()
        this.getTierBooleans()
        this.currentConversion();
        const link = `${process.env.PUBLIC_URL}/executions`
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :('));
            this.state.hubConnection!.on('ExecutionUpdated', (execution: ExecutionsModel) => {
                if ((execution.description === "Succeeded" || execution.description === "Completed") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'success', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
                else if ((execution.description === "Failed" || execution.description === "Canceled" || execution.description === "Ended Unexpectedly" || execution.description === "Stopping") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'error', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
                this.getExecutions()
                clearInterval(this.state.timeout);
            });
            this.state.hubConnection!.on('ExecutionCalled', (call: string) => {
                this.getExecutions();
            });
        })
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
    }

    getExecutions() {
        this.executions!.listExecutions(this.props.match.params.conversionId)
            .then(results => {
                this.setState({ executions: results! }, () => {
                    this.migrateEntities.forEach(m => this.migrateButton(m))
                    this.buttonLock();
                    const copyExecutions = this.state.executions.filter(e => e.packageName === '03-19-01-Action-Types.dtsx')
                    if (copyExecutions.length > 0) {
                        const lastCopy = copyExecutions[copyExecutions.length - 1]
                        this.copyButton(lastCopy);
                    };
                })
            })
            .catch(e => {
                console.log(e)
            })
    }

    getTierBooleans = () => {
        this.executions.listExecutionsTierBoolean(this.props.match.params.conversionId)
            .then(response =>
                this.setState({ tierBooleans: response![0] })
            )
            .catch(error => { })
    }

    migrateButton = (migrateSection: any) => {
        const execution = this.state.executions.filter(e => e.packageName === migrateSection.packageName)
        const lastExecution = execution[execution.length - 1]
        if (lastExecution) {
            this.setLastExecution(migrateSection.sectionId, lastExecution)
            if (lastExecution.endTime) {
                this.disableButton(migrateSection.sectionId, false);
            } else {
                this.disableButton(migrateSection.sectionId, true);
            }
        }
    }

    setLastExecution = (entityName: string, lastExecution: ExecutionsModel) => {
        const lastExecutions = this.state.lastExecutions;
        (lastExecutions as any)[entityName] = lastExecution;
        this.setState({ lastExecutions });
    }

    disableButton = (entityName: string, bool: boolean) => {
        const disabled = this.state.disabled;
        (disabled as any)[entityName] = bool;
        this.setState({ disabled });
    }

    setDefaults = (entity: ActionTypeModel) => new ActionTypeModel({
        ...entity,
        destinationCode: entity.destinationCode || '',
    });

    getItems() {
        this.client.getAll(this.props.match.params.conversionId, '')
            .then((response) => {
                this.setState({
                    items: response!
                }, () => {
                    this.buttonLock();
                    this.setState({
                        items: this.state.items.map(this.setDefaults).
                            sort((a: any, b: any) => {
                                let x = a[this.state.sortColumn]
                                let y = b[this.state.sortColumn]
                                if (typeof y === "string") {
                                    y = y.toLowerCase().trim()
                                    x = x.toLowerCase().trim()
                                }
                                if (x === y) {
                                    return 0;
                                }
                                else if (x === null) {
                                    return 1;
                                }
                                else if (y === null) {
                                    return -1;
                                }
                                else if (!this.state.sort) {
                                    return x < y ? -1 : 1;
                                }
                                else {
                                    return x < y ? 1 : -1;
                                }
                            }),
                    })
                })
            })
            .catch(error => { })
    }

    put(entity: ActionTypeModel) {
        stagingPageUpdate(this, this.client)
            .withSource('Action Type', entity.sourceCode!)
            .update(entity)
            .catch(() => { });
    }

    loadEntity = (entityName: string) => {
        this.disableButton(entityName, true)
        this.setState({ buttonLock: true })
        clearInterval(this.state.timeout);
        this.setState({
            timeout: setInterval(() => {
                this.setState({ buttonLock: true })
            }, 2000)
        })
        const conversionId = this.props.match.params.conversionId
        const userId = this.props.currentUser!.id
        if (userId !== undefined) {
            this.genericClient.migrateEntity(conversionId, userId, entityName)
                .catch(error => { })
        }
    }

    currentConversion = () => {
        this.conversion.get(this.props.match.params.conversionId)
            .then(response =>
                this.setState({ currentConversion: response! }, () => {
                    this.buttonLock();
                })
            )
            .catch(error => { })
        this.options.get(this.props.match.params.conversionId)
            .then(values => {
                const options = values!.reduce(
                    (result, current) => ({ ...result, [current.id!]: current, }), {});
                this.setState({ options });
            })
            .catch(error => { });
    }

    handleSearch = (filter: string) => {
        this.setState({ filter })
    }

    handleChange = (e: any) => {
        const dc = e.value;
        const id = Number(e.target.name);

        const items = this.state.items;
        const i = this.state.items.findIndex(v => v.id === id);
        items[i].destinationCode = dc;

        this.setState({ items });
        this.put(ActionTypeModel.fromJS(items[i]))
    }

    handleCheckboxChange = (id: number, e: { target: { name: any, checked: any } }) => {
        const items = this.state.items.map(
            v => v.id === id
                ? new ActionTypeModel({
                    ...v,
                    [e.target.name]: e.target.checked,
                })
                : v)
        this.setState({ items });
        this.put(items.find(v => v.id === id)!)
    }

    sort(column: string) {
        const items = this.state.items.sort((a: any, b: any) => {
            let x = a[column]
            let y = b[column]
            if (typeof y === "string") {
                y = y.toLowerCase().trim()
                x = x.toLowerCase().trim()
            }
            if (this.state.sort) {
                if (x < y) { return -1; }
                if (x > y) { return 1; }
            } else {
                if (x > y) { return -1; }
                if (x < y) { return 1; }
            }
            return 0
        })
        this.setState({ sort: !this.state.sort, sortColumn: column, items });
    }

    mappedCheck(check: string) {
        if (check === 'notMapped') {
            const notMapped = !this.state.notMapped
            this.setState({ notMapped })
        } else if (check === 'mapped') {
            const mapped = !this.state.mapped
            this.setState({ mapped })
        } else if (check === 'dropped') {
            const dropped = !this.state.dropped
            this.setState({ dropped })
        }
    }

    exportToExcel = (id: string) => {
        this.report.exportToExcel(this.props.match.params.conversionId, id, null, null)
            .then(response => saveAs(response!.data, response!.fileName))
            .catch(error => { });
    }

    statusUpdate = (entityName: string) => {
        const lastExecutions = this.state.lastExecutions;
        const lastExecution = (lastExecutions as any)[entityName];
        if (lastExecution) {
            const dt = new Date(lastExecution!.endTime);
            const day = dt.getDate(),
                month = dt.getMonth(),
                year = dt.getFullYear() - 2000,
                minutes = (dt.getMinutes() < 10 ? '0' : "") + dt.getMinutes();
            let hours = dt.getHours();
            const period = (hours < 12) ? 'AM' : 'PM';
            if (period === 'PM' && hours !== 12) hours = hours - 12;
            if (hours === 0) hours = 12;
            let color = ""
            const status = lastExecution.description
            if (status === "Canceled" || status === "Failed" || status === "Ended Unexpectedly") {
                color = "danger"
            } else if (status === "Succeeded" || status === "Completed") {
                color = "success"
            } else {
                color = "primary"
                const now = new Date().valueOf();
                const estimate = new Date(lastExecution.estimatedFinish).valueOf();
                const timeLeft: string = estimate > now ? `${((estimate - now) / 60000).toFixed(2)} minutes` : 'Pending';
                return < h6 className='status' > <Badge color={color}>Estimate: {timeLeft} - Status: {lastExecution.description} </Badge></h6>
            }
            return < h6 className='status' > <Badge color={color}>
                <div> Migrated {
                    `${entityName} ${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                } </div>
                <div>Status: {lastExecution.description} </div></Badge></h6>
        }
        else {
            return < div className='emptyStatus' ><Badge color="light"> </Badge> </div>
        }
    }

    clearMappings = (id: string) => {
        const userId = this.props.currentUser!.id ? this.props.currentUser!.id : '';
        this.genericClient.clearMappings(this.props.match.params.conversionId, userId, id)
            .then(() => {
                this.setState({ clearModal: false });
                this.getItems();
            })
            .catch(error => { console.log(error) });
    }

    actionTypesRefs = (): any[] => {
        const arr = [];
        const actionTypesRefs = this.state.destinationCodes;
        let index = 0;
        for (const [, actionTypesRef] of actionTypesRefs.entries()) {
            arr.push(<option
                key={index}
                data-value={actionTypesRef.name}
            >
                {actionTypesRef.name}
            </option>)
            index = index + 1;
        }
        return arr;
    }

    copy() {
        if (confirm('Are you sure, do you want to continue with Copy Mappings?')) {
            this.setState({ copyDisable: true, buttonLock: true })
            this.copyMappings.submitMappings('actiontypes', this.props.currentUser)
        }
    }

    copyButton(lastCopy: any) {
        if (lastCopy) {
            this.setState({ copyDisable: true, buttonLock: true });
            if (lastCopy!.endTime) {
                this.getItems();
                this.setState({ copyDisable: false });
            } else {
                this.setState({ copyDisable: true });
            }
        }
    }

    buttonLock = () => {
        const conversionId = this.props.match.params.conversionId;
        if (this.state.currentConversion && this.state.currentConversion!.dateCompleted === undefined) {
            this.executions.listExecutionsAllTierBoolean(conversionId)
                .then((response) => {
                    if (response) {
                        this.setState({ buttonLock: response.isLocked })
                    }
                })
                .catch(error => { })
        }
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;
        const items = this.state.items
            .map(AssignRowStatus)
            .filter(i => (i.sourceCode && i.sourceCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.description && i.description!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.destinationCode && i.destinationCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())))
            .filter(item =>
                (item.mapped === RowStatus.NotMapped && this.state.notMapped)
                || (item.mapped === RowStatus.Mapped && this.state.mapped)
                || (item.mapped === RowStatus.Dropped && this.state.dropped));
        const tierBool = this.state.tierBooleans ? this.state.tierBooleans.tier3 : true;


        return (
            <>
                <StageToolbar
                    conversionId={conversionId}
                    handleSearch={this.handleSearch}
                    sectionId='actiontypes'
                    filter={this.state.filter}
                >
                    <SecureContainer roles={[
                        Roles.vertaforeInternalRoleId,
                        Roles.developerAdministrationRoleId,
                        Roles.administrationRoleId
                    ]}>
                        <>
                            <Button className='load-button' onClick={() => this.loadEntity('suspense')} disabled={this.state.disabled.suspense || !tierBool || this.state.buttonLock} >
                                Migrate Mapped Suspense
                            </Button>
                            {this.state.disabled.suspense ? <img className='loading-indicator' alt="loader" src={'loader.gif'} /> : null}
                            <Button className='load-button' onClick={() => this.loadEntity('images')} disabled={this.state.disabled.images || !tierBool || this.state.buttonLock || this.state.currentConversion!.imagePath === '' || (this.state.options && this.state.options["60b9bc6c-b2ed-4258-b4e9-4192cbce04cc"].value === '0')}>
                                Migrate Mapped Images
                            </Button>
                            {this.state.disabled.images ? <img className='loading-indicator' alt="loader" src={'loader.gif'} /> : null}
                            <Button className='load-button' onClick={() => this.loadEntity('activities')} disabled={this.state.disabled.activities || !tierBool || this.state.buttonLock}>
                                Migrate Mapped Activity
                            </Button>
                            {this.state.disabled.activities ? <img className='loading-indicator' alt="loader" src={'loader.gif'} /> : null}
                        </>
                    </SecureContainer>
                    <div>
                        <SecureContainer roles={[
                            Roles.vertaforeInternalRoleId,
                            Roles.developerAdministrationRoleId,
                            Roles.administrationRoleId
                        ]}>
                            <>
                                {!this.state.buttonLock &&
                                    <div>
                                        {this.state.currentConversion && this.state.currentConversion!.parentConversionId && <DataTableButton
                                            icon='scanner'
                                            id='actiontypesCopy'
                                            title='Copy Mappings'
                                            action={() => this.copy()} />}
                                        <DataTableButton
                                            icon='backspace'
                                            id={`ActionTypeClear`}
                                            title='Clear Mappings'
                                            action={() => this.setState({ clearModal: true })} />
                                    </div>}
                                {this.state.copyDisable &&
                                    <img className='copy-loading-indicator' alt="loader" src={'loader.gif'} />}
                            </>
                        </SecureContainer>
                        < DataTableButton icon='file_download' id={`actiontypesMap`} title='Export to Excel'
                            action={() => this.exportToExcel(`actiontypesMap`)} />
                    </div>
                </StageToolbar>
                <div className='actionTypeStatus'>
                    {this.statusUpdate('activities')}
                    {(this.state.options && this.state.options["60b9bc6c-b2ed-4258-b4e9-4192cbce04cc"].value === '1' && this.state.currentConversion && this.state.currentConversion!.imagePath && this.state.currentConversion!.imagePath !== '') ? this.statusUpdate('images') : <div className='emptyStatus'><Badge color="light"> </Badge> </div>}
                    {this.statusUpdate('suspense')}
                </div>
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th onClick={() => this.sort('sourceCode')}>Source Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('description')}>Source Description<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isDrop')} className='checkbox-column'>Exclude<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('destinationCode')}>Destination Code<i className="fa fa-sort"></i></th>
                            <th className='checkbox-column'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Mapped
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={!this.state.notMapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('notMapped')}>&#x274C; Not Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.mapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('mapped')}>&#x2705; Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.dropped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('dropped')}>&#x2796; Excluded</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginate(items, this.state.pagination, (item, index) => (
                                <DataRow id={item.id.toString()} key={item.id}>
                                    <DataCell>{item.sourceCode}</DataCell>
                                    <DataCell>{item.description}</DataCell>
                                    <DataCell className='checkbox-column'>
                                        <input className='custom-checkbox'
                                            type='checkbox'
                                            name='isDrop'
                                            checked={item.isDrop}
                                            onChange={e => this.handleCheckboxChange(item.id, e)}
                                            disabled={this.state.buttonLock}
                                        />
                                    </DataCell>
                                    <DataCell>
                                        <DataListInput
                                            id={`referenceId - ${index}`}
                                            name={item.id.toString()}
                                            value={item.destinationCode ? item.destinationCode : ""}
                                            onValueChange={e => this.handleChange(e)}
                                            disabled={this.state.buttonLock}
                                        >
                                            <option data-value='' />
                                            {this.actionTypesRefs()}
                                        </DataListInput>


                                    </DataCell>
                                    <DataCell className='checkbox-column' itemProp={item.mapped === RowStatus.NotMapped ? 'unmapped' : item.mapped === RowStatus.Mapped ? 'mapped' : 'dropped'}>
                                        {item.mapped === 1 ? < p className='mapped' itemProp='unmapped'>&#x274C;</p> : item.mapped === 2 ? < p className='mapped' itemProp='mapped'>&#x2705;</p> : < p className='mapped' itemProp='dropped'>&#x2796;</p>}
                                    </DataCell>
                                </DataRow>
                            ))
                        }
                    </tbody>
                </DataTable>
                <ClearConfirmationModal
                    isOpen={this.state.clearModal}
                    message={'WARNING: This will clear ALL mapings for Action Types. \nPlease confirm how you want to proceed:'}
                    onSubmit={() => this.clearMappings('actiontypes')}
                    onClose={() => this.setState({ clearModal: false })}
                />
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
            </>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser
        }
    },
)(ActionTypesStagingPage);