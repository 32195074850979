import * as React from 'react';
import { Nav, Navbar } from 'reactstrap';
import './Toolbar.scss';

export type ToolbarProps = {
    children?: React.ReactNode,
}

export default class Toolbar<T = {}> extends React.PureComponent<ToolbarProps, T> {
    public render() {
        return (
            <div className='toolbar-container'>
                <Navbar expand="md">
                    <Nav className="mr-auto" navbar>
                    </Nav>
                </Navbar>
                <div className="controls-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export class NavToolbar extends React.PureComponent<ToolbarProps> {
    public render() {
        return (
            <div className='toolbar-container'>
                <Navbar expand="md">
                    <Nav className="mr-auto" navbar>
                        {this.props.children}
                    </Nav>
                </Navbar>
            </div>
        )
    }
}
