import * as React from 'react';
import { ModalHeader, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

interface IConfirmationModalProps {
    isOpen: boolean;
    message: string;
    onSubmit: () => void;
}

export default class ConfirmationModal extends React.Component<IConfirmationModalProps, {}>{
    public render() {
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalBody>{this.props.message}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.onSubmit}>OK</Button>
                </ModalFooter>
            </Modal>
        )
    }
}