import * as React from 'react';
import Toolbar from '../basic/Toolbar';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import MappingMergeDynamicDataTable from './mapping/mergetool/MappingMergeDynamicDataTable'
import * as Clients from '../../client/ApiClient';
import { DataCell } from '../basic/DataTable';
import DataListInput from '../basic/DataListInput';

interface IMergeStageTablePageState {
    selected: string
    selectedConnection: string
    stageTables: Clients.StageTablesModel[]
    connections: Clients.EnvironmentModel[]
    columnTake: number
}
interface MergeStageTableArgs {
    conversionId: string,
}

type MergeStageTableProps = RouteComponentProps<MergeStageTableArgs>;

class MergeStageTablePage extends React.Component<MergeStageTableProps, IMergeStageTablePageState>{

    private mergeClient: Clients.MergeMapClient = new Clients.MergeMapClient();
    private conversionClient: Clients.ConversionClient = new Clients.ConversionClient();

    constructor(props: Readonly<MergeStageTableProps>) {
        super(props)
        this.state = {
            selected: "",
            selectedConnection: "",
            stageTables: [],
            connections: [],
            columnTake: 0,
        }
    }

    componentDidMount() {
        this.getTables()
        this.getConnections();
    }

    getTables = () => {
        this.mergeClient.getStageTables('STG', this.props.match.params.conversionId)
            .then(response => {
                response && this.setState({ stageTables: response })
            })
            .catch(err => { console.error(err) })
    }

    getConnections = () => {
        this.conversionClient.getConnectionsAll(this.props.match.params.conversionId, 0)
            .then(response => {
                response && this.setState({ connections: response })
            })
            .catch(err => { console.error(err) })
    }

    handleChange = (event: any) => {
        const selected = event.target.value
        let columnTake = 0;
        for (let i = 0; i < this.state.stageTables.length; i++) {
            if (this.state.stageTables[i].name === selected) {
                columnTake = this.state.stageTables[i].columnTake
            }
        }
        this.setState({ selected, columnTake })
    }

    handleChangeConnection(event: any) {
        const selectedConnection = event.target.value
        this.setState({ selectedConnection })
    }

    public render() {
        const stageTables = this.state.stageTables
        const selected = this.state.selected
        const connections = this.state.connections
        const selectedConnection = this.state.selectedConnection
        return (
            <>
                <h3 className='mapping-body-header'>Review Data in Stage </h3>
                <Toolbar>
                    <select
                        className="custom-select tableSearch"
                        name="fileType"
                        value={selectedConnection}
                        onChange={event => this.handleChangeConnection(event)}
                    >
                        <option value="">Select Source</option>
                        {connections.map((type, i) => (
                            <option
                                value={i}
                                key={i}
                            >
                                {type.name}
                            </option>
                        ))}
                    </select>
                    <DataListInput
                        className="custom-select tableSearch"
                        id={'stagetables'}
                        name={'stagetables'}
                        value={selected || ''}
                        onValueChange={(event) => this.handleChange(event)}
                        placeholder={"Select Table"}
                    >
                        <option
                            key={0}
                            data-value={''}
                        >
                        </option>
                        {stageTables.map((table, i) => (
                            <option
                                key={i}
                                data-value={table.name}
                            >
                                {table.name}
                            </option>
                        ))}
                    </DataListInput>
                </Toolbar>
                {this.state.selected !== "" && < MappingMergeDynamicDataTable source='stage' conversionId={this.props.match.params.conversionId} sectionId={this.state.selected} nameIndex={this.state.selectedConnection} columnTake={this.state.columnTake} />}
            </>
        )
    }
}

export default withRouter(MergeStageTablePage)