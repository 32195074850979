export const conversionsLocationTemplate = '/conversion';

export const mappingLocationTemplate = '/conversion/:conversionId/mapping/entity';

export const tierSummaryLocationTemplate = '/conversion/:conversionId/mapping/entity/:tierId';

export const conversionSummaryLocationTemplate = '/conversion/:conversionId/mapping/entity/';

export const entityLocationTemplate = '/conversion/:conversionId/mapping/entity/:tierId/:sectionId/:tabId';

export const mergeLocationTemplate = '/conversion/:conversionId/mapping/entity/merge';

export const entityMergeLocationTemplate = '/conversion/:conversionId/mapping/entity/merge/:sectionId/:tabId';

export const mergeSummaryLocationTemplate = '/conversion/:conversionId/mapping/entity/merge';

export const optionsLocationTemplate = '/conversion/:conversionId/mapping/options';

export const editLocationTemplate = '/conversion/:conversionId/:tabId';
