import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PolicyDetailsCommercialPropertyCauseOfLossClient, PolicyDetailsCommercialPropertyCauseOfLossModel, PolicyDetailsCommercialPropertyCauseOfLossRefModel } from '../../../../../client/ApiClient';
import { DataCell, DataRow, DataTable } from '../../../../basic/DataTable';
import { paginate, RangePaginationState, RangePagination } from '../../../../basic/Pagination';
import { stagingPageCopyMappings, StagingPageCopyMappingsItem } from '../generic/Abstractions'
import { DropdownMenu, DropdownToggle, Input, UncontrolledDropdown, DropdownItem } from 'reactstrap';
import StageActionToolbar from '../generic/StageActionToolbar';
import { RowStatus, AssignRowStatus } from '../generic';
import { enqueueNotification } from "../../../../../store/Notifications"
import { store } from "AppContext"
import DataListInput from '../../../../basic/DataListInput';

interface PolicyDetailsCommercialPropertyCauseOfLossStagingPageArgs {
    conversionId: string,
}


type PolicyDetailsCommercialPropertyCauseOfLossStagingPageProps =
    RouteComponentProps<PolicyDetailsCommercialPropertyCauseOfLossStagingPageArgs>;



interface PolicyDetailsCommercialPropertyCauseOfLossStagingPageState {
    items: (PolicyDetailsCommercialPropertyCauseOfLossModel
        & StagingPageCopyMappingsItem)[];
    destinationCodes: PolicyDetailsCommercialPropertyCauseOfLossRefModel[];
    filter: string;
    sort: boolean;
    sortColumn: string;
    mapped: boolean;
    notMapped: boolean;
    lastValue: string;
    dropDownIndex: number | null;
    pagination: RangePaginationState;
    buttonLock: boolean;
}


export class PolicyDetailsCommercialPropertyCauseOfLossStagingPage extends React.Component<PolicyDetailsCommercialPropertyCauseOfLossStagingPageProps, PolicyDetailsCommercialPropertyCauseOfLossStagingPageState>{
    private client: PolicyDetailsCommercialPropertyCauseOfLossClient = new PolicyDetailsCommercialPropertyCauseOfLossClient()

    private readonly copyMappings = stagingPageCopyMappings(this);

    constructor(props: PolicyDetailsCommercialPropertyCauseOfLossStagingPageProps) {
        super(props)
        this.state = {
            items: [],
            destinationCodes: [],
            filter: '',
            sort: true,
            sortColumn: '',
            mapped: true,
            notMapped: true,
            lastValue: '',
            dropDownIndex: null,

            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            buttonLock: false,
        }
    }

    componentDidMount() {
        this.getItems();

        this.client.getRef(this.props.match.params.conversionId)
            .then(response => {
                if (response != null) {
                    this.setState({
                        destinationCodes: response,
                    })
                }
            })
            .catch(error => { console.log(error) })
    }

    setDefaults = (entity: PolicyDetailsCommercialPropertyCauseOfLossModel) => new PolicyDetailsCommercialPropertyCauseOfLossModel({
        ...entity,
        destinationCode: entity.destinationCode || '',
    })

    getItems = () => {
        this.client.getAll(this.props.match.params.conversionId, '')
            .then((response) => {
                this.setState({
                    items: response!.map(this.setDefaults).sort((a: any, b: any) => {
                        let x = a[this.state.sortColumn]
                        let y = b[this.state.sortColumn]
                        if (typeof y === "string") {
                            y = y.toLowerCase().trim()
                            x = x.toLowerCase().trim()
                        }
                        if (x === y) {
                            return 0;
                        }
                        else if (x === null) {
                            return 1;
                        }
                        else if (y === null) {
                            return -1;
                        }
                        else if (!this.state.sort) {
                            return x < y ? -1 : 1;
                        }
                        else {
                            return x < y ? 1 : -1;
                        }
                    }),
                })
            })
            .catch(error => { console.log(error) })
    }

    put(entity: PolicyDetailsCommercialPropertyCauseOfLossModel) {
        this.client.put(this.props.match.params.conversionId, entity)
            .then(() => {
                this.getItems()
                store.dispatch(enqueueNotification({ type: 'success', message: `Cause of Loss ${entity.sourceCode} was saved.` }))
            })
            .catch((error) => {
                console.log(error)
                const response = JSON.parse(error.response)
                store.dispatch(enqueueNotification({ type: 'error', message: response }))
            });
    }

    handleSearch = (filter: string) => {
        this.setState({ filter })
    }

    sort(column: string) {
        const items = this.state.items.sort((a: any, b: any) => {
            let x = a[column]
            let y = b[column]
            if (typeof y === "string") {
                y = y.toLowerCase().trim()
                x = x.toLowerCase().trim()
            }
            if (this.state.sort) {
                if (x < y) { return -1; }
                if (x > y) { return 1; }
            } else {
                if (x > y) { return -1; }
                if (x < y) { return 1; }
            }
            return 0
        })
        this.setState({ sort: !this.state.sort, sortColumn: column, items });
    }

    mappedCheck(check: string) {
        if (check === 'notMapped') {
            const notMapped = !this.state.notMapped
            this.setState({ notMapped })
        } else if (check === 'mapped') {
            const mapped = !this.state.mapped
            this.setState({ mapped })
        }
    }

    lockButton = (lock: boolean) => {
        this.setState({ buttonLock: lock })
    }

    handleChangeDropDown = (item: PolicyDetailsCommercialPropertyCauseOfLossModel, e: any) => {
        const i = this.state.items.findIndex(v => v.id === item.id);
        const items = this.state.items.map(
            v => v.id === item.id
                ? new PolicyDetailsCommercialPropertyCauseOfLossModel({
                    ...v,
                    destinationCode: e.value
                })
                : v
        )
        this.setState({ items });
        this.put(items[i])
    }

    DestinationCodes = (): any[] => {
        const arr = [];
        const dc = this.state.destinationCodes;
        for (let i = 0; i < dc.length; i++) {
            arr.push(<option
                key={i}
                data-value={dc[i].sourceCode}
            >
                {`${dc[i].sourceCode!.trim()} - ${dc[i].sourceDescription}`}
            </option>)
        }
        return arr;
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;
        const items = this.state.items
            .map(AssignRowStatus)
            .filter(i => (i.sourceCode && i.sourceCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.sourceDescription && i.sourceDescription!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.destinationCode && i.destinationCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())))
            .filter(item =>
                (item.mapped === RowStatus.NotMapped && this.state.notMapped)
                || (item.mapped === RowStatus.Mapped && this.state.mapped));
        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='cp'
                    sectionType='causeofloss'
                    tierGate='tier4'
                    handleSearch={this.handleSearch}
                    handleCopyMapping={this.copyMappings.submitMappings}
                    filter={this.state.filter}
                    copyMap={true}
                    getItems={this.getItems}
                    lockButton={this.lockButton}
                />
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th onClick={() => this.sort('sourceCode')}>Source Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('sourceDescription')}>Source Description<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('destinationCode')}>Destination Code<i className="fa fa-sort"></i></th>
                            <th className='checkbox-column'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Mapped
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={!this.state.notMapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('notMapped')}>&#x274C; Not Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.mapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('mapped')}>&#x2705; Mapped</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginate(items, this.state.pagination, (item, index) => (
                                <DataRow id={item.id.toString()} key={item.id}>
                                    <DataCell>{item.sourceCode}</DataCell>
                                    <DataCell>{item.sourceDescription}</DataCell>
                                    <DataCell>
                                        {index === this.state.dropDownIndex ?
                                            <>
                                                <DataListInput
                                                    id={`referenceId - ${index}`}
                                                    name={item.id.toString()}
                                                    value={item.destinationCode ? item.destinationCode : undefined}
                                                    onValueChange={event => this.handleChangeDropDown(item, event)}
                                                    disabled={this.state.buttonLock}
                                                >
                                                    <option data-value='' />
                                                    {this.DestinationCodes()}
                                                </DataListInput>
                                            </>
                                            :
                                            <Input
                                                value={item.destinationCode ? `${item.destinationCode} - ${item.description}` : ''}
                                                onFocus={() => this.setState({ dropDownIndex: index })}
                                                onBlur={() => this.setState({ dropDownIndex: null })}
                                                disabled={this.state.buttonLock}
                                            />}
                                    </DataCell>
                                    <DataCell className='checkbox-column' itemProp={item.mapped === RowStatus.NotMapped ? 'unmapped' : item.mapped === RowStatus.Mapped ? 'mapped' : 'dropped'}>
                                        {item.mapped === 1 ? < p className='mapped' itemProp='unmapped'>&#x274C;</p> : < p className='mapped' itemProp='mapped'>&#x2705;</p>}
                                    </DataCell>
                                </DataRow>
                            ))
                        }
                    </tbody>
                </DataTable>
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
            </>
        );
    }
}