import { Action, Reducer } from 'redux';
import { TabName } from '../components/conversion/mapping/Constants';

type TabPageName = 'edit' | 'options' | 'additionaldata' | 'reports' | 'mergestagetable' | 'mergetool' | 'minidash' | '';

export interface SelectState {
    tab: TabName;
    tabName: TabPageName;
    userCompany: string;
}

// Actions

export interface UpdateSelectAction {
    tab: TabName; type: 'UPDATE_TAB' 
}

export interface UpdateSelectTabAction {
    tabName: TabPageName; type: 'UPDATE_TAB_PAGE'
}

export interface UpdateUserCompanyAction {
    userCompany: string; type: 'UPDATE_USER_COMPANY'
}

export const actionCreators = {
    update: (tab: TabName) => ({ tab, type: 'UPDATE_TAB' } as UpdateSelectAction),
    updateTab: (tabName: TabPageName) => ({ tabName, type: 'UPDATE_TAB_PAGE' } as UpdateSelectTabAction),
    updateUserCompany: (userCompany: string) => ({ userCompany, type: 'UPDATE_USER_COMPANY' } as UpdateUserCompanyAction),
};

export type KnownAction = UpdateSelectAction | UpdateSelectTabAction | UpdateUserCompanyAction;

// Reducer

export const reducer: Reducer<SelectState> =
    (state: SelectState | undefined, incomingAction: Action): SelectState => {
        if (state === undefined) {
            return {
                tab: 'staging',
                tabName: 'edit',
                userCompany: '',
            };
        }

        const action = incomingAction as KnownAction;

        switch (action.type) {
            case 'UPDATE_TAB':
                return {
                    tab: action.tab,
                    tabName: '',
                    userCompany: '',
                };
            case 'UPDATE_TAB_PAGE':
                return {
                    tab: 'staging',
                    tabName: action.tabName,
                    userCompany: '',
                };
            case 'UPDATE_USER_COMPANY':
                return {
                    tab: 'staging',
                    tabName: '',
                    userCompany: action.userCompany,
                }
            default:
                return state;
        }
    };
