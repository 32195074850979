import * as React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { store } from 'AppContext';
import { ApplicationState } from '../../store';
import { Notification, NotificationType, removeNotification } from '../../store/Notifications';

import './Notifications.scss';

interface INotificationsProps {
    notifications: Notification[]
}

interface NotificationsState {
    visible: boolean;
}

const mapStateToProps = (state: ApplicationState): INotificationsProps => {
    return {
        notifications: state.notifications.items
    }
}

const alertColors = new Map<NotificationType, string>([
    ['success', 'success'],
    ['error', 'danger'],
    ['primary', 'primary']
])

export class Notifications extends React.PureComponent<INotificationsProps, NotificationsState, {}> {

    constructor(props: INotificationsProps) {
        super(props)
        this.state = {
            visible: true,
        }
    }

    componentDidMount() {
        this.props.notifications
            .forEach((notification) => {
                if (notification.message === "The user session has timed out.") {
                    setTimeout(() => {
                        store.dispatch(removeNotification(notification.key!))
                    }, 3000)
                }
            })
    }

    componentDidUpdate(prevProps: INotificationsProps) {
        if (this.props.notifications !== prevProps.notifications) {
            this.setState({ visible: true })
            this.props.notifications
                .filter(x => prevProps.notifications.indexOf(x) === -1)
                .forEach((notification) => {
                    const time = typeof notification.message === "string" && notification.message.indexOf('Copy') > -1 ? 60000 : 3000
                    if (notification.type !== 'error') {
                        setTimeout(() => {
                            store.dispatch(removeNotification(notification.key!))
                        }, time)
                        if (this.props.notifications.some(n => n.type === 'success')) {
                            this.props.notifications
                                .filter(err => err.type === 'error')
                                .forEach(note =>
                                store.dispatch(removeNotification(note.key!))
                            )
                        }
                    } 
                })
        }
    }

    removeNotification = () => {
        this.setState({ visible: false })
        this.props.notifications
            .forEach((notification) => { store.dispatch(removeNotification(notification.key!)) })
    }

    alertType = (alert: any) => {
        if (alert.type === 'error') {
            return(< Alert
                color={alertColors.get(alert.type)}
                className='notification'
                key={alert.key}
                isOpen={this.state.visible}
                toggle={() => this.removeNotification()}
            >
                {alert.message.title ? alert.message.title : alert.message}
            </Alert  >)
        } else {
            return (< Alert
                color={alertColors.get(alert.type)}
                className='notification'
                key={alert.key}
            >
                {alert.message}
            </Alert  >)
        }
    }


    public render() {
        if (this.props.notifications.length > 0) {
            this.props.notifications.forEach(notification => {
                if (typeof notification.message === "string" && notification.message.indexOf('Copy') > -1) {
                    window.onclick = (event: MouseEvent) => {
                        this.removeNotification();
                    }
                }
            })
        }

        return (
            <div className='alert-container'>
                {this.props.notifications
                    .filter((note, i, s) => {
                        return i === s.findIndex((n) => {
                            return note.message === n.message
                        })
                    })
                    .map(alert => {
                        return this.alertType(alert)
                    })}
            </div>
        )
    }
}

export default connect(mapStateToProps)(Notifications)