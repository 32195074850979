import * as React from 'react';
import Toolbar from '../../basic/Toolbar';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ADMTable from './ADMTable'
import * as Clients from '../../../client/ApiClient';
import { DataCell } from '../../basic/DataTable';
import DataListInput from '../../basic/DataListInput';
import { Input  } from 'reactstrap';

interface IADMPagePageState {
    selected: string
    stageTables: Clients.StageTablesModel[]
    columnTake:number
}
interface ADMPageArgs {
    conversionId: string,
}

type ADMPageProps = RouteComponentProps<ADMPageArgs>;

class ADMPagePage extends React.Component<ADMPageProps, IADMPagePageState>{

    private mergeClient: Clients.MergeMapClient = new Clients.MergeMapClient();

    constructor(props: Readonly<ADMPageProps>) {
        super(props)
        this.state = {
            selected: "",
            stageTables: [],
            columnTake: 0,
        }
    }

    componentDidMount() {
        this.getTables()
    }

    getTables = () => {
        this.mergeClient.getStageTables('ADM', this.props.match.params.conversionId)
            .then(response => {
                response && this.setState({ stageTables: response })
            })
            .catch(err => { console.error(err) })
    }

    handleChange = (event: any) => {
        const selected = event.target.value
        let columnTake = 0;
        for (let i = 0; i < this.state.stageTables.length; i++) {
            if (this.state.stageTables[i].name === selected) {
                columnTake = this.state.stageTables[i].columnTake
            }
        }
        this.setState({ selected, columnTake })
    }

    public render() {
        const stageTables = this.state.stageTables
        const selected = this.state.selected
        return (
            <>
                <h3 className='mapping-body-header'>ADM Tables </h3>
                <Toolbar>
                    <DataCell>
                        <Input
                            className="custom-select tableSearch"
                            id={'stagetables'}
                            name={'stagetables'}
                            value={selected || ''}
                            type="select"
                            onChange={(event: any) => this.handleChange(event)}
                            placeholder={"Select Table"}
                        >
                            <option
                                key={0}
                                data-value={''}
                                value={''}
                            >
                                Select Table
                            </option>
                            {stageTables.map((table,i) => (
                                <option
                                    key={i}
                                    data-value={table.name}
                                >
                                    {table.name}
                                </option>
                            ))}
                        </Input >
                    </DataCell>
                </Toolbar>
                {this.state.selected !== "" && <ADMTable source='stage' conversionId={this.props.match.params.conversionId} sectionId={selected} columnTake={this.state.columnTake} />}
            </>
        )
    }
}

export default withRouter(ADMPagePage)