import { sendError } from "../services/Notifications";
import { store } from "AppContext";
import { actionCreators } from "../store/Requests";
import { checkErrorForSessionTimeout } from "../services/UserSession"

type ResultProcessor<T> = (response: Response) => Promise<T>;

export default class BaseClient {
    transformOptions(init: RequestInit): Promise<RequestInit> {
        store.dispatch(actionCreators.incrementActiveRequestsCount())
        return Promise.resolve(init)        
    }

    transformResult<T>(url: string, response: Response, processor: ResultProcessor<T>): Promise<T> {
        return processor(response)
            .then(result => {
                store.dispatch(actionCreators.decrementActiveRequestsCount())
                return Promise.resolve(result)
            })
            .catch<T>(error => {
                sendError(error)
                checkErrorForSessionTimeout(error)
                store.dispatch(actionCreators.decrementActiveRequestsCount())
                return Promise.reject(error)
            })
    }
}
