import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import './Button.scss';

export type ToolbarLinkButtonProps<T = {}> = LinkProps & {
    type: 'primary' | 'secondary',
} & T;

export class ToolbarLinkButton<T = {}> extends React.Component<ToolbarLinkButtonProps<T>> {
    public render() {
        return (
            <Link {...this.props}>
                <Button className={'toolbar-link-button toolbar-link-button-'+this.props.type}>{this.props.children}</Button>
            </Link>
        )
    }
}
