import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ConversionClient, ExecutionsClient, MergeMapClient, MergeMapModel, MergeMapRefModel, MergeTableModel } from '../../../../client/ApiClient';
import { RangePaginationState, RangePagination, paginate } from 'components/basic/Pagination';
import StageActionToolbar from '../entities/generic/StageActionToolbar';
import { stagingPageCopyMappings, StagingPageCopyMappingsItem } from '../entities/generic/Abstractions'

import { AssignRowStatus, RowStatus } from '../entities/generic';
import { DataCell, DataRow, DataTable, DataTableButton } from '../../../basic/DataTable';
import { Button, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown } from 'reactstrap';
import * as Constants from '../Constants';
import { enqueueNotification } from "../../../../store/Notifications"
import { store } from '../../../../AppContext';
import DataListInput from '../../../basic/DataListInput';
import { ValueChangeEvent } from '../../../basic/Events';

interface MergeToolStagePageArgs {
    conversionId: string,
    stage: string,
}

type MergeToolStagePageProps = RouteComponentProps<MergeToolStagePageArgs>;

interface MergeToolStagePageState {
    items: (MergeMapModel
        & StagingPageCopyMappingsItem)[]
    parentItems: (MergeMapModel
        & StagingPageCopyMappingsItem)[]
    ref: MergeMapRefModel[];
    itemsByDestinationCodes: (MergeMapModel
        & StagingPageCopyMappingsItem)[]
    filter: string;
    sortColumn: string;
    sort: boolean;
    mapped: boolean;
    notMapped: boolean;
    lastValue: string;
    value: string | undefined;
    dropDownIndex: number | undefined;
    addToMainIndex: number | undefined;
    includeAddToMain: boolean;
    includeCopyMap: boolean;
    agencyNumbers: {
        agencyNo: string,
        selected: boolean
    }[];
    zero: boolean;
    modal: boolean;
    repeatedCode: string;
    pagination: RangePaginationState;
    mergeTool: MergeTableModel[] | null;
    buttonLock: boolean;
    included: {
        code: boolean,
        parentShortName: boolean,
        parentName: boolean,
        shortName: boolean,
        status: boolean,
        type: boolean,
        categoryDesc: boolean,
        policyCount: boolean,
        clientCount: boolean,
        isUserMapped: boolean,
        isHide: boolean,
    };
}

export class MergeToolStagePage extends React.Component<MergeToolStagePageProps, MergeToolStagePageState>{
    private client: MergeMapClient = new MergeMapClient();
    private conversionClient: ConversionClient = new ConversionClient();
    private readonly executions: ExecutionsClient = new ExecutionsClient();
    private readonly copyMappings = stagingPageCopyMappings(this);

    constructor(props: MergeToolStagePageProps) {
        super(props)
        this.state = {
            items: [],
            parentItems: [],
            ref: [],
            itemsByDestinationCodes: [],
            filter: '',
            sortColumn: '',
            sort: true,
            mapped: true,
            notMapped: true,
            dropDownIndex: undefined,
            addToMainIndex: undefined,
            includeAddToMain: false,
            includeCopyMap: false,
            lastValue: '',
            value: '',
            zero: true,
            agencyNumbers: [],
            modal: false,
            repeatedCode: '',
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            mergeTool: null,
            buttonLock: false,
            included: {
                code: false,
                parentShortName: false,
                parentName: false,
                shortName: false,
                status: false,
                type: false,
                categoryDesc: false,
                policyCount: false,
                clientCount: false,
                isUserMapped: false,
                isHide: false,
            },
        }
    }

    componentDidMount() {
        this.getRef();
        this.getItems();
        this.getConnections(0);
        this.UIController();
        this.mergeTable();
    }

    UIController = () => {
        this.conversionClient.getUIController()
            .then((response) => {
                if (response) this.setState({ includeAddToMain: response!.addToMain, includeCopyMap: response!.copyMapMerge })
            })
            .catch(err => console.log(err))
    }

    mergeTable = () => {
        this.conversionClient.getMergeTable()
            .then((response) => {
                this.setState({ mergeTool: response })
            })
            .catch(err => console.log(err))
    }

    componentDidUpdate(prevProps: MergeToolStagePageProps) {
        if (prevProps.match.params.stage !== this.props.match.params.stage) {
            this.getRef();
            this.getItems();
            const pagination = this.state.pagination
            pagination.pageNumber = 0;
            this.setState({ dropDownIndex: undefined, addToMainIndex: undefined, filter: '', sortColumn: '', mapped: true, notMapped: true, pagination })
        }
    }

    getItems = () => {
        const params = this.props.match.params
        if (params.stage === 'branchmerge') {
            this.getParents(params.conversionId, 'divisionmerge')
        } else if (params.stage === 'departmentmerge') {
            this.getParents(params.conversionId, 'branchmerge')
        } else if (params.stage === 'groupmerge') {
            this.getParents(params.conversionId, 'departmentmerge')
        } else {
            this.setState({ parentItems: [] })
        }
        this.client.get(params.conversionId, params.stage)
            .then((response) => {
                let items = response!;
                const included = this.state.included;
                for (const inc in included) {
                    for (let i = 0; i < items.length; i++) {
                        if ((items[i] as any)[inc] !== null) {
                            (included as any)[inc] = true;
                            break;
                        }
                        (included as any)[inc] = false;
                    }
                }
                if (this.state.sortColumn !== '') {
                    const column = this.state.sortColumn
                    items.sort((a: any, b: any) => {
                        let x = a[column] !== null ? a[column] : ''
                        let y = b[column] !== null ? b[column] : ''
                        if (column === 'isUserMapped') {
                            x = !a.isUserMapped && a.referenceId !== null
                            y = !b.isUserMapped && b.referenceId !== null
                        }
                        if (column === 'isPrimary') {
                            x = a.isPrimary && a.referenceId === null && a.isMapped
                            y = b.isPrimary && b.referenceId === null && b.isMapped
                        }
                        y = typeof y === "string" ? y.toLowerCase().trim() : y
                        x = typeof x === "string" ? x.toLowerCase().trim() : x
                        if (this.state.sort) {
                            if (x < y) { return -1; }
                            if (x > y) { return 1; }
                        } else {
                            if (x > y) { return -1; }
                            if (x < y) { return 1; }
                        }
                        if (x === y) {
                            if (column === 'parentName' || column === 'parentShortName') {
                                const c = 'type'
                                const x2 = a[c] !== null ? a[c].toLowerCase().trim() : ''
                                const y2 = b[c] !== null ? b[c].toLowerCase().trim() : ''
                                if (x2 < y2) return -1
                                if (x2 > y2) return 1;
                                if (x2 === y2) {
                                    const c = column === 'parentName' ? 'name' : 'shortName'
                                    const x3 = a[c] !== null ? a[c].toLowerCase().trim() : ''
                                    const y3 = b[c] !== null ? b[c].toLowerCase().trim() : ''
                                    if (this.state.sort) {
                                        if (x3 < y3) { return -1; }
                                        if (x3 > y3) { return 1; }
                                    } else {
                                        if (x3 > y3) { return -1; }
                                        if (x3 < y3) { return 1; }
                                    }
                                    if (x3 === y3) return 0;
                                }
                            }
                            else {
                                return 0
                            }
                        }
                        return 0
                    })
                }
                this.setState({
                    items, included, addToMainIndex: undefined
                })
            })
            .catch(error => { })
    }

    getParents = (conversionId: string, stage: string) => {
        this.client.get(conversionId, stage)
            .then((response) => {
                let parentItems = response!;
                this.setState({ parentItems })
            })
            .catch(error => { })
    }

    getRef = () => {
        const params = this.props.match.params
        this.client.getRef(params.conversionId, params.stage)
            .then((response) => {
                let ref = response!;
                this.setState({ ref })
            })
            .catch(error => { })
    }

    getConnections = (connectionId: number) => {
        const params = this.props.match.params
        this.conversionClient.getConnectionsAll(params.conversionId, connectionId)
            .then(response => {
                const agencyNumbers: { agencyNo: string, selected: boolean }[] = []
                response!.forEach(r => {
                    agencyNumbers.push({ agencyNo: r.name!, selected: true })
                })
                this.setState({ agencyNumbers })
            })
            .catch(error => { })
    }

    put = (item: MergeMapModel) => {
        const params = this.props.match.params
        const entityDisplayName = Constants.entityNames.get(params.stage.toLowerCase());
        let save = true;
        if (item.mappedValue === null) {
            item.mappedValue = '';
        }
        if (item.referenceId === null) {
            save = false;
        }
        this.client.put(params.conversionId, item.id, item, params.stage)
            .then(async () => {
                if (save) store.dispatch(enqueueNotification({ type: 'success', message: `${entityDisplayName} ${item.name} was saved` }))
                if (params.stage === 'coveragecodes' || params.stage === 'companymerge') {
                this.client.getEntity(params.conversionId, item.id, params.stage)
                    .then((response) => {
                        const items = this.state.items.map(
                            v => v.id === item.id
                                ? v = response![0] : v)
                        this.setState({ items })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                this.getItems();
            }
                if (item.mappedValue === '' && (params.stage === 'divisionmerge' || params.stage === 'branchmerge' || params.stage === 'departmentmerge')) this.clearBusinessUnits(item.shortName!.trim(), params.stage)
            })
            .catch(error => {
                this.getItems();
            })
    }

    clearBusinessUnits = (shortName: string, entity: string) => {
        this.client.clearBusinessUnits(this.props.match.params.conversionId, shortName, entity)
            .then(() => { })
            .catch(error => { console.log(error) })
    }

    handleSearch = (filter: string) => {
        if (filter === '') {
            this.setState({ dropDownIndex: undefined, addToMainIndex: undefined })
        }
        this.setState({ filter })
    }

    sort(column: string) {
        let sort = column === this.state.sortColumn ? !this.state.sort : true;
        const items = this.state.items.sort((a: any, b: any) => {
            let x = a[column] !== null ? a[column] : ''
            let y = b[column] !== null ? b[column] : ''
            if (column === 'isUserMapped') {
                x = !a.isUserMapped && a.referenceId !== null
                y = !b.isUserMapped && b.referenceId !== null
            }
            if (column === 'isPrimary') {
                x = a.isPrimary && a.referenceId === null && a.isMapped
                y = b.isPrimary && b.referenceId === null && b.isMapped
            }
            y = typeof y === "string" ? y.toLowerCase().trim() : y
            x = typeof x === "string" ? x.toLowerCase().trim() : x
            if (sort) {
                if (x < y) { return -1; }
                if (x > y) { return 1; }
            } else {
                if (x > y) { return -1; }
                if (x < y) { return 1; }
            }
            if (x === y) {
                if (column === 'parentName' || column === 'parentShortName') {
                    const c = 'type'
                    const x2 = a[c] !== null ? a[c].toLowerCase().trim() : ''
                    const y2 = b[c] !== null ? b[c].toLowerCase().trim() : ''
                    if (x2 < y2) return -1
                    if (x2 > y2) return 1;
                    if (x2 === y2) {
                        const c = column === 'parentName' ? 'name' : 'shortName'
                        const x3 = a[c] !== null ? a[c].toLowerCase().trim() : ''
                        const y3 = b[c] !== null ? b[c].toLowerCase().trim() : ''
                        if (sort) {
                            if (x3 < y3) { return -1; }
                            if (x3 > y3) { return 1; }
                        } else {
                            if (x3 > y3) { return -1; }
                            if (x3 < y3) { return 1; }
                        }
                        if (x3 === y3) return 0;
                    }
                }
                else {
                    return 0
                }
            }
            return 0
        })
        this.setState({ sort, items, sortColumn: column });
    }

    mappedCheck(check: string) {
        if (check === 'notMapped') {
            const notMapped = !this.state.notMapped
            this.setState({ notMapped })
        } else if (check === 'mapped') {
            const mapped = !this.state.mapped
            this.setState({ mapped })
        }
    }

    zero = () => {
        this.setState({ zero: !this.state.zero })
    }

    agencyNumber = (agencyNo: string) => {
        const agencyNumbers = this.state.agencyNumbers.map(
            a => a.agencyNo === agencyNo
                ? ({
                    ...a,
                    selected: !a.selected,
                })
                : a
        )
        this.setState({ agencyNumbers });
    }

    modalToggle = (repeatedCode: string) => {
        const modal = !this.state.modal
        this.setState({ modal, repeatedCode }, () => {
            if (modal) this.getByDestinationCodes(this.state.repeatedCode)
        })
    }

    getByDestinationCodes = (code: string) => {
        const params = this.props.match.params
        this.client.getByCode(params.conversionId, params.stage, code)
            .then((response) => {
                let itemsByDestinationCodes = response!;
                this.setState({
                    itemsByDestinationCodes
                })
            })
            .catch(error => { })
    }

    handleSelectDestination = (i: number) => {
        const itemsByDestinationCodes = this.state.itemsByDestinationCodes
        let selected = '';
        itemsByDestinationCodes.map((item, index) => {
            if (index === i) {
                item.isPrimary = true
                selected = item.id
            } else {
                item.isPrimary = false
            }
            return item;
        })

        this.setState({ itemsByDestinationCodes }, () => {
            this.updatePrimary(selected)
        })
    }

    updatePrimary(selected: string) {
        const params = this.props.match.params
        this.client.putPrimary(params.conversionId, selected, params.stage, this.state.repeatedCode, this.state.itemsByDestinationCodes.length)
            .then(() => {
                this.getItems();
            })
            .catch(error => { })
    }

    datalist = (item: MergeMapModel): any[] => {
        const arr = [];
        const params = this.props.match.params
        const ref = this.state.ref
        let refFilter = ref.filter(r => r.type === item.type || r.type === null || (r.type === 'Brokerage' && item.type === 'Insurance') || (r.type === 'Insurance' && item.type === 'Brokerage'));
        if (item.type === "Writing") {
            refFilter = []
            const parent = this.state.items.filter(i => i.code === item.parentCoCode && i.agencyNo === item.agencyNo)
            if (parent.length > 0 && parent[0].mappedValue) {
                const parentRef = ref.filter(r => r.id === parent[0].referenceId)
                if (parentRef.length > 0 && parentRef[0].parentCoCode) {
                    refFilter = ref.filter(r => r.parentCoCode === parentRef[0].parentCoCode).sort((a, b) => { return a.type! < b.type! ? -1 : a.type! > b.type! ? 1 : 0 });
                }
            }
        }
        else if (params.stage === 'branchmerge' || params.stage === 'departmentmerge' || params.stage === 'groupmerge') {
            refFilter = []
            const parent = this.state.parentItems.filter(i => i.id === item.parentId)
            if (parent.length > 0 && parent[0].mappedValue) {
                const parentRef = ref.filter(r => r.parentId === parent[0].referenceId)
                if (parentRef.length > 0 && parentRef[0].parentCoCode) {
                    refFilter = ref.filter(r => r.parentCoCode === parentRef[0].parentCoCode);
                }
            }
        }
        else if (params.stage == "billingtransactions") {
            refFilter = ref.filter(r => r.category === item.category)
        }

        for (const [, r] of refFilter.entries()) {
            let string = r.type && r.shortName ? `${r.type} - ${r.shortName!.trim()} - ${r.name!.trim()}` :
                r.shortName ? `${r.shortName!.trim()} - ${r.name!.trim()}` :
                    r.code ? `${r.code!.trim()} - ${r.name!.trim()}` : r.name!.trim();
            if (r.isHide) {
                const hide = r.isHide === 'Y' ? 'Inactive' : 'Active'
                string = string.concat(' - ', hide)
            } else if (r.status) {
                const status = r.status === 'A' ? 'Active' : 'Inactive'
                string = string.concat(' - ', status)
            }
            arr.push(<option
                key={r.id}
                data-value={r.id}
            >
                {string}
            </option>)
        }
        return arr;
    }

    handleValueChange = (id: string, e: { target: { name: any, value: any } }) => {
        const value = e.target.value === "" ? undefined : e.target.value;
        const items = this.state.items.map(
            v => v.id === id
                ? new MergeMapModel({
                    ...v,
                    referenceId: value,
                    isUserMapped: true,
                })
                : v)
        this.setState({ items, value }, () => {
            const i = this.state.items.findIndex(v => v.id === id);
            this.put(MergeMapModel.fromJS(items[i]))
        });
    }


    handleChange = (e: ValueChangeEvent<HTMLInputElement, string>, index: string) => {
        const value = e.value === "" ? undefined : e.value;
        const items = this.state.items.map(
            v => v.id === index
                ? new MergeMapModel({
                    ...v,
                    referenceId: value,
                    mappedValue: "",
                    isUserMapped: true,
                })
                : v)
        this.setState({ items, value }, () => {
            const i = this.state.items.findIndex(v => v.id === index);
            const item = items[i]
            if ((item.type === "Brokerage" || item.type === "Insurance") && value === undefined) {
                const writings = this.state.items.filter(i => i.parentCoCode === item.code && i.type === "Writing" && i.agencyNo === item.agencyNo)
                if (writings.length > 0) {
                    for (let j = 0; j < writings.length; j++) {
                        const write = writings[j]
                        if (write.mappedValue !== null) {
                            write.referenceId = undefined;
                            write.mappedValue = "";
                            this.put(MergeMapModel.fromJS(write))
                        }
                    }
                }
            }
            this.put(MergeMapModel.fromJS(item))
        });
    }

    handleTextChange = (id: string, e: { target: { name: any, value: any } }) => {
        let value = this.props.match.params.stage === 'employeemerge' ? e.target.value.replace(/[^A-Za-z0-9]/gi, '') : e.target.value;
        const items = this.state.items.map(
            v => v.id === id
                ? new MergeMapModel({
                    ...v,
                    mappedValue: value,
                    referenceId: undefined,
                    isUserMapped: true,
                })
                : v)
        this.setState({ items, value });
    }

    mappedValueLast = (id: string, e: { target: { name: any, value: any } }) => {
        let value = this.props.match.params.stage === 'employeemerge' ? e.target.value.replace(/[^A-Za-z0-9]/gi, '') : e.target.value;
        const items = this.state.items.map(
            v => v.id === id
                ? new MergeMapModel({
                    ...v,
                    mappedValueLast: value,
                })
                : v)
        this.setState({ items });
    }

    trim(item: any, length: number) {
        if (item.mappedValue) item.mappedValue = item.mappedValue.trim();
        if (item.mappedValue && item.mappedValue.length > length) {
            const items = this.state.items.map(
                v => v.id === item.id
                    ? new MergeMapModel({
                        ...v,
                        mappedValue: '',
                        referenceId: undefined,
                        isUserMapped: true,
                    })
                    : v)
            this.setState({ items }, () => {
                if (item.mappedValue.length > length) {
                    store.dispatch(enqueueNotification({ type: 'error', message: `You are over the max ${length} character length` }))
                }
            })
        } else if (item.mappedValue === '') {
            this.put(item)
            if (item.type === "Brokerage" || item.type === "Insurance") {
                const writings = this.state.items.filter(i => i.parentCoCode === item.code && i.type === "Writing" && i.agencyNo === item.agencyNo)
                if (writings.length > 0) {
                    for (let j = 0; j < writings.length; j++) {
                        const write = writings[j]
                        if (write.mappedValue !== null) {
                            write.referenceId = undefined;
                            write.mappedValue = "";
                            this.put(MergeMapModel.fromJS(write))
                        }
                    }
                }
            }
        } else if (item.mappedValue !== null && item.mappedValue !== '') {
            item.mappedValue.replace(/[^A-Za-z0-9\"_, '!~&%*-:;<>=?\[\]^\$\(\)@./\\:#&+-]/gi, '')
            this.put(item)
        }
    }

    showDropDown = (index: number | undefined) => {
        const dropDownIndex = index === this.state.dropDownIndex ? undefined : index;
        this.setState({ dropDownIndex, addToMainIndex: undefined })
    }

    addToMain = (index: number | undefined) => {
        const addToMainIndex = index === this.state.addToMainIndex ? undefined : index;
        if (index) {
            const items = this.state.items
            if (index && items[index].referenceId !== null && items[index].referenceId !== undefined) {
                items[index].mappedValue = ''
                this.setState({ items })
            }
        }
        this.setState({ addToMainIndex, dropDownIndex: undefined })
    }

    lockButton = (lock: boolean) => {
        this.setState({ buttonLock: lock })
    }

    public render() {
        const params = this.props.match.params;
        const entityDisplayName = Constants.entityNames.get(params.stage.toLowerCase());
        const mergeObj = this.state.mergeTool && this.state.mergeTool!.find(m => params.stage.toLowerCase() === m.stage);
        const conversionId = params.conversionId;
        const placeHolder = mergeObj ? `Add To Main - ${mergeObj!.length} max` : '';
        const filteredAgencies = this.state.agencyNumbers
            .filter(a => {
                return a.selected
            })
        const items = this.state.items
            .map(AssignRowStatus)
            .filter((item) =>
                (mergeObj && mergeObj!.code && item.code && item.code.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.agencyNo && item.agencyNo.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.parentShortName && item.parentShortName.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.parentName && item.parentName.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.shortName && item.shortName.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.name && item.name.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.type && item.type.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.mappedValue && item.mappedValue.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.mappedValueLast && item.mappedValueLast.toLowerCase().includes(this.state.filter.trim().toLowerCase())))
            .filter(item =>
                (item.mapped === RowStatus.NotMapped && this.state.notMapped)
                || (item.mapped === RowStatus.Mapped && this.state.mapped))
            .filter(item => {
                return filteredAgencies.some(a => {
                    return a.agencyNo === item.agencyNo
                })
            })
            .filter(item => {
                return item.clientCount! > 0 || item.policyCount! > 0 || this.state.zero || item.isMappingDefault
            })
        const zero = !this.state.zero ? "Show All" : "Hide 0 Rows"
        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId={params.stage}
                    handleSearch={this.handleSearch}
                    getItems={this.getItems}
                    handleCopyMapping={this.copyMappings.submitMappings}
                    copyMap={this.state.includeCopyMap}
                    noMapping={false}
                    disableMigrate={true}
                    noClear={false}
                    filter={this.state.filter}
                    lockButton={this.lockButton}
                />
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th className='merge-name'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Agency No
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {this.state.agencyNumbers.map((a: { agencyNo: string, selected: boolean }) => {
                                            return (<div className={!a.selected ? 'mappedChecked' : 'mappedUnchecked'} onClick={() => this.agencyNumber(a.agencyNo)}>{a.agencyNo}</div>)
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                            {this.state.included.code && mergeObj && mergeObj!.code && <th onClick={() => this.sort('code')} className='merge-name'>Code<i className="fa fa-sort"></i></th>}
                            {this.state.included.parentShortName && <th onClick={() => this.sort('parentShortName')} className='merge-name'>Parent Short Name<i className="fa fa-sort"></i></th>}
                            {this.state.included.parentName && <th onClick={() => this.sort('parentName')} className='merge-name'>Parent Name<i className="fa fa-sort"></i></th>}
                            {this.state.included.shortName && <th onClick={() => this.sort('shortName')} className='merge-name'>Short Name<i className="fa fa-sort"></i></th>}
                            <th onClick={() => this.sort('name')} className='merge-name'>{entityDisplayName} Name<i className="fa fa-sort"></i></th>
                            {this.state.included.status && !this.state.included.isHide && <th onClick={() => this.sort('status')} className='merge-name'>Status<i className="fa fa-sort"></i></th>}
                            {this.state.included.isHide && <th onClick={() => this.sort('isHide')} className='merge-name'>Inactive<i className="fa fa-sort"></i></th>}
                            {this.state.included.type && <th onClick={() => this.sort('type')} className='merge-name'>Type<i className="fa fa-sort"></i></th>}
                            {this.state.included.categoryDesc && <th onClick={() => this.sort('categoryDesc')} className='merge-name'>Category<i className="fa fa-sort"></i></th>}

                            <th onClick={() => this.sort('mappedValue')} className='mappedValue'><div className='mappedValueTitle'>Mapped Value<i className="fa fa-sort"></i></div></th>
                            {this.state.included.clientCount && <th className='checkbox-column-merge' onClick={() => this.sort('clientCount')}>Client #<i className="fa fa-sort"></i></th>}
                            {this.state.included.policyCount && <th className='checkbox-column-merge' onClick={() => this.sort('policyCount')}>Policy #<i className="fa fa-sort"></i></th>}
                            <th className='checkbox-column-merge'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Mapped
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <div className={!this.state.notMapped ? 'mappedChecked' : 'mappedUnchecked'} onClick={() => this.mappedCheck('notMapped')}>&#x274C; Not Mapped</div>
                                        <div className={!this.state.mapped ? 'mappedChecked' : 'mappedUnchecked'} onClick={() => this.mappedCheck('mapped')}>&#x2705; Mapped</div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                            {this.state.items && this.state.items[0] && this.state.items[0].isPrimary !== null && this.state.includeAddToMain && mergeObj && mergeObj!.addToMain && <th className='checkbox-column-merge' onClick={() => this.sort('isPrimary')}>Primary<i className="fa fa-sort"></i></th>}
                            {this.state.included.isUserMapped && <th className='checkbox-column-merge' onClick={() => this.sort('isUserMapped')}>Auto-Mapped<i className="fa fa-sort"></i></th>}
                            <th className='merge-name-toggle'><label className="switch"><input type="checkbox" readOnly checked={!this.state.zero} onClick={this.zero} /><span className="slider round"></span></label>{zero}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginate(items, this.state.pagination, (item, index) => {
                                const datalist = index === this.state.dropDownIndex ? this.datalist(item) : [];
                                let parentCheck = false;
                                let parentType = '';
                                if (params.stage === 'branchmerge') {
                                    parentType = 'Division'
                                } else if (params.stage === 'departmentmerge') {
                                    parentType = 'Branch'
                                } else if (params.stage === 'groupmerge') {
                                    parentType = 'Department'
                                }
                                if (item.type === "Writing") {
                                    const parent = this.state.items.filter(i => i.code === item.parentCoCode && i.agencyNo === item.agencyNo)
                                    if (parent.length > 0) {
                                        const parentMappedValue = parent[0].mappedValue;
                                        parentCheck = parentMappedValue === '' || parentMappedValue === null;
                                    }
                                } else if (params.stage === 'branchmerge' || params.stage === 'departmentmerge' || params.stage === 'groupmerge') {
                                    const parent = this.state.parentItems.filter(i => i.id === item.parentId)
                                    if (parent.length > 0) {
                                        const parentMappedValue = parent[0].mappedValue;
                                        parentCheck = parentMappedValue === '' || parentMappedValue === null;
                                    }
                                }

                                const ref = params.stage === "billingtransactions" ? this.state.ref.filter(r => r.category === item.category) : this.state.ref;
                                return (<DataRow id={item.id.toString()} key={item.id}>
                                    <DataCell className='merge-name'>{item.agencyNo && item.agencyNo!.trim()}</DataCell>
                                    {this.state.included.code && mergeObj && mergeObj!.code && <DataCell className='merge-name'>{item.code && item.code!.trim()}</DataCell>}
                                    {this.state.included.parentShortName && <DataCell className='merge-name'>{item.parentShortName && item.parentShortName!.trim()}</DataCell>}
                                    {this.state.included.parentName && <DataCell className='merge-name'>{item.parentName && item.parentName!.trim()}</DataCell>}
                                    {this.state.included.shortName && <DataCell className='merge-name'>{(entityDisplayName !== 'Companies' || (entityDisplayName === 'Companies' && (item.type === 'Writing' || this.state.sortColumn === 'shortName'))) && item.shortName && item.shortName!.trim()}</DataCell>}
                                    <DataCell className='merge-name-hidden'>{(entityDisplayName !== 'Companies' || (entityDisplayName === 'Companies' && (item.type === 'Writing' || this.state.sortColumn === 'name'))) && item.name && item.name!.trim()}</DataCell>
                                    {this.state.included.status && !this.state.included.isHide && <DataCell className='merge-name'>{item.status && item.status!.trim()}</DataCell>}
                                    {this.state.included.isHide && <DataCell className='merge-name'>{item.isHide && item.isHide!.trim()}</DataCell>}
                                    {this.state.included.type && <DataCell className='merge-name'>{item.type && item.type!.trim()}</DataCell>}
                                    {this.state.included.categoryDesc && <DataCell className='merge-name'>{item.categoryDesc && item.categoryDesc!.trim()}</DataCell>}
                                    {(mergeObj && mergeObj!.addToMain) ?
                                        <DataCell
                                            className='mappedValue addToMainContainer'
                                        >
                                            {index === this.state.dropDownIndex ?
                                                <div className={this.state.includeAddToMain && mergeObj && mergeObj!.addToMain ? "addToMain" : ''}>
                                                    <DataListInput
                                                        id={item.id.toString()}
                                                        value={item.referenceId ? item.referenceId : undefined}
                                                        onValueChange={event => this.handleChange(event, item.id)}
                                                        placeholder={item.type === 'Writing' && datalist.length === 0 ? `Map Parent ${item.parentShortName}` : ''}
                                                        disabled={item.type === 'Writing' && datalist.length === 0 || this.state.buttonLock}
                                                    >
                                                        <option data-value='' />
                                                        {datalist}
                                                    </DataListInput>
                                                </div> : index === this.state.addToMainIndex || (item.mappedValue !== null && item.mappedValue !== '' && item.referenceId === null) ?
                                                    <Input
                                                        className={this.state.includeAddToMain && mergeObj && mergeObj!.addToMain ? "addToMain" : ''}
                                                        value={item.mappedValue}
                                                        onChange={(e) => this.handleTextChange(item.id, e)}
                                                        onFocus={(e) => (this.setState({ lastValue: e.target.value.trim(), addToMainIndex: index }, () => { this.mappedValueLast(item.id, e) }))}
                                                        onBlur={() => {
                                                            if (item.mappedValue !== this.state.lastValue) {
                                                                this.trim(item, mergeObj!.length)
                                                            }
                                                        }}
                                                        disabled={this.state.buttonLock}
                                                        placeholder={placeHolder}>{ }
                                                    </Input>
                                                    :
                                                    <Input
                                                        className={this.state.includeAddToMain && mergeObj && mergeObj!.addToMain ? "addToMain" : ''}
                                                        value={item.mappedValue || ''}
                                                        onFocus={() => this.showDropDown(index)}
                                                        placeholder={parentCheck ? `Map ${parentType} ${item.parentShortName}` : ''}
                                                        disabled={parentCheck || this.state.buttonLock}
                                                    >{ }
                                                    </Input>
                                            }
                                            {this.state.includeAddToMain && mergeObj && mergeObj!.addToMain && !item.isMappingDefault && !parentCheck && < DataTableButton
                                                icon={(this.state.addToMainIndex === index || (item.mappedValue !== null && item.mappedValue !== '' && item.referenceId === null)) && index !== this.state.dropDownIndex ? 'list' : 'edit'}
                                                id={"addToMain"}
                                                title={(this.state.addToMainIndex === index || (item.mappedValue !== null && item.mappedValue !== '' && item.referenceId === null)) && index !== this.state.dropDownIndex ? 'Dropdown List' : 'Add To Main'}
                                                action={() => (this.state.addToMainIndex === index || (item.mappedValue !== null && item.mappedValue !== '' && item.referenceId === null)) && index !== this.state.dropDownIndex ? this.showDropDown(index) : this.addToMain(index)}
                                            />}
                                        </DataCell> :
                                        <DataCell className="mappedValue">
                                            {(mergeObj && mergeObj!.stage !== 'profilequestions') ?
                                                <div className={""}>
                                                    <DataListInput
                                                        id={item.id.toString()}
                                                        value={item.referenceId ? item.referenceId : undefined}
                                                        onValueChange={event => this.handleChange(event, item.id)}
                                                        placeholder={parentCheck ? `Map ${parentType} ${item.parentShortName}` : ''}
                                                        disabled={parentCheck || this.state.buttonLock}
                                                    >
                                                        <option data-value='' />
                                                        {this.datalist(item)}
                                                    </DataListInput>
                                                </div> :
                                                <select disabled={this.state.buttonLock} className="custom-select" name='mappedValue' value={item.referenceId || ''} id={item.id} onChange={e => this.handleValueChange(item.id, e)}>
                                                    <option value={""}>Select Destination</option>
                                                    {ref
                                                        .map((r, i) =>
                                                            <option value={r.id}>
                                                                {r.code ? r.shortName ? `${r.code} - ${r.shortName} - ${r.name}` : `${r.code} - ${r.name}` : r.name}
                                                            </option>
                                                        )}
                                                </select>}
                                        </DataCell>
                                    }
                                    {this.state.included.clientCount && <DataCell className='checkbox-column-merge'>{!item.isMappingDefault && item.clientCount}</DataCell>}
                                    {item.policyCount !== null && <DataCell className='checkbox-column-merge'>{!item.isMappingDefault && item.policyCount}</DataCell>}
                                    <DataCell className='checkbox-column-merge' itemProp={item.mapped === RowStatus.NotMapped ? 'unmapped' : item.mapped === RowStatus.Mapped ? 'mapped' : 'dropped'}>
                                        {item.mapped === RowStatus.NotMapped ? < p className='mapped' itemProp='unmapped'>&#x274C;</p> : item.mapped === RowStatus.Mapped ? < p className='mapped' itemProp='mapped'>&#x2705;</p> : < p className='mapped' itemProp='dropped'>&#x2796;</p>}
                                        {/*{item.isPrimary !== null && mergeObj && mergeObj!.addToMain && <DataCell className='checkbox-column-merge'>*/}
                                        {/*    <DataTableButton*/}
                                        {/*        id={item.id + "-primary"}*/}
                                        {/*        action={() => this.modalToggle(item.mappedValue!)}*/}
                                        {/*        title='Click to set a primary record'*/}
                                        {/*    >*/}
                                        {/*        {item.isPrimary && item.referenceId === null && item.mapped === RowStatus.Mapped ?*/}
                                        {/*            <span className='mapped' itemProp='mapped'>&#x2705;</span> : item.referenceId === null && item.mapped === RowStatus.Mapped ?*/}
                                        {/*                <span className='mapped' itemProp='unmapped'>&#x274C;</span> : null}*/}
                                        {/*    </DataTableButton>*/}
                                        {/*</DataCell>}*/}
                                    </DataCell>
                                    {this.state.includeAddToMain && mergeObj && mergeObj!.addToMain &&
                                        <DataCell
                                            className='checkbox-column-merge'
                                            itemProp={item.isPrimary && item.referenceId === null && item.mapped === RowStatus.Mapped ? 'primary' :
                                                item.referenceId === null && item.mapped === RowStatus.Mapped ? 'non-primary' : ''}
                                        >
                                            {item.isPrimary && item.referenceId === null && item.mapped === RowStatus.Mapped ?
                                                < p className='mapped' itemProp='primary'>&#x2705;</p> :
                                                item.referenceId === null && item.mapped === RowStatus.Mapped ?
                                                    < p className='mapped' itemProp='non-primary'>&#x274C;</p> : null}
                                        </DataCell>}
                                    {this.state.included.isUserMapped &&
                                        <DataCell
                                            className='checkbox-column-merge'
                                            itemProp={!item.isUserMapped && item.referenceId !== null ? 'auto-mapped' : ''}
                                        >
                                            {!item.isUserMapped && item.referenceId !== null ? < p className='mapped' itemProp='auto-mapped'>&#x2705;</p> : ''}
                                        </DataCell>}
                                    <DataCell className='merge-name-toggle'>
                                    </DataCell>
                                </DataRow>)
                            })
                        }
                    </tbody>
                </DataTable>
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
                <div>
                    <Modal isOpen={this.state.modal}>
                        <ModalHeader>Please Set Primary of Destination: {this.state.repeatedCode}</ModalHeader>
                        <ModalBody>
                            <select
                                name='isPrimary'
                                className="custom-select"
                                onChange={(e) => this.handleSelectDestination(Number(e.target.value))}
                                id={this.state.repeatedCode.toString()}
                                key={this.state.repeatedCode}
                            >
                                {this.state.itemsByDestinationCodes.map((item, index) => (
                                    <option
                                        value={index}
                                        key={item.id}
                                        selected={item.isPrimary}
                                    >
                                        {item.code ? item.shortName ? `${item.code} - ${item.shortName} - ${item.name}` : `${item.code} - ${item.name}` : item.name}
                                    </option>
                                ))}
                            </select>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.modalToggle(this.state.repeatedCode)}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}