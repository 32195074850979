import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { NoteClient, NoteModel } from '../../../../../client/ApiClient';
import { RangePaginationState, RangePagination } from 'components/basic/Pagination';
import StageActionToolbar from '../generic/StageActionToolbar';

import '../../../../../styles/Icons.scss';

interface NoteStagingPageArgs {
    conversionId: string,
}

type NoteStagingPageProps = RouteComponentProps<NoteStagingPageArgs>;

interface NoteStagingPageState {
    items: NoteModel[];
    filter: string;

    pagination: RangePaginationState;
}

export class NoteStagingPage extends React.Component<NoteStagingPageProps, NoteStagingPageState>{
    private client: NoteClient = new NoteClient()

    constructor(props: NoteStagingPageProps) {
        super(props)
        this.state = {
            items: [],
            filter: '',

            pagination: {
                pageSize: 25,
                pageNumber: 0,
            }
        }
    }

    componentDidMount() {

    }


    public render() {
        const conversionId = this.props.match.params.conversionId;
        const items = this.state.items;

        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='notes'
                    tierGate='tier4'
                    noMapping={true}
                    filter={this.state.filter}
                />
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
            </>
        );
    }
}