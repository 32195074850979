import * as React from 'react';
import * as Clients from '../../client/ApiClient';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardText, CardTitle, Table } from 'reactstrap';
import { DataCell, DataRow, DataTableButton, DataTable } from './DataTable';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'

interface IUIControllerRunningExecutionsPageState {
    executions: Clients.ExecutionsModel[] | null;
    hubConnection: HubConnection | null;
}

class UIControllerRunningExecutionsPage extends React.Component<RouteComponentProps, IUIControllerRunningExecutionsPageState>{
    private readonly client: Clients.ExecutionsClient = new Clients.ExecutionsClient();

    constructor(props: Readonly<RouteComponentProps>) {
        super(props)
        this.state = {
            executions: [],
            hubConnection: null,
        }
    }

    componentDidMount() {
        this.getExecutions()
        const link = '/executions';
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log("Connection started for Image Table"))
                .catch(() => console.log("there is an error"));
            this.state.hubConnection!.on("ExecutionCalled", (running) => {
                this.getExecutions()
            })
        })
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
    }

    getExecutions = () => {
        this.client.listRunningExecutions()
            .then(response => {
                this.setState({executions:response})
            })
            .catch(err => {
                console.log(err)
            })
    }

    killExecution = (id: any, packageName: any) => {
        if (window.confirm("Confirm to cancel " + packageName) == true) {
            this.client.killRunningExecution(id)
                .then()
                .catch(err => {
                    console.log(err)
                })
        }
    }

    public render() {
        return (
            <>
                <Card style={{
                    width: '85rem',
                    margin: '1% 10%'
                }}>
                    <DataTable hover>
                        <thead>
                            <tr>
                                <th>
                                    Project Name
                                </th>
                                <th>
                                    Package Name
                                </th>
                                <th>
                                    Start Time
                                </th>
                                <th>
                                    Estimated End Time
                                </th>
                                <th className='status-column'>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.executions && this.state.executions!
                                .map(u => {
                                    return (<DataRow id={u.id!.toString()} key={u!.id}>
                                        <DataCell>
                                            {u.conversionDescription}
                                        </DataCell>
                                        <DataCell>
                                            {u.packageName}
                                        </DataCell>
                                        <DataCell>
                                            {u.startTime && u.startTime!.toLocaleString()}
                                        </DataCell>
                                        <DataCell>
                                            {u.estimatedFinish && u.estimatedFinish!.toLocaleString()}
                                        </DataCell>
                                        <DataCell className='status-column'>
                                            <DataTableButton
                                                icon='close'
                                                id={"removeEmailList"}
                                                title={"Remove: " + u.packageName}
                                                action={() => this.killExecution(u.executionId!, u.packageName)} />
                                        </DataCell>
                                    </DataRow>)
                                })}
                        </tbody>
                    </DataTable>
                </Card>
            </>
        )
    }
}

export default withRouter(UIControllerRunningExecutionsPage)