import * as Clients from 'client/ApiClient';

import { actionCreators } from 'store/Authentication';
import { store } from 'AppContext';
import * as Roles from 'constants/Roles';

export class Authentication {
    private static readonly UserLoginModelKey = "userDetails";

    private static readonly UserClientKey = "userClient";

    private static readonly AMS360URLSAGENCY = "AMS360URLS.AGENCY";
    private static readonly AMS360URLSACC = "AMS360URLS.ACC";

    public static readonly RefreshDelayMilliseconds = 1000 * 60 * 10;

    public static readonly GracePeriodSeconds = 10;

    private readonly accountClient: Clients.AccountClient;

    private readonly clientClient: Clients.ClientClient;

    private readonly utilitiesClient: Clients.UtilitiesClient;

    private readonly getCurrentDate: () => Date;

    constructor(accountClient: Clients.AccountClient, getCurrentDate: () => Date, clientClient: Clients.ClientClient, utilitiesClient: Clients.UtilitiesClient) {
        this.accountClient = accountClient;
        this.getCurrentDate = getCurrentDate;
        this.clientClient = clientClient;
        this.utilitiesClient = utilitiesClient;

        this.queueTokenRefresh();
    }

    login = (login: string, password: string): Promise<Clients.UserLoginDetailsModel> => {
        return this.accountClient.login(
            new Clients.UserLoginModel({
                userName: login,
                password: password,
            }))
            .then(details => {
                this.setCurrentUser(details!);
                if (details!.role === Roles.customerAdministratorRoleId || details!.role === Roles.customerUserRoleId) {
                    this.clientClient.getDescription().then(result => this.setCurrentClient(result!))
                }
                return details!;
            });
    }

    logout = (): Promise<void> => {
        return this.accountClient.logout()
            .then(() => this.removeCurrentUser());
    }


    getAMS360URLs = (): Promise<void> => {
        return this.utilitiesClient.getConfigurationValue("AMS360URLS:Agency")
            .then((response) => {
                if (response)
                    this.setLocalStorageValue(Authentication.AMS360URLSAGENCY, response.valueOf());

                this.utilitiesClient.getConfigurationValue("AMS360URLS:ACC")
                    .then((response) => {
                        if (response)
                            this.setLocalStorageValue(Authentication.AMS360URLSACC, response.valueOf());
                    })
            })
    }
    queueTokenRefresh = () => {
        setTimeout(this.queueTokenRefreshAndDoRefresh, Authentication.RefreshDelayMilliseconds);
    }

    refreshToken = async () => {
        if (this.shouldRefreshToken()) {
            const details = await this.accountClient.refreshToken()
            this.setCurrentUser(details!)
        }
    }

    getCurrentUser(): Clients.UserLoginDetailsModel | undefined {
        const serialized = localStorage.getItem(Authentication.UserLoginModelKey);
        if (!serialized) {
            return undefined;
        }
        const currentUser = Clients.UserLoginDetailsModel.fromJS(JSON.parse(serialized));
        if (this.isUserExpired(currentUser)) {
            return undefined;
        }
        return currentUser;
    }

    getCurrentClient(): string {
        const currentClient = localStorage.getItem(Authentication.UserClientKey);
        if (!currentClient) {
            return "";
        }
        return currentClient;
    }

    getAMS360URLAgency(): string {
        const value = localStorage.getItem(Authentication.AMS360URLSAGENCY);
        if (!value) {
            return "";
        }
        return value;
    }

    getAMS360URLACC(): string {
        const value = localStorage.getItem(Authentication.AMS360URLSACC);
        if (!value) {
            return "";
        }
        return value;
    }

    setEulaAccepted() {
        const currentUser = this.getCurrentUser()
        if (currentUser) {
            currentUser.isEulaAccepted = true;

            this.setCurrentUser(currentUser);
        }
    }

    private queueTokenRefreshAndDoRefresh = async () => {
        this.queueTokenRefresh();
        await this.refreshToken();
    }

    private setCurrentUser(user: Clients.UserLoginDetailsModel) {
        const serialized = JSON.stringify(user.toJSON());
        localStorage.setItem(Authentication.UserLoginModelKey, serialized);
        store.dispatch(actionCreators.update());
    }

    private setCurrentClient(clientDescription: string) {
        localStorage.setItem(Authentication.UserClientKey, clientDescription);
        store.dispatch(actionCreators.update());
    }


    private setLocalStorageValue(key: string, value: string) {
        localStorage.setItem(key, value);
        store.dispatch(actionCreators.update());
    }
    private removeCurrentUser() {
        localStorage.removeItem(Authentication.UserLoginModelKey);
        localStorage.removeItem(Authentication.AMS360URLSACC);
        localStorage.removeItem(Authentication.AMS360URLSAGENCY);
        store.dispatch(actionCreators.update());
    }

    private shouldRefreshToken(): boolean {
        const currentUser = this.getCurrentUser();

        if (!currentUser) {
            return false;
        }

        if (this.isUserExpired(currentUser)) {
            return false;
        }

        const refreshDate = this.getCurrentDate(); // The latest date the token may be refreshed by this instance
        refreshDate.setMilliseconds(refreshDate.getMilliseconds() + Authentication.RefreshDelayMilliseconds);
        refreshDate.setSeconds(refreshDate.getSeconds() + Authentication.GracePeriodSeconds);

        if (currentUser.expiration > refreshDate) {
            // Leave it up to the next refresh. This way the client won't flood
            // the API with requests if user got several tabs open.
            return false;
        }

        return true;
    }

    private isUserExpired(user: Clients.UserLoginDetailsModel): boolean {
        const currentDate = this.getCurrentDate();
        currentDate.setSeconds(currentDate.getSeconds(), -Authentication.GracePeriodSeconds);
        return user.expiration <= currentDate;
    }
}

export const authentication =
    new Authentication(
        new Clients.AccountClient(),
        () => new Date(),
        new Clients.ClientClient(),
        new Clients.UtilitiesClient()
    );
