import * as React from 'react';
import { Progress } from 'reactstrap';
import { ImageQueueClient, ImageQueueModel } from '../../client/ApiClient';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'

interface IImageLoadingBarProps {
    conversionId: string;
    email?: string;
}

interface ImageLoadingBarPageState {
    progress: ImageQueueModel | null
    hubConnection: HubConnection | null;
}

export default class ImageLoadingBar extends React.Component<IImageLoadingBarProps, ImageLoadingBarPageState>{

    private client: ImageQueueClient = new ImageQueueClient()

    constructor(props: IImageLoadingBarProps) {
        super(props)
        this.state = {
            progress: null,
            hubConnection: null,
        }
    }

    componentDidMount() {
        this.getProgress();
        const link = '/executions';
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log("Connection started for Image Table"))
                .catch(() => console.log("there is an error"));

            this.state.hubConnection!.on("ImageLoaderCalled", (message) => {
                this.getProgress();
            })
        })
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
    }

    getProgress = () => {
        this.client.imageQueue(this.props.conversionId)
            .then(response => {
                if (response) {
                    this.setState({ progress: response }, () => {
                        if (response.total !== response.uploaded + response.error) {
                            setTimeout(this.getProgress, 1000);
                        } else {
                            //this.conversion.sendEmail('Finished processing Image Loader',this.props.email!)
                            //    .then()
                            //    .catch(err => {
                            //        console.log(err)
                            //    })
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    public render() {
        const uploaded = this.state.progress ? this.state.progress.uploaded : 0;
        const total = this.state.progress && this.state.progress.total > 0 ? this.state.progress.total : 1;
        const error = this.state.progress ? this.state.progress.error : 0;
        let precent = (uploaded / total) * 100;
        let err = (error / total) * 100;
        return (
            <div>
                <Progress multi>
                    <Progress bar color="success" value={precent} > {precent.toFixed(2)} %</Progress>
                    <Progress bar color="danger" value={err}> {err.toFixed(2)} %</Progress>
                </Progress >
            </div>

        )
    }
}