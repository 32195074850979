import * as React from 'react';
import { FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { IEnvironmentModel, ConversionsServersListModel } from '../../../client/ApiClient';

interface ISourceFormProps {
    isEditing: boolean;
    source: IEnvironmentModel;
    handleChange: (value: string, e: any) => void;
    serverList: ConversionsServersListModel[];
    errors: any;
    get: (errors: any, val: string) => React.ReactNode;
}

export default class SourceForm extends React.Component<ISourceFormProps, {}>{
    public render() {
        return (
            <Row>
                <Col md={{ size: 5, offset: 1 }}>
                    {this.props.isEditing ?
                        <FormGroup>
                            <Label for="sourceHost">Host</Label>
                            <Input
                                type="text"
                                name="sourceHost"
                                id="sourceHost"
                                value={this.props.source.host}
                                readOnly={this.props.isEditing}
                            />
                        </FormGroup>
                        :
                        <FormGroup>
                            <Label for="sourceHost">Host</Label>
                            <select
                                className="custom-select"
                                id="sourceHost"
                                value={this.props.source.host}
                                onChange={e => this.props.handleChange('source.host', e)}
                                disabled={this.props.isEditing}
                            >
                                <option key={1} value={''}>Please Select Source Host</option>
                                {this.props.serverList
                                    .filter(server => server.serverType === 0)
                                    .map((server) => (
                                        <option
                                            key={server.id}
                                            value={server.serverInstanceName}
                                        >
                                            {server.serverInstanceName}
                                        </option>
                                    ))}
                            </select>
                            <div className='validationError'>
                                <p>{this.props.get(this.props.errors, 'source.host')}</p>
                            </div>
                        </FormGroup>}
                </Col>
                <Col md={{ size: 5 }}>
                    <FormGroup>
                        <Label for="sourceCatalog">Catalog</Label>
                        <Input
                            type="text"
                            name="sourceCatalog"
                            id="sourceCatalog"
                            maxLength={50}
                            value={this.props.source.catalog || ''}
                            onChange={e => this.props.handleChange('source.catalog', e)}
                            readOnly={this.props.isEditing}
                        />
                        <div className='validationError'>
                            <p>{this.props.get(this.props.errors, 'source.catalog')}</p>
                        </div>
                    </FormGroup>
                </Col>
            </Row >
        )
    }
}