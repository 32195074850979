import { Backdrop, CircularProgress } from '@mui/material';
import * as React from "react";
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';

import './LoadingOverlay.scss';

interface ILoadingOverlayProps {
    requestsInProgress: number,
    settingPDCodeStageState?:boolean
}

interface ILoadingOverlayState {
    show?: boolean,
    settingPDCodeStageState?:boolean
}


const mapStateToProps = (state: ApplicationState, ownProps: ILoadingOverlayState): ILoadingOverlayProps => {
    return {
        requestsInProgress: state.requests.requestsInProgress,
    }
}

class LoadingOverlay extends React.Component<ILoadingOverlayProps, ILoadingOverlayState>{
    constructor(props: ILoadingOverlayProps) {
        super(props)
        this.state = {
            show: false,
            settingPDCodeStageState: props.settingPDCodeStageState
        }
    }

    componentDidUpdate(prevProps: ILoadingOverlayProps) {
        if (prevProps.settingPDCodeStageState !== this.props.settingPDCodeStageState) {
            this.setState({ settingPDCodeStageState: this.props.settingPDCodeStageState })
        }
        if (this.props.requestsInProgress !== prevProps.requestsInProgress) {
            if (this.props.requestsInProgress > 0) {
                setTimeout(() => {
                    if (this.props.requestsInProgress > 0) {
                        this.setState({
                            show: true
                        })
                    }
                }, 1000)
            }
            else {
                setTimeout(() => {
                    if (this.props.requestsInProgress === 0) {
                        this.setState({
                            show: false
                        })
                    }
                }, 200)
            }
        }
    }

    public render() {
        return (
                <Backdrop
                className='loading-overlay'
                open={!!(this.state.show || this.state.settingPDCodeStageState)}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
        )
    }
}

export default connect(mapStateToProps)(LoadingOverlay)