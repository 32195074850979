import * as React from 'react';
import { DataCell, DataRow, DataTable, DataTableButton } from './DataTable';
import { RangePaginationState, RangePagination, paginate } from 'components/basic/Pagination';
import { ImageLogModel, ImageQueueClient, ConversionReportsClient } from '../../client/ApiClient';
import { Row, Col, Button } from 'reactstrap';
import StageToolbar from '../conversion/mapping/entities/generic/StageToolbar';
import ImageLoadingBar from './ImageLoadingBar';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { enqueueNotification } from "../../store/Notifications"
import { store } from '../../AppContext';
import { saveAs } from 'file-saver';

interface IImageLogTableProps {
    conversionId: string;
    email?: string;
    tab: string;
}

interface ImageLogTableState {
    items: ImageLogModel[]
    pagination: RangePaginationState;
    filter: string;
    sort: boolean;
    hubConnection: HubConnection | null;
    dateRefreshed: string;
}

export default class ImageLogTable extends React.Component<IImageLogTableProps, ImageLogTableState>{
    private client: ImageQueueClient = new ImageQueueClient()
    private report: ConversionReportsClient = new ConversionReportsClient();

    constructor(props: IImageLogTableProps) {
        super(props);
        this.state = {
            items: [],
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            filter: '',
            sort: true,
            hubConnection: null,
            dateRefreshed: '',
        }
    }

    componentDidMount() {
        document.body.classList.add('hideScroll');
        const link = '/executions';
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log("Connection started for Image Table"))
                .catch(() => console.log("there is an error"));
            this.state.hubConnection!.on("ImageLoaded", (email, message) => {
                store.dispatch(enqueueNotification({ type: 'success', message: message }))
            })
        })
    }

    componentDidUpdate(prevProps: IImageLogTableProps) {
        if (prevProps.tab !== this.props.tab && this.props.tab === 'imageLog' && this.state.items.length === 0) {
            this.getItems();
        }
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
        document.body.classList.remove('hideScroll');
    }


    getItems = () => {
        this.client.imageLog(this.props.conversionId)
            .then(response => {
                const date = new Date()
                const dateRefreshed = date.toLocaleTimeString()
                if (response) {
                    this.setState({ items: response, dateRefreshed });
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleSearch = (filter: string) => {
        this.setState({ filter });
    }

    sort(column: string) {
        const items = this.state.items.sort((a: any, b: any) => {
            let x = a[column]
            let y = b[column]
            if (typeof y === "string") {
                y = y.toLowerCase().trim()
                x = x.toLowerCase().trim()
            }
            if (this.state.sort) {
                if (x < y) { return -1; }
                if (x > y) { return 1; }
            } else {
                if (x > y) { return -1; }
                if (x < y) { return 1; }
            }
            return 0;
        })
        this.setState({ sort: !this.state.sort, items });
    }

    exportToExcel = (id: string) => {
        this.report.exportToExcel(this.props.conversionId, id, null, null)
            .then(response => saveAs(response!.data, response!.fileName))
            .catch(error => { });
    }

    public render() {
        //const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const items = this.state.items
            .filter(i => (i.pathToSourceImage && i.pathToSourceImage!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.description && i.description!.toLowerCase().replace('\r\n', ' ').includes(this.state.filter.trim().toLowerCase()))
                //||
                //(i.dateCreated && i.dateCreated!.toLocaleDateString('en-US', options).toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                //(i.readyForProcessingDate && i.readyForProcessingDate!.toLocaleDateString('en-US', options).toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                //(i.message && i.message!.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
            )
        const date = new Date("Mon Jan 01 1900 00:00:00 GMT-0700")
        return (
            <>
                <StageToolbar
                    conversionId={this.props.conversionId}
                    handleSearch={this.handleSearch}
                    filter={this.state.filter}
                >
                    <Button
                        className='load-button'
                        onClick={this.getItems}
                    > {this.state.dateRefreshed !== '' ? 'Refresh Table Log' : 'Load Table Log'} </Button>
                </StageToolbar>
                <Row>
                    <div className={"imageLogIcon"}>
                        <DataTableButton
                            icon='file_download'
                            id={"imageLog"}
                            title='Export to Excel'
                            action={() => this.exportToExcel(`imageLog`)} />
                    </div>
                    <Col s={{ size: 1 }}>
                        <ImageLoadingBar conversionId={this.props.conversionId} email={this.props.email} />
                    </Col>
                    {this.state.dateRefreshed !== '' && <div className={"timeLog"}>Last loaded: {this.state.dateRefreshed}</div>}
                </Row>
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th onClick={() => this.sort('pathToSourceImage')}>Path <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('description')}>Description <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('message')}>Message <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isCrawled')}>Succeed <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isWarning')}>Warning <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isError')}>Failed <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('dateCreated')}>Date Created <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('readyForProcessingDate')}>Date Processed <i className="fa fa-sort"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginate(items, this.state.pagination, (item, index) => (
                                <DataRow id={item.id.toString()} key={item.id}>
                                    <DataCell>{item.pathToSourceImage}</DataCell>
                                    <DataCell>{item.description}</DataCell>
                                    <DataCell>{item.message}</DataCell>
                                    <DataCell>{item.isCrawled && <p className='mapped'>&#x2705;</p>}</DataCell>
                                    <DataCell>{item.isWarning && <p className='mapped'>&#x26A0;</p>}</DataCell>
                                    <DataCell>{item.isError && <p className='mapped'>&#x274C;</p>}</DataCell>
                                    <DataCell>{item.dateCreated.toLocaleDateString()}</DataCell>
                                    <DataCell>{date < item.readyForProcessingDate ? item.readyForProcessingDate.toLocaleDateString() : "Image not processed"}</DataCell>
                                </DataRow>
                            ))
                        }
                    </tbody>
                </DataTable>
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
            </>
        );
    }
}