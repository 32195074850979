import * as React from 'react';
import { Button, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { ConversionsServersListModel } from '../../../client/ApiClient';

interface ISourceFormProps {
    isEditing: boolean;
    source: any;
    handleChangeSource: (e: any, index: number) => void;
    serverList: ConversionsServersListModel[];
    multiple: boolean;
    number: number;
    id: number;
    errors: any;
    close: (index: number) => void;
    get: (errors: any, val: string) => React.ReactNode;
}

export default class MultiSourceForm extends React.Component<ISourceFormProps, {}>{
    public render() {
        return (
            <>
                <Row>
                    {this.props.isEditing &&
                        <Col md={{ size: 3, offset: 1 }}>
                            <FormGroup>
                                <Label for="name">Agency No</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={this.props.source.name || ''}
                                    readOnly={this.props.isEditing}
                                />
                            </FormGroup>
                        </Col>
                    }
                    {!this.props.isEditing &&
                        <Col md={{ offset: 1 }}>
                        </Col>
                    }
                    <Col md={{ size: this.props.isEditing ? 3 : 5 }}>
                        {this.props.isEditing ?
                            <FormGroup>
                                <Label for="host">Host</Label>
                                <Input
                                    type="text"
                                    name="host"
                                    id="host"
                                    value={this.props.source.host}
                                    readOnly={this.props.isEditing}
                                />
                            </FormGroup>
                            :
                            <FormGroup>
                                <Label for="host">Host</Label>
                                <select
                                    className="custom-select"
                                    id="host"
                                    name="host"
                                    value={this.props.source.host}
                                    onChange={e => this.props.handleChangeSource(e, this.props.id)}
                                    disabled={this.props.isEditing}
                                >
                                    <option key={1} value={''}>Please Select Source Host</option>
                                    {this.props.serverList
                                        .filter(server => server.serverType === 0)
                                        .map((server) => (
                                            <option
                                                key={server.id}
                                                value={server.serverInstanceName}
                                            >
                                                {server.serverInstanceName}
                                            </option>
                                        ))}
                                </select>
                                <div className='validationError'>
                                    <p>{this.props.get(this.props.errors, `source[${this.props.id}].host`)}</p>
                                </div>
                            </FormGroup>}
                    </Col>
                    <Col md={{ size: this.props.isEditing ? 4 : 5 }}>
                        <FormGroup>
                            <Label for="catalog">Catalog</Label>
                            <Input
                                type="text"
                                name="catalog"
                                id="catalog"
                                value={this.props.source.catalog || ''}
                                onChange={e => this.props.handleChangeSource(e, this.props.id)}
                                readOnly={this.props.isEditing}
                            />
                            <div className='validationError'>
                                <p>{this.props.get(this.props.errors, `source[${this.props.id}].catalog`)}</p>
                            </div>
                        </FormGroup>
                    </Col>
                    {this.props.number > 1 && !this.props.isEditing && < Col md={{ size: 1 }}>
                        <Button close onClick={() => this.props.close(this.props.id)} />
                    </Col>}
                </Row >
                <Row>
                    <Col md={{ size: 5, offset: 1 }}>
                        <FormGroup>
                            <Label for="version">Version</Label>
                            <Input
                                type="text"
                                name="version"
                                id="version"
                                value={this.props.source.version || ''}
                                readOnly={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={{ size: 5 }}>
                        <FormGroup>
                            <Label for="compatibilityLevel">Compatibility Level</Label>
                            <Input
                                type="text"
                                name="compatibilityLevel"
                                id="compatibilityLevel"
                                value={this.props.source.compatibilityLevel || ''}
                                readOnly={true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        )
    }
}