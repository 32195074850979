import * as React from 'react';
import * as Clients from '../../client/ApiClient';
import { DataTable, DataRow, DataCell, DataTableButton } from 'components/basic/DataTable';
import Toolbar from '../basic/Toolbar';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Input, Button } from 'reactstrap';
import { RangePagination, RangePaginationState, paginate } from 'components/basic/Pagination';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { enqueueNotification } from "../../store/Notifications"
import { store } from "AppContext"
import * as FileTypes from 'constants/FileUploadType';
import { authentication } from '../../services/Authentication';
import ConfirmationModal from '../basic/modals/ConfirmationModal';

interface IAcquisitionPageState {
    acquisitionCodes: Clients.CustomerAcquisitionCodeModel[];
    options: { [key: string]: Clients.ConversionOptionValueModel } | undefined;
    selectedFile: any;
    pagination: RangePaginationState;
    hubConnection: HubConnection | null;
    hubConnected: boolean;
    running: boolean;
    buttonLock: boolean;
    errMessage: string,
    confirmationModal: boolean,
    closeConfirmationModal: boolean,
}
interface AcquisitionArgs {
    conversionId: string,
}

type AcquisitionProps = RouteComponentProps<AcquisitionArgs>;

class AcquisitionPage extends React.Component<AcquisitionProps, IAcquisitionPageState>{
    private readonly customerAcquistionClient: Clients.CustomerAcquisitionCodeClient = new Clients.CustomerAcquisitionCodeClient();
    private options: Clients.ConversionOptionsClient = new Clients.ConversionOptionsClient();
    private executions: Clients.ExecutionsClient = new Clients.ExecutionsClient();
    public isFromCSVValidation: boolean = false;
    private conversionId: string = '';

    constructor(props: Readonly<AcquisitionProps>) {
        super(props)
        this.state = {
            acquisitionCodes: [],
            selectedFile: null,
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            hubConnection: null,
            running: false,
            buttonLock: true,
            errMessage: '',
            hubConnected: false,
            confirmationModal: false,
            options: undefined,
            closeConfirmationModal: false,
        }
    }
    componentDidMount() {
        this.conversionId = this.props.match.params.conversionId;
        this.buttonLock();
        this.refreshList(false);
        this.getOptions();        
        const link = `${process.env.PUBLIC_URL}/stagesignal`
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :('));
            this.state.hubConnection!.on('AcquisitionComplete', (done: string) => {
                this.setState({ running: false })
                this.refreshList(true);
            });
            let reload = 0;
            this.state.hubConnection!.on('AcquisitionRunning', (running: string) => {
                reload += 1;
                if (reload === 15 || reload % 100 === 0) {
                    this.refreshList(false);
                }
                if (!this.state.running) {
                    this.setState({ running: true })
                }
            });
            this.state.hubConnection!.on('CustomerAcquisitionCodesWorkbookProtected', (done: string) => {
                this.popErrMessage("Customer Acquisition Codes file has restricted access, is password protected or is not an Excel document. File cannot be uploaded.");
            });
            this.state.hubConnection!.on('CustomersAcquisitionCodesError', (done: string) => {
                this.popErrMessage("An error occurred. File cannot be uploaded.");
            });
            this.state.hubConnection!.on('AcquisitionFail', (errMsg: string) => {
                this.refreshList(false);
                store.dispatch(enqueueNotification({ type: 'error', message: errMsg == 'fail' ? 'File is in incorrect orientation. Please see file example.' : errMsg }));
                this.setState({ running: false });
            });
            if (this.state.hubConnected && this.state.hubConnection!.state == HubConnectionState.Disconnected) {
                store.dispatch(enqueueNotification({ type: 'error', message: "User session has been disconnected." }));
                authentication.logout()
                    .then(() => this.props.history.push('/'))
                    .catch(error => { }
                    )
            };
        })
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
    }

    private popErrMessage = (message: string) => {
        this.setState({ running: false });
        if (this.state.acquisitionCodes.length > 0) { this.refreshList(true); }
        this.setState({ errMessage: message });
        this.openConfirmationModal();
    }

    openCloseConfirmationModal = () => {
        this.setState({ closeConfirmationModal: true })
    }

    private gotoFileUploads = () => {
        this.setState({ closeConfirmationModal: false, confirmationModal: false });
        this.refreshPage('additionaldata');
    }

    refreshPage = (landingTab: string) => {
        window.location.reload();
        this.props.history.push({
            pathname: window.location.href.split('/').pop(),
            state: { tabName: landingTab }
        });
    }
    removeConfirmationModal = () => {
        this.setState({ closeConfirmationModal: false, confirmationModal: false });
    }

    openConfirmationModal = () => {
        this.setState({ confirmationModal: true })
    }

    refreshList = (done: boolean) => {
        this.customerAcquistionClient.getAll(this.conversionId)
            .then(response => {
                this.setState({
                    acquisitionCodes: response!
                }, () => {
                    if (done) {
                        if (this.state.acquisitionCodes.length > 0) {
                            store.dispatch(enqueueNotification({ type: 'success', message: "Complete" }))
                            this.openCloseConfirmationModal();
                        } else {
                            store.dispatch(enqueueNotification({ type: 'error', message: "File is in incorrect orientation. Please see file example." }));
                            this.setState({ running: false });
                            this.isFromCSVValidation = false;
                        }
                    }
                })
            })
            .catch(error => { });
    }

    getOptions = () => {
        this.options.get(this.props.match.params.conversionId)
            .then(values => {
                const options = values!.reduce(
                    (result, current) => ({ ...result, [current.id!]: current, }), {});
                this.setState({ options });
            })
            .catch(e => {
                console.log(e)
            })
    }

    setOption = (optionId: string, optionValue: string) => {
        const optionsClient: Clients.ConversionOptionsClient = new Clients.ConversionOptionsClient();
        const optionModel: Clients.ConversionOptionValueModel[] = [];
        const valuesArray: any = {
            conversionId: this.conversionId,
            value: optionValue,
            isLocked: false,
            id: optionId,
        };
        optionModel.push(valuesArray);
        optionsClient.update(this.conversionId, optionModel);
    }
    
    handleFileInput = (event: React.ChangeEvent) => {
        let selectedFile = (event.target as HTMLInputElement).files![0];
        if ((event.target as HTMLInputElement).files!.length > 0) {
            if (selectedFile.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                this.isFromCSVValidation = true;
                store.dispatch(enqueueNotification({ type: 'error', message: "Please upload Excel files (.xlsx) only." }));
                this.state = {
                    acquisitionCodes: [],
                    selectedFile: null,
                    pagination: {
                        pageSize: 25,
                        pageNumber: 0,
                    },
                    hubConnection: null,
                    running: false,
                    buttonLock: true,
                    errMessage: '',
                    hubConnected: false,
                    confirmationModal: false,
                    options: undefined,
                    closeConfirmationModal: false,
                };
            }
            else {

                this.setState({ selectedFile });
                this.isFromCSVValidation = false;
            }
        }
        else {
            if (selectedFile == undefined) {
                this.state = {
                    acquisitionCodes: [],
                    selectedFile: null,
                    pagination: {
                        pageSize: 25,
                        pageNumber: 0,
                    },
                    hubConnection: null,
                    running: false,
                    buttonLock: true,
                    errMessage: '',
                    hubConnected: false,
                    confirmationModal: false,
                    options: undefined,
                    closeConfirmationModal: false,
                };
            }
            this.isFromCSVValidation = false;
        }
    };


    onFileUpload = () => {
        if (this.state.selectedFile === null) {
            if (this.isFromCSVValidation)
                store.dispatch(enqueueNotification({ type: 'error', message: "Please upload Excel files (.xlsx) only." }))
            else
                store.dispatch(enqueueNotification({ type: 'error', message: "Please upload file." }))
        } else {
            this.setState({ running: true });
            const fileField: any = document.querySelector('input[type="file"]');
            const formData = new FormData();
            const postUrl = '/api/v1/' + this.conversionId + '/fileUpload';

            formData.append("contentType", fileField!.files[0].type);
            formData.append("data", fileField!.files[0]);
            formData.append("fileUploadTypeId", FileTypes.clientAcquisitionCode);
            formData.append("name", fileField!.files[0].name);

            fetch(postUrl, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    this.customerAcquistionClient.getAll(this.conversionId)
                        .then(response => {
                            this.setState({
                                acquisitionCodes: response!
                            })
                        })
                        .catch(error => { });
                })
                .catch(error => {
                    console.log(error)
                })


        }

    }


    buttonLock = () => {
        this.executions.listExecutionsAllTierBoolean(this.props.match.params.conversionId)
            .then((response) => {
                if (response) {
                    this.setState({ buttonLock: response.isLocked })
                }
            })
            .catch(error => { })
    }

    public render() {
        return (
            <>
                <Toolbar>
                    <DataTableButton
                        icon='file_download'
                        id={`acquisitionExample`}
                        title="Acquisition Excel Example"
                        value="Download"
                        onClick={() => { window.open("/ClientAcquiredAccountTemplate.xlsx") }} />
                    <Input type="file" name="file" id="exampleFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={e => this.handleFileInput(e)} />
                    {this.state.running ?
                        <img className='loading-indicator' alt="loader" src={'loader.gif'} /> :
                        <Button className='submit-button-orange' onClick={this.onFileUpload} disabled={this.state.buttonLock}>
                            Upload
                        </Button>}
                </Toolbar>
                <DataTable>
                    <thead>
                        <tr>
                            <th className="number-column">#</th>
                            <th>Source Code</th>
                            <th>Acquisition Code</th>
                            <th>Acquisition Description</th>
                        </tr>
                    </thead>
                    <tbody className="nonEntity">
                        {paginate(this.state.acquisitionCodes, this.state.pagination, (acquisitionCode, i) => (
                            <DataRow key={acquisitionCode.id}
                                id={`${acquisitionCode.id}`}
                            >
                                <DataCell className="number-column">{acquisitionCode.rowNumber}</DataCell>
                                <DataCell>{acquisitionCode.sourceCode}</DataCell>
                                <DataCell>{acquisitionCode.acquisitionCode}</DataCell>
                                <DataCell>{acquisitionCode.acquisitionDescription}</DataCell>
                            </DataRow>
                        ))}
                    </tbody>
                </DataTable >
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={this.state.acquisitionCodes.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
                <ConfirmationModal
                    isOpen={this.state.confirmationModal}
                    message={this.state.errMessage}
                    onSubmit={this.gotoFileUploads}
                />
               
            </>
        )
    }
}

export default withRouter(AcquisitionPage)