import * as React from 'react';
import * as Clients from '../../client/ApiClient';
import Toolbar from '../basic/Toolbar';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ClientMergePage from './Merge';
import AcquisitionPage from './Acquisition';
import CustomersPage from './Customers';
import PoliciesPage from './Policies';

interface IAdditionalDataPageState {
    types: { description: string }[]
    selected: string
}
//interface AdditionalDataArgs {
//    conversionId: string,
//}

//type AdditionalDataProps = RouteComponentProps<AdditionalDataArgs>;
interface AdditionalDataProps extends RouteComponentProps{
    renderMode: string,
    customersFile: boolean,
    policiesFile: boolean,
}

class AdditionalDataPage extends React.Component<AdditionalDataProps, IAdditionalDataPageState>{
    private readonly fileUploadClient: Clients.FileUploadClient = new Clients.FileUploadClient();
    private types: { key: number, description: string }[] = [];
    private selected: string = '';

    constructor(props: Readonly<AdditionalDataProps>) {
        super(props)
        this.state = {
            types: [],
            selected: "",
        }
    }

    componentDidMount() {
        //this.fileUploadClient.getAllTypes()
        //    .then((response) => {
        //        console.log(response)
        //    })
        this.setState({ types: this.types });
        this.setState({ selected: this.selected });
    }

    handleCompanyTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.selected = event.target.value
        this.setState({ selected: this.selected })
    }

    setTypes() {
        if (this.props.renderMode !== "Mini") {
            this.types = [
                {
                    key: 1, description: "Select Additional Data Type"
                },
                {
                    key: 2, description: "Client Acquisition Code"
                },
                {
                    key: 3, description: "Client Merge"
                }
            ]
            if (this.selected.length === 0) { this.selected = "Select Additional Data Type"; }
        } else if (this.props.renderMode === 'Mini' && this.props.customersFile && this.props.policiesFile) {
            this.types = [
                {
                    key: 1, description: "Select File Upload Type"
                },
                {
                    key: 2, description: "Customers File"
                },
                {
                    key: 3, description: "Policies File"
                }
            ]
            if (this.selected.length === 0) { this.selected = "Select File Upload Type"; }
        } else if (this.props.renderMode === 'Mini' && this.props.customersFile && !this.props.policiesFile) {
            this.types = [
                {
                    key: 1, description: "Select File Upload Type"
                },
                {
                    key: 2, description: "Customers File"
                }
            ]
            if (this.selected.length === 0) { this.selected = "Select File Upload Type"; }
        } else {
            this.types = [
                {
                    key: 1, description: "Select File Upload Type"
                }
            ]
            this.selected = "Select File Upload Type";
        }
    }

    public render() {
        this.setTypes();
        return (
            <>
                <Toolbar>
                    <div className="moveLeft">
                        <select
                            className="custom-select"
                            name="fileType"
                            value={this.selected}
                            onChange={event => this.handleCompanyTypeChange(event)}
                        >
                            {this.types.map((type) => (
                                <option
                                    key={type.key}
                                    value={type.description}
                                >
                                    {type.description}
                                </option>
                            ))}
                        </select>
                    </div>
                </Toolbar>
                {this.selected === 'Client Merge' && <ClientMergePage />}
                {this.selected === 'Client Acquisition Code' && <AcquisitionPage />}
                {this.selected === 'Customers File' && <CustomersPage />}
                {this.selected === 'Policies File' && <PoliciesPage />}
            </>
        )
    }
}

export default withRouter(AdditionalDataPage)