import { Reducer } from 'redux'

export interface RequestsState {
    requestsInProgress: number
}

export interface IncrementActiveRequestsCountAction {
    type: 'INCREMENT_ACTIVE_REQUESTS_COUNT'
}

export interface DecrementActiveRequestsCountAction {
    type: 'DECREMENT_ACTIVE_REQUESTS_COUNT'
}

export type RequestsActions = IncrementActiveRequestsCountAction | DecrementActiveRequestsCountAction;

export const incrementActiveRequestsCount = (): RequestsActions => {
    return {
        type: 'INCREMENT_ACTIVE_REQUESTS_COUNT'
    }
}

export const decrementActiveRequestsCount = (): RequestsActions => {
    return {
        type: 'DECREMENT_ACTIVE_REQUESTS_COUNT'
    }
}

export const actionCreators = {
    incrementActiveRequestsCount: (): RequestsActions => ({
        type: 'INCREMENT_ACTIVE_REQUESTS_COUNT'
    }),
    decrementActiveRequestsCount: (): RequestsActions => ({
        type: 'DECREMENT_ACTIVE_REQUESTS_COUNT'
    })
}

const initialState: RequestsState = {
    requestsInProgress: 0
}

export const reducer: Reducer<RequestsState, RequestsActions> =
    (state = initialState, action: RequestsActions): RequestsState => {
        switch (action.type) {
            case 'INCREMENT_ACTIVE_REQUESTS_COUNT':
                return {
                    requestsInProgress: state.requestsInProgress + 1
                }
            case 'DECREMENT_ACTIVE_REQUESTS_COUNT':
                return {
                    requestsInProgress: state.requestsInProgress > 0 ? state.requestsInProgress - 1 : 0
                }
            default:
                return state
        }
    }