import { Action, Reducer } from 'redux';
import { UserLoginDetailsModel } from 'client/ApiClient';

import { Authentication, authentication } from 'services/Authentication';

export interface AuthenticationState {
    currentUser: UserLoginDetailsModel | undefined;
    currentClient: string;
    ams360URLAgency: string;
    ams360URLACC: string;
}

// Actions

export interface UpdateAuthenticatedUserAction { type: 'UPDATE_AUTHENTICATED_USER' }

export const actionCreators = {
    update: () => ({ type: 'UPDATE_AUTHENTICATED_USER' } as UpdateAuthenticatedUserAction),
};

export type KnownAction = UpdateAuthenticatedUserAction;

// Reducer

export const reducer: Reducer<AuthenticationState> =
    (state: AuthenticationState | undefined, incomingAction: Action): AuthenticationState => {
        if (state === undefined) {
            return {
                currentUser: authentication.getCurrentUser(),
                currentClient: authentication.getCurrentClient(),
                ams360URLAgency: authentication.getAMS360URLAgency(),
                ams360URLACC: authentication.getAMS360URLACC()
            };
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case 'UPDATE_AUTHENTICATED_USER':
                return {
                    currentUser: authentication.getCurrentUser(),
                    currentClient: authentication.getCurrentClient(),
                    ams360URLAgency: authentication.getAMS360URLAgency(),
                    ams360URLACC: authentication.getAMS360URLACC()
                };
            default:
                return state;
        }
    };
