import * as React from 'react';
import { Button, Input, InputGroup, } from 'reactstrap';
import * as Clients from 'client/ApiClient';

import { MaterialIcon } from 'components/basic/Icon';
import { RangePagination } from 'components/basic/Pagination';

import DynamicDataTable from 'components/basic/DynamicDataTable';
import Toolbar from "components/basic/Toolbar";

type DynamicDataTableState = {
    records: Clients.DynamicEntityListModel;
    isError: boolean;

    pageSize: number;
    pageNumber: number;

    columnTargetTake: number;
    columnSourceTake: number;

    sortColumn: number | undefined;
    sortDirection: Clients.SortDirection;

    filter: string;
};

export type DynamicDataTableProps = {
    conversionId: string;
    sectionId: string;
    source: 'source' | 'target';
    children?: React.ReactNode;
};

export default class MappingDynamicDataTable extends React.Component<DynamicDataTableProps, DynamicDataTableState> {
    private readonly client = new Clients.GenericClient();

    constructor(props: Readonly<DynamicDataTableProps>) {
        super(props);
        this.state = {
            records: new Clients.DynamicEntityListModel({ count: 0 }),
            isError: false,

            pageSize: 50,
            pageNumber: 0,

            columnTargetTake: 9,
            columnSourceTake: 3,

            sortColumn: undefined,
            sortDirection: Clients.SortDirection.None,

            filter: '',
        }
    }

    fetchRecords = () => {
        var promise: Promise<Clients.DynamicEntityListModel | null>;

        const take = this.state.pageSize;
        const offset = this.state.pageSize * this.state.pageNumber;
        const sortColumn = (this.state.sortColumn !== undefined
            && this.state.records.schema!.columns![this.state.sortColumn].id) || '';
        switch (this.props.source) {
            case 'source':
                promise = this.client.getSourceAll(
                    this.props.conversionId,
                    this.props.sectionId,
                    this.state.columnSourceTake,
                    take,
                    offset,
                    sortColumn,
                    this.state.sortDirection,
                    this.state.filter,
                );
                break;
            case 'target':
                promise = this.client.getTargetAll(
                    this.props.conversionId,
                    this.props.sectionId,
                    this.state.columnTargetTake,
                    take,
                    offset,
                    sortColumn,
                    this.state.sortDirection,
                    this.state.filter,
                );
                break;
            default:
                throw new Error(`The source ${this.props.source} is not supported.`);
        }

        promise
            .then(r => r && this.setState({
                records: r,
                isError: false,
            }))
            .catch(error => {
                if (error instanceof Clients.ApiException && error.status === 404) {
                    this.setState({
                        // A stored procedure for the entity has not been registered.
                        isError: true,
                    })
                } else {
                    this.setState({ records: new Clients.DynamicEntityListModel() })
                    console.log({
                        message: `error getting dynamic records`,
                        source: this.props.source,
                        conversionId: this.props.conversionId,
                        sectionId: this.props.sectionId,
                        error: error,
                    })
                }
            });
    }

    componentDidMount() {
        this.fetchRecords();
    }

    componentDidUpdate(prevProps: DynamicDataTableProps, prevState: DynamicDataTableState) {
        if (this.props.sectionId !== prevProps.sectionId
            && (this.state.sortColumn !== undefined
                || this.state.filter !== '')) {
            // Setting defaults.
            this.setState({
                sortColumn: undefined,
                filter: '',
            })
        } else if (this.props.sectionId !== prevProps.sectionId
            || this.props.source !== prevProps.source
            || this.props.conversionId !== prevProps.conversionId
            || (this.state.pageNumber !== prevState.pageNumber && Math.ceil(this.state.records.count / this.state.pageSize) >= this.state.pageNumber && this.state.pageNumber >= 0)
            || this.state.pageSize !== prevState.pageSize
            || this.state.sortColumn !== prevState.sortColumn
            || this.state.sortDirection !== prevState.sortDirection
            || this.state.filter !== prevState.filter
        ) {
            this.fetchRecords();
        }
    }

    render() {
        if (this.state.isError) {
            return <MaterialIcon className='dynamic-data-table-not-found'>block</MaterialIcon>;
        }

        const arr: (string | undefined)[] = [];
        if (this.props.source === 'target' && this.state.records.schema) {
            this.state.records.schema!.columns!.map((v, i: number) => (
                i < this.state.columnTargetTake
                && (v.id !== 'MidName' && v.id !== 'MiddleName'))
                ? arr.push(v.id) : null);
        } else if (this.props.source === 'source' && this.state.records.schema) {
            this.state.records.schema!.columns!.map((v, i: number) => i < this.state.columnSourceTake ? arr.push(v.id) : null);
        }

        const columns = arr;

        return (
            <>
                <Toolbar>
                    <InputGroup>
                        <React.Fragment>
                            <Input placeholder='Search'
                                name='filter'
                                onChange={e => this.setState({ filter: e.target.value, pageNumber: 0 })}
                                value={this.state.filter}
                            />
                            <InputGroup addonType='append' className='material-icon'>
                                <Button onClick={() => this.setState({ filter: '' })} > close</Button>
                            </InputGroup>
                        </React.Fragment>
                    </InputGroup>
                    {this.props.children}
                </Toolbar>
                <DynamicDataTable
                    records={this.state.records}
                    columns={columns}
                    sorting={{
                        sortColumn: this.state.sortColumn,
                        sortDirection: this.state.sortDirection,
                        onSort: (sortColumn, sortDirection) => this.setState({
                            sortColumn: sortColumn,
                            sortDirection: sortDirection,
                        })
                    }}
                    sectionId={this.props.sectionId}
                    ref="table"
                />
                <RangePagination
                    pageNumber={this.state.pageNumber}
                    pageSize={this.state.pageSize}
                    recordsCount={this.state.records.count}
                    onChangeIndex={index => { if (index.toString() !== "-0") this.setState({ pageNumber: index }) }}
                />
            </>
        )
    }
}
