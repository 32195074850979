import * as React from 'react';
import { Container } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import Footer from './components/Footer';
import Landing from './components/landing/Landing';
import SplashScreen from './components/splashScreen/SplashScreen';
import LoadingOverlay from './components/basic/LoadingOverlay';
import PromiseErrorBoundary from './components/errorHandling/PromiseErrorBoundary';
import * as Roles from 'constants/Roles';

import ConversionListPage from './components/conversion/List';
import ConversionEditPage from './components/conversion/Edit';
import ConversionTabsPage from './components/conversion/Tabs';
import ReportViewPage from 'components/conversion/reports/Report';
import MappingPage from './components/conversion/mapping/Mapping';

import UserListPage from './components/user/List'
import UserEditPage from './components/user/Edit'

import ClientListPage from './components/client/List';
import ClientEditPage from './components/client/Edit';
import { SecureRoute } from './components/basic/Authentication';
import UIControllerPage from './components/basic/UIController';
import ADMPage from './components/conversion/mapping/ADMPage';

import { authentication } from './services/Authentication'

import './custom.css';
import './fonts.css';

export default class App extends React.PureComponent<{}>{

    constructor(props: Readonly<{}>) {
        super(props);
        authentication.refreshToken();
    }

    public render() {
        if (!sessionStorage['splashShown']) {
            setTimeout(() => {
                sessionStorage['splashShown'] = true
                this.forceUpdate()
            }, 3000)
            return <SplashScreen />
        }
        else {
            return (
                <PromiseErrorBoundary>
                    <Layout>
                        <Route exact path='/' component={Landing} />

                        <SecureRoute authenticated exact path='/conversion' component={ConversionListPage} />
                        <Switch>
                            <SecureRoute authenticated path='/conversion/new'
                                render={props =>
                                    // TODO: bring it to a separate component.
                                    <Container>
                                        <ConversionEditPage {...props} isEditing={false} />
                                    </Container>
                                } />
                            <SecureRoute authenticated exact path='/conversion/:conversionId/:tabId' component={ConversionTabsPage} />
                            <SecureRoute authenticated path='/conversion/:conversionId/mapping' component={MappingPage} />
                            <SecureRoute authenticated path='/conversion/:conversionId/reports/:reportId' component={ReportViewPage} />
                        </Switch>

                        <SecureRoute roles={[
                            Roles.vertaforeInternalRoleId,
                            Roles.administrationRoleId,
                            Roles.developerAdministrationRoleId,
                            Roles.customerAdministratorRoleId
                        ]} exact path='/user' component={UserListPage} />
                        <Switch>
                            <SecureRoute roles={[
                                Roles.vertaforeInternalRoleId,
                                Roles.administrationRoleId,
                                Roles.developerAdministrationRoleId,
                                Roles.customerAdministratorRoleId,
                            ]} path='/user/new' component={UserEditPage} />
                            <SecureRoute roles={[
                                Roles.vertaforeInternalRoleId,
                                Roles.administrationRoleId,
                                Roles.developerAdministrationRoleId,
                                Roles.customerAdministratorRoleId,
                                Roles.customerUserRoleId
                            ]} exact path='/user/:userId' render={(props) => <UserEditPage {...props} isEditing={true} />} />
                        </Switch>

                        <SecureRoute roles={[Roles.developerAdministrationRoleId, Roles.administrationRoleId]} exact path='/client' component={ClientListPage} />
                        <SecureRoute roles={[Roles.developerAdministrationRoleId]} exact path='/uicontroller' component={UIControllerPage} />
                        <SecureRoute roles={[Roles.developerAdministrationRoleId]} exact path='/:conversionId/admpage' component={ADMPage} />
                        <Switch>
                            <SecureRoute roles={[Roles.developerAdministrationRoleId, Roles.administrationRoleId]} path='/client/new' render={props => <ClientEditPage {...props} isEditing={false} />} />
                            <SecureRoute roles={[Roles.developerAdministrationRoleId, Roles.administrationRoleId]} exact path='/client/:clientId' render={(props) => <ClientEditPage {...props} isEditing={true} />} />
                        </Switch>
                    </Layout>
                    <Footer />
                    <LoadingOverlay />
                </PromiseErrorBoundary>
            )
        }
    }
}
