import * as React from 'react';
import { Container } from 'reactstrap';
import Notifications from './basic/Notifications';
import Eula from './eula/Eula';
import NavMenu from './NavMenu';

export default (props: { children?: React.ReactNode }) => {
    const d = new Date();
    let year = d.getFullYear();
    return (
        <React.Fragment>
            <NavMenu />
            <Eula>
                <Container fluid style={{ flex: 1 }}>
                    {props.children}
                </Container>
            </Eula>
            <Notifications />
        </React.Fragment>
    )
}
