import { DataCell, DataRow, DataTable, DataTableButton } from 'components/basic/DataTable';
import { Input, Form, Label, Container, Row, Col, Button, Collapse, CardBody, Card } from 'reactstrap';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ConversionReportsClient, ExecutionsClient } from '../../../client/ApiClient';
import { reportsList, miniReportsList, mergeReportsList } from './ReportsList';
import * as SourceSystem from 'constants/SourceSystem';

import '../../../styles/Forms.scss';

interface IConversionReportsPageProps extends RouteComponentProps<{
    conversionId: string
}> {
    conversionTypeId: string,
}

interface IConversionReportsPageState {
    reports: { id: string, name: string }[];
    buttonLock: boolean;
}

class ConversionReportsPage extends React.Component<IConversionReportsPageProps, IConversionReportsPageState>{
    private readonly client = new ConversionReportsClient();
    private executions: ExecutionsClient = new ExecutionsClient();
    private conversionId: string = '';


    constructor(props: Readonly<IConversionReportsPageProps>) {
        super(props)
        this.state = {
            reports: [],
            buttonLock: false,
        }
    }

    componentDidMount() {
        this.buttonLock();
    }

    componentDidUpdate(prevProps: IConversionReportsPageProps) {
        if (this.props.conversionTypeId !== prevProps.conversionTypeId) {
            const reports = this.getReports();
            this.setState({ reports })
        }
    }

    getReports = () => {
        if (this.props.conversionTypeId === SourceSystem.miniToAMS360Id) {
            return miniReportsList
        } else if (SourceSystem.ams360ToAMS360Id === this.props.conversionTypeId) {
            return mergeReportsList
        }
        else {
            return reportsList;
        }
    }

    exportToExcel = (id: string) => {
        this.client.exportToExcel(this.conversionId, id, null, null)
            .then(response => saveAs(response!.data, response!.fileName))
            .catch(error => { console.log(error) });
    }
    exportToExcelAll = () => {
        try {
            const reports = this.getReports();
            reports.forEach(element =>
                this.client.exportToExcel(this.conversionId, element.id, null, null)
                    .then(response => saveAs(response!.data, response!.fileName))
                    .catch(error => { console.log(error) })
            );

            //reports.every(element =>
            //    this.client.exportToExcel(this.conversionId, element.id, null, null)
            //        .then(response => saveAs(response!.data, response!.fileName))
            //        .catch(error => { console.log(error) })
            //);
        } catch (e) { };
    }

    buttonLock = () => {
        this.executions.listExecutionsAllTierBoolean(this.conversionId)
            .then((response) => {
                if (response) this.setState({ buttonLock: response.isLocked })
            })
            .catch(error => { console.log(error) })
    }

    public render() {
        this.conversionId = this.props.match.params.conversionId;
        return (
            <>

                <Row>
                    <Col> <h3 className='mapping-body-header'>Reports</h3></Col>

                    <Col>

                        <Button
                            className='submit-button-orange float-right'
                            onClick={() => this.exportToExcelAll()}
                            disabled={this.state.buttonLock}
                        >Download all reports</Button>
                    </Col>
                </Row>

                <DataTable>
                    <thead>
                        <tr>
                            <th>Name</th>
                            {!this.state.buttonLock &&
                                <th className='controls-column'></th>
                            }

                        </tr>
                    </thead>
                    <tbody>
                        {this.state.reports.map(report => (
                            <DataRow id={report.id!} key={report.id}>
                                <DataCell>{report.name}</DataCell>
                                {!this.state.buttonLock &&
                                    <DataCell>
                                        <DataTableButton icon='visibility' id={report.id!} title='Run'
                                            action={id => this.props.history.push(`/conversion/${this.conversionId}/reports/${report.id}${(this.props.conversionTypeId === SourceSystem.miniToAMS360Id) ? '?renderMode=Mini' : ''}`)} />
                                        <DataTableButton icon='file_download' id={report.id!} title='Export to Excel'
                                            action={() => this.exportToExcel(report.id!)} />
                                    </DataCell>}
                            </DataRow>
                        ))}
                    </tbody>
                </DataTable>
            </>
        )
    }
}

export default withRouter(ConversionReportsPage)