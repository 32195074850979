import React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from 'store';

import { enqueueNotification } from "../../../../../store/Notifications"
import { store } from "AppContext"
import { saveAs } from 'file-saver';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Badge } from 'reactstrap';
import { DataCell, DataRow, DataTable, DataTableButton } from '../../../../basic/DataTable';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import * as Constants from '../../Constants';
import * as Roles from 'constants/Roles';

import { GenericClient, ExecutionsClient, ExecutionsModel, ExecutionsTierBooleanModel, IUserLoginDetailsModel, ConversionReportsClient, ConversionModel, ConversionClient, EmployeeModel, CompanyClient } from '../../../../../client/ApiClient';
import StageToolbar, { StageToolbarProps } from './StageToolbar';
import ClearConfirmationModal from 'components/basic/modals/ClearConfirmationModal';
import { SecureContainer } from 'components/basic/Authentication';
import { setPrimary } from './Primary'



import './StageActionToolbar.scss';

interface StageActionToolbarProps extends StageToolbarProps {
    sectionId: string
    sectionType?: string
    buttonText?: string;
    primaryList?: any[];
    getItems?: Function;
    handleCopyMapping?: (sectionId: string, currentUser: any) => void;
    disableMigrate?: boolean;
    tierGate?: string;
    currentUser: IUserLoginDetailsModel | undefined;
    noMapping?: boolean;
    copyMap?: boolean;
    autoMap?: boolean;
    noClear?: boolean;
    filter: string;
    lockButton?: Function;
};

interface StageActionToolbarState {
    modal: boolean
    modalType?: string;
    sort: boolean
    executions: ExecutionsModel[];
    lastExecution: ExecutionsModel | undefined;
    disabled: boolean;
    tierBooleans: ExecutionsTierBooleanModel | undefined;
    hubConnection: HubConnection | null;
    currentConversion: ConversionModel | undefined;
    copyDisable: boolean;
    clearModal: boolean;
    timeout: any;
    status: any;
    autoDisable: boolean;
    buttonLocked: boolean;
}

class StageActionToolbar extends React.Component<StageActionToolbarProps, StageActionToolbarState>{
    private client: GenericClient = new GenericClient();
    private executions: ExecutionsClient = new ExecutionsClient();
    private report: ConversionReportsClient = new ConversionReportsClient();
    private conversion: ConversionClient = new ConversionClient();
    private company: CompanyClient = new CompanyClient();

    private primaryEntities = [
        'employees',
        'branch',
        'company',
        'brokers',
        'department',
        'division',
        'group',
        'vendor'
    ]


    constructor(props: StageActionToolbarProps) {
        super(props)
        this.state = {
            modal: false,
            modalType: '',
            sort: true,
            executions: [],
            lastExecution: undefined,
            disabled: false,
            tierBooleans: undefined,
            hubConnection: null,
            currentConversion: undefined,
            copyDisable: false,
            clearModal: false,
            timeout: null,
            status: null,
            autoDisable: false,
            buttonLocked: true,
        }
    }

    componentDidMount = () => {
        clearInterval(this.state.timeout);
        this.getExecutions()
        this.getTierBooleans()
        this.currentConversion()
        const link = `${process.env.PUBLIC_URL}/executions`
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        const sectionMap = this.props.sectionType ? this.props.sectionId + this.props.sectionType : this.props.sectionId
        const entityObject = Constants.migrateEntities.filter(m => m.sectionId === sectionMap);
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :('));
            if (entityObject[0]) {
                if (entityObject[0].packageName) this.state.hubConnection!.on(entityObject[0].packageName, (execution: ExecutionsModel) => {
                    this.setState({ lastExecution: execution })
                    if ((execution.description === "Succeeded" || execution.description === "Completed") && execution.userId === this.props.currentUser!.id) {
                        store.dispatch(enqueueNotification({ type: 'success', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                    }
                    else if ((execution.description === "Failed" || execution.description === "Canceled" || execution.description === "Ended Unexpectedly" || execution.description === "Stopping") && execution.userId === this.props.currentUser!.id) {
                        store.dispatch(enqueueNotification({ type: 'error', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                    }
                });
                if (entityObject[0].copy) if (entityObject[0].copy) {
                    this.state.hubConnection!.on(entityObject[0]!.copy, (execution: ExecutionsModel) => {
                        if ((execution.description === "Succeeded" || execution.description === "Completed") && execution.userId === this.props.currentUser!.id) {
                            store.dispatch(enqueueNotification({ type: 'success', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                            if (this.props.getItems) this.props.getItems();
                        }
                        else if ((execution.description === "Failed" || execution.description === "Canceled" || execution.description === "Ended Unexpectedly" || execution.description === "Stopping") && execution.userId === this.props.currentUser!.id) {
                            store.dispatch(enqueueNotification({ type: 'error', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                            if (this.props.getItems) this.props.getItems();
                        }
                    });
                }
                if (entityObject[0].auto) if (entityObject[0].auto) {
                    this.state.hubConnection!.on(entityObject[0]!.auto, (execution: ExecutionsModel) => {
                        if ((execution.description === "Succeeded" || execution.description === "Completed") && execution.userId === this.props.currentUser!.id) {
                            store.dispatch(enqueueNotification({ type: 'success', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                        }
                        else if ((execution.description === "Failed" || execution.description === "Canceled" || execution.description === "Ended Unexpectedly" || execution.description === "Stopping") && execution.userId === this.props.currentUser!.id) {
                            store.dispatch(enqueueNotification({ type: 'error', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                        }
                    });
                }
                this.state.hubConnection!.on('UpdateCountdown', (call: string) => {
                    if (this.state.lastExecution && this.state.lastExecution.description === 'Running') {
                        this.migrateButton()
                        this.statusUpdate();
                    }
                });

                this.state.hubConnection!.on('ExecutionCalled', (call: string) => {
                    this.getExecutions()
                });
            }
        })
    }

    componentDidUpdate(prevProps: StageActionToolbarProps) {
        if (this.props.sectionId !== prevProps.sectionId) {
            clearInterval(this.state.timeout);
            this.setState({ buttonLocked: true }, () => {
                if (this.props.lockButton) {
                    this.props.lockButton(this.state.buttonLocked);
                }
                this.getExecutions()
                this.getTierBooleans()
                this.currentConversion()
            })
        }
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
        clearInterval(this.state.timeout);
    }

    getExecutions() {
        this.executions!.listExecutions(this.props.conversionId)
            .then(results => {
                this.setState({ executions: results! })
                if (this.state.executions) {
                    this.migrateButton()
                    this.statusUpdate()
                    this.buttonLock();
                    clearInterval(this.state.timeout);
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    currentConversion = () => {
        this.conversion.get(this.props.conversionId)
            .then(response =>
                this.setState({ currentConversion: response! }, () => {
                    this.buttonLock();
                })
            )
            .catch(error => { })
    }

    getTierBooleans = () => {
        this.executions.listExecutionsTierBoolean(this.props.conversionId)
            .then(response =>
                this.setState({ tierBooleans: response![0] })
            )
            .catch(error => { })
    }

    migrateButton = () => {
        const sectionMap = this.props.sectionType ? this.props.sectionId + this.props.sectionType : this.props.sectionId
        const entityObject = Constants.migrateEntities.filter(m => m.sectionId === sectionMap);
        if (entityObject[0] && this.props.copyMap) setTimeout(() => this.copyButton(entityObject[0]), 1000);
        if (entityObject[0] && this.props.autoMap) setTimeout(() => this.autoButton(entityObject[0]), 1000);
        if (entityObject.length > 0) {
            const execution = this.state.executions.filter(e => e.packageName === entityObject[0]!.packageName);
            const lastExecution = execution[execution.length - 1];
            if ((this.state.lastExecution === undefined || this.state.lastExecution!.description === 'Pending') && lastExecution) this.setState({ lastExecution })
            if (this.state.lastExecution) {
                if (this.state.lastExecution!.endTime) {
                    this.setState({ disabled: false });
                } else {
                    this.disableButton();
                }
            } else {
                clearInterval(this.state.timeout);
            }
        }
    }

    copyButton = (entityObject: any) => {
        clearInterval(this.state.timeout);
        const copy = this.state.executions.filter(e => e.packageName === entityObject!.copy);
        const lastCopy = copy[copy.length - 1];
        if (lastCopy) {
            this.setState({ copyDisable: true });
            if (lastCopy!.endTime) {
                if (this.props.getItems) this.props.getItems();
                this.setState({ copyDisable: false });
            } else {
                this.setState({ copyDisable: true });
            }
        }
    }

    autoButton = (entityObject: any) => {
        clearInterval(this.state.timeout);
        const auto = this.state.executions.filter(e => e.packageName === entityObject!.auto);
        const lastauto = auto[auto.length - 1];
        if (lastauto) {
            this.setState({ autoDisable: true });
            if (lastauto!.endTime) {
                if (this.props.getItems) this.props.getItems();
                this.setState({ autoDisable: false });
            } else {
                this.setState({ autoDisable: true });
            }
        }
    }

    disableButton = () => {
        this.setState({ disabled: true, buttonLocked: true }, () => {
            this.statusUpdate();
            if (this.props.lockButton) {
                this.props.lockButton(this.state.buttonLocked);
            }
        });
    }

    statusUpdate = () => {
        if (this.state.lastExecution) {
            let dt = this.state.lastExecution!.endTime;
            if (typeof dt === 'string') {
                dt = new Date(dt);
            }
            if (typeof dt === 'object' && dt && !this.state.disabled) {
                const day = dt.getDate(),
                    month = dt.getMonth(),
                    year = dt.getFullYear(),
                    minutes = (dt.getMinutes() < 10 ? '0' : "") + dt.getMinutes();
                let hours = dt.getHours();
                const period = (hours < 12) ? 'AM' : 'PM';
                if (period === 'PM' && hours !== 12) hours = hours - 12;
                if (hours === 0) hours = 12;

                let color = ""
                const status = this.state.lastExecution.description
                if (status === "Canceled" || status === "Failed" || status === "Ended Unexpectedly") {
                    color = "danger"
                    clearInterval(this.state.timeout);
                    this.setState({
                        status: < h6 className='status' > <Badge color={color}> Migrate completed on {
                            `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                        } - Status: {this.state.lastExecution.description} </Badge></h6>
                    })
                } else if (status === "Succeeded" || status === "Completed") {
                    color = "success"
                    clearInterval(this.state.timeout);
                    this.setState({
                        status: < h6 className='status' > <Badge color={color}> Migrate completed on {
                            `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                        } - Status: {this.state.lastExecution.description} </Badge></h6>
                    })
                }
            }
            else {
                const color = "primary"
                const now = new Date().valueOf();
                const estimate = this.state.lastExecution.estimatedFinish ? this.state.lastExecution.estimatedFinish!.valueOf() : 0;
                const timeLeft: string = estimate > now ? `${((estimate - now) / 60000).toFixed(2)} minutes` : 'Pending';
                const description = this.state.lastExecution.description === 'Succeeded' ? 'Running' : this.state.lastExecution.description;
                this.setState({
                    status:
                        <h6 className='status'> <Badge color={color}>Estimate: {timeLeft} Status: {description} </Badge></h6>
                })
            }
        }
    }

    loadEntities = () => {
        clearInterval(this.state.timeout);
        if (this.state.modal) { this.modal('') }
        this.disableButton()
        const userId = this.props.currentUser!.id
        if (userId !== undefined) {
            this.client.migrateEntity(this.props.conversionId, userId, this.props.sectionId)
                .catch(error => { });
        }
    }

    validatePrimaries = () => {
        const primaryList = this.props.primaryList!
        if (this.primaryEntities.includes(this.props.sectionId) && primaryList.length > 0) {
            if (this.props.sectionId === 'company') {
                this.modal('parent')
            } else {
                this.modal('')
            }
        } else {
            this.loadEntities()
        }
    }

    modal = (type: string) => {
        this.setState({ modal: !this.state.modal, modalType: type })
    }

    sort(column: string) {
        this.props.primaryList!.sort((a: any, b: any) => {
            let x = a[column]
            let y = b[column]
            if (typeof y === "string") {
                y = y.toLowerCase().trim()
                x = x.toLowerCase().trim()
            }
            if (this.state.sort) {
                if (x < y) { return -1; }
                if (x > y) { return 1; }
            } else {
                if (x > y) { return -1; }
                if (x < y) { return 1; }
            }
            return 0
        })
        this.setState({ sort: !this.state.sort });
    }

    handleSelectPrimary = (e: { target: { name: any, value: any } }) => {
        const code = e.target.name
        const i = Number(e.target.value)
        const items = this.state.modalType === 'parent' ? this.props.primaryList!.filter(p => p.parentDestinationCode === code) : this.props.primaryList!.filter(p => p.destinationCode === code)
        const pItems = this.state.modalType === 'parent' ?
            items[0].pItems.filter((item: { parentSourceCode: any; }, index: any, self: any[]) => {
                return index === self.findIndex((i) => (
                    i.parentSourceCode === item.parentSourceCode
                ))
            }) : items[0].pItems;
        const primaryItem = pItems[i]
        if (this.props.sectionId !== 'company') {
            setPrimary(this.props.sectionId, this.props.conversionId, code, primaryItem.id, items[0].pItems.length)
                .then(() => this.props.getItems!())
        }
        else if (this.state.modalType === 'parent') {
            this.company.updateParentPrimary(this.props.conversionId, code, primaryItem.parentSourceCode)
                .then(() => this.props.getItems!())
        } else if (this.state.modalType === 'writing') {
            this.company.updatePrimary(this.props.conversionId, code, primaryItem.sourceCode, primaryItem.id)
                .then(() => this.props.getItems!())
        }
    }

    exportToExcel = (id: string) => {
        this.report.exportToExcel(this.props.conversionId, id, null, null)
            .then(response => saveAs(response!.data, response!.fileName))
            .catch(error => { });
    }

    copyMapping = (id: string) => {
        if (confirm('Are you sure, do you want to continue with Copy Mappings?')) {
            this.setState({ copyDisable: true, buttonLocked: true }, () => {
                if (this.props.lockButton) {
                    this.props.lockButton(this.state.buttonLocked);
                }
            });
            const userId = this.props.currentUser!.id ? this.props.currentUser!.id : '';
            this.client.copyMappings(this.props.conversionId, userId, id)
                .then(() => {
                    clearInterval(this.state.timeout);
                    this.setState({ copyDisable: true });
                })
                .catch(error => { console.log(error) });
        }
    }
    clearMappings = (id: string) => {
        const userId = this.props.currentUser!.id ? this.props.currentUser!.id : '';
        this.client.clearMappings(this.props.conversionId, userId, id)
            .then(() => {
                clearInterval(this.state.timeout);
                this.setState({ clearModal: false });
                if (this.props.getItems) this.props.getItems();
                if (id === 'companyValidate') store.dispatch(enqueueNotification({ type: 'primary', message: 'Company Validation Executed' }))
            })
            .catch(error => { console.log(error) });
    }

    employeePrimary = (item: any) => {
        let string = `${item.sourceCode} - ${item.sourceDescription}`;
        if (item.isProd || item.isRep || item.isTeleMarketer || item.isOther) { string = string.concat(" - Type: ") }
        if (item.isProd) {
            string = string.concat("Producer");
            if (item.isRep) {
                string = string.concat("/CSR");
            }
            if (item.isTeleMarketer) {
                string = string.concat("/Sales Rep");
            }
            if (item.isOther) {
                string = string.concat("/Other");
            }
        } else if (item.isRep) {
            string = string.concat("CSR");
            if (item.isTeleMarketer) {
                string = string.concat("/Sales Rep");
            }
            if (item.isOther) {
                string = string.concat("/Other");
            }
        } else if (item.isTeleMarketer) {
            string = string.concat("Sales Rep");
            if (item.isOther) {
                string = string.concat("/Other");
            }
        } else if (item.isOther) {
            string = string.concat("Other");
        }
        if (item.email) string = string.concat(` - e: ${item.email}`);
        if (item.email2) string = string.concat(` - e: ${item.email2}`);
        if (item.businessNumber) string = string.concat(` - B#: ${item.businessNumber}`);
        if (item.mobileNumber) string = string.concat(` - M#: ${item.mobileNumber}`);
        if (item.residenceNumber) string = string.concat(` - H#: ${item.residenceNumber}`);
        if (item.address1) string = string.concat(` - ${item.address1}`);
        if (item.address2) string = string.concat(` ${item.address2}`);
        if (item.address3) string = string.concat(` ${item.address3}`);
        if (item.city) string = string.concat(` ${item.city}, ${item.state} ${item.postalCode}`);
        return string
    }

    autoMap(sectionId: string) {
        this.setState({ autoDisable: true, buttonLocked: true }, () => {
            if (this.props.lockButton) {
                this.props.lockButton(this.state.buttonLocked);
            }
        });
        const userId = this.props.currentUser!.id ? this.props.currentUser!.id : '';
        this.client.autoMappings(this.props.conversionId, userId, sectionId)
            .then(() => {
                clearInterval(this.state.timeout);
                if (this.props.getItems) this.props.getItems();
            })
            .catch(error => { console.log(error) });
    }

    buttonLock = () => {
        const conversionId = this.props.conversionId;
        if (this.state.currentConversion && this.state.currentConversion!.dateCompleted === undefined) {
            this.executions.listExecutionsAllTierBoolean(conversionId)
                .then((response) => {
                    if (response) {
                        this.setState({ buttonLocked: response.isLocked })
                        if (this.props.lockButton) {
                            this.props.lockButton(response.isLocked);
                        }
                    }
                })
                .catch(error => { })
        }
    }

    public render() {
        let entityDisplayName = Constants.entityNames.get(this.props.sectionId.toLowerCase());
        entityDisplayName = entityDisplayName ? entityDisplayName : Constants.entityNames.get(this.props.sectionId + this.props.sectionType);
        const buttonText = this.props.buttonText || 'Migrate Mapped ' + entityDisplayName;
        const sectionMap = this.props.sectionType ? this.props.sectionId + this.props.sectionType : this.props.sectionId
        const tierBool = this.props.tierGate && this.state.tierBooleans ? (this.state.tierBooleans as any)[this.props.tierGate] : true;
        const modalType = this.state.modalType
        const primaryList = modalType === 'parent' ? this.props.primaryList!.filter(p => p.parentDestinationCode) : modalType === 'writing' ? this.props.primaryList!.filter(p => p.destinationCode) : this.props.primaryList ? this.props.primaryList : [];
        return (
            <>

                <StageToolbar
                    conversionId={this.props.conversionId}
                    handleSearch={this.props.handleSearch}
                    filter={this.props.filter}
                >

                    {(!this.props.disableMigrate) &&
                        (this.props.children || <SecureContainer roles={[
                            Roles.vertaforeInternalRoleId,
                            Roles.developerAdministrationRoleId,
                            Roles.administrationRoleId
                        ]}>
                            <>
                                <Button className='load-button' color={!this.state.buttonLocked && !this.state.disabled && tierBool ? "primary" : "secondary"} onClick={this.validatePrimaries} disabled={this.state.buttonLocked || this.state.disabled || !tierBool} >
                                    {buttonText}
                                </Button>
                                {this.state.disabled ? <img className='loading-indicator' alt="loader" src={'loader.gif'} /> : null}
                            </>
                        </SecureContainer>)}
                    <div>
                        {(this.state.currentConversion && this.state.currentConversion!.parentConversionId && this.props.copyMap) ?
                            this.state.buttonLocked ?
                                this.state.copyDisable ?
                                    <img className='copy-loading-indicator' alt="loader" src={'loader.gif'} />
                                    : null
                                : (this.props.children || <SecureContainer roles={[
                                    Roles.vertaforeInternalRoleId,
                                    Roles.developerAdministrationRoleId,
                                    Roles.administrationRoleId
                                ]}>
                                    <DataTableButton
                                        icon='scanner'
                                        id={sectionMap + "Copy"}
                                        title='Copy Mappings'
                                        action={() => this.copyMapping(sectionMap)} />
                                </SecureContainer>) : null}
                        {this.props.autoMap && !this.state.buttonLocked ?
                            this.state.autoDisable ?
                                <img className='copy-loading-indicator' alt="loader" src={'loader.gif'} /> :
                                <DataTableButton
                                    icon='work'
                                    id={sectionMap + "AutoMap"}
                                    title='Auto Map Employee Role Types'
                                    action={() => this.autoMap(sectionMap)} /> : null}
                        <div>
                            {this.props.noMapping ? null :
                                <DataTableButton
                                    icon='file_download'
                                    id={sectionMap + "Map"}
                                    title='Export to Excel'
                                    action={() => this.exportToExcel(`${sectionMap}Map`)} />
                            }
                            {!this.state.buttonLocked && !this.props.noMapping && !this.props.noClear && <DataTableButton
                                icon='backspace'
                                id={sectionMap + "Clear"}
                                title='Clear Mappings'
                                action={() => this.setState({ clearModal: true })} />}
                        </div>
                    </div>
                </StageToolbar>
                {this.state.lastExecution ? this.state.status : null}
                <div>
                    <Modal isOpen={this.state.modal}>
                        <ModalHeader>Please Confirm {entityDisplayName ? entityDisplayName : ''} Primaries </ModalHeader>
                        <ModalBody className='modalBody'>
                            <DataTable>
                                <thead>
                                    {modalType === 'parent' ? <tr><th>Parent Company Primaries</th></tr> : modalType === 'writing' ? <tr><th>Writing Company Primaries</th></tr> : null}
                                    <tr>
                                        {modalType === 'parent' ? <th onClick={() => this.sort('parentDestinationCode')}>Destination Code <i className="fa fa-sort"></i></th> : <th onClick={() => this.sort('destinationCode')}>Destination Code <i className="fa fa-sort"></i></th>}
                                        <th>Primary Source Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {primaryList.map((primary) => (
                                        <DataRow id={primary.destinationCode} key={primary.destinationCode}>
                                            {primary.parentDestinationCode ? <DataCell>{primary.parentDestinationCode}</DataCell> : <DataCell>{primary.destinationCode}</DataCell>}
                                            <DataCell>
                                                <select
                                                    name={modalType === 'parent' ? primary.parentDestinationCode : primary.destinationCode}
                                                    className="custom-select"
                                                    onChange={this.handleSelectPrimary}
                                                    id={`${primary.destinationCode}dropDown`}
                                                    key={`${primary.destinationCode}dropDown`}
                                                >
                                                    {(modalType === 'parent' ? primary.pItems
                                                        .filter((item: { parentSourceCode: any; }, index: any, self: any[]) => {
                                                            return index === self.findIndex((i) => (
                                                                i.parentSourceCode === item.parentSourceCode
                                                            ))
                                                        })
                                                        : primary.pItems)
                                                        .filter((i: { parentIsPrimary: any; }) => {
                                                            if (modalType === 'writing') {
                                                                return i.parentIsPrimary
                                                            } else {
                                                                return i
                                                            }
                                                        })
                                                        .map((item:
                                                            {
                                                                id: string | number | undefined;
                                                                isPrimary: boolean | undefined;
                                                                parentIsPrimary: boolean | undefined;
                                                                writingSourceCode: string | undefined;
                                                                writingSourceDescription: string | undefined;
                                                                parentSourceCode: string | undefined;
                                                                parentSourceDescription: string | undefined;
                                                                sourceCode: React.ReactNode;
                                                                sourceDescription: React.ReactNode;
                                                                businessNumber: React.ReactNode;
                                                                email: React.ReactNode;
                                                                email2: React.ReactNode;
                                                                mobileNumber: React.ReactNode;
                                                                residenceNumber: React.ReactNode;
                                                                address1: React.ReactNode;
                                                                address2: React.ReactNode;
                                                                address3: React.ReactNode;
                                                                city: React.ReactNode;
                                                                state: React.ReactNode;
                                                                postalCode: React.ReactNode;
                                                            }, index: string | number | string[] | undefined) => (
                                                            <option
                                                                selected={modalType === 'parent' ? item.parentIsPrimary : item.isPrimary}
                                                                value={index}
                                                                key={item.id}
                                                            >
                                                                {this.props.sectionId === 'employees' ?
                                                                    this.employeePrimary(item)
                                                                    : modalType === 'writing' ? `${item.writingSourceCode} - ${item.writingSourceDescription} `
                                                                        : modalType === 'parent' && item.parentSourceCode === '' ? `Parent ${Number(index) + 1}`
                                                                            : modalType === 'parent' ? `${item.parentSourceCode} - ${item.parentSourceDescription}`
                                                                                : `${item.sourceCode} - ${item.sourceDescription} `}
                                                            </option>
                                                        ))}
                                                </select>
                                            </DataCell>
                                        </DataRow>
                                    ))}
                                </tbody>
                            </DataTable>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.modal('')}>Close</Button>
                            {modalType === "parent" ?
                                <Button color="primary" onClick={() => this.setState({ modalType: "writing" })}>Submit</Button> :
                                <Button color="primary" onClick={this.loadEntities}>Submit</Button>}
                        </ModalFooter>
                    </Modal>
                </div>
                <ClearConfirmationModal
                    isOpen={this.state.clearModal}
                    message={`WARNING: This will clear ALL mapings for ${entityDisplayName ? entityDisplayName : ''}. \nPlease confirm how you want to proceed:`}
                    onSubmit={() => this.clearMappings(sectionMap)}
                    onClose={() => this.setState({ clearModal: false })}
                />
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser
        }
    },
)(StageActionToolbar);
