import * as React from 'react';
import _ from 'lodash';

import { Button, Input, InputGroupAddon, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import './Pagination.scss';

export type PaginationProps = {
    children?: React.ReactNode,
}

export class PaginationText extends React.PureComponent<PaginationProps> {
    render() {
        return <div className='page-item-text'>{this.props.children}</div>;
    }
}

export type RangePaginationProps = {
    pageNumber: number,
    pageSize: number,
    recordsCount: number,

    onChangeIndex?: (index: number) => void;
}

interface PaginationState {
    pageNumber: number
}

export class RangePagination extends React.Component<RangePaginationProps, PaginationState> {

    constructor(props: RangePaginationProps) {
        super(props)
        this.state = {
            pageNumber: 1
        }
    }

    componentDidMount() {
        this.checkPageNumber();
    }

    componentDidUpdate(prevProps: RangePaginationProps) {
        if (prevProps.pageNumber !== this.props.pageNumber
            || prevProps.pageSize !== this.props.pageSize
            || prevProps.recordsCount !== this.props.recordsCount) {
            this.checkPageNumber();
        }
    }

    private checkPageNumber = () => {
        this.setState({ pageNumber: this.props.pageNumber + 1 })
        if (this.props.pageNumber < 0) {
            this.handleOnChangeIndex(0);
        } else {
            const maxPageNumber = this.getLastPageIndex();
            if (this.props.pageNumber > maxPageNumber) {
                this.handleOnChangeIndex(maxPageNumber);
            }
        }
    }

    private getRecordPageIndex = (recordIndex: number) => {
        return Math.trunc(recordIndex / this.props.pageSize);
    }

    private getLastPageIndex = () => {
        return this.getRecordPageIndex(this.props.recordsCount - 1)
    }

    // Gets inclusive max and min indexes of the pages, which buttons are
    // displayed in the page navigator.
    private getPaginationRange = () => {
        const displayCount = 9; // must be an odd number

        const extraPages = (displayCount - 1) / 2;
        // The number of items, that won't fit either side of page numbers
        // related to current page.
        const delta =
            Math.max(0, -(this.props.pageNumber - extraPages)) +
            Math.max(0, -(this.getLastPageIndex() - (this.props.pageNumber + extraPages)));

        return {
            min: Math.max(0, this.props.pageNumber - (extraPages + delta)),
            max: Math.min(this.getLastPageIndex(), this.props.pageNumber + (extraPages + delta)),
        };
    }

    private handleOnChangeIndex = (index: number) => {
        this.props.onChangeIndex && this.props.onChangeIndex(index);
        this.setState({ pageNumber: index + 1 })
    }

    render() {
        if (this.props.recordsCount === 0) {
            return <div />;
        }
        const max = this.getLastPageIndex();
        const maxPage = max.toString() === 'NaN' ? 0 : max;
        const paginationRangeBounds = this.getPaginationRange();
        return (
            <Pagination>
                <PaginationItem disabled={this.props.pageNumber === 0}>
                    <PaginationLink onClick={() => this.handleOnChangeIndex(0)}>
                        &laquo;
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={this.props.pageNumber === 0}>
                    <PaginationLink previous onClick={() => this.handleOnChangeIndex(this.props.pageNumber - 1)}>
                        &lsaquo;
                    </PaginationLink>
                </PaginationItem>
                {
                    paginationRangeBounds.min !== 0 &&
                    <PaginationItem>
                        <PaginationText>...</PaginationText>
                    </PaginationItem>
                }
                {
                    _.range(paginationRangeBounds.min, paginationRangeBounds.max + 1)
                        .map((n, i) => (
                            <PaginationItem key={i} active={n === this.props.pageNumber}>
                                <PaginationLink next onClick={() => this.handleOnChangeIndex(n)}>
                                    {n + 1}
                                </PaginationLink>
                            </PaginationItem>
                        ))
                }
                {
                    paginationRangeBounds.max !== this.getLastPageIndex() &&
                    <PaginationItem>
                        <PaginationText>...</PaginationText>
                    </PaginationItem>
                }
                <PaginationItem disabled={this.props.pageNumber === this.getLastPageIndex()}>
                    <PaginationLink next onClick={() => this.handleOnChangeIndex(this.props.pageNumber + 1)}>
                        &rsaquo;
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={this.props.pageNumber === this.getLastPageIndex()}>
                    <PaginationLink onClick={() => this.handleOnChangeIndex(this.getLastPageIndex())}>
                        &raquo;
                    </PaginationLink>
                </PaginationItem>
                <Input placeholder='Page'
                    type='number'
                    min={1}
                    max={maxPage + 1}
                    step="1"
                    pattern="[0-9]"
                    className={"searchPage"}
                    value={this.state.pageNumber}
                    onChange={(e) => {
                        let pageNumber = Number(e.target.value.replace(/[^0-9]/gi, ''))
                        this.setState({
                            pageNumber
                        })
                    }}
                />
                <InputGroupAddon addonType='append' className='material-icon'>
                    <Button onClick={() => {
                        let pageNumber = this.state.pageNumber
                        if (pageNumber > maxPage) {
                            pageNumber = maxPage + 1
                            this.setState({ pageNumber })
                        }
                        this.handleOnChangeIndex(pageNumber - 1)
                    }
                    }> search</Button>
                </InputGroupAddon>
            </Pagination>
        )
    }
}

export type RangePaginationState = {
    pageSize: number;
    pageNumber: number;
}

export function paginate<I, R>(items: I[], state: RangePaginationState, f: (item: I, index: number) => R): R[] {
    const pageOffset = state.pageNumber * state.pageSize;

    return _(items)
        .slice(pageOffset)
        .take(state.pageSize)
        .map((item, index) => f(item, index + pageOffset))
        .value();
}