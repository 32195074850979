import * as React from 'react';
import Table, { TableProps } from 'reactstrap/lib/Table';
import classnames from 'classnames';

import '../../styles/Icons.scss';
import './DataTable.scss';

export type DataRowProps<T = {}> =
    React.HTMLAttributes<HTMLTableRowElement> & {
        id: string,
        action?: (id: string) => void
    } & T;

export class DataRow<T = {}> extends React.Component<DataRowProps<T>> {
    tryInvokeAction = (id: string) => this.props.action && this.props.action(id);

    render() {
        const { action, ...props } = this.props;
        return (
            <tr
                onClick={_ => { this.tryInvokeAction(this.props.id); }}
                onKeyDown={event => {
                    if (event.keyCode === 13 /* the Enter key code */) {
                        this.tryInvokeAction(this.props.id);
                    }
                }} tabIndex={0} {...props}>
                {this.props.children}
            </tr>
        )
    }
}

export type DataCellProps<T = {}> = React.TdHTMLAttributes<HTMLTableDataCellElement> & T;

export class DataCell<T = {}> extends React.Component<DataCellProps<T>> {
    static dateFormat =
        new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        });

    renderValue(value: any): any {
        if (value instanceof Date) {
            return DataCell.dateFormat.format(value);
        } else {
            return value;
        }
    }

    render() {
        return (<td {...this.props}>{this.renderValue(this.props.children)}</td>)
    }
}

export type DataTableProps<T = {}> = TableProps & {
    fixed?: boolean,
} & T;

export class DataTable<T = {}> extends React.Component<DataTableProps<T>> {
    render() {
        const { fixed, ...props } = this.props;
        return (
            <Table className={classnames({
                'data-table': true,
                'data-table-fixed': this.props.fixed,
            })} responsive hover {...props}>
                {this.props.children}
                    </Table>
        )
    }
}

export type DataTableButtonProps<T = {}> =
    React.HTMLAttributes<HTMLElement> & {
        id: string;
        action?: (id: string) => void
        // See the icon names here: https://material.io/resources/icons/.
        icon?: string;
    } & T;

export class DataTableButton<T = {}> extends React.Component<DataTableButtonProps<T>> {
    tryInvokeAction = (id: string) => this.props.action && this.props.action(id);

    render() {
        const { icon, className, action, ...props } = this.props;
        return <a
            onClick={event => {
                event.stopPropagation();
                this.tryInvokeAction(this.props.id);
            }}
            onKeyDown={event => {
                if (event.keyCode === 13 /* the Enter key code */) {
                    this.tryInvokeAction(this.props.id);
                }
            }}
            tabIndex={0}
            className={classnames('user-select-none', 'data-table-button', className, {
                'material-icon': !!this.props.icon,
                'data-table-material-button': !!this.props.icon,
            })} {...props}>
            {icon || this.props.children}
        </a>
    }
}
