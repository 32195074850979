import * as React from 'react';
import { Prompt } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { IUserLoginDetailsModel } from '../client/ApiClient';

export interface ConversionEditAndOptionsPageProps extends RouteComponentProps<{
    conversionId: string,
}> {
    isEditing?: boolean,
    currentUser?: IUserLoginDetailsModel | undefined,
    refreshPage?: any,
    customersFile?: boolean,
    policiesFile?: boolean,
    currentTabName: string,
}

export function promptAgainstState<P, S extends T & { initial: T }, T = S>(
    component: React.ComponentClass<ConversionEditAndOptionsPageProps, S>,
    selector: (state: T) => any,
    tabName: string,
    message?: string
): React.ComponentClass<ConversionEditAndOptionsPageProps, S> {

    return class PromptedComponent extends component {
        private static readonly Message = "Changes you made will be lost. Do you really want to leave the page?";

        private stateWasChanged = (): boolean => {
            if (tabName !== this.props.currentTabName) {
                return false;
            }

            const initial = selector(this.state.initial);
            const current = selector(this.state);

            return !_.isEqual(initial, current);
        }

        private handleBeforeUnload = ((e: BeforeUnloadEvent) => {
            if (!this.stateWasChanged()) {
                return null;
            }

            e.preventDefault();
            e.returnValue = true;

            return true;
        });

        componentDidMount() {
            window.addEventListener("beforeunload", this.handleBeforeUnload);
            super.componentDidMount && super.componentDidMount();
        }

        componentWillUnmount() {
            super.componentWillUnmount && super.componentWillUnmount();
            window.removeEventListener("beforeunload", this.handleBeforeUnload);
        }

        render() {
            return (
                <React.Fragment>
                    <Prompt
                        message={message || PromptedComponent.Message}
                        when={this.stateWasChanged()}
                    />
                    {super.render()}
                </React.Fragment>
            )
        }
    }
}
