import * as React from 'react';
import { Button, Input, InputGroup, } from 'reactstrap';
import { ConversionReportsClient } from '../../../../../client/ApiClient';
import { DataTableButton } from '../../../../basic/DataTable';
import { saveAs } from 'file-saver';
import Toolbar from "../../../../basic/Toolbar";

export interface StageToolbarProps {
    conversionId: string
    handleSearch?: (filter: string) => void
    sectionId?: string;
    mapExcel?: boolean;
    filter: string;
    children?: React.ReactNode;
}

export default class StageToolbar extends React.Component<StageToolbarProps>{

    private report: ConversionReportsClient = new ConversionReportsClient();

    handleSearch = (e: any) => {
        let filter = e.target.value
        if (this.props.handleSearch) {
            this.props.handleSearch(filter)
        }
    }

    clearFilter = () => {
        if (this.props.handleSearch) {
            this.props.handleSearch('')
        }
    }

    handleSearchInput = (event: { keyCode: any; which: any; preventDefault: () => void; }) => {
        const keyCode = event.keyCode || event.which

        // 37 is '%' symbol
        if (keyCode === 37) {
            event.preventDefault()
        }
    }

    private canHandleSearch = () => !!this.props.handleSearch;

    exportToExcel = (id: string) => {
        this.report.exportToExcel(this.props.conversionId, id, null, null)
            .then(response => saveAs(response!.data, response!.fileName))
            .catch(error => { });
    }


    public render() {
        const sectionMap = this.props.sectionId;
        return (
            <Toolbar>
                <InputGroup>
                    {this.canHandleSearch() &&
                        <React.Fragment>
                            <Input placeholder='Search'
                                name='filter'
                                onChange={this.handleSearch}
                                value={this.props.filter}
                            />
                            <InputGroup addonType='append' className='material-icon'>
                                <Button onClick={this.clearFilter}> close</Button>
                        </InputGroup>
                        {this.props.mapExcel ? < DataTableButton icon='file_download' id={sectionMap+"Map"} title='Export to Excel'
                            action={() => this.exportToExcel(`${sectionMap}Map`)} /> : null}
                        </React.Fragment>}
                </InputGroup>
                {this.props.children}
            </Toolbar>
        )
    }
}