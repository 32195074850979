import * as React from 'react';
import { ModalHeader, Modal, ModalFooter, Button } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';


type ConfirmationModalNewConversionProps = RouteComponentProps & {
    isOpen: boolean;
    message: string;
    conversionId: string | undefined;
};


export class ConfirmationModalNewConversion extends React.Component<ConfirmationModalNewConversionProps, {}>{
    constructor(props: ConfirmationModalNewConversionProps) {
        super(props)
        this.state = {};
    }

    redirectionToConversionPage(tab: string) {
        if (tab === 'conversions') {
            this.props.history.push({
                pathname: `/conversion`,
            });
        } else {
            this.props.history.push({
                pathname: `/conversion/${this.props.conversionId}/${tab}`,
                state: { tabName: tab }
            });
        }
    }

    public render() {
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader toggle={() => this.redirectionToConversionPage('edit')}>{this.props.message}</ModalHeader>
                <ModalFooter>
                    <Button color="info" onClick={() => this.redirectionToConversionPage('options')}>Input Options</Button>
                    <Button color="primary" onClick={() => this.redirectionToConversionPage('conversions')}>Conversions</Button>
                    <Button color="secondary" onClick={() => this.redirectionToConversionPage('edit')}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default withRouter(ConfirmationModalNewConversion);