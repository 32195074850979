import classnames from 'classnames';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { store } from "AppContext";
import * as Roles from 'constants/Roles';
import { saveAs } from 'file-saver';
import { Badge, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { ConversionClient, ConversionModel, ConversionOptionValueModel, ConversionOptionsClient, ConversionReportsClient, ExecutionsClient, ExecutionsModel, GenericClient, IUserLoginDetailsModel, StatisticsClient } from '../../../client/ApiClient';
import { sendNotification } from '../../../services/Notifications';
import { actionCreators } from "../../../store/Boolean";
import { enqueueNotification } from "../../../store/Notifications";
import '../../../styles/Forms.scss';
import { SecureContainer } from '../../basic/Authentication';
import { DataTableButton } from '../../basic/DataTable';
import ImageLogTable from '../../basic/ImageLogTable';
import Toolbar from '../../basic/Toolbar';
import { actionTypes, migrateEntities, missingEntitiesTier2, missingEntitiesTier3, missingEntitiesTier4, missingEntitiesTier5, tierNames } from './Constants';
import ConversionSummaryTable from './ConversionSummaryTable';
import './entities/generic/StageActionToolbar.scss';
import './entities/generic/TierSummary.scss';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Link, LinkProps } from 'react-router-dom';
interface ConversionSummaryPageArgs {
    conversionId: string,
}

class StatisticsViewModel {
    entityType?: string | undefined;
    mapped?: number | undefined;
    notMapped?: number | undefined;
    isDropped?: number | undefined;
    total?: number | undefined;
    status?: any | undefined;
    statuses?: any | undefined;
    sectionId?: string | undefined;
    tier?: string | undefined;
    isMappable?: boolean | undefined;
}
interface ConversionSummaryPageState {
    items: StatisticsViewModel[];
    copyDisable: boolean;
    executions: ExecutionsModel[];
    hubConnection: HubConnection | null;
    buttonLock: boolean;
    loading: boolean;
    lastExecution: ExecutionsModel | undefined;
    entityType: string | undefined;
    status1: any;
    status2: any;
    conversion: ConversionModel | undefined;
    options: { [key: string]: ConversionOptionValueModel } | undefined;
    tab: string;
}


type ConversionSummaryPageProps = RouteComponentProps<ConversionSummaryPageArgs> & {
    currentUser: IUserLoginDetailsModel | undefined,
    isMappable: boolean,
};

type TabName = 'summaryTable' | 'imageLog' | '';

class ConversionSummaryPage extends React.Component<ConversionSummaryPageProps, ConversionSummaryPageState>{
    private readonly client: GenericClient = new GenericClient();
    private readonly statisticsClient: StatisticsClient = new StatisticsClient();
    private executions: ExecutionsClient = new ExecutionsClient();
    private conversion: ConversionClient = new ConversionClient();
    private options: ConversionOptionsClient = new ConversionOptionsClient();
    private report: ConversionReportsClient = new ConversionReportsClient();
    private readonly tierIds: string[] = ['tier0', 'tier1', 'tier4', 'tier5']
    private readonly allTiers: string = "alltiers";
    private readonly unMatchedEntities = ["Dwelling Fire Form Type", "Homeowners Coverage Codes", "Homeowners Form Type", "Dwelling Fire Coverage Codes"]


    constructor(props: ConversionSummaryPageProps) {
        super(props);
        this.state = {
            items: [],
            copyDisable: false,
            executions: [],
            hubConnection: null,
            buttonLock: true,
            loading: false,
            lastExecution: undefined,
            entityType: undefined,
            status1: null,
            status2: null,
            conversion: undefined,
            options: undefined,
            tab: 'summaryTable',
        }
    }

    componentDidMount() {
        this.currentConversion();
        this.getStatisticsData();
        this.getOptions();
        const link = `${process.env.PUBLIC_URL}/executions`
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :('));

            this.state.hubConnection!.on('ExecutionUpdated', (execution: ExecutionsModel) => {
                if ((execution.description === "Succeeded" || execution.description === "Completed") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'success', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
                else if ((execution.description === "Failed" || execution.description === "Canceled" || execution.description === "Ended Unexpectedly" || execution.description === "Stopping") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'error', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
            });
            this.state.hubConnection!.on('ExecutionCalled', (call: string) => {
                this.getStatisticsData();
            });
            this.state.hubConnection!.on('UpdateCountdown', (call: string) => {
                if (this.state.lastExecution && this.state.entityType) this.updateStatus(this.state.lastExecution, this.state.entityType);
            });
        })

    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
    }

    getStatisticsData() {
        this.buttonLock();
        const conversionId = this.props.match.params.conversionId;
        const promises: any = [];
        for (let tierId of this.tierIds) {
            promises.push(this.statisticsClient.get(conversionId, tierId))
        }
        promises.push(this.executions!.listExecutions(this.props.match.params.conversionId));
        Promise.all(promises)
            .then((data: any) => { if (data) { this.processData(data) } })
            .catch((err: any) => console.log(err));
    }

    processData(statisticsData: any) {
        const items = [];
        if (statisticsData) {
            let tiersDataWithoutMigrations = [...statisticsData[0], ...statisticsData[1], ...missingEntitiesTier2, ...missingEntitiesTier3, ...statisticsData[2], ...missingEntitiesTier4, ...statisticsData[3], ...missingEntitiesTier5];
            let executionsReversed = statisticsData[statisticsData.length - 1].reverse();
            this.setState({ executions: executionsReversed }, () => { this.mainStatus() })
            const copyExecution = executionsReversed.find((e: any) => e.packageName === '00-00-00-Transfer-Main.dtsx');
            if (copyExecution) this.copyButton(copyExecution);
            for (let tierData of tiersDataWithoutMigrations) {
                let packageName = "";
                let sectionId = "";
                let tier = "";
                if (migrateEntities.find(m => m.sectionId === tierData.entityType.replace(/\s/g, '').toLowerCase())) {
                    const migrateEntity = migrateEntities.find(m => m.sectionId === tierData.entityType.replace(/\s/g, '').toLowerCase());
                    packageName = migrateEntity!.packageName!;
                    sectionId = migrateEntity!.sectionId;
                    tier = migrateEntity!.tier!;
                    //console.log("FIND", tierData.entityType, packageName, sectionId, tier)
                }
                else if (tierData.entityType.toLowerCase() === "commercial property cause of loss") {
                    const migrateEntity = migrateEntities.find(m => m.sectionId === "cpcauseofloss");
                    packageName = migrateEntity!.packageName!;
                    sectionId = migrateEntity!.sectionId;
                    tier = migrateEntity!.tier!;
                    //console.log("CP COL", tierData.entityType, packageName, sectionId, tier)
                }
                else if (!packageName && this.unMatchedEntities.includes(tierData.entityType)) {
                    packageName = '04-04-00-HomeownerDwellingFire.dtsx';
                    sectionId = 'hodf';
                    tier = 'tier5';
                    if (tierData.entityType.includes('Dwelling')) {
                        sectionId += "df"
                    } else {
                        sectionId += "ho"
                    }
                    if (tierData.entityType.includes('Coverage')) {
                        sectionId += "coveragecode"
                    } else {
                        sectionId += "formtype"
                    }
                    //console.log("HODF", tierData.entityType, packageName, sectionId, tier)
                    //} else {
                    //    console.log("NO MATCH", tierData.entityType, packageName, sectionId, tier)
                }
                let lastExecution = executionsReversed.find((e: any) => e.packageName === packageName);
                if (lastExecution) {
                    this.setState({ lastExecution, entityType: tierData.entityType })
                }
                let status = lastExecution ? this.statusUpdate(lastExecution, tierData.entityType) : "";
                let statuses = undefined;
                if (tierData.entityType === 'Action Types') {
                    const lastExecutions: any[] = []
                    statuses = []
                    const actions = (this.state.conversion && this.state.conversion.imagePath !== '' && this.state.options && this.state.options["60b9bc6c-b2ed-4258-b4e9-4192cbce04cc"].value === '1') ? actionTypes : actionTypes.filter(a => a.sectionId !== 'images');
                    actions.forEach(at => lastExecutions.push({ execution: executionsReversed.find((e: any) => e.packageName === at.packageName), sectionId: at.sectionId }))
                    lastExecutions.forEach(le => statuses.push(this.statusUpdate(le.execution, le.sectionId.charAt(0).toUpperCase() + le.sectionId.slice(1))))
                }
                const total = [tierData.mapped, tierData.isDropped, tierData.notMapped].reduce((a, b) => a + b, 0)
                const isMappable = total > 0
                let item = { ...tierData, packageName: packageName, status: status, statuses: statuses, sectionId: sectionId, tier: tier, isMappable: isMappable };
                items.push(item);
            }
        }
        this.setState({ items });
    }

    statusUpdate(lastExecution: ExecutionsModel, entityType: string) {
        if (lastExecution) {
            const dt = lastExecution!.endTime;
            if (dt) {
                const day = dt.getDate(),
                    month = dt.getMonth(),
                    year = dt.getFullYear(),
                    minutes = (dt.getMinutes() < 10 ? '0' : "") + dt.getMinutes();
                let hours = dt.getHours();

                const period = (hours < 12) ? 'AM' : 'PM';
                if (period === 'PM' && hours !== 12) hours = hours - 12;
                if (hours === 0) hours = 12;

                let color = ""
                const status = lastExecution.description
                if (status === "Canceled" || status === "Failed" || status === "Ended Unexpectedly") {
                    color = "danger"
                } else if (status === "Succeeded" || status === "Completed") {
                    color = "success"
                }
                return <h6> <Badge color={color}> {entityType} Completed:<br />{
                    `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                } <br />Status: {lastExecution.description} </Badge></h6>
            } else {
                const color = "primary"
                const now = new Date().valueOf();
                const estimate = lastExecution.estimatedFinish ? lastExecution.estimatedFinish!.valueOf() : 0;
                const timeLeft: string = estimate > now ? `${((estimate - now) / 60000).toFixed(2)} minutes` : 'Pending';
                return <h6> <Badge color={color}>Estimate: {timeLeft} <br />Status: {lastExecution.description} </Badge></h6>
            }

        }
    }

    mainStatusUpdate(lastExecution: ExecutionsModel, entityType: string) {
        if (lastExecution) {
            const dt = lastExecution!.endTime;
            if (dt) {
                this.setState({ loading: false })
                const day = dt.getDate(),
                    month = dt.getMonth(),
                    year = dt.getFullYear(),
                    minutes = (dt.getMinutes() < 10 ? '0' : "") + dt.getMinutes();
                let hours = dt.getHours();

                const period = (hours < 12) ? 'AM' : 'PM';
                if (period === 'PM' && hours !== 12) hours = hours - 12;
                if (hours === 0) hours = 12;

                let color = ""
                const status = lastExecution.description
                if (status === "Canceled" || status === "Failed" || status === "Ended Unexpectedly") {
                    color = "danger"
                } else if (status === "Succeeded" || status === "Completed") {
                    color = "success"
                }
                return <h6> <Badge color={color}> {entityType} Completed: {
                    `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                } Status: {lastExecution.description} </Badge></h6>
            } else {
                this.setState({ loading: true })
                const color = "primary"
                const now = new Date().valueOf();
                const estimate = lastExecution.estimatedFinish ? lastExecution.estimatedFinish!.valueOf() : 0;
                const timeLeft: string = estimate > now ? `${((estimate - now) / 60000).toFixed(2)} minutes` : 'Pending';
                return <h6> <Badge color={color}>Estimate: {timeLeft} Status: {lastExecution.description} </Badge></h6>
            }

        }
    }

    updateStatus(lastExecution: ExecutionsModel, entityType: string) {
        let items = this.state.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].entityType === entityType) {
                items[i].status = this.statusUpdate(lastExecution, entityType)
                this.mainStatus();
            }
        }
        this.setState({ items });
    }

    mainStatus = () => {
        const execution1 = this.state.executions.filter(e => e.packageName === '00-00-00-00-Tier-Proc-All.dtsx')
        const status1 = execution1[0] ? this.mainStatusUpdate(execution1[0], 'Entities: Tiers 1-4') : null

        const execution2 = this.state.executions.filter(e => e.packageName === '00-00-00-PD-Prep.dtsx')
        const status2 = execution2[0] ? this.mainStatusUpdate(execution2[0], 'Entities: Policy Details') : null
        this.setState({ status1, status2 })
    }

    currentConversion = () => {
        this.conversion.get(this.props.match.params.conversionId)
            .then(response =>
                this.setState({ conversion: response! }, () => {
                    this.buttonLock();
                })
            )
            .catch(error => { })
    }

    getOptions = () => {
        this.options.get(this.props.match.params.conversionId)
            .then(values => {
                const options = values!.reduce(
                    (result, current) => ({ ...result, [current.id!]: current, }), {});
                this.setState({ options });
            })
            .catch(error => { });
    }

    buttonLock = () => {
        const conversionId = this.props.match.params.conversionId;
        if (this.state.conversion && this.state.conversion!.dateCompleted === undefined) {
            this.executions.listExecutionsAllTierBoolean(conversionId)
                .then((response) => {
                    if (response) this.setState({ buttonLock: response.isLocked })
                    if (!this.state.conversion!.refreshRan) this.setState({ buttonLock: true })
                })
                .catch(error => { })
        }
    }

    undoMigration = () => {
        const conversionId = this.props.match.params.conversionId
        const user = this.props.currentUser;
        for (let tierId of this.tierIds) {
            const tierName = tierNames.get(tierId)
            if (user) {
                this.client.runUndoMigrationAction(conversionId, user.id!, tierId)
                    .then(_ => {
                        sendNotification('success', 'Migration of ' + tierName + ' was reverted.');
                    })
                    .catch(error => { })
            }

        }
    }

    migrateAll = () => {
        this.setState({ buttonLock: true, loading: true });
        const conversionId = this.props.match.params.conversionId
        const user = this.props.currentUser;
        if (user) {
            this.client.runMigrateAllAction(conversionId, user.id!, this.allTiers)
                .then(_ => {
                })
                .catch(error => { })
        }
    }

    copyMapping = (id: string) => {
        if (confirm('Are you sure, do you want to continue with Copy Mappings?')) {
            this.setState({ copyDisable: true });
            const userId = this.props.currentUser!.id ? this.props.currentUser!.id : '';
            this.client.copyMappings(this.props.match.params.conversionId, userId, id)
                .then(() => {
                    this.setState({ copyDisable: true });
                    if (this.getStatisticsData) this.getStatisticsData();
                })
                .catch(error => { console.log(error) });
        }
    }

    copyButton = (lastCopy: any) => {
        if (lastCopy) {
            this.setState({ copyDisable: true });
            if (lastCopy.endTime) {
                this.setState({ copyDisable: false });
            } else {
                this.setState({ copyDisable: true });
            }
        }
    }

    toEntity = (item: any) => {
        this.props.history.push({
            pathname: `/conversion/${this.props.match.params.conversionId}/mapping/entity/${item.tier}/${item.sectionId}/staging`,
            state: { tabName: 'staging' }
        });
    }

    isMappable = () => {
        store.dispatch(actionCreators.update())
    }

    exportToExcel = (id: string) => {
        this.report.exportToExcel(this.props.match.params.conversionId, id, null, null)
            .then(response => saveAs(response!.data, response!.fileName))
            .catch(error => { store.dispatch(enqueueNotification({ type: 'error', message: 'Conversion created before feature released.' })) });
    }

    selected = (tab: TabName) => this.state.tab === tab;

    toggle = (tab: TabName) => {
        if (this.state.tab !== tab) {
            this.setState({ tab })
        }
    }

    handleHover = (statusvalue:any) => {
        if (statusvalue.props.children[1].props.color.toString() == "danger") {
            this.executions!.listExecutionErrorDetail(this.props.match.params.conversionId)
                .then(results => {
                    if (results!.length != 0)
                        alert(results);



                })
                .catch(e => { console.log(e) })
            //setisHover(true);
            //alert("test message in");
        }
    };
   
    public render() {
        const items = this.state.items.filter(i => {
            return (this.state.options && this.state.options["42a30566-b350-4e93-b0a1-d76919db2c6b"] && this.state.options["42a30566-b350-4e93-b0a1-d76919db2c6b"].value !== 'N' && i.entityType === 'Group') ||
                (this.state.options && this.state.options["61d5689e-56fe-4098-b964-7a797c540c10"] && this.state.options["61d5689e-56fe-4098-b964-7a797c540c10"].value !== 'N' && i.entityType === 'Branch')
                || (i.entityType !== 'Branch' && i.entityType !== 'Group')
        }).filter(i => {
            return !this.props.isMappable || (this.props.isMappable && i.isMappable)
        })

        const imageConversion = this.state.conversion && this.state.conversion.imagePath !== '' && this.state.options && this.state.options["60b9bc6c-b2ed-4258-b4e9-4192cbce04cc"].value === '1'
        const migrateAll = this.state.conversion && this.state.conversion!.imagePath === "" ? "Migrate All Mapped" : "Migrate All Mapped with Images"
        return (
            <>
                <div className="header-display">
                    <h3 className='mapping-body-header'>All Entities</h3>
                    <div className="header-display">
                        <SecureContainer roles={[
                            Roles.vertaforeInternalRoleId,
                            Roles.developerAdministrationRoleId,
                            Roles.administrationRoleId
                        ]}>
                            < Button className='load-button' color={!this.state.buttonLock ? "primary" : "secondary"} onClick={this.migrateAll} disabled={this.state.buttonLock} >{migrateAll}</Button>
                        </SecureContainer>
                        <div>
                            <SecureContainer roles={[
                                Roles.vertaforeInternalRoleId,
                                Roles.developerAdministrationRoleId,
                                Roles.administrationRoleId
                            ]}>
                                <>
                                    {this.state.loading ? <img className='loading-indicator' alt="loading" src={'loader.gif'} /> : null}
                                    {this.state.copyDisable && this.state.conversion && this.state.conversion.refreshRan ?
                                        <img className='copy-loading-indicator' alt="loading" src={'loader.gif'} /> :
                                        this.state.buttonLock || (this.state.conversion && this.state.conversion!.parentConversionId === null) ? null :
                                            <DataTableButton
                                                icon='scanner'
                                                id="MigrateAllCopy"
                                                title='Copy Mappings'
                                                action={() => this.copyMapping("MigrateAllCopy")} />}
                                </>
                            </SecureContainer>
                            <div>
                            <DataTableButton
                                icon='file_download'
                                id='exportMappingsAll_Epic'
                                title='Export All Entity Mappings to Excel'
                                action={() => this.exportToExcel('exportMappingsAll_Epic')} />
                            <SecureContainer roles={[Roles.developerAdministrationRoleId]}>
                                <DataTableButton
                                    icon='table'
                                    id="ADMTables"
                                    title='ADM Tables'
                                    action={() => this.props.history.push(`/${this.state.conversion!.id}/admpage`)} />
                                </SecureContainer>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-display">
                    <h4 className='statistical-info-header'>Statistical Information</h4>
                    <div>
                        <div>    <Link to={this.state.status1} onClick={()=>this.handleHover(this.state.status1)}>
                            {this.state.status1}
                        </Link></div>
                        <div><Link to={this.state.status2} onClick={() => this.handleHover(this.state.status2)}>
                            {this.state.status2}
                        </Link></div>
                    </div>
                </div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.selected('summaryTable') })}
                            onClick={() => this.toggle('summaryTable')}>Summary Table</NavLink>
                    </NavItem>
                    {imageConversion &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.selected('imageLog') })}
                                onClick={() => this.toggle('imageLog')}>Image Log</NavLink>
                        </NavItem>
                    }
                </Nav>
                <TabContent activeTab={this.state.tab} className={this.state.tab}>
                    <TabPane tabId='summaryTable'>
                        <ConversionSummaryTable conversionId={this.props.match.params.conversionId} items={items} isMappable={this.props.isMappable} isMappableToggle={this.isMappable} status1={this.state.status1} toEntity={this.toEntity} />
                        </TabPane>
                    <TabPane tabId='imageLog'>
                        <ImageLogTable conversionId={this.props.match.params.conversionId} email={this.props.currentUser!.emailAddress!} tab={this.state.tab} />
                    </TabPane>
                </TabContent>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser,
            isMappable: state.boolean && state.boolean.isMappable,
        }
    })(ConversionSummaryPage);
