import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Toolbar from '../basic/Toolbar';
import { ToolbarLinkButton } from '../basic/Button';
import { DataTable, DataRow, DataCell } from 'components/basic/DataTable';
import * as Clients from '../../client/ApiClient';
import { Button, Input, InputGroupAddon, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { actionCreators } from '../../store/Select';
import { store } from "AppContext";
import * as Roles from 'constants/Roles';

import './User.scss';

type UserListProps = RouteComponentProps & {
    currentUser: Clients.IUserLoginDetailsModel | undefined,
    currentSelect: string,
}

type UserListPageState = {
    users: Clients.UserModel[];
    clients: Clients.ConversionClientModel[];
    clientIds: Clients.UserToConversionClientModel[]
    sort: boolean;
    isActive: boolean;
    filter: string;
    modal: boolean;
    user: Clients.UserModel;
}

class UserListPage extends React.Component<UserListProps, UserListPageState>{
    private readonly client: Clients.UserClient = new Clients.UserClient();
    private readonly clients: Clients.ClientClient = new Clients.ClientClient();

    constructor(props: any) {
        super(props)
        this.state = {
            users: [],
            clients: [],
            clientIds: [],
            sort: true,
            isActive: true,
            filter: '',
            modal: false,
            user: new Clients.UserModel(),
        }
    }

    componentDidMount() {
        this.getUsers();

        this.clients.getAll()
            .then(response => {
                if (response != null) {
                    this.setState({
                        clients: response,
                    })
                }
            })
            .catch(error => { })

        this.client.getAllClientId()
            .then(response => {
                if (response != null) {
                    this.setState({
                        clientIds: response,
                    })
                }
            })
            .catch(error => { })
    }

    getUsers() {
        this.client.getAll()
            .then(response => {
                if (response != null) {
                    this.setState({
                        users: response,
                    })
                }
            })
            .catch(error => { })
    }

    sort(column: string, column2: string) {
        const users = this.state.users.sort((a: any, b: any) => {
            let x = column2 === '' ? a[column] : a[column][column2]
            let y = column2 === '' ? b[column] : b[column][column2]
            if (typeof x === 'string' && typeof y === 'string') {
                x = x.toLowerCase().trim()
                y = y.toLowerCase().trim()
            }
            if (this.state.sort) {
                if (x < y) { return -1; }
                if (x > y) { return 1; }
            } else {
                if (x > y) { return -1; }
                if (x < y) { return 1; }
            }
            return 0
        })
        this.setState({ sort: !this.state.sort, users });
    }

    isActive = () => {
        const isActive = !this.state.isActive
        this.setState({ isActive })
    }

    handleSearch = (e: any) => {
        const filter = e.target.value
        this.setState({ filter })
    }

    clearFilter = () => {
        this.setState({ filter: '' })
    }

    handleChange = (e: any) => {
        const client = e.target.value
        store.dispatch(actionCreators.updateUserCompany(client))
    }

    clientFilter = (user: Clients.UserModel) => {
        const filter = this.props.currentSelect;
        if (filter === '') {
            return user
        } else if (filter === Roles.developerAdministrationRoleId) {
            return user.role!.id === Roles.developerAdministrationRoleId
        } else if (filter === Roles.administrationRoleId) {
            return user.role!.id === Roles.administrationRoleId
        } else if (filter === Roles.vertaforeInternalRoleId) {
            return user.role!.id === Roles.vertaforeInternalRoleId
        } else if (filter === Roles.noAccessRoleId) {
            return user.role!.id === Roles.noAccessRoleId
        } else {
            if (user.role!.id === Roles.customerAdministratorRoleId
                || user.role!.id === Roles.customerUserRoleId) {
                const clientId = this.state.clientIds.filter(clientId => clientId.clientId === this.props.currentSelect)
                return clientId.some(id => id.userId === user.id)
            }
        }
    }

    activeToggle = (user: Clients.UserModel) => {
        if (user.id !== this.props.currentUser!.id) {
            if (this.props.currentUser!.role === Roles.administrationRoleId || this.props.currentUser!.role === Roles.developerAdministrationRoleId) {
                this.setState({ user }, () => { this.modalToggle() })
            }
        }
    }

    activate = (user: Clients.UserModel) => {
        if (this.state.modal) { this.setState({ modal: false }) }
        user.isActive = !user.isActive;

        (user.role as any).createdDate = undefined;
        (user.role as any).lastEditedDate = undefined;
        this.client.put(user.id as string, user)
            .then(() => {
                this.getUsers();
            })
            .catch(error => { console.log(error) })
    }


    modalToggle = () => {
        const modal = !this.state.modal
        this.setState({ modal })
    }

    toEditPage = (user: Clients.UserModel) => {
        if ((user.role!.id === Roles.administrationRoleId && this.props.currentUser!.role === Roles.administrationRoleId) || ((user.role!.id === Roles.developerAdministrationRoleId || user.role!.id === Roles.administrationRoleId) && this.props.currentUser!.role === Roles.developerAdministrationRoleId)) {
            this.props.history.push(`/user/${user.id}`)
        } else if (user.role!.id !== Roles.administrationRoleId && user.role!.id !== Roles.developerAdministrationRoleId) {
            this.props.history.push(`/user/${user.id}`)
        }
    }

    public render() {
        return (
            <>
                <Toolbar>
                    {this.props.currentUser && this.props.currentUser.role && this.props.currentUser.role !== Roles.customerUserRoleId && this.props.currentUser.role !== Roles.customerAdministratorRoleId &&
                        <select className="custom-select user-select" value={this.props.currentSelect} onChange={this.handleChange}>
                            <option value=''>All Users</option>
                            <option value={Roles.administrationRoleId}>Administration</option>
                            <option value={Roles.vertaforeInternalRoleId}>Vertafore Internal</option>
                            {this.state.clients.map((client) => (
                                <option
                                    key={client.id}
                                    value={client.id}
                                >
                                    {client.description}
                                </option>
                            ))}
                            <option value={Roles.noAccessRoleId}>No Access</option>
                        </select>}
                    <Input placeholder='Search'
                        name='filter'
                        onChange={this.handleSearch}
                        value={this.state.filter}
                        className="searchUser"
                    />
                    <InputGroupAddon addonType='append' className='material-icon'>
                        <Button onClick={this.clearFilter}> close</Button>
                    </InputGroupAddon>
                    <ToolbarLinkButton type='primary' to='/user/new'>Create a new user</ToolbarLinkButton>
                </Toolbar>
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th className="number-column">#</th>
                            <th onClick={() => this.sort('lastName', '')} >Last Name <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('firstName', '')}>First Name <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('emailAddress', '')}>Email Address <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('role', 'description')}>Role <i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('createdDate', '')}>Date Created <i className="fa fa-sort"></i></th>
                            <th className="switchColumn"><label className="switch"><input type="checkbox" readOnly checked={this.state.isActive} onClick={this.isActive} /> <span className="slider round"></span></label>Active Users Only</th>
                        </tr>
                    </thead>
                    <tbody className="nonEntity">
                        {this.state.users
                            .filter(user => user.lastName!.toLowerCase().includes(this.state.filter.trim().toLowerCase()) || user.firstName!.toLowerCase().includes(this.state.filter.trim().toLowerCase()) || user.emailAddress!.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                            .filter(user => user.isActive || user.isActive === this.state.isActive)
                            .filter(this.clientFilter)
                            .map((user, index) => (
                                <DataRow className={user.isActive ? "" : "inactive"} key={user.id} id={user.id!}>
                                    <DataCell className="number-column" onClick={() => this.toEditPage(user)}>{index + 1}</DataCell>
                                    <DataCell onClick={() => this.toEditPage(user)}>{user.lastName}</DataCell>
                                    <DataCell onClick={() => this.toEditPage(user)}>{user.firstName}</DataCell>
                                    <DataCell onClick={() => this.toEditPage(user)}>{user.emailAddress}</DataCell>
                                    <DataCell onClick={() => this.toEditPage(user)}>{user.role && user.role.description}</DataCell>
                                    <DataCell onClick={() => this.toEditPage(user)}>{user.createdDate.toLocaleDateString()}</DataCell>
                                    <DataCell onClick={() => this.activeToggle(user)} className="switchColumn" title={user.isActive ? 'Inactivate ' + user.firstName + ' ' + user.lastName : 'Activate ' + user.firstName + ' ' + user.lastName}> {user.isActive ? < p className='mapped'>&#x2705;</p> : < p className='mapped'>&#x2796;</p>}</DataCell>
                                </DataRow>
                            ))}
                    </tbody>
                </DataTable>
                <div>
                    <Modal isOpen={this.state.modal} toggle={this.modalToggle}>
                        <ModalHeader toggle={this.modalToggle}>Please Confirm to {this.state.user.isActive ? 'Inactivate' : 'Activate'} User: {this.state.user.firstName} {this.state.user.lastName} </ModalHeader>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.activate(this.state.user)}>Confirm</Button>{' '}
                            <Button color="secondary" onClick={this.modalToggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser,
            currentSelect: state.select && state.select.userCompany
        }
    },
)(withRouter(UserListPage));