import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown, DropdownItem } from 'reactstrap';
import { BranchClient, BranchModel, BranchRefModel } from '../../../../../client/ApiClient';
import { DataCell, DataRow, DataTable, DataTableButton } from '../../../../basic/DataTable';
import { RangePaginationState, RangePagination, paginate } from 'components/basic/Pagination';
import StageActionToolbar from '../generic/StageActionToolbar';
import { stagingPageUpdate, stagingPageSetPrimary, stagingPageCopyMappings, StagingPageCopyMappingsItem } from '../generic/Abstractions'
import { AssignRowStatus, RowStatus } from '../generic';
import DataListInput from '../../../../basic/DataListInput';

import '../../../../../styles/Icons.scss';

interface BranchStagingPageArgs {
    conversionId: string,
}

type BranchStagingPageProps = RouteComponentProps<BranchStagingPageArgs>;

interface BranchStagingPageState {
    items: (BranchModel
        & StagingPageCopyMappingsItem)[]
    itemsByDestinationCodes: BranchModel[];
    filter: string
    destinationCodes: BranchRefModel[];
    sort: boolean;
    sortColumn: string;
    mapped: boolean;
    notMapped: boolean;
    dropped: boolean;
    modal: boolean;
    repeatedCode: string;
    canCancel: boolean;
    primaryList: {
        destinationCode?: string,
        pItems?: BranchModel[]
    }[];

    pagination: RangePaginationState;
    buttonLock: boolean;
}

export class BranchStagingPage extends React.Component<BranchStagingPageProps, BranchStagingPageState>{
    private client: BranchClient = new BranchClient()

    private readonly copyMappings = stagingPageCopyMappings(this);

    constructor(props: BranchStagingPageProps) {
        super(props)
        this.state = {
            items: [],
            itemsByDestinationCodes: [],
            filter: '',
            destinationCodes: [],
            sort: true,
            sortColumn: '',
            mapped: true,
            notMapped: true,
            dropped: true,
            modal: false,
            repeatedCode: '',
            canCancel: false,
            primaryList: [],

            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            buttonLock: false,
        }
    }

    componentDidMount() {
        this.getItems();

        this.client.getRef(this.props.match.params.conversionId)
            .then(response => {
                if (response != null) {
                    this.setState({
                        destinationCodes: response,
                    })
                }
            })
            .catch(error => { })
    }

    setDefaults = (entity: BranchModel) => new BranchModel({
        ...entity,
        destinationCode: entity.destinationCode || '',
        destinationDescription: entity.destinationDescription || ''
    });

    getItems = () => {
        this.client.getAll(this.props.match.params.conversionId, '')
            .then((response) => {
                let items = response!;

                items = items.map(this.setDefaults)
                    .sort((a: any, b: any) => {
                        let x = a[this.state.sortColumn]
                        let y = b[this.state.sortColumn]
                        if (this.state.sortColumn === 'isPrimary') {
                            y = this.state.primaryList.some(p => p.destinationCode === a.destinationCode) && a.isPrimary && a.isMapped && !a.isDrop ? 3 : !a.isPrimary && a.isMapped && !a.isDrop ? 2 : 1;
                            x = this.state.primaryList.some(p => p.destinationCode === b.destinationCode) && b.isPrimary && b.isMapped && !b.isDrop ? 3 : !b.isPrimary && b.isMapped && !b.isDrop ? 2 : 1;
                        }
                        if (typeof y === "string") {
                            y = y.toLowerCase().trim()
                            x = x.toLowerCase().trim()
                        }
                        if (x === y) {
                            return 0;
                        }
                        else if (x === null) {
                            return 1;
                        }
                        else if (y === null) {
                            return -1;
                        }
                        else if (!this.state.sort) {
                            return x < y ? -1 : 1;
                        }
                        else {
                            return x < y ? 1 : -1;
                        }
                    })
                this.updatePrimaryList(items)

                this.setState({
                    items
                })
            })
            .catch(error => { })
    }

    put(entity: BranchModel) {
        entity.isPrimary = true;
        stagingPageUpdate(this, this.client)
            .withSource('Branch', entity.sourceCode!)
            .update(entity)
            .then(() => this.getItems())
            .catch(error => {
                const response = JSON.parse(error.response)
                console.log(response)
            })
    }

    updatePrimaryList(items: any[]) {
        let primaryList:
            {
                destinationCode?: string,
                pItems: BranchModel[]
            }[] = [];

        items.forEach((item: BranchModel) => {
            if (item.destinationCode) {
                let primaryObject = primaryList.find(x => x.destinationCode!.trim() === item.destinationCode!.trim());
                if (!primaryObject) {
                    primaryObject = { destinationCode: item.destinationCode, pItems: [] }
                    primaryList.push(primaryObject);
                }
                if (!item.isDrop) primaryObject.pItems.push(item);
            }
        });

        primaryList = primaryList.filter(x => x.pItems!.length > 1 && x.pItems.some(p => p.isPrimary));

        this.setState({ primaryList })
    }

    getByDestinationCodes = (destinationCode: string) => {
        this.client.getByDestinationCode(this.props.match.params.conversionId, destinationCode)
            .then(response => {
                if (response != null) {
                    this.setState({ itemsByDestinationCodes: response })
                }
            })
            .catch(error => { })
    }

    handleSearch = (filter: string) => {
        this.setState({ filter })
    }

    handleChange = (item: BranchModel, e: any) => {
        const dc = e.value;
        const id = e.target.name;

        const items = this.state.items;
        const i = this.state.items.findIndex(v => v.id === id);

        items[i].destinationCode = dc;

        this.setState({ items });
        this.put(items[i])
    }

    handleCheckboxChange = (id: string, e: { target: { name: any, checked: any } }) => {
        const items = this.state.items.map(
            v => v.id === id
                ? new BranchModel({
                    ...v,
                    [e.target.name]: e.target.checked,
                })
                : v)
        this.setState({ items });

        this.put(items.find(v => v.id === id)!)
    }

    sort(column: string) {
        const items = this.state.items.sort((a: any, b: any) => {
            let x = a[column]
            let y = b[column]
            if (typeof y === "string") {
                y = y.toLowerCase().trim()
                x = x.toLowerCase().trim()
            }
            if (this.state.sort) {
                if (x < y) { return -1; }
                if (x > y) { return 1; }
            } else {
                if (x > y) { return -1; }
                if (x < y) { return 1; }
            }
            return 0
        })
        this.setState({ sort: !this.state.sort, sortColumn: column, items });
    }

    mappedCheck(check: string) {
        if (check === 'notMapped') {
            const notMapped = !this.state.notMapped
            this.setState({ notMapped })
        } else if (check === 'mapped') {
            const mapped = !this.state.mapped
            this.setState({ mapped })
        } else if (check === 'dropped') {
            const dropped = !this.state.dropped
            this.setState({ dropped })
        }
    }

    modalToggle = () => {
        const modal = !this.state.modal
        this.setState({ modal })
        if (modal) this.getByDestinationCodes(this.state.repeatedCode)
    }

    handleSelectDestination = (i: number) => {
        const itemsByDestinationCodes = this.state.itemsByDestinationCodes
        itemsByDestinationCodes.map((item, index) => {
            if (index === i) {
                item.isPrimary = true
            } else {
                item.isPrimary = false
            }
            return item;
        })

        this.setState({ itemsByDestinationCodes })
    }

    putPrimary = () => {
        const codes = this.state.itemsByDestinationCodes
        let num = 0
        codes.forEach(c => c.isPrimary ? num += 1 : null)
        if (num === 1) {
            codes.forEach(code => code.isPrimary ? this.updatePrimary(code.id) : null)
            this.modalToggle()
        } else {
            alert('Please only have one Source Code set to primary')
        }
    }

    updatePrimary(id: string) {
        const item = this.state.itemsByDestinationCodes.find(item => item.id === id)!;
        stagingPageSetPrimary(this, this.client)
            .withSource(item.sourceCode!)
            .setPrimary(id)
            .catch(() => { });
    }

    desCodeDisplay(destinationCode: string | undefined, key: string) {
        const display = this.state.destinationCodes.filter(d => d.sourceCode === destinationCode)
        if (display.length > 0) {
            return (display[0] as any)[key]
        } else {
            return ''
        }
    }

    primarySelectionModalShowIfHasCode = (repeatedCode: string | undefined, canCancel: boolean) => {
        if (!repeatedCode) {
            alert('Please specify a destination code in order to set a primary record.');
        } else {
            this.primarySelectionModalShow(repeatedCode, canCancel);
        }
    }

    primarySelectionModalShow = (repeatedCode: string, canCancel: boolean) => {
        this.setState({
            modal: true,
            canCancel: canCancel,
            repeatedCode,
        })
        this.getByDestinationCodes(repeatedCode)
    }

    primarySelectionModalHide = () => {
        this.setState({ modal: false })
    }

    DestinationCodes = (): any[] => {
        const arr = [];
        const dc = this.state.destinationCodes;
        for (let i = 0; i < dc.length; i++) {
            arr.push(<option
                key={i}
                data-value={dc[i].shortName}
            >
                {`${dc[i].shortName!.trim()} - ${dc[i].name}`}
            </option>)
        }
        return arr;
    }

    lockButton = (lock: boolean) => {
        this.setState({ buttonLock: lock })
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;
        const items = this.state.items
            .map(AssignRowStatus)
            .filter(i => (i.sourceCode && i.sourceCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.sourceDescription && i.sourceDescription!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.destinationCode && i.destinationCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.destinationDescription && i.destinationDescription!.toLowerCase().includes(this.state.filter.trim().toLowerCase())))
            .filter(item =>
                (item.mapped === RowStatus.NotMapped && this.state.notMapped)
                || (item.mapped === RowStatus.Mapped && this.state.mapped)
                || (item.mapped === RowStatus.Dropped && this.state.dropped));


        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='branch'
                    handleSearch={this.handleSearch}
                    primaryList={this.state.primaryList}
                    getItems={this.getItems}
                    handleCopyMapping={this.copyMappings.submitMappings}
                    copyMap={true}
                    filter={this.state.filter}
                    lockButton={this.lockButton}
                />
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th onClick={() => this.sort('sourceCode')}>Source Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('sourceDescription')}>Branch Name<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isDrop')} className='checkbox-column'>Exclude<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('destinationCode')} className='drop-col'>Destination Code<i className="fa fa-sort"></i></th>
                            {/*<th onClick={() => this.sort('isPrimary')} className='checkbox-column'>Primary<i className="fa fa-sort"></i></th>*/}
                            <th className='checkbox-column'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Mapped
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={!this.state.notMapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('notMapped')}>&#x274C; Not Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.mapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('mapped')}>&#x2705; Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.dropped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('dropped')}>&#x2796; Excluded</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginate(items, this.state.pagination, (item, i) => (
                                <DataRow id={item.id.toString()} key={item.id}>
                                    <DataCell>{item.sourceCode}</DataCell>
                                    <DataCell>{item.sourceDescription}</DataCell>
                                    <DataCell className='checkbox-column'>
                                        <input className='custom-checkbox'
                                            type='checkbox'
                                            name='isDrop'
                                            checked={item.isDrop}
                                            onChange={e => this.handleCheckboxChange(item.id, e)}
                                            disabled={this.state.buttonLock}
                                        />
                                    </DataCell>
                                    <DataCell>
                                        <DataListInput
                                            id={`referenceId - ${i}`}
                                            name={item.id.toString()}
                                            value={item.destinationCode ? item.destinationCode : undefined}
                                            onValueChange={event => this.handleChange(item, event)}
                                            disabled={this.state.buttonLock}
                                        >
                                            <option data-value='' />
                                            {this.DestinationCodes()}
                                        </DataListInput>
                                    </DataCell>
                                    {/*<DataCell className='checkbox-column' itemProp={this.state.primaryList.some(p => p.destinationCode!.toUpperCase() === item.destinationCode!.toUpperCase()) && !item.isDrop*/}
                                    {/*    ? item.isPrimary && item.isMapped*/}
                                    {/*        ? 'primary'*/}
                                    {/*        : 'non-primary' : ''}>*/}
                                    {/*    <DataTableButton*/}
                                    {/*        id={item.id + "-primary"}*/}
                                    {/*        action={() => this.primarySelectionModalShowIfHasCode(item.destinationCode, true)}*/}
                                    {/*        disabled={this.state.buttonLock}*/}
                                    {/*        title='Click to set a primary record'*/}
                                    {/*    >*/}
                                    {/*        {this.state.primaryList.some(p => p.destinationCode!.trim() === item.destinationCode!.trim()) && !item.isDrop*/}
                                    {/*            ? item.isPrimary && item.isMapped*/}
                                    {/*                ? <span className='mapped' itemProp='primary'>&#x2705;</span>*/}
                                    {/*                : <span className='mapped' itemProp='non-primary'>&#x274C;</span> : null}*/}
                                    {/*    </DataTableButton>*/}
                                    {/*</DataCell>*/}
                                    <DataCell className='checkbox-column' itemProp={item.mapped === RowStatus.NotMapped ? 'unmapped' : item.mapped === RowStatus.Mapped ? 'mapped' : 'dropped'}>
                                        {item.mapped === RowStatus.NotMapped ? < p className='mapped' itemProp='unmapped'>&#x274C;</p> : item.mapped === RowStatus.Mapped ? < p className='mapped' itemProp='mapped'>&#x2705;</p> : < p className='mapped' itemProp='dropped'>&#x2796;</p>}
                                    </DataCell>
                                </DataRow>
                            ))
                        }
                    </tbody>
                </DataTable>
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
                <div>
                    <Modal isOpen={this.state.modal}>
                        <ModalHeader>Please Set Primary of Destination: {this.state.repeatedCode}</ModalHeader>
                        <ModalBody>
                            <select
                                name='isPrimary'
                                className="custom-select"
                                onChange={(e) => this.handleSelectDestination(Number(e.target.value))}
                                id={this.state.repeatedCode.toString()}
                                key={this.state.repeatedCode}
                            >
                                {this.state.itemsByDestinationCodes.map((item, index) => (
                                    <option
                                        value={index}
                                        key={item.id}
                                        selected={item.isPrimary}
                                    >
                                        {item.sourceCode} - {item.destinationDescription}
                                    </option>
                                ))}
                            </select>

                        </ModalBody>
                        <ModalFooter>
                            {this.state.canCancel && <Button color="secondary" onClick={() => this.primarySelectionModalHide()}>Close</Button>}
                            <Button color="primary" onClick={this.putPrimary}>Submit</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}