import { generatePath } from 'react-router';
import * as Routes from 'constants/Routes';

export function getMappingLocation(conversionId: string) {
    return generatePath(Routes.mappingLocationTemplate, { conversionId });
}

export function getMergeMappingLocation(conversionId: string) {
    return generatePath(Routes.mergeLocationTemplate, { conversionId });
}

export function getConversionEditLocation(conversionId: string, tabId: string) {
    return generatePath(Routes.editLocationTemplate, { conversionId , tabId});
}

export function getConversionsLocation() {
    return Routes.conversionsLocationTemplate
}
