import * as React from 'react';
import * as Clients from '../../client/ApiClient';
import { DataTable, DataRow, DataCell } from 'components/basic/DataTable';
import Toolbar from '../basic/Toolbar';
import { ToolbarLinkButton } from '../basic/Button';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface IClientListPageState {
    clients: Clients.ConversionClientModel[];
}

class ClientListPage extends React.Component<RouteComponentProps, IClientListPageState>{
    private readonly client: Clients.ClientClient = new Clients.ClientClient();

    constructor(props: Readonly<RouteComponentProps>) {
        super(props)
        this.state = {
            clients: []
        }
    }

    componentDidMount() {
        this.client.getAll()
            .then(response => {
                this.setState({
                    clients: response!
                })
            })
            .catch(error => { });
    }

    public render() {
        return (
            <>
                <Toolbar>
                    <ToolbarLinkButton type='primary' to='/client/new'>Create a new client</ToolbarLinkButton>
                </Toolbar>
                <DataTable>
                    <thead>
                        <tr>
                            <th className="number-column">#</th>
                            <th>Name</th>
                            <th>Enabled for Mini Conversions</th>
                            <th>Mini Conversion Host</th>
                            <th>Mini Conversion Catalog</th>
                        </tr>
                    </thead>
                    <tbody className="nonEntity">
                        {this.state.clients
                            .map((client, index) => (
                                <DataRow key={client.id}
                                    id={client.id!}
                                    action={id => this.props.history.push(`/client/${id}`)}
                                >
                                    <DataCell className="number-column">{index + 1}</DataCell>
                                    <DataCell>{client.description}</DataCell>
                                    <DataCell>{client.isMiniConvEnabled ? "Yes" : "No"}</DataCell>
                                    <DataCell>{client.miniTargetHost}</DataCell>
                                    <DataCell>{client.miniTargetCatalog}</DataCell>
                                </DataRow>
                            ))}
                    </tbody>
                </DataTable >
            </>
        )
    }
}

export default withRouter(ClientListPage)