export enum RowStatus {
    NotMapped = 1,
    Mapped = 2,
    Dropped = 3,
}

export function AssignRowStatus<T extends {
    isMapped: boolean,
    isDrop?: boolean,
}>(item: T) {
    const mapped = item.isDrop
        ? RowStatus.Dropped
        : (item.isMapped
            ? RowStatus.Mapped
            : RowStatus.NotMapped);

    return Object.assign({ mapped }, item);
}
