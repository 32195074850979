import * as React from 'react';
import { ModalHeader, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

interface ICloseConfirmationModalProps {
    isOpen: boolean;
    title: string;
    message: string;
    onSubmit: () => void;
    onClose: () => void;
}

export default class CloseConfirmationModal extends React.Component<ICloseConfirmationModalProps, {}>{
    public render() {
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader>{this.props.title}</ModalHeader>
                <ModalBody>{this.props.message}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.onSubmit}>Confirm</Button>
                    <Button color="secondary" onClick={this.props.onClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}