import * as React from 'react';
import memoize from 'memoize-one';
import { ValueChangeEvent } from './Events';

export type DataListInputProps =
    React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > & {
        onValueChange?: (event: ValueChangeEvent<HTMLInputElement, string>) => void
    };

type DataListInputState = {
    text: string
    value: string
    focused: boolean
}

type DataListInputOption = {
    text: string,
    value: string,
}

export default class DataListInput extends React.Component<DataListInputProps, DataListInputState> {
    constructor(props: Readonly<DataListInputProps>) {
        super(props);

        const value = DataListInput.getPropsValue(this.props.value);
        this.state = {
            text: this.getOptionTextById(value),
            value: value,
            focused: false,
        }
    }

    private static getPropsValue = (v: string | readonly string[] | number | undefined): string => {
        if (v instanceof Array) {
            throw Error("Cannot use array as a data list input value.");
        }
        return (v && v.toString()) || '';
    }

    componentDidUpdate(prevProps: DataListInputProps) {
        if (this.props.value !== prevProps.value ||
            this.props.children !== prevProps.children) {
            const value = DataListInput.getPropsValue(this.props.value);
            this.setState({
                text: this.getOptionTextById(value),
                value: value,
            })
        }
    }

    private options = memoize(children => {
        // TODO: replace an array with a dictionary by data-value.
        return React.Children.map(
            children,
            ch => {
                const value = ch.props["data-value"] as (string | number);
                if (!value && value !== '') {
                    throw Error("The data-value must be specified for data list input options.");
                }
                if (value !== 'readOnly') {
                    return {
                        text: ch.props["children"] as string || '',
                        value: DataListInput.getPropsValue(value),
                    };
                }
            })
    });

    private static normalizeText = (text: string) => text.replace(/\s+/g, ' ').trim().toLowerCase();

    private getOptionByText = (text: string): DataListInputOption | null => {
        const template = DataListInput.normalizeText(text);
        return this.options(this.props.children)
            .find((option: DataListInputOption) => {
                const normalized = option.text && DataListInput.normalizeText(option.text);
                return normalized === template;
            }) || null;
    }

    private getOptionById = (id: string): DataListInputOption | null =>
        this
            .options(this.props.children)
            .find((option: DataListInputOption) => option.value === id) || null;

    private getOptionTextById = (id: string): string => {
        const option = this.getOptionById(id);
        return (option && option.text) || '';
    }

    private getDataListId = () => `${this.props.id}-datalist`;

    render() {
        const { children, value, onValueChange, ...props } = this.props;
        return (
            <React.Fragment>
                <input
                    value={this.state.text}
                    className='form-control'
                    autoComplete="off"
                    {...props}
                    list={this.getDataListId()}
                    onChange={event => {
                        this.setState({ text: event.target.value });
                        this.props.onChange && this.props.onChange(event);
                    }}
                    onFocus={() => this.setState({ focused: true })}
                    onBlur={event => {
                        const option = this.getOptionByText(this.state.text);
                        if (!option) {
                            this.setState({
                                text: this.getOptionTextById(this.state.value),
                            });
                        } else {
                            const previousValue = this.state.value;
                            this.setState({
                                text: option.text,
                                value: option.value,
                            });

                            option.value !== previousValue
                                && this.props.onValueChange
                                && this.props.onValueChange({
                                    value: option.value || '',
                                    target: event.target,
                                })
                        }
                        this.setState({ focused: false })
                        this.props.onBlur && this.props.onBlur(event);
                    }}
                />
                <datalist id={this.getDataListId()}>
                    {this.state.focused && children}
                </datalist>
            </React.Fragment>
        );
    }
}
