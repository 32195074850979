import * as React from "react";

export type PromiseErrorProps = {
    children?: React.ReactNode,
}

export default class PromiseErrorBoundary extends React.Component<PromiseErrorProps>{
    componentDidMount() {
        window.onunhandledrejection = (event: PromiseRejectionEvent) => {
            event.preventDefault()
        }
    }

    public render() {
        return (
            this.props.children
        )
    }
}