import { history } from '../AppContext'
import { authentication } from './Authentication';

export const checkErrorForSessionTimeout = (error: any) => {
    if (error && error.status === 401) {
        authentication.logout()
            .then(() => history.push('/'))
            .catch(error => { });
    }
}

