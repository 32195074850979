import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PolicyStatusTypeClient, PolicyStatusTypeModel, PolicyStatusTypeRefModel } from '../../../../../client/ApiClient';
import { DataCell, DataRow, DataTable } from '../../../../basic/DataTable';
import { RangePaginationState, RangePagination, paginate } from 'components/basic/Pagination';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from 'reactstrap';
import StageActionToolbar from '../generic/StageActionToolbar';
import { stagingPageUpdate, stagingPageCopyMappings, StagingPageCopyMappingsItem } from '../generic/Abstractions'
import { AssignRowStatus, RowStatus } from '../generic';

import '../../../../../styles/Icons.scss';
import './PolicyStatusTypeStage.scss';

interface PolicyStatusTypeStagingPageArgs {
    conversionId: string,
}

type PolicyStatusTypeStagingPageProps = RouteComponentProps<PolicyStatusTypeStagingPageArgs>

interface PolicyStatusTypeStagingPageState {
    items: (PolicyStatusTypeModel
        & StagingPageCopyMappingsItem)[];
    filter: string;
    destinationCodes: PolicyStatusTypeRefModel[];
    sort: boolean;
    sortColumn: string;
    mapped: boolean;
    notMapped: boolean;
    pagination: RangePaginationState;
    buttonLock: boolean;
}

export class PolicyStatusTypeStagingPage extends React.Component<PolicyStatusTypeStagingPageProps, PolicyStatusTypeStagingPageState> {
    private client: PolicyStatusTypeClient = new PolicyStatusTypeClient();

    private readonly copyMappings = stagingPageCopyMappings(this);

    constructor(props: PolicyStatusTypeStagingPageProps) {
        super(props);
        this.state = {
            items: [],
            filter: "",
            destinationCodes: [],
            sort: true,
            sortColumn: '',
            mapped: true,
            notMapped: true,
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            buttonLock: false,
        }
    }

    componentDidMount() {
        this.getItems();
    }

    setDefaults = (entity: PolicyStatusTypeModel) => new PolicyStatusTypeModel({
        ...entity,
        preEffectiveReferenceId: entity.preEffectiveReferenceId || '',
        effectiveReferenceId: entity.effectiveReferenceId || '',
        expiredReferenceId: entity.expiredReferenceId || '',
        preEffective: this.destinationName(entity.preEffectiveReferenceId),
        effective: this.destinationName(entity.effectiveReferenceId),
        expired: this.destinationName(entity.expiredReferenceId),
    })

    getItems = () => {
        const items = this.client.getAll(this.props.match.params.conversionId, '')
        const refs = this.client.getRef(this.props.match.params.conversionId)
        Promise.all([items, refs])
            .then(result => {
                this.setState({
                    items: result[0]!,
                    destinationCodes: result[1]!
                }, async () => {
                    if (await items && await refs) {
                        this.setState({
                            items: this.state.items.map(this.setDefaults).sort((a: any, b: any) => {
                                let x = a[this.state.sortColumn]
                                let y = b[this.state.sortColumn]
                                if (typeof y === "string") {
                                    y = y.toLowerCase().trim()
                                    x = x.toLowerCase().trim()
                                }
                                if (x === y) {
                                    return 0;
                                }
                                else if (x === null) {
                                    return 1;
                                }
                                else if (y === null) {
                                    return -1;
                                }
                                else if (!this.state.sort) {
                                    return x < y ? -1 : 1;
                                }
                                else {
                                    return x < y ? 1 : -1;
                                }
                            }),
                        });
                    }
                })

            })
            .catch(error => { })
    }

    destinationName = (refId: string | undefined) => {
        if (refId) {
            const destination = this.state.destinationCodes.filter(ref => ref.id === refId);
            return destination[0].description
        } else {
            return ''
        }
    }

    handleChange = (e: any, type: string, name: string) => {
        const dc = e.target.value;
        const id = e.target.id;

        const items = this.state.items;
        const i = this.state.items.findIndex(v => v.id === id);
        (items[i] as any)[type] = dc;
        (items[i] as any)[name] = this.destinationName(dc);

        this.setState({ items });

        if ((items[i].preEffectiveReferenceId && items[i].effectiveReferenceId && items[i].expiredReferenceId) || dc === '') {
            this.put(items[i])
        }
    }

    put(entity: PolicyStatusTypeModel) {
        if (entity.preEffectiveReferenceId === '') {
            entity.preEffectiveReferenceId = undefined
        }
        if (entity.effectiveReferenceId === '') {
            entity.effectiveReferenceId = undefined
        }
        if (entity.expiredReferenceId === '') {
            entity.expiredReferenceId = undefined
        }
        stagingPageUpdate(this, this.client)
            .withSource('Policy Status Type', entity.sourceDescription!)
            .update(entity)
            .catch(() => { })
    }

    sort(column: string) {
        const items = this.state.items.sort((a: any, b: any) => {
            let x = a[column]
            let y = b[column]
            if (typeof y === "string") {
                y = y.toLowerCase().trim()
                x = x.toLowerCase().trim()
            }
            if (x === y) {
                return 0;
            }
            else if (x === null || x === '') {
                return 1;
            }
            else if (y === null || y === '') {
                return -1;
            }
            else if (this.state.sort) {
                return x < y ? -1 : 1;
            }
            else {
                return x < y ? 1 : -1;
            }
        });
        this.setState({ sort: !this.state.sort, sortColumn: column, items });
    }

    mappedCheck(check: string) {
        if (check === 'notMapped') {
            const notMapped = !this.state.notMapped
            this.setState({ notMapped })
        } else if (check === 'mapped') {
            const mapped = !this.state.mapped
            this.setState({ mapped })
        }
    }

    lockButton = (lock: boolean) => {
        this.setState({ buttonLock: lock })
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;
        const items = this.state.items
            .map(AssignRowStatus)
            .filter(i =>
                (i.sourceCode || '').toLowerCase().includes(this.state.filter.trim().toLowerCase())
                || (i.sourceDescription || '').toLowerCase().includes(this.state.filter.trim().toLowerCase())
                || (i.preEffective || '').toLowerCase().includes(this.state.filter.trim().toLowerCase())
                || (i.effective || '').toLowerCase().includes(this.state.filter.trim().toLowerCase())
                || (i.expired || '').toLowerCase().includes(this.state.filter.trim().toLowerCase()))
            .filter(item =>
                (item.mapped === RowStatus.NotMapped && this.state.notMapped)
                || (item.mapped === RowStatus.Mapped && this.state.mapped));

        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    handleSearch={x => this.setState({ filter: x })}
                    disableMigrate={true}
                    handleCopyMapping={this.copyMappings.submitMappings}
                    sectionId={'policystatustypes'}
                    mapExcel={true}
                    copyMap={true}
                    getItems={() => this.getItems()}
                    filter={this.state.filter}
                    lockButton={this.lockButton}
                />
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th onClick={() => this.sort('sourceCode')}>Source Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('sourceDescription')}>Source Description<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('preEffective')}>Pre-Effective<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('effective')}>Effective<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('expired')}>Expired<i className="fa fa-sort"></i></th>
                            <th className='checkbox-column'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Mapped
                                 </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={!this.state.notMapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('notMapped')}>&#x274C; Not Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.mapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('mapped')}>&#x2705; Mapped</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginate(items, this.state.pagination, (item) => (
                                <DataRow id={item.id.toString()} key={item.id}>
                                    <DataCell>{item.sourceCode}</DataCell>
                                    <DataCell>{item.sourceDescription}</DataCell>
                                    <DataCell>
                                        <select className="custom-select" value={item.preEffectiveReferenceId} id={item.id} onChange={e => this.handleChange(e, 'preEffectiveReferenceId', 'preEffective')} disabled={this.state.buttonLock}>
                                            <option value={''}>Select Destination</option>
                                            {this.state.destinationCodes
                                                .map((dc, i) =>
                                                    <option key={`pre${i}`} value={dc.id}>{dc.description}</option>
                                                )}
                                        </select>
                                    </DataCell>
                                    <DataCell>
                                        <select className="custom-select" value={item.effectiveReferenceId} id={item.id} onChange={e => this.handleChange(e, 'effectiveReferenceId', 'effective')} disabled={this.state.buttonLock}>
                                            <option value=''>Select Destination</option>
                                            {this.state.destinationCodes
                                                .map((dc, i) =>
                                                    <option key={`eff${i}`} value={dc.id}>{dc.description}</option>
                                                )}
                                        </select>
                                    </DataCell>
                                    <DataCell>
                                        <select className="custom-select" value={item.expiredReferenceId} id={item.id} onChange={e => this.handleChange(e, 'expiredReferenceId', 'expired')} disabled={this.state.buttonLock}>
                                            <option value=''>Select Destination</option>
                                            {this.state.destinationCodes
                                                .map((dc, i) =>
                                                    <option key={`ex${i}`} value={dc.id}>{dc.description}</option>
                                                )}
                                        </select>
                                    </DataCell>
                                    <DataCell className='checkbox-column' itemProp={item.mapped === RowStatus.NotMapped ? 'unmapped' : item.mapped === RowStatus.Mapped ? 'mapped' : 'dropped'}>
                                        {item.mapped === RowStatus.NotMapped ? < p className='mapped' itemProp='unmapped'>&#x274C;</p> : item.mapped === RowStatus.Mapped ? < p className='mapped' itemProp='mapped'>&#x2705;</p> : < p className='mapped' itemProp='dropped'>&#x2796;</p>}
                                    </DataCell>
                                </DataRow>
                            ))
                        }
                    </tbody>
                </DataTable>
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
            </>
        );
    }
}