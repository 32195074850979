import * as React from 'react';
import { Button, Input, InputGroup, } from 'reactstrap';
import * as Clients from 'client/ApiClient';

import { MaterialIcon } from 'components/basic/Icon';
import { RangePagination } from 'components/basic/Pagination';

import DynamicDataTable from 'components/basic/DynamicDataTable';
import Toolbar from "components/basic/Toolbar";

type DynamicDataTableState = {
    records: Clients.DynamicEntityListModel;
    sourceRecords: Clients.DynamicEntityListModel;
    isError: boolean;

    pageSize: number;
    pageNumber: number;

    sortColumn: number | undefined;
    sortDirection: Clients.SortDirection;

    filter: string;
};

export type DynamicDataTableProps = {
    conversionId: string;
    sectionId: string;
    source: 'stage';
    nameIndex: string;
    columnTake: number;
    children?: React.ReactNode;
};

export default class MappingMergeDynamicDataTable extends React.Component<DynamicDataTableProps, DynamicDataTableState> {
    private readonly client = new Clients.GenericClient();

    constructor(props: Readonly<DynamicDataTableProps>) {
        super(props);
        this.state = {
            records: new Clients.DynamicEntityListModel({ count: 0 }),
            sourceRecords: new Clients.DynamicEntityListModel({ count: 0 }),
            isError: false,

            pageSize: 50,
            pageNumber: 0,

            sortColumn: undefined,
            sortDirection: Clients.SortDirection.None,

            filter: '',
        }
    }

    fetchRecords = () => {
        const take = this.state.pageSize;
        const offset = this.state.pageSize * this.state.pageNumber;
        const sortStageColumn = (this.state.sortColumn !== undefined
            && this.state.records.schema!.columns![this.state.sortColumn].id) || '';
        this.client.getStageAll(
            this.props.conversionId,
            `[STG].[${this.props.sectionId}]`,
            this.props.columnTake,
            take,
            offset,
            sortStageColumn,
            this.state.sortDirection,
            this.state.filter)
            .then(r => r && this.setState({
                records: r,
                isError: false,
            }))
            .catch(error => {
                if (error instanceof Clients.ApiException && error.status === 404) {
                    this.setState({
                        // A stored procedure for the entity has not been registered.
                        isError: true,
                    })
                } else {
                    this.setState({ records: new Clients.DynamicEntityListModel() })
                    console.log({
                        message: `error getting dynamic records`,
                        source: this.props.source,
                        conversionId: this.props.conversionId,
                        sectionId: this.props.sectionId,
                        error: error,
                    })
                }
            });

        let sortSourceColumn = (this.state.sortColumn !== undefined 
            && this.state.records.schema!.columns![this.state.sortColumn].id) || '';

        sortSourceColumn = sortSourceColumn === "AgencyNo" || sortSourceColumn === "IsValid" ? '' : sortSourceColumn

        this.client.getMultipleSourceAll(
            this.props.conversionId,
            `[dbo].[${this.props.sectionId}]`,
            Number(this.props.nameIndex),
            this.props.columnTake,
            take,
            offset,
            sortSourceColumn,
            this.state.sortDirection,
            this.state.filter)
            .then(r => r && this.setState({
                sourceRecords: r,
                isError: false,
            }))
            .catch(error => {
                if (error instanceof Clients.ApiException && error.status === 404) {
                    this.setState({
                        // A stored procedure for the entity has not been registered.
                        isError: true,
                    })
                } else {
                    this.setState({ sourceRecords: new Clients.DynamicEntityListModel() })
                    console.log({
                        message: `error getting dynamic records`,
                        source: this.props.source,
                        conversionId: this.props.conversionId,
                        sectionId: this.props.sectionId,
                        error: error,
                    })
                }
            });
    }

    componentDidMount() {
        this.fetchRecords();
    }

    componentDidUpdate(prevProps: DynamicDataTableProps, prevState: DynamicDataTableState) {
        if (this.props.sectionId !== prevProps.sectionId
            && (this.state.sortColumn !== undefined
                || this.state.filter !== '')) {
            // Setting defaults.
            this.setState({
                sortColumn: undefined,
                filter: '',
            })
        } else if (this.props.sectionId !== prevProps.sectionId
            || this.props.source !== prevProps.source
            || this.props.conversionId !== prevProps.conversionId
            || this.state.pageNumber !== prevState.pageNumber
            || this.state.pageSize !== prevState.pageSize
            || this.state.sortColumn !== prevState.sortColumn
            || this.state.sortDirection !== prevState.sortDirection
            || this.state.filter !== prevState.filter
        ) {
            this.fetchRecords();
        }
        if (this.props.nameIndex !== prevProps.nameIndex) {
            this.fetchRecords();
        }
    }

    render() {
        if (this.state.isError) {
            return <MaterialIcon className='dynamic-data-table-not-found'>block</MaterialIcon>;
        }

        const arr: (string | undefined)[] = [];
        if (this.props.source === 'stage' && this.state.records.schema) {
            this.state.records.schema!.columns!.forEach((v, i: number) => {
                if (v.name !== "Stage Guid Pk") {
                    arr.push(v.id)
                }
        })
        } 
        const columns = arr;
        const count = this.state.sourceRecords.count > this.state.records.count ? this.state.sourceRecords.count : this.state.records.count;
        return (
            <>
                <Toolbar>
                    <InputGroup>
                        <React.Fragment>
                            <Input placeholder='Search'
                                name='filter'
                                onChange={e => this.setState({ filter: e.target.value })}
                                value={this.state.filter}
                            />
                            <InputGroup addonType='append' className='material-icon'>
                                <Button onClick={() => this.setState({ filter: '' })} > close</Button>
                            </InputGroup>
                        </React.Fragment>
                    </InputGroup>
                    {this.props.children}
                </Toolbar>
                <h5 className='mapping-body-header'>Source Data </h5>
                <DynamicDataTable
                    records={this.state.sourceRecords}
                    columns={columns}
                    sorting={{
                        sortColumn: this.state.sortColumn,
                        sortDirection: this.state.sortDirection,
                        onSort: (sortColumn, sortDirection) => this.setState({
                            sortColumn: sortColumn,
                            sortDirection: sortDirection,
                        })
                    }}
                    sectionId={this.props.sectionId}
                    ref="table"
                    type="SourceMerge"
                />
                <hr />
                <h5 className='mapping-body-header'>Staged Data </h5>
                <DynamicDataTable
                    records={this.state.records}
                    columns={columns}
                    sorting={{
                        sortColumn: this.state.sortColumn,
                        sortDirection: this.state.sortDirection,
                        onSort: (sortColumn, sortDirection) => this.setState({
                            sortColumn: sortColumn,
                            sortDirection: sortDirection,
                        })
                    }}
                    sectionId={this.props.sectionId}
                    ref="table"
                />
                <RangePagination
                    pageNumber={this.state.pageNumber}
                    pageSize={this.state.pageSize}
                    recordsCount={count}
                    onChangeIndex={index => this.setState({ pageNumber: index })}
                />
            </>
        )
    }
}
