import * as React from 'react';
import { Container, Button, FormGroup, Input, Label, Row, Col, Badge } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as Clients from '../../client/ApiClient';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { enqueueNotification } from "../../store/Notifications"
import { store } from '../../AppContext';
import { DataCell, DataRow, DataTable } from '../basic/DataTable';
import { Link, LinkProps } from 'react-router-dom';
interface MergeToolPageState {
    agencyNumbers: {
        agencyNo: string,
        selected: boolean
    }[];
    merge: string;
    buttonLock: boolean;
    timeout: any;
    hubConnection: HubConnection | null;
    status: any;
    reportStatus: any;
    loading: boolean;
    timings: Clients.TimingsModel[];
    items: Clients.TimingsModel[];
    timingSelected: string;
    sort: boolean;
    sortTimings: boolean;
}
interface MergeToolArgs {
    conversionId: string,
}

type MergeToolProps = RouteComponentProps<MergeToolArgs> & {
    currentUser: Clients.IUserLoginDetailsModel | undefined,
};

class MergeToolPage extends React.Component<MergeToolProps, MergeToolPageState>{

    private conversionClient: Clients.ConversionClient = new Clients.ConversionClient();
    private conversonGenericClient: Clients.GenericClient = new Clients.GenericClient();
    private executions: Clients.ExecutionsClient = new Clients.ExecutionsClient();
    private mergeClient: Clients.MergeMapClient = new Clients.MergeMapClient();

    constructor(props: Readonly<MergeToolProps>) {
        super(props)
        this.state = {
            agencyNumbers: [],
            merge: '',
            buttonLock: true,
            timeout: null,
            hubConnection: null,
            status: null,
            reportStatus: null,
            loading: false,
            timings: [],
            items: [],
            timingSelected: '',
            sort: true,
            sortTimings: true,
        }
    }

    componentDidMount() {
        this.getConnections(0);
        this.buttonLock();
        this.refreshCall();
        this.getExecution();
        this.getTimings();
    }

    refreshCall() {
        clearInterval(this.state.timeout);
        const link = '/executions';
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log("Connection started for Merge"))
                .catch(() => console.log("there is an error"));

            this.state.hubConnection!.on("Merge", (execution: Clients.ExecutionsModel) => {
                clearInterval(this.state.timeout);
                if ((execution.description === "Succeeded" || execution.description === "Completed") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'success', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
                else if ((execution.description === "Failed" || execution.description === "Canceled" || execution.description === "Ended Unexpectedly" || execution.description === "Stopping") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'error', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
                if (execution.conversionId === this.props.match.params.conversionId && !execution.packageName!.includes("Reporting")) {
                    const name = execution.packageName === 'Orchestration-StageValidateAndLoad.dtsx' ? 'Full Merge to Target' :
                        execution.packageName === 'Orchestration-StageAndValidate.dtsx' ? 'Merge to Stage' :
                            execution.packageName === 'Orchestration-LoadData.dtsx' ? 'Write Stage to Target' : ''
                    if (execution.endTime) {
                        clearInterval(this.state.timeout);
                        this.setState({
                            timeout: null, status: null
                        }, () => {
                            const status = this.setStatusForMerge(execution!.estimatedFinish, execution!.description, execution!.endTime, name)
                            this.setState({ status })
                        })
                    } else if (execution.estimatedFinish) {
                        this.setState({
                            timeout: setInterval(() => {
                                const status = this.setStatusForMerge(execution!.estimatedFinish, execution!.description, execution!.endTime, name)
                                this.setState({ status })
                            }, 1000)
                        })
                    } else if (execution.status === 2 && execution.startTime === null) {
                        clearInterval(this.state.timeout);
                        const status = this.setStatusForMerge(execution!.estimatedFinish, execution!.description, execution!.endTime, name)
                        this.setState({ status })
                    } else {
                        clearInterval(this.state.timeout);
                    }
                }
                else if (execution.conversionId === this.props.match.params.conversionId && execution.packageName!.includes("Reporting")) {
                    const name = "Merge Reporting"
                    if (execution.endTime) {
                        clearInterval(this.state.timeout);
                        this.setState({
                            timeout: null, reportStatus: null
                        }, () => {
                            const reportStatus = this.setStatusForMerge(execution!.estimatedFinish, execution!.description, execution!.endTime, name)
                            this.setState({ reportStatus })
                        })
                    } else if (execution.estimatedFinish) {
                        this.setState({
                            timeout: setInterval(() => {
                                const reportStatus = this.setStatusForMerge(execution!.estimatedFinish, execution!.description, execution!.endTime, name)
                                this.setState({ reportStatus })
                            }, 1000)
                        })
                    } else if (execution.status === 2 && execution.startTime === null) {
                        clearInterval(this.state.timeout);
                        const reportStatus = this.setStatusForMerge(execution!.estimatedFinish, execution!.description, execution!.endTime, name)
                        this.setState({ reportStatus })
                    } else {
                        clearInterval(this.state.timeout);
                    }
                }

            });
        })
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
        this.setState = (_state, _callback) => {
            return;
        };
    }

    getConnections = (connectionId: number) => {
        const params = this.props.match.params
        this.conversionClient.getConnectionsAll(params.conversionId, connectionId)
            .then(response => {
                const agencyNumbers: { agencyNo: string, selected: boolean }[] = []
                response!.forEach(r => {
                    agencyNumbers.push({ agencyNo: r.name!, selected: true })
                })
                this.setState({ agencyNumbers })
            })
            .catch(error => { })
    }

    agencyNumber = (agencyNo: string) => {
        const agencyNumbers = this.state.agencyNumbers.map(
            a => a.agencyNo === agencyNo
                ? ({
                    ...a,
                    selected: !a.selected,
                })
                : a
        )
        this.setState({ agencyNumbers });
    }

    merge = () => {
        const conversionId = this.props.match.params.conversionId
        const userId = this.props.currentUser!.id
        if (conversionId && userId) {
            const merge = this.state.merge
            const name = merge === 'merge' ? 'Full Merge to Target' :
                merge === 'stage' ? 'Merge to Stage' :
                    merge === 'load' ? 'Write Stage to Target' : ''
            const status = < h5 className='status' > <Badge color={"primary"}>{name} - Estimate: Pending - Status: Running </Badge></h5>
            this.setState({ buttonLock: true, loading: true, status })
            this.conversonGenericClient.runMergeAction(conversionId, userId, this.state.merge)
                .then()
                .catch((err) => {
                    console.log(err)
                    this.setState({ buttonLock: false, loading: false, status: null }, () => {
                        this.getExecution();
                    })
                });
        }
    }

    buttonLock = () => {
        const conversionId = this.props.match.params.conversionId;
        this.executions.listExecutionsAllTierBoolean(conversionId)
            .then((response) => {
                if (response) {
                    this.setState({ buttonLock: response.isLocked })
                }
                if (!response!.isLocked) {
                    this.mergeClient.mergeLock(conversionId)
                        .then((response) => {
                            this.setState({ buttonLock: response })
                        })
                        .catch(error => { })
                }
            })
            .catch(error => { })
    }

    getExecution = () => {
        this.executions!.listExecutions(this.props.match.params.conversionId)
            .then(results => {
                if (results!.length === 0) {
                    this.setState({
                        loading: false,
                        status: null,
                        reportStatus: null,
                    })
                }
                else {
                    const tierPrep = results!.filter(r => r.packageName!.includes('Orchestration') && r.packageName !== 'Orchestration-Map.dtsx' && r.packageName !== 'Orchestration-Reporting.dtsx')
                    const reports = results!.filter(r => r.packageName === 'Orchestration-Reporting.dtsx')

                    let lastExecution = tierPrep![tierPrep!.length - 1];
                    let lastReport = reports![reports!.length - 1];
                    const estimatedFinish = lastExecution.estimatedFinish;

                    if (lastExecution.endTime) clearInterval(this.state.timeout);

                    const endTime = lastExecution.endTime;

                    const name = lastExecution.packageName === 'Orchestration-StageValidateAndLoad.dtsx' ? 'Full Merge to Target' :
                        lastExecution.packageName === 'Orchestration-StageAndValidate.dtsx' ? 'Merge to Stage' :
                            lastExecution.packageName === 'Orchestration-LoadData.dtsx' ? 'Write Stage to Target' : ''

                    const merge = lastExecution.packageName === 'Orchestration-StageValidateAndLoad.dtsx' && endTime === undefined ? 'merge' :
                        lastExecution.packageName === 'Orchestration-StageAndValidate.dtsx' && endTime === undefined ? 'stage' :
                            lastExecution.packageName === 'Orchestration-LoadData.dtsx' && endTime === undefined ? 'load' : '';

                    this.setState({
                        merge, timeout: setInterval(() => {
                            const status = this.setStatusForMerge(estimatedFinish, lastExecution.description!, endTime, name)
                            const reportStatus =  lastReport && this.setStatusForMerge(lastReport.estimatedFinish, lastReport.description!, lastReport.endTime, 'Merge Reports')
                            this.setState({ status, reportStatus })
                        }, 1000)
                    });
                }
            })
            .catch(e => { console.log(e) })
    }

    private setStatusForMerge(timeEstimate: Date | undefined, status: string | undefined, endTime: Date | undefined, name: string) {
        let color = ""
        if (endTime) {
            clearInterval(this.state.timeout);
            const dt = new Date(endTime);
            const day = dt.getDate(),
                month = dt.getMonth(),
                year = dt.getFullYear(),
                minutes = (dt.getMinutes() < 10 ? '0' : "") + dt.getMinutes();
            let hours = dt.getHours();
            const period = (hours < 12) ? 'AM' : 'PM';
            if (period === 'PM' && hours !== 12) hours = hours - 12;
            if (hours === 0) hours = 12;
            if (status === "Canceled" || status === "Failed" || status === "Ended Unexpectedly") {
                color = "danger"
            } else if (status === "Succeeded" || status === "Completed") {
                color = "success"
            }
            this.setState({ loading: false });
            return <h5 className='status' > <Badge color={color}> {name} completed on {
                `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
            } - Status: {status} </Badge></h5>
        } else {
            color = "primary"
            const now = new Date().valueOf();
            const estimate = timeEstimate ? new Date(timeEstimate).valueOf() : now;
            const timeRemain: number = (estimate - now) / 60000;
            const timeLeft = timeEstimate && timeRemain > 0 ? `${timeRemain.toFixed(2)} minutes` : "Pending";
            this.setState({ loading: true });
            return <h5 className='status'> <Badge color={color}>{name} - Estimate: {timeLeft} - Status: {status} </Badge></h5>
        }
    }

    getTimings = () => {
        this.mergeClient.getLogTimings(this.props.match.params.conversionId)
            .then((response) => {
                if (response) {
                    this.setState({ timings: response }, () => { this.sortTimings('logSequenceId') })
                }
            })
            .catch(error => { })

        this.mergeClient.getLogTimeActions(this.props.match.params.conversionId)
            .then((response) => {
                if (response) {
                    this.setState({ items: response }, () => { this.sort('startTimestamp') })
                }
            })
            .catch(error => { })
    }

    sort(column: string) {
        const items = this.state.items.sort((a: any, b: any) => {
            let x = a[column] !== null ? a[column] : ''
            let y = b[column] !== null ? b[column] : ''
            y = typeof y === "string" ? y.toLowerCase().trim() : y
            x = typeof x === "string" ? x.toLowerCase().trim() : x
            if (this.state.sort) {
                if (x < y) { return -1; }
                if (x > y) { return 1; }
            } else {
                if (x > y) { return -1; }
                if (x < y) { return 1; }
            }
            return 0
        })
        this.setState({ sort: !this.state.sort, items });
    }

    sortTimings(column: string) {
        const timings = this.state.timings.sort((a: any, b: any) => {
            let x = a[column] !== null ? a[column] : ''
            let y = b[column] !== null ? b[column] : ''
            y = typeof y === "string" ? y.toLowerCase() : y
            x = typeof x === "string" ? x.toLowerCase() : x
            if (this.state.sortTimings) {
                if (x < y) { return -1; }
                if (x > y) { return 1; }
            } else {
                if (x > y) { return -1; }
                if (x < y) { return 1; }
            }
            return 0
        })
        this.setState({ sortTimings: !this.state.sortTimings, timings });
    }

    handleHover = () => {
        if (this.state.status.props.children[1].props.color.toString() == "danger") {
            this.executions!.listExecutionErrorDetail(this.props.match.params.conversionId)
                .then(results => {
                    if (results!.length != 0)
                        alert(results);
                })
                .catch(e => { console.log(e) })
        }
    };

    public render() {
        const items = this.state.items
            .filter(i => {
                return i.agencyNo === this.state.timingSelected ||
                    (i.agencyNo === null && this.state.timingSelected === '')
            })
        //const timings = this.state.timings
        //    .filter(i => {
        //        return i.agencyNo === this.state.timingSelected ||
        //            (i.agencyNo === null && this.state.timingSelected === '')
        //    })
        return (
            <>
                <br />
                <h3 className='mapping-body-header'>Merge To Staging or Target</h3>
                <br />
                <Container>
                    <br />
                    <Row>
                        <Col md={{ size: 5 }}>
                            <legend>Select Source(s) to Merge</legend>
                            {this.state.agencyNumbers.map(
                                (a, i) => {
                                    return (
                                        <FormGroup check className="agencyCheck" key={i}>
                                            <Label check>
                                                <Input type="checkbox" checked={a.selected} onChange={() => this.agencyNumber(a.agencyNo)} disabled={true} />{' '}
                                                {a.agencyNo}
                                            </Label>
                                        </FormGroup>
                                    )
                                })}
                        </Col>
                        <Col md={{ size: 5 }}>
                            <legend>Select Action</legend>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radio1" value={this.state.merge} checked={this.state.merge === 'stage'} disabled={this.state.loading || this.state.buttonLock} onChange={() => this.setState({ merge: 'stage' })} />{' '}
                                    Merge to Stage
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radio1" value={this.state.merge} checked={this.state.merge === 'load'} disabled={this.state.loading || this.state.buttonLock} onChange={() => this.setState({ merge: 'load' })} />{' '}
                                    Write Stage to Target
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radio1" value={this.state.merge} checked={this.state.merge === 'merge'} disabled={this.state.loading || this.state.buttonLock} onChange={() => this.setState({ merge: 'merge' })} />{' '}
                                    Full Merge to Target
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <Button disabled={this.state.merge === '' || !this.state.agencyNumbers.some(a => a.selected) || this.state.buttonLock} className="toolbar-link-button toolbar-link-button-primary" onClick={() => this.merge()}>Run Merge</Button>
                            {this.state.loading && <img className='loading-indicator' alt="loader" src={'loader.gif'} />}
                        </Col>
                    </Row>

                    <br />
                    <hr />
                    <br />
                    <Row>
                        <Col md={{ size: 2 }}>
                            <h4>Merge Status: </h4>
                        </Col>
                        <Col md={{ size: 6 }}>
                            <Link to={this.state.status} onClick={this.handleHover}>
                                {this.state.status}
                            </Link>
                            <Link to={this.state.reportStatus}>
                                {this.state.reportStatus}
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 3 }}>
                            <select className="custom-select" name='mappedValue' onChange={(e) => { this.setState({ timingSelected: e.target.value }) }}>
                                <option value=''>Select Agency</option>
                                {this.state.agencyNumbers.map((a, i) =>
                                    <option value={a.agencyNo} key={i}>{a.agencyNo}</option>
                                )}
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTable>
                                <thead>
                                    <tr>
                                        <th onClick={() => this.sort('mergeSequenceId')}>Sequence # <i className="fa fa-sort"></i></th>
                                        <th onClick={() => this.sort('agencyNo')}>Agency No <i className="fa fa-sort"></i></th>
                                        <th onClick={() => this.sort('action')}>Action <i className="fa fa-sort"></i></th>
                                        <th onClick={() => this.sort('startTimestamp')}>Start Time <i className="fa fa-sort"></i></th>
                                        <th onClick={() => this.sort('finishTimestamp')}>End Time<i className="fa fa-sort"></i></th>
                                        <th onClick={() => this.sortTimings('isRunning')}>Running <i className="fa fa-sort"></i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => {
                                        return <>
                                            <DataRow id={item.mergeSequenceId.toString()} key={index}>
                                                <DataCell>{item.mergeSequenceId}</DataCell>
                                                <DataCell>{item.agencyNo}</DataCell>
                                                <DataCell>{item.action}</DataCell>
                                                <DataCell>{item.startTimestamp.toLocaleString()}</DataCell>
                                                <DataCell>{item.finishTimestamp && item.finishTimestamp!.toLocaleString()}</DataCell>
                                                <DataCell className='checkbox-column'>
                                                    {item.isRunning === 0 && item.finishTimestamp !== null ? < p className='mapped'>&#x2705;</p> : item.isRunning === 1 ? <img className='loading-indicator' alt="loader" src={'loader.gif'} /> : null}
                                                </DataCell>
                                            </DataRow>
                                        </>
                                    })}
                                </tbody>
                            </DataTable>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <DataTable>*/}
                    {/*            <thead>*/}
                    {/*                <tr className="nonFullWidthRow">*/}
                    {/*                    <th onClick={() => this.sortTimings('logSequenceId')}>Sequence # <i className="fa fa-sort"></i></th>*/}
                    {/*                    <th onClick={() => this.sortTimings('agencyNo')}>Agency No <i className="fa fa-sort"></i></th>*/}
                    {/*                    <th onClick={() => this.sortTimings('action')}>Action <i className="fa fa-sort"></i></th>*/}
                    {/*                    <th onClick={() => this.sortTimings('tableName')}>Table Name <i className="fa fa-sort"></i></th>*/}
                    {/*                    <th onClick={() => this.sortTimings('recordCount')}>Record Count <i className="fa fa-sort"></i></th>*/}
                    {/*                    <th onClick={() => this.sortTimings('executionTimeInMilliseconds')}>Execution Time <i className="fa fa-sort"></i></th>*/}
                    {/*                    <th onClick={() => this.sortTimings('isRunning')}>Running <i className="fa fa-sort"></i></th>*/}
                    {/*                    <th onClick={() => this.sortTimings('startTimestamp')}>Start Time <i className="fa fa-sort"></i></th>*/}
                    {/*                    <th onClick={() => this.sortTimings('finishTimestamp')}>End Time<i className="fa fa-sort"></i></th>*/}
                    {/*                </tr>*/}
                    {/*            </thead>*/}
                    {/*            <tbody>*/}
                    {/*                {timings.map((item, index) => {*/}
                    {/*                    return <>*/}
                    {/*                        <DataRow id={item.mergeSequenceId.toString()} key={index}>*/}
                    {/*                            <DataCell>{item.logSequenceId}</DataCell>*/}
                    {/*                            <DataCell>{item.agencyNo}</DataCell>*/}
                    {/*                            <DataCell>{item.action}</DataCell>*/}
                    {/*                            <DataCell>{item.tableName}</DataCell>*/}
                    {/*                            <DataCell>{item.recordCount}</DataCell>*/}
                    {/*                            <DataCell>{item.executionTimeInMilliseconds}</DataCell>*/}
                    {/*                            <DataCell>{item.isRunning}</DataCell>*/}
                    {/*                            <DataCell>{item.startTimestamp.toLocaleString()}</DataCell>*/}
                    {/*                            <DataCell>{item.finishTimestamp.toLocaleString()}</DataCell>*/}
                    {/*                        </DataRow>*/}
                    {/*                    </>*/}
                    {/*                })}*/}
                    {/*            </tbody>*/}
                    {/*        </DataTable>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Container>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser,
        }
    },
)(withRouter(MergeToolPage));