import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { connect } from 'react-redux';
import { ApplicationState } from 'store';

import { Container, Form, FormGroup, Input, Label, Button, Jumbotron } from 'reactstrap';

import { authentication } from 'services/Authentication';
import * as Clients from 'client/ApiClient';

import './Landing.scss'
import 'bootstrap/dist/css/bootstrap.css';

type LandingPageState = {
    login: string,
    password: string,
};

type LandingPageProps = RouteComponentProps & {
    currentUser: Clients.IUserLoginDetailsModel | undefined,
};

class Landing extends React.Component<LandingPageProps, LandingPageState>{
    constructor(props: Readonly<LandingPageProps>) {
        super(props);
        this.state = {
            login: "",
            password: "",
        };
    }

    private login = () => {
        authentication.login(this.state.login, this.state.password)
            .then(() => {
                authentication.getAMS360URLs()
                    .then(() => {
                        this.props.history.push('/conversion');
                    })
                    .catch(error => {
                        this.props.history.push('/conversion');
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }

    private handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.login();
        }
    }

    private renderLoginBox = () => {
        return (
            <Form>
                <FormGroup className="form-group-email">
                    <Label for="username" hidden>Username</Label>
                    <Input
                        type="email"
                        name="email"
                        id="username"
                        placeholder="Username"
                        value={this.state.login}
                        onKeyPress={this.handleKeyDown}
                        onChange={event => this.setState({
                            login: event.target.value,
                        })} />
                </FormGroup>
                <FormGroup className="form-group-password">
                    <Label for="password" hidden>Password</Label>
                    <Input type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        value={this.state.password}
                        onKeyPress={this.handleKeyDown}
                        onChange={event => this.setState({
                            password: event.target.value,
                        })} />
                </FormGroup>
                <FormGroup className="form-group-signin">
                    <Button className="signin" onClick={this.login} onKeyPress={this.handleKeyDown} >Sign in</Button>
                </FormGroup>
            </Form>
        );
    }

    public render() {
        return (
            <>
                <div className="login-form" >
                    <div className="login-label">
                    </div>
                    {!this.props.currentUser && this.renderLoginBox()}
                </div>
                <div>
                    <Jumbotron>
                        <Container>
                            <h1 className="display-3">Welcome to SmartMap!</h1>
                        </Container>
                    </Jumbotron>
                </div>
            </>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser
        }
    },
)(Landing);
