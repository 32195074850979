import * as React from 'react';
import * as Clients from '../../client/ApiClient';
import { authentication } from '../../services/Authentication';
import { DataTable, DataRow, DataCell, DataTableButton } from '../basic/DataTable';
import Toolbar from '../basic/Toolbar';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Input, Button } from 'reactstrap';
import { RangePagination, RangePaginationState, paginate } from '../basic/Pagination';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { enqueueNotification } from "../../store/Notifications";
import { store } from "../../AppContext";
import CloseConfirmationModal from '../basic/modals/CloseConfirmationModal';
import ConfirmationModal from '../basic/modals/ConfirmationModal';
import * as FileTypes from '../../constants/FileUploadType';
import * as MiniConstants from '../../constants/MiniConversion';
import './Mini.scss';

interface IPoliciesPageState {
    policies: Clients.PoliciesMiniConversionModel[];
    options: { [key: string]: Clients.ConversionOptionValueModel } | undefined;
    selectedFile: any;
    pagination: RangePaginationState;
    hubConnection: HubConnection | null;
    hubConnected: boolean;
    running: boolean;
    buttonLock: boolean;
    closeConfirmationModal: boolean,
    confirmationModal: boolean,
    errMessage: string,
}
interface PoliciesArgs {
    conversionId: string,
}

type PoliciesProps = RouteComponentProps<PoliciesArgs>;

class PoliciesPage extends React.Component<PoliciesProps, IPoliciesPageState>{
    private readonly clientPolicies: Clients.PoliciesMiniConversionClient = new Clients.PoliciesMiniConversionClient();
    private options: Clients.ConversionOptionsClient = new Clients.ConversionOptionsClient();
    private executions: Clients.ExecutionsClient = new Clients.ExecutionsClient();
    private conversionId: string = '';
    private polFileQueued: boolean = false;
    private polFileUploaded: boolean = false;

    constructor(props: Readonly<PoliciesProps>) {
        super(props)
        this.state = {
            policies: [],
            options: undefined,
            selectedFile: null,
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            hubConnection: null,
            hubConnected: false,
            running: false,
            buttonLock: true,
            closeConfirmationModal: false,
            confirmationModal: false,
            errMessage: '',
        }
    }

    componentDidMount() {
        this.conversionId = this.props.match.params.conversionId;
        this.getOptions();
        this.buttonLock();

        const link = `${process.env.PUBLIC_URL}/stagesignal`
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => this.setState({ hubConnected: true }))
                .catch(err => console.log('Error while establishing connection :('));
            this.state.hubConnection!.on('PoliciesComplete', (done: string) => {
                this.setState({ running: false })
                this.refreshList(true);
            });
            let reload = 0;
            this.state.hubConnection!.on('PoliciesRunning', (running: string) => {
                reload += 1;
                if (reload === 15 || reload % 300 === 0) {
                    this.refreshList(false);
                }
                if (!this.state.running) {
                    this.setState({ running: true })
                }
            });
            this.state.hubConnection!.on('PoliciesError', (done: string) => {
                this.popErrMessage("An error occurred. File cannot be uploaded.");
            });
            this.state.hubConnection!.on('PoliciesFail', (done: string) => {
                this.popErrMessage("File is in incorrect orientation. Please see file example.");
            });
            this.state.hubConnection!.on('PoliciesCustRefNumFail', (done: string) => {
                this.popErrMessage("Customer Ref # missing from one or more rows. File cannot be uploaded.");
            });
            this.state.hubConnection!.on('PoliciesRefNumFail', (done: string) => {
                this.popErrMessage("Policy Ref # missing from one or more rows. File cannot be uploaded.");
            });
            this.state.hubConnection!.on('PoliciesWorkbookProtected', (done: string) => {
                this.popErrMessage("Policies file has restricted access, is password protected or is not an Excel document. File cannot be uploaded.");
            });
            if (this.state.hubConnected && this.state.hubConnection!.state == HubConnectionState.Disconnected) {
                store.dispatch(enqueueNotification({ type: 'error', message: "User session has been disconnected." }));
                authentication.logout()
                    .then(() => this.props.history.push('/'))
                    .catch(error => { }
            )};
        })
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
    }

    refreshList = (done: boolean) => {
        this.clientPolicies.getAll(this.conversionId)
            .then(response => {
                this.setState({
                    policies: response!
                }, () => {
                    if (done) {
                        if (this.state.policies.length > 0) {
                            store.dispatch(enqueueNotification({ type: 'success', message: "Complete" }))
                            this.setOption(MiniConstants.conversionOption_PolFileUploadedId, '1');
                            this.openCloseConfirmationModal();
                        } else {
                            this.setOption(MiniConstants.conversionOption_PolFileUploadedId, '0');
                        }
                    }
                })
            })
            .catch(error => { console.log('Error loading Policies'); });
    }

    getOptions = () => {
        this.options.get(this.conversionId)
            .then(values => {
                const options = values!.reduce(
                    (result, current) => ({ ...result, [current.id!]: current, }), {});
                this.setState({ options });
            })
            .catch(e => {
                console.log(e)
            })
    }

    handleFileInput = (event: React.ChangeEvent) => {
        let selectedFile = (event.target as HTMLInputElement).files![0];
        if (selectedFile.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            (event.target as HTMLInputElement).value = '';
            store.dispatch(enqueueNotification({ type: 'error', message: "Please upload Excel files (.xlsx) only." }));
        }
        else {
            console.log("selected file changed");
            this.setState({ selectedFile });
        }
    };

    onFileUpload = () => {
        if (this.state.selectedFile === null) {
            store.dispatch(enqueueNotification({ type: 'error', message: "Please upload file." }))
        } else {
            if (this.polFileUploaded) {
                this.setOption(MiniConstants.conversionOption_PolFileUploadedId, '0');
                this.setOption(MiniConstants.conversionOption_PolFileValidatedId, '0');
            }
            this.setState({ running: true });
            const formData = new FormData();
            const postUrl = '/api/v1/' + this.conversionId + '/fileUpload';

            formData.append("contentType", this.state.selectedFile!.type);
            formData.append("data", this.state.selectedFile!);
            formData.append("fileUploadTypeId", FileTypes.miniConversion_Policies);
            formData.append("name", this.state.selectedFile!.name);

            fetch(postUrl, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .catch(error => {
                    console.log(error)
                })
        }
    }

    buttonLock = () => {
        this.executions.listExecutionsAllTierBoolean(this.conversionId)
            .then((response) => {
                if (response) {
                    this.setState({ buttonLock: response.isLocked })
                }
            })
            .catch(error => { })
    }

    openConfirmationModal = () => {
        this.setState({ confirmationModal: true })
    }

    openCloseConfirmationModal = () => {
        this.setState({ closeConfirmationModal: true })
    }

    refreshPage = (landingTab: string) => {
        window.location.reload();
        this.props.history.push({
            pathname: window.location.href.split('/').pop(),
            state: { tabName: landingTab }
        });
    }

    private gotoFileUploads = () => {
        this.setState({ closeConfirmationModal: false, confirmationModal: false });
        this.refreshPage('additionaldata');
    }

    private gotoMiniDashboard = () => {
        this.setState({ closeConfirmationModal: false, confirmationModal: false });
        this.refreshPage('minidash');
    }

    removeConfirmationModal = () => {
        this.setState({ closeConfirmationModal: false, confirmationModal: false });
    }

    setOption = (optionId: string, optionValue: string) => {
        const optionsClient: Clients.ConversionOptionsClient = new Clients.ConversionOptionsClient();
        const optionModel: Clients.ConversionOptionValueModel[] = [];
        const valuesArray: any = {
            conversionId: this.conversionId,
            value: optionValue,
            isLocked: false,
            id: optionId,
        };
        optionModel.push(valuesArray);
        optionsClient.update(this.conversionId, optionModel);
    }

    private popErrMessage = (message: string) => {
        this.setState({ running: false });
        if (this.state.policies.length > 0) { this.refreshList(true); }
        this.setState({ errMessage: message });
        this.openConfirmationModal();
    }


    public render() {
        if (this.state.options) {
            for (const id of Object.keys(this.state.options)) {
                const value = this.state.options[id].value;
                switch (id.toUpperCase()) {
                    case MiniConstants.conversionOption_PolFileUploadedId: {
                        this.polFileUploaded = (value === '1');
                        if (this.polFileUploaded && (!this.state.policies || this.state.policies.length === 0)) {
                            this.refreshList(false);
                        }
                        break;
                    }
                    case MiniConstants.conversionOption_PolFileQueuedId: {
                        this.polFileQueued = (value === '1');
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        }

        return (
            <>
                <Toolbar>
                    <DataTableButton
                        icon='file_download'
                        id={`policiesExample`}
                        title="Policies Excel Example"
                        value="Download"
                        onClick={() => { window.open("/PoliciesUploadFileTemplate.xlsx") }} />
                    <Input
                        type="file"
                        name="file"
                        id="exampleFile"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        disabled={this.state.buttonLock || this.polFileQueued}
                        onChange={e => this.handleFileInput(e)}
                    />
                    {this.state.running ?
                        < img className='loading-indicator' src={'loader.gif'} /> :
                        <Button
                            className='submit-button-orange'
                            disabled={this.state.buttonLock || this.polFileQueued}
                            onClick={this.onFileUpload}>Upload
                        </Button>}
                </Toolbar>
                <DataTable>
                    <thead>
                        <tr>
                            <th className="number-column mini-column-mini">#</th>
                            <th className="mini-column-med">Customer Ref #</th>
                            <th className="mini-column-med">Policy Ref #</th>
                            <th className="mini-column-med">Policy #</th>
                            <th className="mini-column">Effective Date</th>
                            <th className="mini-column">Expiration Date</th>
                            <th className="mini-column">Division</th>
                            <th className="mini-column">Branch</th>
                            <th className="mini-column">Department</th>
                            <th className="mini-column">Group</th>
                            <th className="mini-column">Type of Business</th>
                            <th className="mini-column">Transaction Type</th>
                            <th className="mini-column">Description</th>
                            <th className="mini-column">Bill Method</th>
                            <th className="mini-column-small">Pay Plan</th>
                            <th className="mini-column-med">Parent Company</th>
                            <th className="mini-column-med">Writing Company</th>
                            <th className="mini-column">Executive</th>
                            <th className="mini-column">Rep</th>
                            <th className="mini-column">Broker</th>
                            <th className="mini-column">Line of Business</th>
                            <th className="mini-column-small">Premium</th>
                            <th className="mini-column-small">How Billed</th>
                        </tr>
                    </thead>
                    <tbody className="nonEntity">
                        {paginate(this.state.policies, this.state.pagination, (policies, i) => (
                            <DataRow key={policies.customerRefNum}
                                id={`${policies.customerRefNum}`}
                            >
                                <DataCell className="number-column mini-column-mini">{policies.rowNumber}</DataCell>
                                <DataCell className="mini-column-med">{policies.customerRefNum}</DataCell>
                                <DataCell className="mini-column-med">{policies.policyRefNum}</DataCell>
                                <DataCell className="mini-column-med">{policies.policyNo}</DataCell>
                                <DataCell className="mini-column">{policies.effectiveDate}</DataCell>
                                <DataCell className="mini-column">{policies.expirationDate}</DataCell>
                                <DataCell className="mini-column">{policies.division}</DataCell>
                                <DataCell className="mini-column">{policies.branch}</DataCell>
                                <DataCell className="mini-column">{policies.department}</DataCell>
                                <DataCell className="mini-column">{policies.group}</DataCell>
                                <DataCell className="mini-column">{policies.typeOfBusiness}</DataCell>
                                <DataCell className="mini-column">{policies.transactionType}</DataCell>
                                <DataCell className="mini-column">{policies.description}</DataCell>
                                <DataCell className="mini-column">{policies.billMethod}</DataCell>
                                <DataCell className="mini-column-small">{policies.payPlan}</DataCell>
                                <DataCell className="mini-column-med">{policies.parentCompany}</DataCell>
                                <DataCell className="mini-column-med">{policies.writingCompany}</DataCell>
                                <DataCell className="mini-column">{policies.executive}</DataCell>
                                <DataCell className="mini-column">{policies.rep}</DataCell>
                                <DataCell className="mini-column">{policies.broker}</DataCell>
                                <DataCell className="mini-column">{policies.lineOfBusiness}</DataCell>
                                <DataCell className="mini-column-small">{policies.premium}</DataCell>
                                <DataCell className="mini-column-small">{policies.howBilled}</DataCell>
                            </DataRow>
                        ))}
                    </tbody>
                </DataTable >
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={this.state.policies.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
                <CloseConfirmationModal
                    isOpen={this.state.closeConfirmationModal}
                    title={'File Upload Completed'}
                    message={'Your file has been successfully uploaded. Proceed to the dashboard for a status check on your project?'}
                    onSubmit={this.gotoMiniDashboard}
                    onClose={this.removeConfirmationModal}
                />
                <ConfirmationModal
                    isOpen={this.state.confirmationModal}
                    message={this.state.errMessage}
                    onSubmit={this.gotoFileUploads}
                />
            </>
        )
    }
}

export default withRouter(PoliciesPage)