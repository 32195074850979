import * as React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { ApplicationState } from 'store';

import * as Clients from 'client/ApiClient';

export type SecureProps = {
    currentUser?: Clients.IUserLoginDetailsModel,
    roles?: string[],
    authenticated?: boolean,
    children?: React.ReactChild,
}

export type SecureRouteBaseProps = RouteProps & RouteComponentProps & SecureProps;

export type SecureRouteBaseState = {
    currentUser?: Clients.IUserLoginDetailsModel,
}

class SecureRouteBase extends React.Component<SecureRouteBaseProps, SecureRouteBaseState> {
    render() {
        const { component, render, currentUser, roles, ...rest } = this.props;
        return (
            <Route {...rest} key={this.props.currentUser && this.props.currentUser.role}
                render={(props) => {
                    if (isAuthorized(this.props)) {
                        const result = (component && React.createElement(component)) || (render && render(props));
                        if (!result) {
                            throw new Error('The route must have either "component" or "render" specified.');
                        }
                        return result;
                    } else {
                        return <Redirect to='/' />;
                    }
                }}
            />
        );
    }
}

class SecureContainerBase extends React.Component<SecureProps> {
    render() {
        return isAuthorized(this.props) ? this.props.children : null;
    }
}

function isAuthorized(props: SecureProps): boolean {
    if (props.authenticated) {
        return !!props.currentUser;
    }
    const rolesResult =
        props.currentUser
        && props.currentUser.role
        && props.roles
        && props.roles.indexOf(props.currentUser.role) !== -1;
    return rolesResult || false;
}

const SecureRoute = withRouter(connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser
        }
    },
)(SecureRouteBase));

const SecureContainer = connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser
        }
    },
)(SecureContainerBase);

export { SecureRoute, SecureContainer }
