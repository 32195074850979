import classnames from 'classnames';
import * as React from 'react';
import * as Clients from 'client/ApiClient';

import { DataCell, DataRow, DataTable } from 'components/basic/DataTable';

import { DynamicEntitySchemaColumnModel } from 'client/ApiClient';

import './ReportDynamicDataTable.scss';

type DynamicDataTableState = {
    classes: any[];
    filter: string;
}

export type DynamicDataTableProps = {
    records: Clients.DynamicEntityListModel;
    columns?: (string | undefined)[];

    sorting?: {
        sortColumn: number | undefined;
        sortDirection: Clients.SortDirection;

        onSort: (column: number | undefined, direction: Clients.SortDirection, index: number) => void;
    }
    sectionId?: string;
};

export default class ReportDynamicDataTable extends React.Component<DynamicDataTableProps, DynamicDataTableState> {
    constructor(props: Readonly<DynamicDataTableProps>) {
        super(props);
        this.state = {
            classes: [],
            filter:'',
        }
    }

    getSchemaColumn(index: number): Clients.DynamicEntitySchemaColumnModel {
        return this.props.records.schema!.columns![index];
    }

    getColumnClasses(columnIndex: number): string {
        const column = this.getSchemaColumn(columnIndex);
        const classes = this.state.classes
        if (!classes.some(c => c.index === columnIndex)) {
            classes.push({ index: columnIndex, type: column.type })
            this.setState({classes})
        }
        return classnames({
            'default-dynamic-column report-column': column.type === Clients.DynamicEntityColumnType.String,
            'date-dynamic-column report-column': column.type === Clients.DynamicEntityColumnType.Date,
            'checkbox-dynamic-column report-column': column.type === Clients.DynamicEntityColumnType.Boolean,
            'number-dynamic-column report-column': column.type === Clients.DynamicEntityColumnType.Number,
        });                      
    }

    // Gets a dynamic value adjusted to the type of its column.
    getTypedValue(value: any, columnType: Clients.DynamicEntityColumnType): any {
        if (value === null || value === undefined) {
            return value;
        }
        switch (columnType) {
            case Clients.DynamicEntityColumnType.Date:
                return new Date(Date.parse(value as string));
            default:
                return value;
        }
    }

    renderValue = (value: any, columnIndex: number): React.ReactNode => {
        const column = this.getSchemaColumn(columnIndex);
        switch (column.type) {
            case Clients.DynamicEntityColumnType.Boolean:
                return (
                    <input
                        className='custom-checkbox'
                        type='checkbox'
                        checked={value as boolean}
                        disabled
                    />
                );
            default:
                return this.getTypedValue(value, column.type);
        }
    }

    private toggleSorting(columnIndex: number): {
        sortColumn: number | undefined;
        sortDirection: Clients.SortDirection;
    } | undefined {
        if (this.props.sorting) {
            if (this.props.sorting.sortColumn !== columnIndex) {
                return {
                    sortColumn: columnIndex,
                    sortDirection: Clients.SortDirection.Ascending,
                };
            } else {
                const sortDirection =
                    this.props.sorting.sortDirection === Clients.SortDirection.Descending
                        ? Clients.SortDirection.Ascending
                        : Clients.SortDirection.Descending;
                return {
                    sortColumn: this.props.sorting.sortColumn,
                    sortDirection: sortDirection
                }
            }
        }
    }

    handleSearch = (filter: string) => {
        this.setState({ filter })
    }

    clearFilter = () => {
        this.setState({ filter: '' })
    }

    columnNameChange = (column: any) => {
        const entity = this.props.sectionId
        if (column.id === "LastName" && (entity === "vendor" || entity === "brokers")) {
            return "Name"
        } else {
            return column.name
        }
    }

    render() {
        const schema = this.props.records.schema;
        let columns = (this.props.records.schema) ? this.props.records.schema!.columns : null;
        let entities = this.props.records.entities;

        let displayColumns: { column: DynamicEntitySchemaColumnModel, index: number }[] = [];
        let displayEntities: {
            index: number,
            value: any,
        }[][] = [];

        if (columns) {
            if (this.props.columns) {
                let indexes: number[] = [];

                this.props.columns.forEach(c => {
                    let index = columns!.findIndex(i => i.id === c);
                    let column = this.getSchemaColumn(index);

                    if (column) {
                        displayColumns.push({ column, index });
                        indexes.push(index);
                    }
                })

                displayEntities = entities!.map(x => indexes.map(i => ({ index: i, value: x.values![i] })))
            }
            else {
                columns.forEach((x, index) => displayColumns.push({ column: x, index: index }));
                displayEntities = entities!.map(x => x.values!.map((v, i) => ({ index: i, value: v })));
            }
        }
        return (
            <>
                <DataTable>
                    <thead>
                        <tr>
                            {schema && displayColumns &&
                                displayColumns
                                    .map(({ column, index }) => {
                                        return (
                                           <th
                                               key={`${column.name}${index}`}
                                               onClick={() => {
                                                   if (this.props.sorting) {
                                                       const { sortColumn, sortDirection } = this.toggleSorting(index)!;
                                                       this.props.sorting.onSort(sortColumn, sortDirection, index);
                                                   }
                                               }}
                                               className={this.getColumnClasses(index)}
                                           >
                                               {this.columnNameChange(column)}
                                               {this.props.sorting && < i className="fa fa-sort"></i>}
                                               </th>
                                        )
                                    })}
                            </tr>
                    </thead>
                    <tbody>
                        {displayEntities &&
                            displayEntities.map((values, i) => (
                                <DataRow key={`${i}`}
                                    id={i.toString()}>
                                    {values.map(({ value, index }) => (
                                        <DataCell key={`${index}${i}`} className={this.getColumnClasses(index)}>{this.renderValue(value, index)}</DataCell>
                                    ))}
                                </DataRow>
                            ))}
                    </tbody>
                </DataTable >
            </>
        )
    }
}
