import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown, DropdownItem, Input } from 'reactstrap';
import * as Client from '../../../../../client/ApiClient';
import { DataCell, DataRow, DataTable, DataTableButton } from '../../../../basic/DataTable';
import { RangePaginationState, RangePagination, paginate } from 'components/basic/Pagination';
import StageActionToolbar from '../generic/StageActionToolbar';
import { stagingPageUpdate, stagingPageSetPrimary, stagingPageCopyMappings, StagingPageCopyMappingsItem } from '../generic/Abstractions'
import { regex } from '../../Constants';

import '../../../../../styles/Icons.scss';
import './VendorStage.scss';
import { AssignRowStatus, RowStatus } from '../generic';
import DataListInput from '../../../../basic/DataListInput';

interface VendorStagingPageArgs {
    conversionId: string,
}

type VendorStagingPageProps = RouteComponentProps<VendorStagingPageArgs>

interface VendorStagingPageState {
    items: (Client.VendorModel
        & StagingPageCopyMappingsItem)[];
    destinationCodes: Client.VendorRefModel[];
    itemsByDestinationCodes: Client.VendorModel[];
    filter: string;
    sort: boolean;
    sortColumn: string;
    mapped: boolean;
    notMapped: boolean;
    dropped: boolean;
    modal: boolean;
    repeatedCode: string;
    canCancel: boolean;
    lastValue: string;
    primaryList: {
        destinationCode?: string,
        pItems?: Client.VendorModel[]
    }[];

    pagination: RangePaginationState;
    buttonLock: boolean;
    includeAddToMain: boolean;
}

export class VendorStagingPage extends React.Component<VendorStagingPageProps, VendorStagingPageState>{
    private readonly client: Client.VendorClient = new Client.VendorClient();
    private conversionClient: Client.ConversionClient = new Client.ConversionClient();

    private readonly copyMappings = stagingPageCopyMappings(this);

    constructor(props: any) {
        super(props);
        this.state = {
            items: [],
            destinationCodes: [],
            itemsByDestinationCodes: [],
            filter: '',
            sort: true,
            sortColumn: '',
            mapped: true,
            notMapped: true,
            dropped: true,
            modal: false,
            repeatedCode: '',
            lastValue: '',
            canCancel: false,
            primaryList: [],
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            buttonLock: false,
            includeAddToMain: false,
        };
    }

    componentDidMount() {
        this.getItems();
        this.UIController();

        this.client.getRef(this.props.match.params.conversionId)
            .then(response => {
                if (response != null) {
                    this.setState({
                        destinationCodes: response,
                    })
                }
            })
            .catch(error => { })
    }

    setDefaults = (entity: Client.VendorModel) => new Client.VendorModel({
        ...entity,
        destinationCode: entity.destinationCode || '',
        isCompany: entity.isCompany || false
    });

    getItems = () => {
        this.client.getAll(this.props.match.params.conversionId)
            .then((response) => {
                let items = response!;
                items = items
                    .map(this.setDefaults).
                    sort((a: any, b: any) => {
                        let x = a[this.state.sortColumn]
                        let y = b[this.state.sortColumn]
                        if (this.state.sortColumn === 'isPrimary') {
                            y = this.state.primaryList.some(p => p.destinationCode === a.destinationCode) && a.isPrimary && a.isMapped && !a.isDrop ? 3 : !a.isPrimary && a.isMapped && !a.isDrop ? 2 : 1;
                            x = this.state.primaryList.some(p => p.destinationCode === b.destinationCode) && b.isPrimary && b.isMapped && !b.isDrop ? 3 : !b.isPrimary && b.isMapped && !b.isDrop ? 2 : 1;
                        }
                        if (typeof y === "string") {
                            y = y.toLowerCase().trim()
                            x = x.toLowerCase().trim()
                        }
                        if (x === y) {
                            return 0;
                        }
                        else if (x === null) {
                            return 1;
                        }
                        else if (y === null) {
                            return -1;
                        }
                        else if (!this.state.sort) {
                            return x < y ? -1 : 1;
                        }
                        else {
                            return x < y ? 1 : -1;
                        }
                    })
                this.updatePrimaryList(items)

                this.setState({
                    items
                })
            })
            .catch(error => { })
    }

    handleCheckboxChange = (id: number, e: { target: { name: any, checked: any } }) => {
        const items = this.state.items.map(
            v => v.id === id
                ? new Client.VendorModel({
                    ...v,
                    [e.target.name]: e.target.checked,
                })
                : v
        )
        this.setState({ items });

        this.put(items.find(v => v.id === id)!)
    }

    handleRadioChange = (id: number) => {
        const items = this.state.items.map(
            x => x.id === id
                ? new Client.VendorModel({
                    ...x,
                    isCompany: !x.isCompany
                })
                : x)
        this.setState({ items });

        this.put(items.find(v => v.id === id)!)
    }

    handleChange = (e: any) => {
        const dc = e.target.value;
        const id = Number(e.target.name);

        const items = this.state.items;
        const i = this.state.items.findIndex(v => v.id === id);
        items[i].destinationCode = dc.toUpperCase().replace(regex.standard360, '');

        this.setState({ items }, () => {
            if (items[i].destinationCode !== this.state.lastValue && this.state.lastValue.includes(this.state.filter) && this.state.filter !== '') this.put(items[i])
        });
    }

    handleSearch = (filter: string) => {
        this.setState({ filter })
    }

    clearFilter() {
        this.setState({ filter: '' })
    }

    put(entity: Client.VendorModel) {
        entity.isPrimary = true;
        stagingPageUpdate(this, this.client)
            .withSource('Vendor', entity.sourceDescription!)
            .update(entity)
            .then(() => this.getItems())
            .catch(error => {
                const response = JSON.parse(error.response)
                console.log(response)
            })
    }

    updatePrimaryList(items: any[]) {
        let primaryList:
            {
                destinationCode?: string,
                pItems: Client.VendorModel[]
            }[] = [];

        items.forEach((item: Client.VendorModel) => {
            if (item.destinationCode) {
                let primaryObject = primaryList.find(x => x.destinationCode!.trim() === item.destinationCode!.trim());
                if (!primaryObject) {
                    primaryObject = { destinationCode: item.destinationCode, pItems: [] }
                    primaryList.push(primaryObject);
                }
                if (!item.isDrop) primaryObject.pItems.push(item);
            }
        });

        primaryList = primaryList.filter(x => x.pItems!.length > 1 && x.pItems.some(p => p.isPrimary));

        this.setState({ primaryList })
    }

    getByDestinationCodes = (destinationCode: string) => {
        this.client.getByDestinationCode(this.props.match.params.conversionId, destinationCode)
            .then(response => {
                if (response != null) {
                    this.setState({ itemsByDestinationCodes: response })
                }
            })
            .catch(error => { })
    }

    sort(column: string) {
        const items = this.state.items.sort((a: any, b: any) => {
            let x = a[column]
            let y = b[column]
            if (column === 'isPrimary') {
                y = this.state.primaryList.some(p => p.destinationCode === a.destinationCode) && a.isPrimary && a.isMapped && !a.isDrop ? 3 : !a.isPrimary && a.isMapped && !a.isDrop ? 2 : 1;
                x = this.state.primaryList.some(p => p.destinationCode === b.destinationCode) && b.isPrimary && b.isMapped && !b.isDrop ? 3 : !b.isPrimary && b.isMapped && !b.isDrop ? 2 : 1;
            }
            if (typeof y === "string") {
                y = y.toLowerCase().trim()
                x = x.toLowerCase().trim()
            }
            if (x === y) {
                return 0;
            }
            else if (x === null) {
                return 1;
            }
            else if (y === null) {
                return -1;
            }
            else if (this.state.sort) {
                return x < y ? -1 : 1;
            }
            else {
                return x < y ? 1 : -1;
            }
        })
        this.setState({ sort: !this.state.sort, sortColumn: column, items });
    }

    mappedCheck(check: string) {
        if (check === 'notMapped') {
            const notMapped = !this.state.notMapped
            this.setState({ notMapped })
        } else if (check === 'mapped') {
            const mapped = !this.state.mapped
            this.setState({ mapped })
        } else if (check === 'dropped') {
            const dropped = !this.state.dropped
            this.setState({ dropped })
        }
    }

    modalToggle = () => {
        const modal = !this.state.modal
        this.setState({ modal })
        if (modal) this.getByDestinationCodes(this.state.repeatedCode)
    }

    handleSelectDestination = (i: number) => {
        const itemsByDestinationCodes = this.state.itemsByDestinationCodes
        itemsByDestinationCodes.map((item, index) => {
            if (index === i) {
                item.isPrimary = true
            } else {
                item.isPrimary = false
            }
            return item
        })
        this.setState({ itemsByDestinationCodes })
    }

    putPrimary = () => {
        const codes = this.state.itemsByDestinationCodes
        let num = 0
        codes.forEach(c => c.isPrimary ? num += 1 : null)
        if (num === 1) {
            codes.forEach(code => code.isPrimary ? this.updatePrimary(code.id) : null)
            this.modalToggle()
        } else {
            alert('Please only have one Source Code set to primary')
        }
    }

    updatePrimary(id: number) {
        const item = this.state.itemsByDestinationCodes.find(item => item.id === id)!;
        stagingPageSetPrimary(this, this.client)
            .withSource(item.sourceCode!)
            .setPrimary(id)
            .catch(() => { });
    }

    primarySelectionModalShowIfHasCode = (repeatedCode: string | undefined, canCancel: boolean) => {
        if (!repeatedCode) {
            alert('Please specify a destination code in order to set a primary record.');
        } else {
            this.primarySelectionModalShow(repeatedCode, canCancel);
        }
    }

    primarySelectionModalShow = (repeatedCode: string, canCancel: boolean) => {
        this.setState({
            modal: true,
            canCancel: canCancel,
            repeatedCode,
        })
        this.getByDestinationCodes(repeatedCode)
    }

    primarySelectionModalHide = () => {
        this.setState({ modal: false })
    }

    lockButton = (lock: boolean) => {
        this.setState({ buttonLock: lock })
    }

    DestinationCodes = (): any[] => {
        const arr = [];
        const dc = this.state.destinationCodes;
        if (dc.length > 0) {
            for (let i = 0; i < dc.length; i++) {
                const name = dc[i].firstName === null ? dc[i].lastName : `${ dc[i].firstName } ${ dc[i].lastName }`
                arr.push(<option
                    key={i}
                    data-value={dc[i].sourceCode?.toUpperCase()}
                >
                    {`${dc[i].sourceCode!.trim()} - ${name}`}
                </option>)
            }
        } else {
            arr.push(<option data-value={'readOnly'}> No Vendors Available in Target</option>)

        }
        return arr;
    }

    handleChangeDropDown = (item: Client.VendorModel, e: any) => {
        const i = this.state.items.findIndex(v => v.id === item.id);
        const items = this.state.items.map(
            v => v.id === item.id
                ? new Client.VendorModel({
                    ...v,
                    destinationCode: e.value
                })
                : v
        )
        this.setState({ items });
        this.put(items[i])
    }

    UIController = () => {
        this.conversionClient.getUIController()
            .then((response) => {
                if (response) this.setState({ includeAddToMain: response!.addToMainEpic })
            })
            .catch(err => console.log(err))
    }

    showDropDown = (vendor: Client.VendorModel) => {
        const items = this.state.items.map(item => {
            return vendor.id === item.id
                ? new Client.VendorModel({
                    ...item,
                    isAddToMain: false,
                    destinationCode: '',
                })
                : item;
        })
        this.setState({ items })
    }

    addToMain = (vendor: Client.VendorModel) => {
        const items = this.state.items.map(item => {
            return vendor.id === item.id
                ? new Client.VendorModel({
                    ...item,
                    isAddToMain: true,
                    destinationCode: '',
                })
                : item;
        })
        this.setState({ items })
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;
        const items = this.state.items
            .map(AssignRowStatus)
            .filter(i => (i.sourceCode && i.sourceCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.sourceDescription && i.sourceDescription!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.destinationCode && i.destinationCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())))
            .filter(item =>
                (item.mapped === RowStatus.NotMapped && this.state.notMapped)
                || (item.mapped === RowStatus.Mapped && this.state.mapped)
                || (item.mapped === RowStatus.Dropped && this.state.dropped));

        return (
            <React.Fragment>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='vendor'
                    handleSearch={this.handleSearch}
                    primaryList={this.state.primaryList}
                    getItems={() => this.getItems()}
                    handleCopyMapping={this.copyMappings.submitMappings}
                    copyMap={true}
                    filter={this.state.filter}
                    lockButton={this.lockButton}
                />
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th onClick={() => this.sort('sourceCode')}>Source Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('sourceDescription')}>Source Description<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isDrop')} className='checkbox-column'>Exclude<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('destinationCode')}>Destination Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('isCompany')} className='checkbox-col'>VendorType<i className="fa fa-sort"></i></th>
                            {this.state.includeAddToMain && <th onClick={() => this.sort('isPrimary')} className='checkbox-column'>Primary<i className="fa fa-sort"></i></th>}
                            <th className='checkbox-column'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Mapped
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={!this.state.notMapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('notMapped')}>&#x274C; Not Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.mapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('mapped')}>&#x2705; Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.dropped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('dropped')}>&#x2796; Excluded</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginate(items, this.state.pagination, (item, i) =>
                                <DataRow id={'vendor' + i} key={i}>
                                    <DataCell>{item.sourceCode}</DataCell>
                                    <DataCell>{item.sourceDescription}</DataCell>
                                    <DataCell className='checkbox-column'>
                                        <input className='custom-checkbox' type='checkbox' name='isDrop' checked={item.isDrop} value={item.id} onChange={e => this.handleCheckboxChange(item.id, e)} disabled={this.state.buttonLock} />
                                    </DataCell>
                                    <DataCell className='addToMainContainer'>
                                        {this.state.includeAddToMain ?
                                            <>
                                                {
                                                    !item.isAddToMain ?
                                                        <div className={"addToMainEpic"}>
                                                            <DataListInput
                                                                id={`referenceId - ${i}`}
                                                                name={item.id.toString()}
                                                                value={item.destinationCode ? item.destinationCode.toUpperCase() : undefined}
                                                                onValueChange={event => this.handleChangeDropDown(item, event)}
                                                                disabled={this.state.buttonLock}
                                                            >
                                                                <option data-value='' />
                                                                {this.DestinationCodes()}
                                                            </DataListInput>
                                                        </div>
                                                        :
                                                        <Input className="addToMainEpic"
                                                            value={item.destinationCode}
                                                            name={item.id.toString()}
                                                            onChange={this.handleChange}
                                                            onFocus={(e) => (this.setState({ lastValue: e.target.value.trim() }))}
                                                            onBlur={() => { if (item.destinationCode !== this.state.lastValue) this.put(item) }}
                                                            maxLength={6}
                                                            autoComplete="off"
                                                            placeholder={"Add to Main - 6 max"}
                                                            disabled={this.state.buttonLock} />
                                                }
                                                {item.sourceCode !== 'DefaultVendor' &&
                                                    <DataTableButton
                                                    icon={item.isAddToMain ? 'list' : 'edit'}
                                                    id={"addToMain"}
                                                    title={item.isAddToMain ? 'Dropdown List' : 'Add To Main'}
                                                    action={() => item.isAddToMain ? this.showDropDown(item) : this.addToMain(item)}
                                                />}
                                            </>
                                            :
                                            <DataListInput
                                                id={`referenceId - ${i}`}
                                                name={item.id.toString()}
                                                value={item.destinationCode ? item.destinationCode.toUpperCase() : undefined}
                                                onValueChange={event => this.handleChangeDropDown(item, event)}
                                                disabled={this.state.buttonLock}
                                            >
                                                <option data-value='' />
                                                {this.DestinationCodes()}
                                            </DataListInput>
                                        }
                                    </DataCell>
                                    <DataCell className='custom-dataCell'>
                                        <label className='type-style-vendor'>
                                            <input className='custom-radioButton' name={`company${i}`} type='radio' checked={item.isCompany} value={item.id} onChange={e => this.handleRadioChange(item.id)} disabled={this.state.buttonLock} />
                                            Firm
                                        </label>
                                        <label className='type-style-vendor'>
                                            <input className='custom-radioButton' name={`company${i}`} type='radio' checked={!item.isCompany} value={item.id} onChange={e => this.handleRadioChange(item.id)} disabled={this.state.buttonLock} />
                                            Individual
                                        </label>
                                    </DataCell>
                                    {this.state.includeAddToMain && <DataCell className='checkbox-column' itemProp={this.state.primaryList.some(p => p.destinationCode!.toUpperCase() === item.destinationCode!.toUpperCase()) && !item.isDrop
                                        ? item.isPrimary && item.isMapped
                                            ? 'primary'
                                            : 'non-primary' : ''}>
                                        <DataTableButton
                                            id={item.id + 'primary'}
                                            action={() => this.primarySelectionModalShowIfHasCode(item.destinationCode, true)}
                                            disabled={this.state.buttonLock}
                                            title='Click to set a primary record'
                                        >
                                            {item.isAddToMain ?
                                                this.state.primaryList.some(p => p.destinationCode!.toUpperCase() === item.destinationCode!.toUpperCase()) && !item.isDrop
                                                    ? item.isPrimary && item.isMapped
                                                        ? <span className='mapped' itemProp='primary'>&#x2705;</span>
                                                        : <span className='mapped' itemProp='non-primary'>&#x274C;</span> : null : null}
                                        </DataTableButton>
                                    </DataCell>}
                                    <DataCell itemProp={item.mapped === RowStatus.NotMapped ? 'unmapped': item.mapped === RowStatus.Mapped ? 'mapped' : 'dropped'}>
                                        { item.mapped === RowStatus.NotMapped ? < p className='mapped' itemProp='unmapped'>&#x274C;</p> : item.mapped === RowStatus.Mapped ? < p className='mapped' itemProp='mapped'>&#x2705;</p> : < p className='mapped' itemProp='dropped'>&#x2796;</p>}
                                    </DataCell>
                                </DataRow >
                            )
                        }
                    </tbody >
                </DataTable >
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
                <div>
                    <Modal isOpen={this.state.modal}>
                        <ModalHeader>Please Set Primary of Destination: {this.state.repeatedCode}</ModalHeader>
                        <ModalBody>
                            <select
                                name='isPrimary'
                                className="custom-select"
                                onChange={(e) => this.handleSelectDestination(Number(e.target.value))}
                                id={this.state.repeatedCode.toString()}
                                key={this.state.repeatedCode}
                            >
                                {this.state.itemsByDestinationCodes.map((item, index) => (
                                    <option
                                        value={index}
                                        key={item.id}
                                        selected={item.isPrimary}
                                    >
                                        {item.sourceCode} - {item.sourceDescription}
                                    </option>
                                ))}
                            </select>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.canCancel && <Button color="secondary" onClick={() => this.primarySelectionModalHide()}>Close</Button>}
                            <Button color="primary" onClick={this.putPrimary}>Submit</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </React.Fragment >
        );
    }
}
