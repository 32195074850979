import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { sendNotification } from 'services/Notifications'
import { GenericClient } from '../../../../../client/ApiClient';

// -----------------------------------------------------------------------------
// Update entity on the staging page

type StagingPageUpdateProps = RouteComponentProps<{ conversionId: string }>;

type StagingPageUpdateState<V> = {
    // The items to refresh after the item is updated.
    items: V[]
}

type StagingPageUpdateClient<V, I> = {
    put(conversionId: string, id: I, value: V | null | undefined): Promise<any>
    get(conversionId: string, id: I): Promise<V | null>
}

export function stagingPageUpdate<V extends { id: I }, I>(
    page: React.Component<StagingPageUpdateProps, StagingPageUpdateState<V>> & {
        setDefaults(entity: V): V
    },
    client: StagingPageUpdateClient<V, I>) {
    return new class StagingPageUpdate {
        private sourceName: string | undefined;
        private sourceId: string | undefined;

        withSource = (name: string, id: string) => {
            [this.sourceName, this.sourceId] = [name, id];
            return this;
        }

        private refresh = (value: V) =>
            client
                .get(page.props.match.params.conversionId, value.id)
                .then(x => {
                    page.setState(prev => ({
                        items: prev.items.map(v => (v.id === value.id ? page.setDefaults(x!) : v))
                    }))
                    return x!
                })

        update = (value: V): Promise<V> => {
            const [sourceName, sourceId] = [this.sourceName, this.sourceId];
            return client
                .put(page.props.match.params.conversionId, value.id, value)
                .then(() => this.refresh(value))
                .then(x => {
                    sendNotification('success', `${this.sourceName} ${this.sourceId} was saved`);
                    return x
                })
                .catch(error => {
                    return this.refresh(value)
                        .then(() => { throw error })
                })
        }
    }()
}

// -----------------------------------------------------------------------------
// Set primary action on the staging page

type StagingPageSetPrimaryProps = RouteComponentProps<{ conversionId: string }>;

type StagingPageSetPrimaryState<V> = {
    // The items to refresh after the item is updated.
    items: V[],
    itemsByDestinationCodes?: V[],
    itemsByReferenceId?: V[],
    repeatedCode: string,
}

type StagingPageSetPrimaryClient<V, I> = {
    updatePrimary(conversionId: string, destinationCode: string | null, id: I, rows: number): Promise<void>
    getAll(conversionId: string, filter?: string | null): Promise<V[] | null>
}

export function stagingPageSetPrimary<V extends {
    id: I,
    isPrimary?: boolean,
}, I>(
    page: React.Component<StagingPageSetPrimaryProps, StagingPageSetPrimaryState<V>> & {
        setDefaults(entity: V): V
    },
    client: StagingPageSetPrimaryClient<V, I>) {
    return new class StagingPageSetPrimary {
        private sourceId: string | undefined;

        withSource = (id: string) => {
            this.sourceId = id;
            return this;
        }

        private refresh = (id: I) => {
            return client
                .getAll(page.props.match.params.conversionId, '')
                .then(items => {
                    page.setState({
                        items: items!.map(page.setDefaults),
                    })
                    return page.state.items.find(v => v.id === id)!;
                })
        }

        setPrimary = (id: I): Promise<V> => {
            const item = page.state.itemsByDestinationCodes!.find(item => item.id === id);
            const rows = page.state.itemsByDestinationCodes!.length;
            const sourceId = this.sourceId;
            return client
                .updatePrimary(page.props.match.params.conversionId, page.state.repeatedCode, id, rows)
                .then(() => this.refresh(id))
                .then(x => {
                    if (this.sourceId) {
                        const primary = item!.isPrimary ? 'primary' : 'not the primary'
                        sendNotification('success', `${sourceId} is set to ${primary}`);
                    }
                    return x
                })
                .catch(error => {
                    return this.refresh(id)
                        .then(() => { throw error })
                })
        }
    }()
}

// -----------------------------------------------------------------------------
// Copy mappings

type StagingPageCopyMappingsProps = RouteComponentProps<{ conversionId: string }>;

type StagingPageCopyMappingsState<V> = {
    items: V[]
}

export type StagingPageCopyMappingsItem = {
    copyMappings?: boolean,
}

export function stagingPageCopyMappings<V extends {
    id: I,
} & StagingPageCopyMappingsItem, I extends {
    toString(): string // Cover both string and integer IDs
}>(
    page: React.Component<StagingPageCopyMappingsProps, StagingPageCopyMappingsState<V>>) {
    return new class StagingPageCopyMappings {
        private readonly client = new GenericClient();

        public toggleCopyMappings = (id: I) => {
            page.setState(prev => ({
                items: prev.items.map(current => {
                    return current.id === id
                        ? Object.assign({}, current, {
                            copyMappings: !current.copyMappings
                        })
                        : current;
                })
            }))
        }

        public submitMappings = (entityId: string, currentUser: any) => {
            this.client.copyMappings(
                page.props.match.params.conversionId,
                currentUser.id,
                entityId)
                .catch(() => page.setState(prev => ({
                    items: prev.items
                        .map(current => Object.assign({}, current, {
                            copyMappings: false
                        }))
                })));
        }
    }()
}
