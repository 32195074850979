import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import StageActionToolbar from '../generic/StageActionToolbar';

import '../../../../../styles/Icons.scss';

interface ClientStagingPageArgs {
    conversionId: string,
}

type ClientStagingPageProps = RouteComponentProps<ClientStagingPageArgs>;

interface ClientStagingPageState {

}

export class ClientStagingPage extends React.Component<ClientStagingPageProps, ClientStagingPageState>{

    componentDidMount() {

    }



    public render() {
        const conversionId = this.props.match.params.conversionId;

        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='clients'
                    tierGate='tier2'
                    noMapping={true}
                    filter={''}
                />
            </>
        );
    }
}