import { DataCell, DataRow, DataTable, DataTableButton } from 'components/basic/DataTable';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import Toolbar from '../basic/Toolbar';
import {
    ConversionOptionValueModel,
    ConversionOptionsClient,
    CustomersMiniConversionValidationResultsClient,
    CustomersMiniConversionValidationResultsModel,
    ExecutionsClient,
    ExecutionsModel,
    GenericClient,
    IUserLoginDetailsModel,
    PoliciesMiniConversionValidationResultsClient,
    PoliciesMiniConversionValidationResultsModel,
    ConversionReportsClient,
} from '../../client/ApiClient';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { store } from "AppContext"
import { enqueueNotification } from "../../store/Notifications";
import CloseConfirmationModal from '../basic/modals/CloseConfirmationModal';
import * as MiniConstants from 'constants/MiniConversion';

import '../../styles/Forms.scss';

interface IConversionMiniDashPageProps extends RouteComponentProps<{
    conversionId: string
}> {
    currentUser: IUserLoginDetailsModel | undefined;
}

interface IConversionMiniDashPageState {
    options: { [key: string]: ConversionOptionValueModel } | undefined;
    custValResults: CustomersMiniConversionValidationResultsModel[];
    polValResults: PoliciesMiniConversionValidationResultsModel[];
    hubConnection: HubConnection | null;
    executions: ExecutionsModel[];
    buttonLock: boolean;
    timeout: any;
    validationRunning: boolean;
    submitRunning: boolean;
    validationFailureModal: boolean,
    validationSuccessModal: boolean,
    submitSuccessModal: boolean,
}

class ConversionMiniDashPage extends React.Component<IConversionMiniDashPageProps, IConversionMiniDashPageState>{
    private readonly conversionGenericClient: GenericClient = new GenericClient();
    private options: ConversionOptionsClient = new ConversionOptionsClient();
    private executions: ExecutionsClient = new ExecutionsClient();
    private clientDescription: string = '';
    private conversionId: string = '';
    private conversionDescription: string = '';
    private custFileSelected: boolean = false;
    private custFileUploaded: boolean = false;
    private custFileValidated: boolean = false;
    private custFileQueued: boolean = false;
    private execDescription: string = '';
    private execTitle: string = '';
    private execUserId: string = '';
    private polFileSelected: boolean = false;
    private polFileUploaded: boolean = false;
    private polFileValidated: boolean = false;
    private polFileQueued: boolean = false;
    private report: ConversionReportsClient = new ConversionReportsClient();

    constructor(props: Readonly<IConversionMiniDashPageProps>) {
        super(props)
        this.state = {
            options: undefined,
            custValResults: [],
            polValResults: [],
            hubConnection: null,
            executions: [],
            buttonLock: false,
            timeout: null,
            validationRunning: false,
            submitRunning: false,
            validationFailureModal: false,
            validationSuccessModal: false,
            submitSuccessModal: false,
        }
    }
    exportToExcel = (id: string) => {
        this.report.exportToExcel(this.props.match.params.conversionId, id, null, null)
            .then(response => saveAs(response!.data, response!.fileName))
            .catch(error => { });
    }
    componentDidMount() {
        this.getOptions();
        this.getExecutions()
        const link = `${process.env.PUBLIC_URL}/executions`
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :('));
            this.state.hubConnection!.on('ExecutionUpdated', (execution: ExecutionsModel) => {
                let done: boolean = false;
                let pkgError: boolean = false;
                this.clientDescription = execution.clientDescription!;
                this.conversionDescription = execution.conversionDescription!;
                this.execDescription = execution.description!;
                this.execTitle = execution.title!;
                this.execUserId = execution.userId;
                if ((this.execDescription === "Succeeded" || this.execDescription === "Completed") && this.execUserId === this.props.currentUser!.id) {
                    done = true;
                }
                else if ((this.execDescription === "Failed" || this.execDescription === "Canceled" || this.execDescription === "Ended Unexpectedly" || this.execDescription === "Stopping") && this.execUserId === this.props.currentUser!.id) {
                    done = true;
                    pkgError = true;
                    store.dispatch(enqueueNotification({ type: 'error', message: this.clientDescription + ' - ' + this.conversionDescription + ' - ' + this.execTitle + ' - ' + this.execDescription }))
                }
                this.getExecutions()
                clearInterval(this.state.timeout);
                if (done && this.state.validationRunning) {
                    if (pkgError) {
                        this.setState({ validationRunning: false });
                        this.buttonLock();
                    } else {
                        this.getValidationResults(execution);
                    }
                }
                if (done && this.state.submitRunning) {
                    this.setState({ submitRunning: false });
                    this.openSubmitSuccessModal();
                }
            });
            this.state.hubConnection!.on('ExecutionCalled', (call: string) => {
                this.getExecutions();
            });
        })
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
    }

    buttonLock = () => {
        this.executions.listExecutionsAllTierBoolean(this.conversionId)
            .then((response) => {
                if (response) this.setState({ buttonLock: response.isLocked })
            })
            .catch(error => { })
    }

    getExecutions() {
        this.executions!.listExecutions(this.conversionId)
            .then(results => {
                this.setState({ executions: results! }, () => {
                    this.buttonLock();
                })
            })
            .catch(e => {
                console.log(e)
            })
    }

    getOptions = () => {
        this.options.get(this.conversionId)
            .then(values => {
                const options = values!.reduce(
                    (result, current) => ({ ...result, [current.id!]: current, }), {});
                this.setState({ options });
            })
            .catch(e => {
                console.log(e)
            })
    }

    getValidationResults = (exec: ExecutionsModel) => {
        const custValResults: CustomersMiniConversionValidationResultsClient = new CustomersMiniConversionValidationResultsClient();
        const polValResults: PoliciesMiniConversionValidationResultsClient = new PoliciesMiniConversionValidationResultsClient();
        custValResults.getResults(this.conversionId)
            .then(results => {
                this.setState({
                    custValResults: results!
                }, () => {
                    if (this.state.custValResults.length > 0) {
                        if (this.state.custValResults[0].errorCount === 0) {
                            this.setOption(MiniConstants.conversionOption_CustFileValidatedId, '1');
                        }
                    }
                })
            })
            .catch(error => { console.log('Error in getValidationResults (custValResults): ' + error); });
        polValResults.getResults(this.conversionId)
            .then(results => {
                this.setState({
                    polValResults: results!
                }, () => {
                    if (this.state.polValResults.length > 0) {
                        if (this.state.polValResults[0].errorCount === 0) {
                            this.setOption(MiniConstants.conversionOption_PolFileValidatedId, '1');
                        }
                    }
                })
            })
            .catch(error => { console.log('Error in getValidationResults (polValResults): ' + error); });
    }

    openSubmitSuccessModal = () => {
        this.setOption(MiniConstants.conversionOption_CustFileQueuedId, '1');
        this.setOption(MiniConstants.conversionOption_PolFileQueuedId, '1');
        this.setState({ submitSuccessModal: true })
    }

    openValidationFailureModal = () => {
        this.setState({ validationRunning: false, validationFailureModal: true })
    }

    openValidationSuccessModal = () => {
        this.setState({ validationRunning: false, validationSuccessModal: true })
    }

    private releaseForSubmit = () => {
        this.removeValidationSuccessModal();
    }

    refreshPage = (landingTab: string) => {
        window.location.reload();
        this.props.history.push({
            pathname: window.location.href.split('/').pop(),
            state: { tabName: landingTab }
        });
    }

    removeSubmitSuccessModal = () => {
        this.buttonLock();
        this.setState({ submitSuccessModal: false });
        this.refreshPage('minidash');
    }

    gotoReports = () => {
        this.buttonLock();
        this.setState({ validationFailureModal: false });
        this.refreshPage('reports');
    }

    removeValidationFailureModal = () => {
        this.buttonLock();
        this.setState({ validationFailureModal: false });
    }

    removeValidationSuccessModal = () => {
        this.buttonLock();
        this.setState({ validationSuccessModal: false });
        this.refreshPage('minidash');
    }

    setOption = (optionId: string, optionValue: string) => {
        const optionsClient: ConversionOptionsClient = new ConversionOptionsClient();
        const optionModel: ConversionOptionValueModel[] = [];
        const valuesArray: any = {
            conversionId: this.conversionId,
            value: optionValue,
            isLocked: false,
            id: optionId,
        };
        optionModel.push(valuesArray);
        optionsClient.update(this.conversionId, optionModel);
        this.getOptions();
    }

    private submitMiniConversion = () => {
        const user = this.props.currentUser;
        if (this.conversionId && user) {
            this.conversionGenericClient.runMiniSubmitAction(this.conversionId, user.id!)
                .catch((e) => { console.log(e); });
            this.openSubmitSuccessModal();
        }
    }

    private validateRawData = () => {
        clearInterval(this.state.timeout);
        this.setState({
            timeout: setInterval(() => {
                this.setState({ buttonLock: true });
                this.setState({ validationRunning: true });
            }, 2000)
        })
        const user = this.props.currentUser;
        if (this.conversionId && user) {
            this.conversionGenericClient.runMiniValidationAction(this.conversionId, user.id!)
                .catch((e) => { console.log(e); });
        }
    }

    public render() {
        this.conversionId = this.props.match.params.conversionId;

        if (this.state.options) {
            for (const id of Object.keys(this.state.options)) {
                const value = this.state.options[id].value;
                switch (id.toUpperCase()) {
                    case MiniConstants.conversionOption_CustFileSelectedId: {
                        this.custFileSelected = (value === '1');
                        break;
                    }
                    case MiniConstants.conversionOption_CustFileUploadedId: {
                        this.custFileUploaded = (value === '1');
                        break;
                    }
                    case MiniConstants.conversionOption_CustFileValidatedId: {
                        this.custFileValidated = (value === '1');
                        break;
                    }
                    case MiniConstants.conversionOption_CustFileQueuedId: {
                        this.custFileQueued = (value === '1');
                        break;
                    }
                    case MiniConstants.conversionOption_PolFileSelectedId: {
                        this.polFileSelected = (value === '1');
                        break;
                    }
                    case MiniConstants.conversionOption_PolFileUploadedId: {
                        this.polFileUploaded = (value === '1');
                        break;
                    }
                    case MiniConstants.conversionOption_PolFileValidatedId: {
                        this.polFileValidated = (value === '1');
                        break;
                    }
                    case MiniConstants.conversionOption_PolFileQueuedId: {
                        this.polFileQueued = (value === '1');
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        }

        if (this.state.validationRunning) {
            if (this.custFileUploaded && this.state.custValResults.length > 0 && this.state.custValResults[0].errorCount === 0 &&
                (!this.polFileUploaded || (this.polFileUploaded && this.state.polValResults.length > 0 && this.state.polValResults[0].errorCount === 0))) {
                store.dispatch(enqueueNotification({
                    type: 'success',
                    message: this.clientDescription + ' - ' + this.conversionDescription + ' - ' + this.execTitle + ' - ' + this.execDescription
                }))
                this.openValidationSuccessModal();
            }
            if ((this.custFileUploaded && this.state.custValResults.length > 0 && this.state.custValResults[0].errorCount > 0) ||
                (this.polFileUploaded && this.state.polValResults.length > 0 && this.state.polValResults[0].errorCount > 0)) {
                store.dispatch(enqueueNotification({
                    type: 'error',
                    message: this.clientDescription + ' - ' + this.conversionDescription + ' - ' + this.execTitle + ' - Completed with Errors'
                }))
                this.openValidationFailureModal();
            }
        }

        return (
            <>
                <div><p /></div>
                <h3 className='mapping-body-header'>Project Status</h3>
                <Toolbar>
                    {
                        (this.state.validationRunning || this.state.submitRunning) &&
                        <img className='loading-indicator' src={'loader.gif'} />
                    }
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Button
                        className='submit-button-orange'
                        disabled={!this.custFileUploaded || (this.polFileSelected && !this.polFileUploaded) || this.custFileQueued || this.state.buttonLock}
                        onClick={() => this.validateRawData()}>Validate</Button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Button
                        className='submit-button-orange'
                        disabled={!this.custFileValidated || (this.polFileSelected && !this.polFileValidated) || this.custFileQueued || this.state.buttonLock}
                        onClick={() => this.submitMiniConversion()}>Submit</Button>
                </Toolbar>
                <DataTable>
                    <thead>
                        <tr>
                            <th>Name</th>
                            {!this.state.buttonLock &&
                                <th className='controls-column'></th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <DataRow id='Customers' key='Customers'>
                            <DataCell>Customers Upload File</DataCell>
                            {!this.state.buttonLock && this.custFileSelected &&
                                <DataCell>
                                    {!this.custFileUploaded &&
                                        <DataTableButton icon='file_upload' id='upload' title='Upload Pending' />
                                    }
                                    {this.custFileUploaded &&
                                        <DataTableButton icon='done' id='upload' title='Upload Completed' />
                                    }
                                    {!this.custFileValidated &&
                                        <DataTableButton icon='settings' id='validate' title='Validation Pending' />
                                    }
                                    {this.custFileValidated &&
                                        <DataTableButton icon='done' id='validate' title='Validation Completed' />
                                    }
                                    {!this.custFileValidated &&
                                        <DataTableButton icon='visibility' id='reports' title='Reports Pending Validation' />
                                    }
                                    {this.custFileValidated &&
                                        <DataTableButton icon='visibility' id='reports' title='View Validation Reports' onClick={() => this.refreshPage('reports')} />
                                    }
                                    {!this.custFileQueued &&
                                        <DataTableButton icon='thumb_up' id='queue' title='Final Acceptance Pending' />
                                    }
                                    {this.custFileQueued &&
                                        <DataTableButton icon='done' id='queue' title='Queued for Processing' />
                                    }
                                    {this.custFileUploaded &&
                                        < DataTableButton icon='file_download' id='CustomerExportMini' title='Export Customer Upload File' action={() => this.exportToExcel('CustomerMiniUploadFileExport')} />
                                    }
                                </DataCell>
                            }
                        </DataRow>
                        <DataRow id='Policies' key='Policies'>
                            <DataCell>Policies Upload File</DataCell>
                            {!this.state.buttonLock && this.polFileSelected &&
                                <DataCell>
                                    {!this.polFileUploaded &&
                                        <DataTableButton icon='file_upload' id='upload' title='Upload Pending' />
                                    }
                                    {this.polFileUploaded &&
                                        <DataTableButton icon='done' id='upload' title='Upload Completed' />
                                    }
                                    {!this.polFileValidated &&
                                        <DataTableButton icon='settings' id='validate' title='Validation Pending' />
                                    }
                                    {this.polFileValidated &&
                                        <DataTableButton icon='done' id='validate' title='Validation Completed' />
                                    }
                                    {!this.polFileValidated &&
                                        <DataTableButton icon='visibility' id='reports' title='Reports Pending Validation' />
                                    }
                                    {this.polFileValidated &&
                                        <DataTableButton icon='visibility' id='reports' title='View Validation Reports' onClick={() => this.refreshPage('reports')} />
                                    }
                                    {!this.polFileQueued &&
                                        <DataTableButton icon='thumb_up' id='queue' title='Final Acceptance Pending' />
                                    }
                                    {this.polFileQueued &&
                                        <DataTableButton icon='done' id='queue' title='Queued for Processing' />
                                    }
                                    {this.polFileUploaded &&
                                        < DataTableButton icon='file_download' id='PolicyExportMini' title='Export Policy Upload File' action={() => this.exportToExcel('PolicyMiniUploadFileExport')} />
                                    }
                                </DataCell>
                            }
                        </DataRow>
                    </tbody>
                </DataTable>
                <CloseConfirmationModal
                    isOpen={this.state.validationFailureModal}
                    title={'File Validation Completed'}
                    message={'Validation has completed with errors. Would you like to review the validation reports for details?'}
                    onSubmit={this.gotoReports}
                    onClose={this.removeValidationFailureModal}
                />
                <CloseConfirmationModal
                    isOpen={this.state.validationSuccessModal}
                    title={'File Validation Completed'}
                    message={'All data has been successfully validated. You may now submit your project for migration to the production environment.'}
                    onSubmit={this.releaseForSubmit}
                    onClose={this.removeValidationSuccessModal}
                />
                <CloseConfirmationModal
                    isOpen={this.state.submitSuccessModal}
                    title={'Project Submitted'}
                    message={'Your project has been successfully submitted for migration to the production environment.'}
                    onSubmit={this.removeSubmitSuccessModal}
                    onClose={this.removeSubmitSuccessModal}
                />
            </>
        )
    }
}

export default withRouter(ConversionMiniDashPage)
