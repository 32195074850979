import * as React from 'react';
import * as Clients from '../../client/ApiClient';
import { authentication } from '../../services/Authentication';
import { DataTable, DataRow, DataCell, DataTableButton } from '../basic/DataTable';
import Toolbar from '../basic/Toolbar';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Input, Button } from 'reactstrap';
import { RangePagination, RangePaginationState, paginate } from '../basic/Pagination';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { enqueueNotification } from "../../store/Notifications";
import { store } from "../../AppContext";
import CloseConfirmationModal from '../basic/modals/CloseConfirmationModal';
import ConfirmationModal from '../basic/modals/ConfirmationModal';
import * as FileTypes from '../../constants/FileUploadType';
import * as MiniConstants from '../../constants/MiniConversion';
import './Mini.scss';

interface ICustomersPageState {
    customers: Clients.CustomersMiniConversionModel[];
    options: { [key: string]: Clients.ConversionOptionValueModel } | undefined;
    selectedFile: any;
    pagination: RangePaginationState;
    hubConnection: HubConnection | null;
    hubConnected: boolean;
    running: boolean;
    buttonLock: boolean;
    closeConfirmationModal: boolean,
    confirmationModal: boolean,
    errMessage: string,
}
interface CustomersArgs {
    conversionId: string,
}

type CustomersProps = RouteComponentProps<CustomersArgs>;

class CustomersPage extends React.Component<CustomersProps, ICustomersPageState>{
    private readonly clientCustomers: Clients.CustomersMiniConversionClient = new Clients.CustomersMiniConversionClient();
    private options: Clients.ConversionOptionsClient = new Clients.ConversionOptionsClient();
    private executions: Clients.ExecutionsClient = new Clients.ExecutionsClient();
    private conversionId: string = '';
    private custFileQueued: boolean = false;
    private custFileUploaded: boolean = false;

    constructor(props: Readonly<CustomersProps>) {
        super(props)
        this.state = {
            customers: [],
            options: undefined,
            selectedFile: null,
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            hubConnection: null,
            hubConnected: false,
            running: false,
            buttonLock: true,
            closeConfirmationModal: false,
            confirmationModal: false,
            errMessage: '',
        }
    }

    componentDidMount() {
        this.conversionId = this.props.match.params.conversionId;
        this.getOptions();
        this.buttonLock();

        const link = `${process.env.PUBLIC_URL}/stagesignal`
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => this.setState({ hubConnected: true }))
                .catch(err => console.log('Error while establishing connection :('));
            this.state.hubConnection!.on('CustomersComplete', (done: string) => {
                this.setState({ running: false })
                this.refreshList(true);
            });
            let reload = 0;
            this.state.hubConnection!.on('CustomersRunning', (running: string) => {
                reload += 1;
                if (reload === 15 || reload % 300 === 0) {
                    this.refreshList(false);
                }
                if (!this.state.running) {
                    this.setState({ running: true })
                }
            });
            this.state.hubConnection!.on('CustomersError', (done: string) => {
                this.popErrMessage("An error occurred. File cannot be uploaded.");
            });
            this.state.hubConnection!.on('CustomersFail', (done: string) => {
                this.popErrMessage("File is in incorrect orientation. Please see file example.");
            });
            this.state.hubConnection!.on('CustomersFirmNameFail', (done: string) => {
                this.popErrMessage("Firm Name and Last Name missing from one or more rows. File cannot be uploaded.");
            });
            this.state.hubConnection!.on('CustomersRefNumFail', (done: string) => {
                this.popErrMessage("Customer Ref # missing from one or more rows. File cannot be uploaded.");
            });
            this.state.hubConnection!.on('CustomersWorkbookProtected', (done: string) => {
                this.popErrMessage("Customers file has restricted access, is password protected or is not an Excel document. File cannot be uploaded.");
            });
            if (this.state.hubConnected && this.state.hubConnection!.state == HubConnectionState.Disconnected) {
                store.dispatch(enqueueNotification({ type: 'error', message: "User session has been disconnected." }));
                authentication.logout()
                    .then(() => this.props.history.push('/'))
                    .catch(error => { }
            )};
        })
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
    }

    refreshList = (done: boolean) => {
        this.clientCustomers.getAll(this.conversionId)
            .then(response => {
                this.setState({
                    customers: response!
                }, () => {
                    if (done) {
                        if (this.state.customers.length > 0) {
                            store.dispatch(enqueueNotification({ type: 'success', message: "Complete" }))
                            this.setOption(MiniConstants.conversionOption_CustFileUploadedId, '1');
                            this.openCloseConfirmationModal();
                        } else {
                            this.setOption(MiniConstants.conversionOption_CustFileUploadedId, '0');
                        }
                    }
                })
            })
            .catch(error => { console.log('Error loading Customers'); });
    }

    getOptions = () => {
        this.options.get(this.conversionId)
            .then(values => {
                const options = values!.reduce(
                    (result, current) => ({ ...result, [current.id!]: current, }), {});
                this.setState({ options });
            })
            .catch(e => {
                console.log(e)
            })
    }

    handleFileInput = (event: React.ChangeEvent) => {
        let selectedFile = (event.target as HTMLInputElement).files![0];
        if (selectedFile.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            (event.target as HTMLInputElement).value = '';
            store.dispatch(enqueueNotification({ type: 'error', message: "Please upload Excel files (.xlsx) only." }));
        }
        else {
            console.log("selected file changed");
            this.setState({ selectedFile });
        }
    };

    onFileUpload = () => {
        if (this.state.selectedFile === null) {
            store.dispatch(enqueueNotification({ type: 'error', message: "Please upload file." }))
        } else {
            if (this.custFileUploaded) {
                this.setOption(MiniConstants.conversionOption_CustFileUploadedId, '0');
                this.setOption(MiniConstants.conversionOption_CustFileValidatedId, '0');
            }
            this.setState({ running: true });
            const formData = new FormData();
            const postUrl = '/api/v1/' + this.conversionId + '/fileUpload';

            formData.append("contentType", this.state.selectedFile!.type);
            formData.append("data", this.state.selectedFile!);
            formData.append("fileUploadTypeId", FileTypes.miniConversion_Customers);
            formData.append("name", this.state.selectedFile!.name);

            fetch(postUrl, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .catch(error => {
                    console.log(error)
                })
        }
    }

    buttonLock = () => {
        this.executions.listExecutionsAllTierBoolean(this.conversionId)
            .then((response) => {
                if (response) {
                    this.setState({ buttonLock: response.isLocked })
                }
            })
            .catch(error => { })
    }

    openConfirmationModal = () => {
        this.setState({ confirmationModal: true })
    }

    openCloseConfirmationModal = () => {
        this.setState({ closeConfirmationModal: true })
    }

    refreshPage = (landingTab: string) => {
        window.location.reload();
        this.props.history.push({
            pathname: window.location.href.split('/').pop(),
            state: { tabName: landingTab }
        });
    }

    private gotoFileUploads = () => {
        this.setState({ closeConfirmationModal: false, confirmationModal: false });
        this.refreshPage('additionaldata');
    }

    private gotoMiniDashboard = () => {
        this.setState({ closeConfirmationModal: false, confirmationModal: false });
        this.refreshPage('minidash');
    }

    removeConfirmationModal = () => {
        this.setState({ closeConfirmationModal: false, confirmationModal: false });
    }

    setOption = (optionId: string, optionValue: string) => {
        const optionsClient: Clients.ConversionOptionsClient = new Clients.ConversionOptionsClient();
        const optionModel: Clients.ConversionOptionValueModel[] = [];
        const valuesArray: any = {
            conversionId: this.conversionId,
            value: optionValue,
            isLocked: false,
            id: optionId,
        };
        optionModel.push(valuesArray);
        optionsClient.update(this.conversionId, optionModel);
    }

    private popErrMessage = (message: string) => {
        this.setState({ running: false });
        if (this.state.customers.length > 0) { this.refreshList(true); }
        this.setState({ errMessage: message });
        this.openConfirmationModal();
    }


    public render() {
        if (this.state.options) {
            // Retrieve Conversion Options for various state updates.
            for (const id of Object.keys(this.state.options)) {
                const value = this.state.options[id].value;
                switch (id.toUpperCase()) {
                    case MiniConstants.conversionOption_CustFileUploadedId: {
                        this.custFileUploaded = (value === '1');
                        if (this.custFileUploaded && (!this.state.customers || this.state.customers.length === 0)) {
                            this.refreshList(false);
                        }
                        break;
                    }
                    case MiniConstants.conversionOption_CustFileQueuedId: {
                        this.custFileQueued = (value === '1');
                        break;
                    }
                    default: {
                        break;
                    }
                }

            }
        }

        return (
            <>
                <Toolbar>
                    <DataTableButton
                        icon='file_download'
                        id={`customersExample`}
                        title="Customers Excel Example"
                        value="Download"
                        onClick={() => { window.open("/CustomersUploadFileTemplate.xlsx") }} />
                    <Input
                        type="file"
                        name="file"
                        id="exampleFile"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        disabled={this.state.buttonLock || this.custFileQueued}
                        onChange={e => this.handleFileInput(e)}
                    />
                    {this.state.running ?
                        < img className='loading-indicator' src={'loader.gif'} /> :
                        <Button
                            className='submit-button-orange'
                            disabled={this.state.buttonLock || this.custFileQueued}
                            onClick={this.onFileUpload}>Upload
                        </Button>}
                </Toolbar>
                <DataTable>
                    <thead>
                        <tr>
                            <th className="number-column mini-column-mini">#</th>
                            <th className="mini-column-med">Customer Ref #</th>
                            <th className="mini-column">Firm Name</th>
                            <th className="mini-column-small">Last Name</th>
                            <th className="mini-column-small">First Name</th>
                            <th className="mini-column-small">Middle Name</th>
                            <th className="mini-column-large">Email</th>
                            <th className="mini-column">Address1</th>
                            <th className="mini-column">Address2</th>
                            <th className="mini-column-small">City</th>
                            <th className="mini-column-small">State</th>
                            <th className="mini-column-med">Zip Code</th>
                            <th className="mini-column">DOB</th>
                            <th className="mini-column">Division</th>
                            <th className="mini-column">Branch</th>
                            <th className="mini-column">Department</th>
                            <th className="mini-column">Group</th>
                            <th className="mini-column">Exec</th>
                            <th className="mini-column">Rep</th>
                            <th className="mini-column">Broker</th>
                            <th className="mini-column">Res Phone</th>
                            <th className="mini-column">Bus Phone</th>
                            <th className="mini-column-small">SIC</th>
                            <th className="mini-column-small">NAICS</th>
                            <th className="mini-column">Formal Sal</th>
                            <th className="mini-column">Informal Sal</th>
                            <th className="mini-column-mid">Personal</th>
                            <th className="mini-column-mid">Commercial</th>
                            <th className="mini-column-mid">Financial</th>
                            <th className="mini-column-mid">Health</th>
                            <th className="mini-column-mid">Life</th>
                            <th className="mini-column-med">Non P & C</th>
                            <th className="mini-column-mid">Benefits</th>
                        </tr>
                    </thead>
                    <tbody className="nonEntity">
                        {paginate(this.state.customers, this.state.pagination, (customers, i) => (
                            <DataRow key={customers.customerRefNum}
                                id={`${customers.customerRefNum}`}
                            >
                                <DataCell className="number-column mini-column-mini">{customers.rowNumber}</DataCell>
                                <DataCell className="mini-column-med">{customers.customerRefNum}</DataCell>
                                <DataCell className="mini-column">{customers.firmName}</DataCell>
                                <DataCell className="mini-column-small">{customers.lastName}</DataCell>
                                <DataCell className="mini-column-small">{customers.firstName}</DataCell>
                                <DataCell className="mini-column-small">{customers.middleName}</DataCell>
                                <DataCell className="mini-column-large">{customers.email}</DataCell>
                                <DataCell className="mini-column">{customers.address1}</DataCell>
                                <DataCell className="mini-column">{customers.address2}</DataCell>
                                <DataCell className="mini-column-small">{customers.city}</DataCell>
                                <DataCell className="mini-column-small">{customers.state}</DataCell>
                                <DataCell className="mini-column-med">{customers.zipCode}</DataCell>
                                <DataCell className="mini-column">{customers.dob}</DataCell>
                                <DataCell className="mini-column">{customers.division}</DataCell>
                                <DataCell className="mini-column">{customers.branch}</DataCell>
                                <DataCell className="mini-column">{customers.department}</DataCell>
                                <DataCell className="mini-column">{customers.group}</DataCell>
                                <DataCell className="mini-column">{customers.exec}</DataCell>
                                <DataCell className="mini-column">{customers.rep}</DataCell>
                                <DataCell className="mini-column">{customers.broker}</DataCell>
                                <DataCell className="mini-column">{customers.resPhone}</DataCell>
                                <DataCell className="mini-column">{customers.busPhone}</DataCell>
                                <DataCell className="mini-column-small">{customers.sic}</DataCell>
                                <DataCell className="mini-column-small">{customers.naics}</DataCell>
                                <DataCell className="mini-column">{customers.formalSal}</DataCell>
                                <DataCell className="mini-column">{customers.informalSal}</DataCell>
                                <DataCell className="mini-column-mid">{customers.personal}</DataCell>
                                <DataCell className="mini-column-mid">{customers.commercial}</DataCell>
                                <DataCell className="mini-column-mid">{customers.financial}</DataCell>
                                <DataCell className="mini-column-mid">{customers.health}</DataCell>
                                <DataCell className="mini-column-mid">{customers.life}</DataCell>
                                <DataCell className="mini-column-med">{customers.nonPropCas}</DataCell>
                                <DataCell className="mini-column-mid">{customers.benefits}</DataCell>
                            </DataRow>
                        ))}
                    </tbody>
                </DataTable >
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={this.state.customers.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
                <CloseConfirmationModal
                    isOpen={this.state.closeConfirmationModal}
                    title={'File Upload Completed'}
                    message={'Your file has been successfully uploaded. Proceed to the dashboard for a status check on your project?'}
                    onSubmit={this.gotoMiniDashboard}
                    onClose={this.removeConfirmationModal}
                />
                <ConfirmationModal
                    isOpen={this.state.confirmationModal}
                    message={this.state.errMessage}
                    onSubmit={this.gotoFileUploads}
                />
            </>
        )
    }
}
export default withRouter(CustomersPage)