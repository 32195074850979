import * as React from 'react';

import '../../styles/Icons.scss';

export class MaterialIcon<P extends React.HTMLAttributes<HTMLElement>> extends React.Component<P> {
    public render() {
        const classNames = ['material-icon', this.props.className].join(' ');
        return (
            <span {...this.props} className={classNames}>{this.props.children}</span>
        )
    }
}
