import * as React from 'react';

export const EulaText = (
    <>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.Curabitur maximus massa quis erat dictum porta.Morbi nulla ex, vestibulum sit amet dolor ut, convallis pellentesque dui.Mauris hendrerit ac est et mattis.Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.In purus sapien, cursus condimentum viverra eu, sagittis facilisis arcu.In id ex vitae odio euismod vehicula non in ante.Sed suscipit lorem sed ornare commodo.Morbi vel leo sed eros aliquam malesuada.Aliquam sed mauris pharetra, mollis felis eget, pharetra leo.Nam tristique, ex vel convallis laoreet, eros dui malesuada ligula, a tristique massa arcu in dolor.Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.Aliquam mi magna, congue at tempus eu, dignissim at justo.In euismod ligula ac nibh ullamcorper, a mattis nulla dignissim.
    
        < br /> <p>& nbsp; </p>Pellentesque gravida ligula ipsum, vel ultricies turpis luctus a. Ut dui arcu, mollis at semper et, scelerisque eu augue. Integer et ipsum consequat, semper mi ut, iaculis ante. Praesent at convallis nisi, id tincidunt felis. Integer vitae tristique justo. Sed quis erat a ex semper malesuada. Duis non ex molestie, sodales urna ut, blandit felis. Mauris euismod malesuada consequat. Donec aliquam mauris eu ex eleifend, sit amet condimentum sapien congue. Vestibulum suscipit, velit eget finibus dignissim, orci nisl cursus neque, sit amet fringilla erat diam at sem. Proin hendrerit mauris at tortor tempus, at dictum ex viverra.

        < br /> <p>& nbsp; </p>Curabitur sed ante rutrum, facilisis nibh sollicitudin, egestas eros. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam dui justo, feugiat quis rutrum nec, volutpat ut nisi. Donec tempor feugiat sem, id lacinia mauris cursus quis. Morbi et turpis non est maximus sodales. Cras sagittis turpis sit amet aliquet luctus. Curabitur vel sapien a sem fringilla fermentum et sed leo. Praesent sodales tincidunt erat eu semper. Nunc faucibus varius libero quis fringilla. Integer fermentum placerat ultrices. Etiam et dui erat. Quisque lacinia eu dui sit amet mattis. Aenean viverra nibh erat, quis porta sapien facilisis et. Phasellus fermentum laoreet massa, in fermentum augue dignissim rhoncus.
    
        < br /> <p>& nbsp; </p>Praesent porttitor gravida ante, in fringilla diam. Praesent consequat consectetur mattis. Etiam lobortis, risus interdum lobortis condimentum, quam ligula placerat ante, sit amet commodo tellus libero a felis. Aenean iaculis risus tellus, tristique tempor metus auctor vel. Proin odio nibh, cursus vel elementum eu, venenatis tristique felis. Aliquam venenatis convallis turpis vel auctor. Sed ornare sodales lorem ac eleifend. Curabitur dui leo, vulputate mattis egestas ac, dictum sit amet orci. Aliquam erat volutpat. Pellentesque ornare, purus dictum venenatis molestie, lectus mauris placerat risus, ac feugiat nibh nulla non ex. Donec euismod purus neque, vitae laoreet nunc posuere in. Curabitur lacinia viverra metus, vitae commodo mauris venenatis aliquet. In interdum ut est id dictum. Curabitur massa massa, dapibus ac velit porttitor, rhoncus pellentesque odio. Integer pharetra ex sem, id efficitur enim pulvinar luctus. Phasellus sem purus, facilisis et ornare vitae, malesuada vel purus.

        < br /> <p>& nbsp; </p>Donec aliquet ex id augue varius luctus. Nullam efficitur id erat id volutpat. Aenean ac tristique sapien. Sed vestibulum consequat justo, et malesuada nibh. Sed ac dui augue. Sed suscipit eros enim, sed sagittis arcu fringilla eu. Aenean euismod vel odio et sollicitudin. Fusce maximus, dolor non pellentesque posuere, ex diam finibus nibh, sed posuere risus turpis eu sapien. Mauris pharetra eget purus sit amet ultricies. Cras tellus odio, porttitor non consequat quis, porta quis nulla. Sed est ipsum, maximus at sodales at, ullamcorper vel nunc. Vestibulum porta elementum sodales.

        < br /> <p>& nbsp; </p>Sed tincidunt placerat ex vitae placerat. Donec et tellus tristique ligula ullamcorper congue. Praesent vitae blandit lacus. Aenean malesuada ex purus, et congue purus faucibus id. Aenean suscipit vel nunc eget rhoncus. Nam vitae efficitur lectus, eget efficitur nisi. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla facilisi. Phasellus tincidunt efficitur magna at sollicitudin. Cras lectus eros, volutpat et ullamcorper in, rutrum vitae lorem. Sed est quam, venenatis eget augue nec, congue molestie justo. Curabitur finibus, sapien vel congue gravida, diam elit bibendum purus, vitae ullamcorper mi leo ut mi. Fusce rutrum nibh pellentesque dolor posuere, at tristique diam accumsan.

        < br /> <p>& nbsp; </p>Phasellus sagittis risus sed libero pharetra viverra. Morbi et risus vulputate, mollis quam suscipit, tempus elit. Nullam a nunc et massa aliquet egestas. Sed gravida ex a massa fringilla mollis. Aliquam euismod quis justo vitae tempus. Curabitur sed malesuada felis, at suscipit quam. Maecenas sit amet ultricies erat. Quisque vehicula ipsum pharetra, cursus urna in, elementum est. Aliquam erat volutpat. Quisque aliquet et erat eget laoreet. In augue tellus, ornare sit amet sapien ac, luctus interdum libero.

        < br /> <p>& nbsp; </p>Mauris augue sem, vehicula sit amet orci id, malesuada vulputate nisl. Phasellus varius tempus turpis quis faucibus. Sed ut semper dolor. Pellentesque a arcu a ligula ultricies imperdiet. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent sit amet eros vel augue aliquet venenatis et sed velit. Sed pretium, enim a cursus condimentum, ante eros scelerisque orci, quis cursus mi justo ac nunc. Morbi lorem risus, porta at ultrices nec, mollis ut nulla. Donec vel sodales sapien, in porta sem. Suspendisse potenti. Curabitur risus ex, suscipit et vulputate pellentesque, mattis id nibh. Phasellus quis hendrerit diam. Phasellus dolor elit, consectetur a ornare non, suscipit non lacus. Maecenas eleifend tellus in aliquam laoreet. Fusce placerat ipsum mi, id tincidunt nulla convallis suscipit.

        < br /> <p>& nbsp; </p>Etiam eget quam aliquam, semper nibh vel, elementum risus. Fusce luctus nibh id dignissim pellentesque. Nullam dignissim cursus venenatis. Phasellus non dui ut velit bibendum placerat congue nec dolor. Sed quis mi placerat, hendrerit justo rhoncus, semper orci. Nulla facilisi. Suspendisse id turpis orci. Ut laoreet ante non erat pellentesque hendrerit. Proin ornare quis mauris non suscipit. Nulla iaculis lectus urna, id vestibulum est gravida a.

        < br /> <p>& nbsp; </p>Aenean ut velit auctor, congue odio eu, auctor ligula. Nullam ac dictum metus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque placerat vestibulum justo. Nam pellentesque fringilla ipsum at varius. Duis justo nibh, venenatis et justo quis, placerat suscipit eros. Fusce lobortis cursus risus quis laoreet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;
    </>
)