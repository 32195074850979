import * as React from 'react';

import { DataTable, DataRow, DataCell } from '../../basic/DataTable';
import '../../../styles/Forms.scss';
import './entities/generic/StageActionToolbar.scss';
import './entities/generic/TierSummary.scss';

interface ConversionSummaryTableState {}

class StatisticsViewModel {
    entityType?: string | undefined;
    entityName?: string | undefined;
    mapped?: number | undefined;
    notMapped?: number | undefined;
    isDropped?: number | undefined;
    total?: number | undefined;
    status?: any | undefined;
    statuses?: any | undefined;
    sectionId?: string | undefined;
    tier?: string | undefined;
    isMappable?: boolean | undefined;
}

interface ConversionSummaryTableProps {
    conversionId: string,
    items: StatisticsViewModel[];
    isMappable: any;
    isMappableToggle: any;
    status1: any;
    toEntity: any;
}

export default class ConversionSummaryTable extends React.Component<ConversionSummaryTableProps, ConversionSummaryTableState>{
 
    constructor(props: ConversionSummaryTableProps) {
        super(props);
        this.state = {}
    }

    public render() {
        const items = this.props.items;
        const mappable = !this.props.isMappable ? "Show All" : "Show Mappable"
        return (
            <DataTable>
                <thead>
                    <tr>
                        <th className="text-column">Entity</th>
                        <th className="number-column">Mapped</th>
                        <th className="number-column">UnMapped</th>
                        <th className="number-column">Excluded</th>
                        <th className="text-column">Status</th>
                        <th className="toggle-column"><label className="switch"><input type="checkbox" readOnly checked={this.props.isMappable} onClick={this.props.isMappableToggle} /> <span className="slider round"></span></label>{mappable}</th>
                    </tr>
                </thead>
                <tbody className={this.props.status1 !== null ? "statusSummary" : "summary"}>
                    {items.map((item, index) => (
                        <DataRow id={index.toString()} className={(item.mapped !== null && item.notMapped !== null) ? 'summaryLink' : ''} key={index} onClick={() => { if (item.mapped !== null && item.notMapped !== null) this.props.toEntity(item) }}>
                            <DataCell className="text-column-cs">{item.entityName !== undefined ? item.entityName : item.entityType}</DataCell>
                            {item.mapped !== null && item.notMapped !== null && item.isMappable ?
                                <>
                                    <DataCell className="number-column-cs summaryLink">{item.mapped}</DataCell>
                                    <DataCell className="number-column-cs summaryLink">{item.notMapped}</DataCell>
                                    <DataCell className="number-column-cs summaryLink">{item.isDropped}</DataCell>
                                </> :
                                item.mapped !== null && item.notMapped !== null && !item.isMappable ?
                                    <>
                                        <DataCell className="number-column-cs summaryLink"></DataCell>
                                        <DataCell className="number-column-cs summaryLink">{'No Mapping Required'}</DataCell>
                                        <DataCell className="number-column-cs summaryLink"></DataCell>
                                    </> :
                                    <>
                                        <DataCell className="number-column-cs"></DataCell>
                                        <DataCell className="number-column-cs">{'No Source Data'}</DataCell>
                                        <DataCell className="number-column-cs"></DataCell>
                                    </>}
                            {item.statuses ?
                                <DataCell className="text-column-cs">{item.statuses!.map((i: any, index: number) => <div key={index}>{i}</div>)}</DataCell> :
                                <DataCell className="text-column-cs">
                                    {item.status}
                                </DataCell>}
                            <DataCell className="toggle-column-cs"></DataCell>
                        </DataRow>
                    ))}

                </tbody>
            </DataTable>
        )
    }
}