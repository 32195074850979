import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import StageActionToolbar from '../generic/StageActionToolbar';

import '../../../../../styles/Icons.scss';

interface PolicyHeaderStagingPageArgs {
    conversionId: string,
}

type PolicyHeaderStagingPageProps = RouteComponentProps<PolicyHeaderStagingPageArgs>;

export class PolicyHeaderStagingPage extends React.Component<PolicyHeaderStagingPageProps>{
    public render() {
        const conversionId = this.props.match.params.conversionId;

        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='policyheaders'
                    tierGate='tier3'
                    noMapping={true}
                    filter={''}
                />
            </>
        );
    }
}