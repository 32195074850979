import * as Clients from 'client/ApiClient';

export class Utilities {
    private readonly utilitiesClient: Clients.UtilitiesClient;

    constructor(utilitiesClient: Clients.UtilitiesClient) {
        this.utilitiesClient = utilitiesClient;
    }

    getVariable = (variable: string): Promise<string> => {
        return this.utilitiesClient.getConfigurationValue(variable)
            .then((response: any) => {
                return response;
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

}

export const utilities =
    new Utilities(
        new Clients.UtilitiesClient()
    );
