import classnames from 'classnames';
import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { MaterialIcon } from '../../basic/Icon';
import * as Constants from './Constants';
import { TabName } from './Constants';
import ActionTypesStagingPage from './entities/ActionTypes/ActionTypesStage';
import { BranchStagingPage } from './entities/Branch/BranchStage';
import { BrokerStagingPage } from './entities/Broker/BrokerStage';
import { BusinessOwnerPoliciesStagingPage } from './entities/BusinessOwnerPolicies/BusinessOwnerPolicies';
//import { ClaimTypeStagingPage } from './entities/ClaimType/ClaimTypeStage';
import { ClientStagingPage } from './entities/Client/ClientStage';
import { CompanyStagingPage } from './entities/Company/CompanyStage';
import { DepartmentStagingPage } from './entities/Department/DepartmentStage';
import { DivisionStagingPage } from './entities/Division/DivisionStage';
import { EmployeeStagingPage } from './entities/Employee/EmployeeStage';
import { EmployeeTypeStagingPage } from './entities/EmployeeType/EmployeeTypeStage';
import MappingDynamicDataTable from './entities/generic/MappingDynamicDataTable';
import { GroupStagingPage } from './entities/Group/GroupStage';
import { KindOfLossStagingPage } from './entities/KindOfLoss/KindOfLossStage';
import { LineOfBusinessStagingPage } from './entities/LineOfBusiness/LineOfBusinessStage';
import { NoteStagingPage } from './entities/Note/NoteStage';
import { PolicyDetailsCommercialApplicationLocationStagingPage } from './entities/PolicyDetailsCommercialApplicationLocation/PolicyDetailsCommercialApplicationLocationStage';
import { PolicyDetailsCommercialAutoStagingPage } from './entities/PolicyDetailsCommercialAuto/PolicyDetailsCommercialAutoStage';
import { PolicyDetailsCommercialUmbrellaStagingPage } from './entities/PolicyDetailsCommercialUmbrella/PolicyDetailsCommercialUmbrellaStage';
import { PolicyDetailsDwellingFireCoverageCodeStagingPage } from './entities/PolicyDetailsDwellingFireCoverageCode/PolicyDetailsDwellingFireCoverageCodeStage';
import { PolicyDetailsDwellingFireFireTypeStagingPage } from './entities/PolicyDetailsDwellingFireFireType/PolicyDetailsDwellingFireFireTypeStage';
import { PolicyDetailsEquipmentFloaterStagingPage } from './entities/PolicyDetailsEquipmentFloater/PolicyDetailsEquipmentFloaterStage';
import { PolicyDetailsGeneralLiabilityStagingPage } from './entities/PolicyDetailsGeneralLiability/PolicyDetailsGeneralLiabilityStage';
import { PolicyDetailsHomeownersCoverageCodeStagingPage } from './entities/PolicyDetailsHomeownersCoverageCode/PolicyDetailsHomeownersCoverageCodeStage';
import { PolicyDetailsHomeownersFormTypeStagingPage } from './entities/PolicyDetailsHomeownersFormType/PolicyDetailsHomeownersFormTypeStage';
import { PolicyDetailsPersonalAutoAntilockBrakeStagingPage } from './entities/PolicyDetailsPersonalAutoAntilockBrake/PolicyDetailsPersonalAutoAntilockBrakeStage';
import { PolicyDetailsPersonalAutoBodyTypeStagingPage } from './entities/PolicyDetailsPersonalAutoBodyType/PolicyDetailsPersonalAutoBodyTypeStage';
import { PolicyDetailsPersonalAutoCoverageCodeStagingPage } from './entities/PolicyDetailsPersonalAutoCoverageCode/PolicyDetailsPersonalAutoCoverageCodeStage';
import { PolicyDetailsPersonalUmbrellaStagingPage } from './entities/PolicyDetailsPersonalUmbrella/PolicyDetailsPersonalUmbrellaStage';
import { PolicyDetailsWorkersCompStagingPage } from './entities/PolicyDetailsWorkersComp/PolicyDetailsWorkersCompStage';
import { PolicyDetailsCommercialPropertyCauseOfLossStagingPage } from './entities/PolicyDetailsCommercialPropertyCauseOfLoss/PolicyDetailsCommercialPropertyCauseOfLossStage';
import { PolicyStatusTypeStagingPage } from './entities/PolicyStatusTypes/PolicyStatusTypeStage';
import { PolicyTransactionTypeStagingPage } from './entities/PolicyTransactionTypes/PolicyTransactionTypeStage';
import { VendorStagingPage } from './entities/Vendor/VendorStage';

// Tier 3
import { PolicyHeaderStagingPage } from './entities/PolicyHeader/PolicyHeaderStage';
import { ClientContactStagingPage } from './entities/ClientContact/ClientContactStage';
import ImageLogTable from '../../basic/ImageLogTable';

// Tier 5
//import { ClaimPaymentStagingPage } from './entities/ClaimPayment/ClaimPaymentStage';

// MergeTool
import { MergeToolStagePage } from './mergetool/MergeToolStage';

import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { IUserLoginDetailsModel } from '../../../client/ApiClient';
import { store } from '../../../AppContext';
import { actionCreators } from '../../../store/Select';

interface TabNavigationProps extends RouteComponentProps<{
    tabId: any;
    tierId: any;
    conversionId: string
    sectionId: string
}> {
    activeTab?: string,
    imagePath?: string,
    options?: any,
    currentUser: IUserLoginDetailsModel | undefined,
    tab: TabName
}

interface TabNavigationState {
    conversionName?: string;
    tabs: TabName[];
}

class TabNavigation extends React.PureComponent<TabNavigationProps, TabNavigationState> {
    constructor(props: TabNavigationProps) {
        super(props)
        const entityName = (this.props.match.params.sectionId as string).toLowerCase();
        const tabs = Constants.mappingTabs.get(entityName) || Constants.defaultMappingTabs

        this.state = {
            tabs: tabs
        }
        if (this.props.match.params.tabId) {
            store.dispatch(actionCreators.update(this.props.match.params.tabId))
        }
    }

    toggle = (tab: TabName) => {
        if (this.props.tab !== tab) {
            this.props.history.push({
                pathname: `/conversion/${this.props.match.params.conversionId}/mapping/entity/${this.props.match.params.tierId}/${this.props.match.params.sectionId}/${tab}`,
            });
            store.dispatch(actionCreators.update(tab))
        }
    }

    static getDerivedStateFromProps(props: TabNavigationProps, state: TabNavigationState) {
        const entityName = (props.match.params.sectionId as string).toLowerCase();
        const tabs = Constants.mappingTabs.get(entityName) || Constants.defaultMappingTabs

        const tab = tabs.includes(props.tab) ? props.tab : tabs[0]

        return {
            tab: tab,
            tabs: tabs
        }
    }

    selected = (tab: TabName) => this.props.tab === tab;

    public render() {
        const entityName = (this.props.match.params.sectionId as string).toLowerCase();
        const displayName = Constants.entityNames.get(entityName);
        if (!displayName) {
            // Don't render anything if missing a correct section ID.
            return null;
        }

        const tabs = this.state.tabs;
        const params = this.props.match.params;

        return (
            <>
                <h3 className='mapping-body-header'>{displayName} Mapping</h3>
                <Nav tabs>
                    {tabs.includes('source') &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.selected('source') })}
                                onClick={() => this.toggle('source')}>Source</NavLink>
                        </NavItem>
                    }
                    {tabs.includes('staging') &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.selected('staging') })}
                                onClick={() => this.toggle('staging')}>Staging</NavLink>
                        </NavItem>
                    }
                    {tabs.includes('target') &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.selected('target') })}
                                onClick={() => this.toggle('target')}>Target</NavLink>
                        </NavItem>
                    }
                    {tabs.includes('imageLog') && this.props.imagePath !== '' && this.props.options["60b9bc6c-b2ed-4258-b4e9-4192cbce04cc"].value === '1' &&
                        <NavItem>
                            <NavLink
                            className={classnames({ active: this.selected('imageLog') })}
                            onClick={() => this.toggle('imageLog')}>Image Log</NavLink>
                        </NavItem>
                    }
                </Nav>
                <TabContent activeTab={this.props.tab}>
                    {tabs.includes('source') &&
                        <TabPane tabId='source'>
                            <MappingDynamicDataTable source='source' conversionId={params.conversionId} sectionId={params.sectionId} />
                        </TabPane>
                    }
                    {tabs.includes('staging') &&
                        <TabPane tabId='staging'>
                            <Switch>
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier0/employeetypes/staging' component={EmployeeTypeStagingPage} />

                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/employees/staging' component={EmployeeStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/company/staging' component={CompanyStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/vendor/staging' component={VendorStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/brokers/staging' component={BrokerStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/lineofbusiness/staging' component={LineOfBusinessStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/policystatustypes/staging' component={PolicyStatusTypeStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/policytransactiontypes/staging' component={PolicyTransactionTypeStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/division/staging' component={DivisionStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/branch/staging' component={BranchStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/department/staging' component={DepartmentStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier1/group/staging' component={GroupStagingPage} />

                                <Route exact path='/conversion/:conversionId/mapping/entity/tier2/clients/staging' component={ClientStagingPage} />

                                <Route exact path='/conversion/:conversionId/mapping/entity/tier3/policyHeaders/staging' component={PolicyHeaderStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier3/clientcontacts/staging' component={ClientContactStagingPage} />

                                <Route exact path='/conversion/:conversionId/mapping/entity/tier4/kindofloss/staging' component={KindOfLossStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier4/actiontypes/staging' component={ActionTypesStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier4/notes/staging' component={NoteStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/personalautoantilockbrakes/staging' component={PolicyDetailsPersonalAutoAntilockBrakeStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/personalautobodytype/staging' component={PolicyDetailsPersonalAutoBodyTypeStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/personalautocoveragecodes/staging' component={PolicyDetailsPersonalAutoCoverageCodeStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/hodfdfformtype/staging' component={PolicyDetailsDwellingFireFireTypeStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/hodfdfcoveragecode/staging' component={PolicyDetailsDwellingFireCoverageCodeStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/hodfhoformtype/staging' component={PolicyDetailsHomeownersFormTypeStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/hodfhocoveragecode/staging' component={PolicyDetailsHomeownersCoverageCodeStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/commercialauto/staging' component={PolicyDetailsCommercialAutoStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/workerscomp/staging' component={PolicyDetailsWorkersCompStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/commercialumbrella/staging' component={PolicyDetailsCommercialUmbrellaStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/cpcauseofloss/staging' component={PolicyDetailsCommercialPropertyCauseOfLossStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/equipmentfloater/staging' component={PolicyDetailsEquipmentFloaterStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/generalliability/staging' component={PolicyDetailsGeneralLiabilityStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/personalumbrella/staging' component={PolicyDetailsPersonalUmbrellaStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/commercialapplication/staging' component={PolicyDetailsCommercialApplicationLocationStagingPage} />
                                <Route exact path='/conversion/:conversionId/mapping/entity/tier5/businessownerpolicies/staging' component={BusinessOwnerPoliciesStagingPage} />

                            // Don't render anything if missing a correct section ID.
                            
                                <Route exact path='/conversion/:conversionId/mapping/entity/merge/:stage/staging' component={MergeToolStagePage} />

                                <Route component={PageNotAvailable} />
                            </Switch>
                        </TabPane>
                    }
                    {tabs.includes('target') &&
                        <TabPane tabId='target'>
                            <MappingDynamicDataTable source='target' conversionId={params.conversionId} sectionId={params.sectionId} />
                        </TabPane>
                    }
                    {tabs.includes('imageLog') &&
                        <TabPane tabId='imageLog'>
                            <ImageLogTable conversionId={params.conversionId} tab={this.props.tab}/>
                        </TabPane>
                    }
                </TabContent>
            </>
        )
    }
}
export default connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser,
            tab: state.select && state.select.tab,
        }
    })(TabNavigation);

class PageNotAvailable extends React.Component {
    public render() {
        return (
            <div className='page-not-available'>
                <MaterialIcon className='not-available-icon'>error_outline</MaterialIcon>
                <span className='not-available-message'>The page is not available</span>
            </div>
        )
    }
}