import * as React from 'react';
import * as IMAGES from '../../assets/images/index';

import './SplashScreen.scss'

export default class SplashScreen extends React.PureComponent<{}>{
    public render() {
        const d = new Date();
        let year = d.getFullYear();
        return (
            <>
                <div className="splash-screen-container">
                    <div className="splash-side-image" style={{ backgroundImage: `url(${IMAGES.prism})` }} >
                    </div>
                    <div className="splash-main">
                        <div className="splash-logo" style={{ backgroundImage: `url(${IMAGES.vertafore_logo})` }} />
                        <div className="splash-footer">
                            <div>© {year} <b>Vertafore, Inc.</b> All rights reserved.</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}