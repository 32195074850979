import { BranchClient, BrokerClient, DepartmentClient, DivisionClient, EmployeeClient, GroupClient, VendorClient } from 'client/ApiClient';

export function setPrimary(entity: string, converisonId: string, destinationCode: string, id: any, rows: number) {
    const createClient = (entity: string) => {
        switch (entity) {
            case 'branch':
                return new BranchClient();
            case 'brokers':
                return new BrokerClient();
            case 'department':
                return new DepartmentClient();
            case 'division':
                return new DivisionClient();
            case 'group':
                return new GroupClient();
            default:
                return null;
        }
    };

    const createClientInt = (entity: string) => {
        switch (entity) {
            case 'employees':
                return new EmployeeClient();
            case 'vendor':
                return new VendorClient();
            default:
                return null;
        }
    };

    const client = createClient(entity);

    const clientInt = createClientInt(entity);

    const primary = typeof id === "number" ?
        clientInt!.updatePrimary(converisonId, destinationCode, id, rows)
            .then(() => {
            })
            .catch((error: any) => {
                console.log(error)
            })
        :
        client!.updatePrimary(converisonId, destinationCode, id, rows)
        .then(() => {
        })
        .catch((error: any) => {
            console.log(error)
        })
return primary
}