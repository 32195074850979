import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PolicyDetailsCommercialAutoClient, PolicyDetailsCommercialAutoModel } from '../../../../../client/ApiClient';
import { RangePaginationState } from 'components/basic/Pagination';
import StageActionToolbar from '../generic/StageActionToolbar';

import '../../../../../styles/Icons.scss';

interface PolicyDetailsCommercialAutoStagingPageArgs {
    conversionId: string,
}

type PolicyDetailsCommercialAutoStagingPageProps = RouteComponentProps<PolicyDetailsCommercialAutoStagingPageArgs>;

interface PolicyDetailsCommercialAutoStagingPageState {
    items: PolicyDetailsCommercialAutoModel[];
    destinationCodes: any;
    filter: string;

    pagination: RangePaginationState;
}

export class PolicyDetailsCommercialAutoStagingPage extends React.Component<PolicyDetailsCommercialAutoStagingPageProps, PolicyDetailsCommercialAutoStagingPageState>{
    private client: PolicyDetailsCommercialAutoClient = new PolicyDetailsCommercialAutoClient()

    constructor(props: PolicyDetailsCommercialAutoStagingPageProps) {
        super(props)
        this.state = {
            items: [],
            destinationCodes: [],
            filter: '',

            pagination: {
                pageSize: 25,
                pageNumber: 0,
            }
        }
    }

    componentDidMount() {
        //this.client.get(this.props.match.params.conversionId, '')
        //    .then((response) => {
        //        this.setState({
        //            items: response!
        //        })
        //    })
        //    .catch(error => { })
    }

    put(id: number) {
        const entity = this.state.items.find(item => item.id === id)

        this.client.put(this.props.match.params.conversionId, id, entity!)
            .then(() => {
            })
            .catch(error => { })
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;

        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='commercialauto'
                    tierGate='tier4'
                    noMapping={true}
                    filter={this.state.filter}
                />
            </>
        );
    }
}