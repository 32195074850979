import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { actionCreators } from '../store/Select';
import { store } from "AppContext";

import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';

import { authentication } from 'services/Authentication';
import { SecureContainer } from 'components/basic/Authentication';

import * as Clients from 'client/ApiClient';
import * as Roles from 'constants/Roles';

import './NavMenu.css';

type NavMenuProps = RouteComponentProps & {
    currentUser: Clients.IUserLoginDetailsModel | undefined,
    currentClient: string,
    AMS360URLAgency: string | null,
    AMS360URLACC: string | null
}

class NavMenu extends React.Component<NavMenuProps, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    private logout = () => {
        authentication.logout()
            .then(() => this.props.history.push('/'))
            .catch(error => { })
    }

    private account = () => {
        this.props.history.push(`/user/${this.props!.currentUser!.id}`)
    }

    private handleUsersOnClick = () => {
        store.dispatch(actionCreators.updateUserCompany(''))
    }

    private getAvatarLetters = (): string => {
        const user = this.props.currentUser;
        const getFirstLetter = (s?: string): string => (s && s.length > 0 && s[0]) || '';
        return (user && (getFirstLetter(user.firstName) + getFirstLetter(user.lastName))) || '';
    }

    private renderAvatar = () => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle className="menu-avatar menu-avatar-icon">
                    {this.getAvatarLetters()}
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={this.account}>Account</DropdownItem>
                    <DropdownItem onClick={this.logout}>Logout</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    private renderLinks = () => {
        let urlACC = "";
        let urlAgency = "";
        let accOption = null;
        let agencyOption = null;

        if (this.props.AMS360URLAgency) {
            urlAgency = this.props.AMS360URLAgency;
            agencyOption = <DropdownItem href={urlAgency}>AMS360 Agency</DropdownItem>;
        }

        if (this.props.AMS360URLACC) {
            urlACC = this.props.AMS360URLACC;
            accOption = <DropdownItem href={urlACC}>AMS360 ACC</DropdownItem>;
        }

        if (accOption || agencyOption)
            return (
                <UncontrolledDropdown>
                    <DropdownToggle nav>
                        Applications
                    </DropdownToggle>
                    <DropdownMenu right>
                        {accOption}
                        {agencyOption}
                    </DropdownMenu>
                </UncontrolledDropdown>
            );
        else
            return null;
    }

    public render() {
        return (
            <header>
                <Navbar dark expand="lg" className="top-menu">
                    <Link className='navbar-brand' to='/'></Link>
                    <div className="d-flex flex-row order-2 order-lg-3">
                        <SecureContainer roles={[
                            Roles.customerAdministratorRoleId,
                            Roles.customerUserRoleId
                        ]}>
                            <p className="client">
                                {this.props.currentClient}
                            </p>
                        </SecureContainer>
                        <span className="navbar-nav flex-row">
                            <Nav>
                                {this.props.currentUser && this.renderAvatar()}
                            </Nav>
                        </span>
                        <NavbarToggler onClick={this.toggle} />
                    </div>
                    <Collapse isOpen={this.state.isOpen} navbar className="order-3 order-lg-2">
                        <Nav className="mr-auto" navbar>
                            <SecureContainer authenticated>
                                <NavItem>
                                    <NavLink tag={Link} to="/conversion">Conversions</NavLink>
                                </NavItem>
                            </SecureContainer>
                            <SecureContainer roles={[
                                Roles.vertaforeInternalRoleId,
                                Roles.customerAdministratorRoleId,
                                Roles.developerAdministrationRoleId,
                                Roles.administrationRoleId
                            ]}>
                                <NavItem>
                                    <NavLink tag={Link} to="/user" onClick={this.handleUsersOnClick}>Users</NavLink>
                                </NavItem>
                            </SecureContainer>
                            <SecureContainer roles={[Roles.developerAdministrationRoleId, Roles.administrationRoleId]}>
                                <NavItem>
                                    <NavLink tag={Link} to="/client">Clients</NavLink>
                                </NavItem>
                            </SecureContainer>
                            <SecureContainer roles={[Roles.developerAdministrationRoleId]}>
                                <NavItem>
                                    <NavLink tag={Link} to="/uicontroller">UI Controller</NavLink>
                                </NavItem>
                            </SecureContainer>
                            <SecureContainer roles={[Roles.developerAdministrationRoleId, Roles.administrationRoleId]}>
                                <>{this.renderLinks()}</>
                            </SecureContainer>
                        </Nav>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser,
            currentClient: state.authentication ? state.authentication.currentClient : "",
            AMS360URLAgency: state.authentication ? state.authentication.ams360URLAgency : "",
            AMS360URLACC: state.authentication ? state.authentication.ams360URLACC : ""
        }
    },
)(withRouter(NavMenu));
