import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Container, Jumbotron } from "reactstrap";
import { AccountClient } from "../../client/ApiClient";
import * as resources from "../../resources/Eula";
import { authentication } from "../../services/Authentication";

import '../../styles/Forms.scss';
import './Eula.scss';

type EulaProps = RouteComponentProps & {
    children?: React.ReactNode
};

class Eula extends React.Component<EulaProps, { }>{
    private readonly client: AccountClient = new AccountClient();

    acceptEula = () => {
        this.client.acceptEula()
            .then(() => {
                authentication.setEulaAccepted();
                this.props.history.push('/conversion');
            })
            .catch(error => {
                console.log(error)
            });
    }

    public render() {
        const user = authentication.getCurrentUser();
        const showEula = (user && !user.isEulaAccepted) || false;

        if (showEula) {
            return (
                <>
                    <Jumbotron>
                        <Container>
                            <h1 className='eula-header'>End User Licensing Agreement</h1>
                        </Container>
                    </Jumbotron>
                    <div className='eula-content'>
                        <div>
                            {resources.EulaText}
                        </div>
                        <div className='eula-button-agree'>
                            <Button
                                className='submit-button-orange'
                                onClick={this.acceptEula}
                            >
                                I Agree
                            </Button>
                        </div>
                    </div>
                </>
            )
        }
        else {
            return this.props.children;
        }
    }
}

export default withRouter(Eula)