import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PolicyTransactionTypeClient, PolicyTransactionTypeModel, PolicyTransactionTypeRefModel } from '../../../../../client/ApiClient';
import { DataCell, DataRow, DataTable } from '../../../../basic/DataTable';
import { RangePaginationState, RangePagination, paginate } from 'components/basic/Pagination';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from 'reactstrap';
import StageActionToolbar from '../generic/StageActionToolbar';
import { stagingPageUpdate, stagingPageCopyMappings, StagingPageCopyMappingsItem } from '../generic/Abstractions'
import { AssignRowStatus, RowStatus } from '../generic';

import '../../../../../styles/Icons.scss';
import './PolicyTransactionTypeStage.scss';

interface PolicyTransactionTypeStagingPageArgs {
    conversionId: string,
}

type PolicyTransactionTypeStagingPageProps = RouteComponentProps<PolicyTransactionTypeStagingPageArgs>

interface PolicyTransactionTypeStagingPageState {
    items: (PolicyTransactionTypeModel
        & StagingPageCopyMappingsItem)[];
    filter: string;
    destinationCodes: PolicyTransactionTypeRefModel[];
    sort: boolean;
    sortColumn: string;
    mapped: boolean;
    notMapped: boolean;
    pagination: RangePaginationState;
    buttonLock: boolean;
}

export class PolicyTransactionTypeStagingPage extends React.Component<PolicyTransactionTypeStagingPageProps, PolicyTransactionTypeStagingPageState> {
    private client: PolicyTransactionTypeClient = new PolicyTransactionTypeClient();

    private readonly copyMappings = stagingPageCopyMappings(this);

    constructor(props: PolicyTransactionTypeStagingPageProps) {
        super(props);
        this.state = {
            items: [],
            filter: "",
            destinationCodes: [],
            sort: true,
            sortColumn: '',
            mapped: true,
            notMapped: true,
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            buttonLock: false,
        }
    }

    componentDidMount() {
        this.getItems();
    }

    setDefaults = (entity: PolicyTransactionTypeModel) =>
        new PolicyTransactionTypeModel({
            ...entity,
            referenceId: entity.referenceId || '',
            destinationCode: this.destinationName(entity.referenceId)
        })

    getItems = () => {

        const items = this.client.getAll(this.props.match.params.conversionId, '')
        const refs = this.client.getRef(this.props.match.params.conversionId)
        Promise.all([items, refs])
            .then(result => {
                this.setState({
                    items: result[0]!,
                    destinationCodes: result[1]!
                }, async () => {
                    if (await items && await refs) {
                        this.setState({
                            items: this.state.items.map(this.setDefaults).sort((a: any, b: any) => {
                                let x = a[this.state.sortColumn]
                                let y = b[this.state.sortColumn]
                                if (typeof y === "string") {
                                    y = y.toLowerCase().trim()
                                    x = x.toLowerCase().trim()
                                }
                                if (x === y) {
                                    return 0;
                                }
                                else if (x === null) {
                                    return 1;
                                }
                                else if (y === null) {
                                    return -1;
                                }
                                else if (!this.state.sort) {
                                    return x < y ? -1 : 1;
                                }
                                else {
                                    return x < y ? 1 : -1;
                                }
                            })
                        });
                    }
                })

            })
            .catch(error => { });
    }

    destinationName = (refId: string | undefined) => {
        if (refId) {
            const destination = this.state.destinationCodes.filter(ref => ref.id === refId);
            return destination[0].description
        } else {
            return ''
        }
    }

    handleChange = (e: any) => {
        const dc = e.target.value;
        const id = e.target.id;

        const items = this.state.items;
        const i = this.state.items.findIndex(v => v.id === id);
        items[i].referenceId = dc;

        this.setState({ items });
        this.put(items[i]);
    }

    put(entity: PolicyTransactionTypeModel) {
        if (entity.referenceId === '') {
            entity.referenceId = undefined
        }
        stagingPageUpdate(this, this.client)
            .withSource('Policy Status Type', entity.sourceDescription!)
            .update(entity)
            .catch(() => { })
    }

    sort(column: string) {
        const items = this.state.items.sort((a: any, b: any) => {
            let x = a[column]
            let y = b[column]
            if (typeof y === "string") {
                y = y.toLowerCase().trim()
                x = x.toLowerCase().trim()
            }
            if (x === y) {
                return 0;
            }
            else if (x === null || x === '') {
                return 1;
            }
            else if (y === null || y === '') {
                return -1;
            }
            else if (this.state.sort) {
                return x < y ? -1 : 1;
            }
            else {
                return x < y ? 1 : -1;
            }
        });
        this.setState({ sort: !this.state.sort, sortColumn: column, items });
    }

    mappedCheck(check: string) {
        if (check === 'notMapped') {
            const notMapped = !this.state.notMapped
            this.setState({ notMapped })
        } else if (check === 'mapped') {
            const mapped = !this.state.mapped
            this.setState({ mapped })
        }
    }

    lockButton = (lock: boolean) => {
        this.setState({ buttonLock: lock })
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;
        const items = this.state.items
            .map(AssignRowStatus)
            .filter(i => (i.sourceCode && i.sourceCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.sourceDescription && i.sourceDescription!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.destinationCode && i.destinationCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())))
            .filter(item =>
                (item.mapped === RowStatus.NotMapped && this.state.notMapped)
                || (item.mapped === RowStatus.Mapped && this.state.mapped));
        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    handleSearch={x => this.setState({ filter: x })}
                    disableMigrate={true}
                    handleCopyMapping={this.copyMappings.submitMappings}
                    sectionId={'policytransactiontypes'}
                    mapExcel={true}
                    copyMap={true}
                    getItems={() => this.getItems()}
                    filter={this.state.filter}
                    lockButton={this.lockButton}
                />
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th onClick={() => this.sort('sourceCode')}>Source Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('sourceDescription')}>Source Description<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('destinationCode')}>Destination Code<i className="fa fa-sort"></i></th>
                            <th className='checkbox-column'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Mapped
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={!this.state.notMapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('notMapped')}>&#x274C; Not Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.mapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('mapped')}>&#x2705; Mapped</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginate(items, this.state.pagination, (item, index) => (
                                <DataRow id={item.id.toString()} key={item.id}>
                                    <DataCell>{item.sourceCode}</DataCell>
                                    <DataCell>{item.sourceDescription}</DataCell>
                                    <DataCell>
                                        <select className="custom-select" value={item.referenceId} id={item.id} onChange={this.handleChange} disabled={this.state.buttonLock}>
                                            <option value=''>Select Destination</option>
                                            {this.state.destinationCodes
                                                .map((dc) =>
                                                    <option value={dc.id}>{dc.description}</option>
                                                )}
                                        </select>
                                    </DataCell>
                                    <DataCell className='checkbox-column' itemProp={item.mapped === RowStatus.NotMapped ? 'unmapped' : item.mapped === RowStatus.Mapped ? 'mapped' : 'dropped'}>
                                        {item.mapped === RowStatus.NotMapped ? < p className='mapped' itemProp='unmapped'>&#x274C;</p> : item.mapped === RowStatus.Mapped ? < p className='mapped' itemProp='mapped'>&#x2705;</p> : < p className='mapped' itemProp='dropped'>&#x2796;</p>}
                                    </DataCell>
                                </DataRow>
                            ))
                        }
                    </tbody>
                </DataTable>
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
            </>
        );
    }
}