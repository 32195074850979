import { Action, Reducer } from 'redux';


export interface BooleanState {
    isMappable: boolean;
}

// Actions

export interface UpdateBooleanAction { type: 'UPDATE_BOOLEAN' }

export const actionCreators = {
    update: () => ({ type: 'UPDATE_BOOLEAN' } as UpdateBooleanAction),
};

export type KnownAction = UpdateBooleanAction;

// Reducer

export const reducer: Reducer<BooleanState> =
    (state: BooleanState | undefined, incomingAction: Action): BooleanState => {

        if (state === undefined) {
            return {
                isMappable: false
            };
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case 'UPDATE_BOOLEAN':
                return {
                    isMappable: !state.isMappable
                };
            default:
                return state;
        }
    };
