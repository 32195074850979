import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { EmployeeTypeClient, EmployeeTypeModel, EmployeeTypeRefModel } from '../../../../../client/ApiClient';
import { DataCell, DataRow, DataTable } from '../../../../basic/DataTable';
import { RangePaginationState, RangePagination, paginate } from 'components/basic/Pagination';
import { DropdownMenu, DropdownToggle, DropdownItem, UncontrolledDropdown, Input } from 'reactstrap';
import StageActionToolbar from '../generic/StageActionToolbar';
import { stagingPageUpdate, stagingPageCopyMappings, StagingPageCopyMappingsItem } from '../generic/Abstractions'
import { RowStatus, AssignRowStatus } from '../generic';
import '../../../../../styles/Icons.scss';
import './EmployeeTypeStage.scss';

interface EmployeeTypeStagingPageArgs {
    conversionId: string,
}

type EmployeeTypeStagingPageProps = RouteComponentProps<EmployeeTypeStagingPageArgs>

interface EmployeeTypeStagingPageState {
    items: (EmployeeTypeModel
        & StagingPageCopyMappingsItem)[];
    filter: string;
    destinationCodes: EmployeeTypeRefModel[];
    sort: boolean;
    sortColumn: string;
    mapped: boolean;
    notMapped: boolean;
    pagination: RangePaginationState;
    buttonLock: boolean;
}

export class EmployeeTypeStagingPage extends React.Component<EmployeeTypeStagingPageProps, EmployeeTypeStagingPageState> {
    private readonly client: EmployeeTypeClient = new EmployeeTypeClient();

    private readonly copyMappings = stagingPageCopyMappings(this);

    constructor(props: EmployeeTypeStagingPageProps) {
        super(props);
        this.state = {
            items: [],
            filter: "",
            destinationCodes: [],
            sort: true,
            sortColumn: '',
            mapped: true,
            notMapped: true,
            pagination: {
                pageSize: 25,
                pageNumber: 0,
            },
            buttonLock: false,
        }
    }

    componentDidMount() {
        this.getItems();
        this.client.getRef(this.props.match.params.conversionId)
            .then(response => {
                if (response != null) {
                    this.setState({
                        destinationCodes: response,
                    });
                }
            })
            .catch(error => { })
    }

    setDefaults = (entity: EmployeeTypeModel) => new EmployeeTypeModel({
        ...entity,
    });

    getItems() {
        this.client.getAll(this.props.match.params.conversionId, "")
            .then(response => {
                this.setState({
                    items: response!.map(this.setDefaults).sort((a: any, b: any) => {
                        let x = a[this.state.sortColumn]
                        let y = b[this.state.sortColumn]
                        if (typeof y === "string") {
                            y = y.toLowerCase().trim()
                            x = x.toLowerCase().trim()
                        }
                        if (x === y) {
                            return 0;
                        }
                        else if (x === null) {
                            return 1;
                        }
                        else if (y === null) {
                            return -1;
                        }
                        else if (!this.state.sort) {
                            return x < y ? -1 : 1;
                        }
                        else {
                            return x < y ? 1 : -1;
                        }
                    }),
                })
            })
            .catch(error => { })
    }

    handleSearch = (filter: string) => {
        this.setState({ filter })
    }

    handleValueChange = (id: string, e: { target: { name: any, value: any } }) => {
        const value = e.target.name === 'rank' ? Number(e.target.value) : e.target.value;
        const items = this.state.items.map(
            v => v.id === id
                ? new EmployeeTypeModel({
                    ...v,
                    [e.target.name]: value,
                })
                : v)
        this.setState({ items });
        this.put(items.find(v => v.id === id)!)
    }

    handleChange = (id: string, e: { target: { name: any, value: any } }) => {
        const value = e.target.name === 'rank' ? Number(e.target.value) : e.target.value;
        const items = this.state.items.map(
            v => v.id === id
                ? new EmployeeTypeModel({
                    ...v,
                    [e.target.name]: value,
                })
                : v)
        this.setState({ items });
    }

    put(employeeTypeSave: EmployeeTypeModel) {
        employeeTypeSave.isPrimary = true;
        if (employeeTypeSave.referenceId === '') {
            employeeTypeSave.referenceId = undefined;
        }

        stagingPageUpdate(this, this.client)
            .withSource('Employee Type', employeeTypeSave!.sourceDescription!)
            .update(employeeTypeSave)
            .catch((err) => { console.log(err)})
    }

    sort(column: string) {
        const items = this.state.items.sort((a: any, b: any) => {
            let x = a[column]
            let y = b[column]
            if (typeof y === "string") {
                y = y.toLowerCase().trim()
                x = x.toLowerCase().trim()
            }
            if (this.state.sort) {
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
            } else {
                if (x > y) {
                    return -1;
                }
                if (x < y) {
                    return 1;
                }
            }
            return 0;
        });
        this.setState({ sort: !this.state.sort, sortColumn: column, items });
    }

    mappedCheck(check: string) {
        if (check === 'notMapped') {
            const notMapped = !this.state.notMapped
            this.setState({ notMapped })
        } else if (check === 'mapped') {
            const mapped = !this.state.mapped
            this.setState({ mapped })
        }
    }
    lockButton = (lock: boolean) => {
        this.setState({ buttonLock: lock })
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;
        const items = this.state.items
            .map(AssignRowStatus)
            .filter(i => (i.sourceCode && i.sourceCode!.toLowerCase().includes(this.state.filter.trim().toLowerCase())) ||
                (i.sourceDescription && i.sourceDescription!.toLowerCase().includes(this.state.filter.trim().toLowerCase())))
            .filter(item => (item.mapped === RowStatus.NotMapped && this.state.notMapped) || (item.mapped === RowStatus.Mapped && this.state.mapped));

        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    handleSearch={this.handleSearch}
                    sectionId='employeetypes'
                    disableMigrate={true}
                    handleCopyMapping={this.copyMappings.submitMappings}
                    mapExcel={true}
                    copyMap={true}
                    getItems={() => this.getItems()}
                    filter={this.state.filter}
                    lockButton={this.lockButton}
               />
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th onClick={() => this.sort('sourceCode')}>Source Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('sourceDescription')}>Source Description<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('referenceId')}>Destination Code<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('rank')}>Rank<i className="fa fa-sort"></i></th>
                            <th className='checkbox-column'>
                                <UncontrolledDropdown setActiveFromChild className="ismappedDropdown">
                                    <DropdownToggle tag="a" className="ismapped" caret>
                                        Mapped
                                 </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={!this.state.notMapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('notMapped')}>&#x274C; Not Mapped</DropdownItem>
                                        <DropdownItem className={!this.state.mapped ? 'mappedChecked' : ''} onClick={() => this.mappedCheck('mapped')}>&#x2705; Mapped</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginate(items, this.state.pagination, (employeeType, index) => (
                                <DataRow id={employeeType.id.toString()} key={employeeType.id}>
                                    <DataCell>{employeeType.sourceCode}</DataCell>
                                    <DataCell>{employeeType.sourceDescription}</DataCell>
                                    <DataCell>
                                        <select className="custom-select" value={employeeType.referenceId || ''} name="referenceId" id={employeeType.id} onChange={e => this.handleValueChange(employeeType.id, e)} disabled={this.state.buttonLock}>
                                            <option key={0} value=''>Select Destination</option>
                                            {this.state.destinationCodes
                                                .map((dc) =>
                                                    <option key={dc.id} value={dc.id}>{dc.description}</option>
                                                )}
                                        </select>
                                    </DataCell>
                                    <DataCell>
                                        <Input className='rank-column' type="number" value={employeeType.rank} name="rank" id={employeeType.id} disabled={this.state.buttonLock} onChange={e => this.handleChange(employeeType.id, e)} onBlur={e => this.handleValueChange(employeeType.id, e)} min="1"/>
                                    </DataCell>
                                    <DataCell className='checkbox-column' itemProp={employeeType.mapped === RowStatus.NotMapped ? 'unmapped' : employeeType.mapped === RowStatus.Mapped ? 'mapped' : 'dropped'}>
                                        {employeeType.mapped === RowStatus.NotMapped ? < p className='mapped' itemProp='unmapped'>&#x274C;</p> : employeeType.mapped === RowStatus.Mapped ? < p className='mapped' itemProp='mapped'>&#x2705;</p> : < p className='mapped' itemProp='dropped'>&#x2796;</p>}
                                    </DataCell>
                                </DataRow>
                            ))
                        }
                    </tbody>
                </DataTable>
                <RangePagination
                    pageNumber={this.state.pagination.pageNumber}
                    pageSize={this.state.pagination.pageSize}
                    recordsCount={items.length}
                    onChangeIndex={index => this.setState({
                        pagination: {
                            ...this.state.pagination,
                            pageNumber: index,
                        }
                    })}
                />
            </>
        );
    }
}