import * as React from 'react';
import { Container, Button, Form, FormGroup, Input, Label, Row, Col, Badge } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { RouteComponentProps, withRouter } from 'react-router';
import {
    IEnvironmentModel,
    IConversionClientModel,
    ConversionModel,
    ClientClient,
    ConversionClient,
    ConversionTypeClient,
    ConversionTypeModel,
    UtilitiesClient,
    ConversionsServersListModel,
    EnvironmentModel,
    IUserLoginDetailsModel,
    GenericClient,
    ExecutionsModel,
    ExecutionsClient,
    ConversionOptionValueModel,
    ConversionOptionsClient,
    PublishDBByConversionTypeIdModel,
    LoggingValuesModel
} from '../../client/ApiClient';

import * as Roles from 'constants/Roles';

import * as SourceSystem from 'constants/SourceSystem';
import * as MiniConstants from 'constants/MiniConversion';

import { promptAgainstState } from 'helpers/ChangeTracker';

import * as Routes from 'services/Routes';
import * as _ from 'lodash';

import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/Forms.scss'
import { SecureContainer } from 'components/basic/Authentication';
import ConfirmationModalNewConversion from 'components/basic/modals/ConfirmationModalNewConversion';
import { store } from "AppContext"
import { enqueueNotification } from "../../store/Notifications"
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import TaskConfirmationModal from '../basic/modals/TaskConfirmationModal';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import SourceForm from './form/SourceForm';
import MultiSourceForm from './form/MultiSourceForm';

import { ConversionEditAndOptionsPageProps } from '../../helpers/ChangeTracker';

const REQUIRED = 'This field is required.'

//interface ConversionEditPageProps extends RouteComponentProps<{
//    conversionId: string,
//}> {
//    isEditing: boolean,
//    currentUser?: IUserLoginDetailsModel | undefined,
//    refreshPage?: any,
//    customersFile?: boolean,
//    policiesFile?: boolean,
//}

interface ConversionDetails {
    client: {
        id: string | undefined
    }
    parentConversionId: string | undefined
    conversionTypeId: string | undefined
    description: string | undefined
    goLiveDate?: Date | null
    createdDate: Date | undefined
    dateCompleted?: Date | undefined
    netsuiteProject: string | undefined
    vertaforeId: string | undefined
    imagePath: string | undefined
    epicVersion: string | undefined
    epicMode: string | undefined
    amsVersion: string | undefined
    isCertified: boolean | undefined
    amsCertified: boolean | undefined
    epicModeCertified: boolean | undefined
    refreshRan: boolean | undefined
    configurationSetting: number
    miniFileUploadType: string[] | []
    source: IEnvironmentModel
    target: IEnvironmentModel
    staging: IEnvironmentModel
}

type ConversionEditPageState = ConversionDetails & {
    initial: ConversionDetails,
    errors: any
    id?: string
    rowVersion?: string
    conversionsList: {
        id: string | undefined,
        clientId: string | undefined,
        description: string | undefined,
        conversionTypeId: string | undefined,
    }[]
    clientsList: IConversionClientModel[]
    conversionTypes: ConversionTypeModel[]
    loggingValues: LoggingValuesModel[]
    sourceSystemCount: number
    sourceSystemArray: IEnvironmentModel[]
    mergeTarget: IEnvironmentModel
    sourceConnection: number
    targetConnection: number
    stagingConnection: number
    imageReportModal: boolean
    hubConnection: HubConnection | null
    buttonLock: boolean
    serverList: ConversionsServersListModel[]
    confirmationModal: boolean
    imageStatus: any
    timeout: any
    oldImagePath: string | undefined
    connection: { staging: boolean, source: boolean, target: boolean }
    miniFileUploadType: string[] | []
    options: { [key: string]: ConversionOptionValueModel } | undefined
}

class ConversionEditPage extends React.Component<ConversionEditAndOptionsPageProps, ConversionEditPageState>{
    private conversionClient: ConversionClient = new ConversionClient();
    private clientClient: ClientClient = new ClientClient();
    private conversionTypeClient: ConversionTypeClient = new ConversionTypeClient();
    private genericClient: GenericClient = new GenericClient();
    private executions: ExecutionsClient = new ExecutionsClient();
    private options: ConversionOptionsClient = new ConversionOptionsClient();
    private conversionId: string = '';
    private renderMode: string = '';
    private custFileQueued: boolean = false;

    constructor(props: ConversionEditAndOptionsPageProps) {
        super(props);
        this.conversionId = props.match.params.conversionId;
        this.state = {
            initial: {
                client: {
                    id: ''
                },
                parentConversionId: undefined,
                conversionTypeId: '',
                description: '',
                createdDate: new Date(),
                netsuiteProject: '',
                vertaforeId: '',
                imagePath: '',
                epicVersion: '',
                epicMode: '',
                amsVersion: '',
                isCertified: true,
                amsCertified: true,
                epicModeCertified: true,
                refreshRan: false,
                configurationSetting: 1,
                miniFileUploadType: [
                    (this.props.customersFile || this.props.customersFile === undefined) ? 'Customers' : '',
                    (this.props.policiesFile || this.props.policiesFile === undefined) ? 'Policies' : ''
                ],
                source: {
                    name: '',
                    host: '',
                    catalog: '',
                },
                target: {
                    host: '',
                    catalog: '',
                },
                staging: {
                    host: '',
                    catalog: '',
                }
            },
            errors: {},
            clientsList: [],
            conversionsList: [],
            client: {
                id: ''
            },
            parentConversionId: undefined,
            conversionTypeId: '',
            conversionTypes: [],
            loggingValues: [],
            sourceSystemCount: 1,
            sourceSystemArray: [{
                host: '',
                catalog: '',
                name: '',
                connectionTypeId: 0,
            }],
            mergeTarget: {
                host: '',
                catalog: '',
                name: '',
                connectionTypeId: 2,
            },
            description: '',
            createdDate: new Date(),
            netsuiteProject: '',
            vertaforeId: '',
            imagePath: '',
            epicVersion: '',
            epicMode: '',
            amsVersion: '',
            isCertified: true,
            amsCertified: true,
            epicModeCertified: true,
            refreshRan: false,
            configurationSetting: 1,
            miniFileUploadType: [
                (this.props.customersFile || this.props.customersFile === undefined) ? 'Customers' : '',
                (this.props.policiesFile || this.props.policiesFile === undefined) ? 'Policies' : ''
            ],
            options: undefined,
            source: {
                name: '',
                host: '',
                catalog: '',
            },
            target: {
                host: '',
                catalog: '',
            },
            staging: {
                host: '',
                catalog: '',
            },
            sourceConnection: 0,
            targetConnection: 0,
            stagingConnection: 0,
            imageReportModal: false,
            hubConnection: null,
            buttonLock: true,
            imageStatus: null,
            timeout: null,
            oldImagePath: '',
            serverList: [],

            confirmationModal: false,
            connection: {
                staging: false,
                source: false,
                target: false
            },
        }

        this.validateFormMini = this.validateFormMini.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSubmitMini = this.handleSubmitMini.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sourceSystemArray = this.sourceSystemArray.bind(this);
    }

    componentDidMount() {
        if (this.props.isEditing) {
            const id = this.conversionId
            if (id) {
                this.conversionClient.get(id)
                    .then((response) => {
                        if (response !== null) {
                            const state = {
                                client: {
                                    id: response.client!.id
                                },
                                parentConversionId: response.parentConversionId,
                                conversionTypeId: response.conversionTypeId,
                                description: response.description,
                                goLiveDate: response.goLiveDate,
                                createdDate: response.createdDate,
                                dateCompleted: response.dateCompleted,
                                netsuiteProject: response.netsuiteProject ? response.netsuiteProject : '',
                                vertaforeId: response.vertaforeId ? response.vertaforeId : '',
                                imagePath: response.imagePath,
                                epicVersion: response.epicVersion,
                                epicMode: response.epicMode,
                                amsVersion: response.amsVersion,
                                isCertified: response.isCertified,
                                amsCertified: response.amsCertified,
                                epicModeCertified: response.epicModeCertified,
                                refreshRan: response.refreshRan,
                                configurationSetting: response.configurationSetting,
                                miniFileUploadType: [
                                    (this.props.customersFile || this.props.customersFile === undefined) ? 'Customers' : '',
                                    (this.props.policiesFile || this.props.policiesFile === undefined) ? 'Policies' : ''
                                ],
                                source: response.source ? response.source : {},
                                target: response.target ? response.target : {},
                                staging: response.staging ? response.staging : {},
                            }
                            this.setState({
                                initial: _.cloneDeep(state),
                                id: id,
                                rowVersion: response.rowVersion,
                                ...state,
                            }, () => {
                                if (this.renderMode === 'Mini') { this.getOptions(); }
                                if (SourceSystem.ams360ToAMS360Id === this.state.conversionTypeId!.toUpperCase()) this.getConnections(2);
                            })
                        }
                    })
                    .catch(error => { })
                this.buttonLock();
                this.getStatus();
                this.getConnections(0);
                this.getLoggingValues();
            }

            const link = '/executions';
            const hubConnection = new HubConnectionBuilder().withUrl(link).build();
            this.setState({ hubConnection }, () => {
                this.state.hubConnection!
                    .start()
                    .then(() => console.log("Connection started for Administration"))
                    .catch(() => console.log("there is an error"));

                this.state.hubConnection!.on("ImageReport", (execution: ExecutionsModel) => {
                    this.buttonLock();
                    if ((execution.description === "Succeeded" || execution.description === "Completed") && execution.userId === this.props.currentUser!.id) {
                        store.dispatch(enqueueNotification({ type: 'success', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                    }
                    else if ((execution.description === "Failed" || execution.description === "Canceled" || execution.description === "Ended Unexpectedly" || execution.description === "Stopping") && execution.userId === this.props.currentUser!.id) {
                        store.dispatch(enqueueNotification({ type: 'error', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                    }
                    if (execution.endTime) {
                        clearInterval(this.state.timeout);
                        this.setStatus(execution!.estimatedFinish, execution!.description, execution!.endTime)
                    } else {
                        this.setState({ timeout: setInterval(() => { this.setStatus(execution!.estimatedFinish, execution!.description, execution!.endTime) }, 1000) })
                    }
                })
            })
        } else {
            this.setState({ buttonLock: false })
        }

        this.clientClient.getAll()
            .then((response) => {
                if (response !== null) {
                    if (response.length < 1) throw new Error('Clients list should not be empty')

                    this.setState({
                        clientsList: (this.renderMode === 'Mini' && this.props.isEditing) ? response.filter(x => x.id === this.state.client.id) : response
                    })
                }
            })
            .catch(error => { })

        this.getConversionTypes();
        this.getConversions();
        this.getServerList();
    }

    componentWillUnmount() {
        if (this.props.isEditing) {
            this.state.hubConnection!.stop();
        }
    }

    componentDidUpdate(prevProps: ConversionEditAndOptionsPageProps) {
        if (this.props.location !== prevProps.location) {
            this.componentDidMount()
        }
    }

    getConversions = () => {
        this.conversionClient.getAll()
            .then(response => {
                if (response !== null) {
                    this.setState({
                        conversionsList: response
                            .filter(x => x.id !== this.conversionId)
                            .map(x => {
                                return { id: x.id, clientId: x.client!.id, description: x.description, conversionTypeId: x.conversionTypeId }
                            }),
                    })
                }
            })
            .catch(error => { })
    }

    getConversionTypes = () => {
        this.conversionTypeClient.getAll()
            .then(response => {
                if (response !== null) {
                    if (response.length < 1) throw new Error('Source System Types list should not be empty.');

                    this.setState({
                        conversionTypes: response
                    })
                }
            })
            .catch(error => { });
    }

    getOptions = () => {
        this.options.get(this.conversionId)
            .then(values => {
                const options = values!.reduce(
                    (result, current) => ({ ...result, [current.id!]: current, }), {});
                this.setState({ options });
            })
            .catch(error => { });
    }

    getServerList = () => {
        this.conversionClient.listConversionsServers()
            .then(response => {
                this.setState({ serverList: response! })
            })
            .catch(error => { })
    }


    getConnections = (connectionId: number) => {
        this.conversionClient.getConnectionsAll(this.conversionId, connectionId)
            .then(response => {
                if (connectionId === 0) {
                    this.setState({ sourceSystemArray: response!, sourceSystemCount: response!.length })
                } else if (connectionId === 2) {
                    this.setState({
                        mergeTarget: response![0]
                    })
                }
            })
            .catch(error => { })
    }

    getLoggingValues = () => {
        this.executions.listLoggingValues()
            .then(response => {
                if (response) this.setState({ loggingValues: response })
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleSubmitMini() {
        if (this.renderMode === 'Mini') {
            this.validateFormMini(() => {
                const isNoErrors = !this.state.errors.description &&
                    !this.state.errors.miniFileUploadType;

                if (isNoErrors) {
                    const model = ConversionModel.fromJS(this.state);

                    model.id = this.props.isEditing ? model.id : undefined;
                    model.createdDate = this.state.createdDate!;
                    model.conversionTypeId = SourceSystem.miniToAMS360Id;
                    model.client!.id = this.state.clientsList[0].id || '';

                    // Replicate Conversion Options for the new Mini Conversion record
                    if (this.state.miniFileUploadType[0] === 'Customers' && this.state.miniFileUploadType[1] !== 'Policies') {
                        // Scenario 1: Customers File only option
                        model.parentConversionId = MiniConstants.parentConversionId_CustFileOnly;
                    } else if (this.state.miniFileUploadType[0] === 'Customers' && this.state.miniFileUploadType[1] === 'Policies') {
                        // Scenario 2: Customers File & Policies File option
                        model.parentConversionId = MiniConstants.parentConversionId_CustAndPolFiles;
                    } else {
                        model.parentConversionId = undefined;
                    }

                    let value = (document.getElementById('targetHost') as HTMLSelectElement).value;
                    model.target!.host = value || '';

                    value = (document.getElementById('targetDbCatalog') as HTMLInputElement).value;
                    model.target!.catalog = value || '';

                    const createStage = new Promise<void>((resolve, reject) => {
                        this.createStageDb(resolve, reject);
                    })
                    createStage
                        .then(() => {
                            model.staging!.host = this.state.staging.host || '';
                            model.staging!.catalog = this.state.staging.catalog || '';
                            model.source!.host = model.staging!.host || '';
                            model.source!.catalog = model.staging!.catalog || '';
                            this.save(model);
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                }
            })
        }
    }


    handleSubmit() {
        this.validateForm(() => {

            const mergeSource = this.state.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id ?
                (this.state.errors.source.find((s: { host: any; catalog: any; }) => !s.host && !s.catalog)) :
                (!this.state.errors.source.host &&
                    !this.state.errors.source.catalog)
            const isNoErrors = !this.state.errors.client.id &&
                !this.state.errors.description &&
                mergeSource &&
                !this.state.errors.target.host &&
                !this.state.errors.target.catalog &&
                !this.state.errors.conversionTypeId;


            if (isNoErrors) {
                //this.setState({ imagePath: this.state!.imagePath!.replace(/\\\\([^\\\\]+)\\/i, '').replace(/\$/gi, ':')})
                const model = ConversionModel.fromJS(this.state);
                model.parentConversionId = model.parentConversionId || model.parentConversionId !== '' ? model.parentConversionId : undefined;
                model.id = this.props.isEditing ? model.id : undefined;
                let keys = Object.keys(this.state.connection);

                const connectionCheck = new Promise<void>((resolve, reject) => {
                    const connection: any = this.state.connection;
                    const email = this.props.currentUser!.emailAddress ? this.props.currentUser!.emailAddress : '';
                    const s = this.state.sourceSystemArray
                    let num = 0;
                    for (let i = 0; i < s.length; i++) {
                        if (i !== 0) {
                            const model = EnvironmentModel.fromJS(s[i]);
                            this.conversionClient.getCheckConnection(model, email, 'source')
                                .then((response: any) => {
                                    num += 1;
                                    if (connection['source']) {
                                        connection['source'] = response
                                    }
                                    this.setState({ connection }, () => {
                                        if (num === s.length - 1) resolve();
                                    })
                                })
                                .catch((err: any) => {
                                    console.log(err)
                                    reject();
                                })
                        }
                    }
                })

                const keyForLoop = new Promise<void>((resolve, reject) => {
                    const connection: any = this.state.connection;
                    const email = this.props.currentUser!.emailAddress ? this.props.currentUser!.emailAddress : '';
                    keys = keys.filter(k => k !== 'staging')
                    let num = 0
                    for (let i = 0; i < keys.length; i++) {
                        const k = keys[i];
                        const enviroment: EnvironmentModel = (model as any)[k];
                        this.conversionClient.getCheckConnection(enviroment, email, k)
                            .then((response) => {
                                connection[k] = response
                                this.setState({ connection }, () => {
                                    num += 1
                                    if (!this.props.isEditing && SourceSystem.ams360ToAMS360Id === this.state.conversionTypeId!.toUpperCase() && this.state.sourceSystemArray.length > 1 && num === keys.length) {
                                        connectionCheck
                                            .then(() => {
                                                resolve();
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                                reject();
                                            })
                                    } else {
                                        if (num === keys.length) resolve();
                                    }
                                })

                            })
                            .catch((err) => {
                                reject("error");
                            })
                    }
                })


                keyForLoop
                    .then(() => {
                        const connection = this.state.connection
                        if (connection.source && connection.target) {
                            const createStage = new Promise<any>((resolve, reject) => {
                                this.createStageDb(resolve, reject);
                            });
                            createStage
                                .then(() => {
                                    const model = ConversionModel.fromJS(this.state);
                                    if (model.source!.name === null) model.source!.name = '';
                                    model.parentConversionId = model.parentConversionId || model.parentConversionId !== '' ? model.parentConversionId : undefined;
                                    if (this.state.imagePath && this.state.imagePath.trim() !== '' && this.state.initial.imagePath !== this.state.imagePath) {
                                        const email = this.props.currentUser!.emailAddress ? this.props.currentUser!.emailAddress : '';
                                        this.conversionClient.getCheckImagePath(this.state.imagePath, email)
                                            .then(response => {
                                                if (response) {
                                                    store.dispatch(enqueueNotification({ type: 'success', message: 'Image Path Saved' }))
                                                    if (this.state.refreshRan) this.setState({ oldImagePath: this.state.initial.imagePath }, () => { this.imageReportModal(false); })
                                                    this.save(model)
                                                } else {
                                                    store.dispatch(enqueueNotification({ type: 'error', message: 'An error has occurred.  Please check your email for details' }))
                                                    model.imagePath = '';
                                                }
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                            })
                                    } else {
                                        this.save(model)
                                    }
                                })
                        } else {
                            store.dispatch(enqueueNotification({ type: 'error', message: 'An error has occurred.  Please check your email for details' }))
                        }
                    })
            }
        })
    }


    save(model: ConversionModel) {
        (this.props.isEditing ? this.put(model) : this.post(model))
            .then((response: any) => {
                let showConfirmationModal = this.props.isEditing ? false : true;
                if (response && response.id && !this.props.isEditing && SourceSystem.ams360ToAMS360Id === this.state.conversionTypeId!.toUpperCase()) this.submitConnections(response!.id)
                this.setState({
                    confirmationModal: (this.renderMode === 'Mini') ? false : showConfirmationModal,
                    id: response!.id,
                    rowVersion: response!.rowVersion,
                    initial: _.cloneDeep(this.state),
                }, () => {
                    if (this.props.isEditing) {
                        store.dispatch(enqueueNotification({ type: 'success', message: 'Project ' + this.state.description + ' was saved.' }));
                        if (this.props.refreshPage) { this.props!.refreshPage(); }
                    }

                    if (this.renderMode === 'Mini') {
                        store.dispatch(enqueueNotification({ type: 'success', message: 'Project ' + this.state.description + ' was saved.' }));
                        this.props.history.push({
                            pathname: Routes.getConversionEditLocation(response!.id, 'additionaldata'),
                            state: { tabName: 'additionaldata', reload: true }
                        });
                    }
                });
            })
            .catch(() => {
                if (this.props.isEditing) {
                    store.dispatch(enqueueNotification({ type: 'error', message: 'Project ' + this.state.description + ' was not saved.' }))
                }
            });
    }

    put(model: ConversionModel) {
        const id = this.state.id
        if (!id) {
            throw new Error('Conversion id cannot be empty')
        }
        return this.conversionClient.put(id, model);
    }

    post(model: ConversionModel) {
        return this.conversionClient.post(model);
    }

    validateFormMini(callback: Function) {
        const errors = {
            description: !this.state.description && REQUIRED,
            miniFileUploadType: !this.state.miniFileUploadType[0] && !this.state.miniFileUploadType[1] && REQUIRED
        }

        this.setState({
            errors: {
                ...errors
            }
        }, () => callback());
    }

    validateForm(callback: Function) {

        let sourceArr = []

        for (let i = 0; i < this.state.sourceSystemCount; i++) {
            sourceArr.push({
                host: !this.state.sourceSystemArray[i].host && REQUIRED,
                catalog: !this.state.sourceSystemArray[i].catalog && REQUIRED,
            })
        }

        const sourceObj = {
            host: !this.state.source.host && REQUIRED,
            catalog: !this.state.source.catalog && REQUIRED
        }

        const source = this.state.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id ? sourceArr : sourceObj

        const errors = {
            description: !this.state.description && REQUIRED,
            client: {
                id: !this.state.client.id && REQUIRED
            },
            conversionTypeId: !this.state.conversionTypeId && REQUIRED,
            source: source,
            target: {
                host: !this.state.target.host && REQUIRED,
                catalog: !this.state.target.catalog && REQUIRED
            }
        }

        this.setState({
            errors: {
                ...errors
            }
        }, () => callback());
    }

    submitConnections = (conversionId: string) => {
        if (this.state.sourceSystemArray.length > 1) {
            this.state.sourceSystemArray.map((s: any, i: number) => {
                if (i !== 0) {
                    s!.conversionId = conversionId
                    this.conversionClient.postConnection(s)
                        .then()
                        .catch((err: any) => {
                            console.log(err)
                        })
                }
            })
        }
    }

    handleCheckboxChange = (event: { target: any; }) => {
        const target = event.target;
        const checked = target.checked;
        const name = target.name;
        const value = target.value;
        if (name === 'miniFileUploadType') {
            const fileUploadTypes: string[] = this.state.miniFileUploadType;
            let idx: number = value === 'Customers' ? 0 : 1;
            fileUploadTypes[idx] = checked ? value : '';

            if (value === 'Customers') {
                // Uncheck and disable the Policies check box if/when Customers is unchecked.
                // Re-enable the Policies check box if/when Customers is checked.
                const chkBoxes = document.querySelectorAll('input[name="miniFileUploadType"]');
                Array.prototype.forEach.call(chkBoxes, function (el: HTMLInputElement) {
                    if (el.value === 'Policies' || el.id === 'Policies') {
                        idx = 1;
                        fileUploadTypes[idx] = checked ? fileUploadTypes[idx] : '';
                        el.disabled = !checked;
                    }
                });
            }

            this.setState({
                miniFileUploadType: fileUploadTypes
            })
        }
    }

    handleChange = (propertyName: string, event: { target: { value: string; }; }) => {
        const newState = _.cloneDeep(this.state);
        let value: any = propertyName === 'imagePath' ? event.target.value.replace(/[^A-Za-z0-9_ ./\\\$:-]/gi, '') : event.target.value.replace(/[^A-Za-z0-9_ @./\\\$\(\):#&+-]/gi, '')
        if (propertyName === 'sourceSystemCount') {
            value = Number(value)
            if (value > 5) value = 5;
            this.sourceSystemArray(value, 0)
        }
        if (propertyName === 'configurationSetting') {
            value = Number(value)
        }
        _.set(newState, propertyName, value);
        const prevCount = this.state.sourceSystemCount
        this.setState({
            ...this.state,
            ...newState
        }, () => {
            if (propertyName === 'sourceSystemCount') {
                this.sourceSystemArray(Number(value), prevCount)
            }
        })
    }

    sourceSystemArray = (num: number, prevNum: number) => {
        const obj: IEnvironmentModel = {
            host: '',
            catalog: '',
            name: '',
            connectionTypeId: 0,
        }
        if (num !== prevNum) {
            const sourceSystemArray = [];
            for (let i = 0; i < this.state.sourceSystemCount; i++) {
                sourceSystemArray.push(obj)
            }
            this.setState({ sourceSystemArray })
        }
    }

    handleChangeSource = (e: { target: { name: any, value: any } }, index: number) => {
        if (index === 0) this.handleChange(`source.${e.target.name}`, e)
        const sourceSystemArray = this.state.sourceSystemArray.map((s: any, i: number) => {
            if (i === index) {
                return ({
                    ...s,
                    [e.target.name]: e.target.value,
                })
            } else {
                return s
            }
        })
        this.setState({ sourceSystemArray });
    }

    createStageDb = (resolve: any, reject: any) => {
        if (this.props.isEditing) {
            resolve();
        } else {
            const staging = this.state.staging;
            const project = this.state.description!.replace(/[^A-Za-z0-9]/gi, '').replace(/\s+/g, '')
            const d = new Date()
            const month = (d.getUTCMonth() < 9 ? '0' : '') + (d.getUTCMonth() + 1),
                day = (d.getUTCDate() < 10 ? '0' : '') + d.getUTCDate(),
                year = d.getUTCFullYear(),
                minute = (d.getUTCMinutes() < 10 ? '0' : '') + d.getUTCMinutes(),
                hour = (d.getUTCHours() < 10 ? '0' : '') + d.getUTCHours(),
                second = d.getUTCSeconds();
            const date = year + month + day + hour + minute + second;
            const server = this.state.serverList
                .filter(server => server.serverType === 1)
            staging.host = server[0].serverInstanceName
            const name = `SMS-${date}-${project}`
            staging.catalog = name.substring(0, 50);

            this.setState({ staging }, () => {
                const conversionModel = ConversionModel.fromJS(this.state)
                if (this.renderMode === 'Mini' && conversionModel.conversionTypeId === '') {
                    conversionModel.conversionTypeId = SourceSystem.miniToAMS360Id;
                }
                const publishDBByConversionTypeIdModel = new PublishDBByConversionTypeIdModel();
                if (conversionModel.conversionTypeId === undefined) {
                    console.warn("Conversion Type ID is undefined");
                    return;
                }

                if (conversionModel.staging === undefined) {
                    console.warn("Staging Model is undefined");
                    return;
                }
                publishDBByConversionTypeIdModel.conversionTypeId = conversionModel.conversionTypeId;
                publishDBByConversionTypeIdModel.databaseName = conversionModel.staging.catalog;
                publishDBByConversionTypeIdModel.serverName = conversionModel.staging.host;

                const publishClient = new UtilitiesClient();
                publishClient.publishDBByConversionTypeId(publishDBByConversionTypeIdModel)
                    .then(() => {
                        const conversionType = conversionModel.conversionTypeId!.toUpperCase() === SourceSystem.ams360ToAMS360Id ? 'merge' : 'epic';
                        this.conversionClient.getCheckInternalConnection(conversionModel.staging || null, 'staging', conversionType)
                            .then((result: boolean) => {
                                if (!result) {
                                    store.dispatch(enqueueNotification({ type: 'error', message: 'An error occurred creating Staging database: internal connection check failed.' }))
                                    console.log('An error occurred creating Staging database: internal connection check failed.');
                                    return;
                                }
                                else {
                                    resolve();
                                }
                            })
                            .catch((err: any) => {
                                console.log('An error occurred creating Staging database: ' + err)
                                return;
                            })
                    })
                    .catch((err) => {
                        console.log('An error occurred creating Staging database: ' + err);
                        return;
                    })
            })
        }
    }

    runImageReport = () => {
        this.imageReportModal(false);
        const userId = this.props.currentUser!.id || ''
        this.genericClient.copyMappings(this.conversionId, userId, 'images')
            .then(() => {
                this.buttonLock();
            })
            .catch((err) => {
                console.log(err)
            })
    }

    imageReportModal = (cancel: boolean) => {
        if (cancel) {
            const model = ConversionModel.fromJS(this.state);
            model.imagePath = this.state.oldImagePath
            this.setState({ imagePath: this.state.oldImagePath }, () => this.save(model))
        }
        this.setState({ imageReportModal: !this.state.imageReportModal })
    }

    buttonLock = () => {
        this.executions.listExecutionsAllTierBoolean(this.conversionId)
            .then((response) => {
                if (response) {
                    this.setState({ buttonLock: response.isLocked })
                }
            })
            .catch(error => { })
    }

    getStatus = () => {
        this.executions!.listExecutions(this.conversionId)
            .then(results => {
                if (results!.length === 0) {
                    this.setState({
                        imageStatus: null,
                    })
                }
                else {
                    const tierPrep = results!.filter(r => r.packageName === 'Epic-MissingImages.dtsx')
                    let lastExecution = tierPrep![tierPrep!.length - 1];
                    if (lastExecution.endTime) clearInterval(this.state.timeout);
                    const endTime = lastExecution.endTime;
                    if (endTime) {
                        clearInterval(this.state.timeout);
                        this.setStatus(lastExecution!.estimatedFinish, lastExecution!.description, lastExecution!.endTime)
                    } else {
                        this.setState({ timeout: setInterval(() => { this.setStatus(lastExecution!.estimatedFinish, lastExecution!.description, lastExecution!.endTime) }, 1000) })
                    }
                }
            })
            .catch(e => { console.log(e) })
    }

    private setStatus(timeEstimate: Date | undefined, status: string | undefined, endTime: Date | undefined) {
        let color = ""
        if (endTime) {
            clearInterval(this.state.timeout);
            const dt = new Date(endTime);
            const day = dt.getDate(),
                month = dt.getMonth(),
                year = dt.getFullYear(),
                minutes = (dt.getMinutes() < 10 ? '0' : "") + dt.getMinutes();
            let hours = dt.getHours();
            const period = (hours < 12) ? 'AM' : 'PM';
            if (period === 'PM' && hours !== 12) hours = hours - 12;
            if (hours === 0) hours = 12;
            if (status === "Canceled" || status === "Failed" || status === "Ended Unexpectedly") {
                color = "danger"
                this.setState({
                    imageStatus: <h6> <Badge color={color}> Image Report completed on {
                        `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                    } - Status: {status} </Badge></h6>
                });
            } else if (status === "Succeeded" || status === "Completed") {
                color = "success"
                this.setState({
                    imageStatus: <h6> <Badge color={color}> Image Report completed on {
                        `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                    } - Status: {status} </Badge></h6>
                });
            }
        } else {
            color = "primary"
            const now = new Date().valueOf();
            const estimate = timeEstimate ? new Date(timeEstimate).valueOf() : now;
            const timeRemain: number = (estimate - now) / 60000;
            const timeLeft = timeEstimate && timeRemain > 0 ? `${timeRemain.toFixed(2)} minutes` : "Pending";
            this.setState({
                imageStatus: <h6> <Badge color={color}>Estimate: {timeLeft} - Status: {status} </Badge></h6>
            });
        }
    }

    closeForm = (index: number) => {
        const sourceSystemArray = this.state.sourceSystemArray
        sourceSystemArray.splice(index, 1)
        const sourceSystemCount = sourceSystemArray.length
        this.setState({ sourceSystemArray, sourceSystemCount })
    }

    public render() {
        const merge = SourceSystem.ams360ToAMS360Id === this.state.conversionTypeId!.toUpperCase() ? true : false;
        if (this.props.match.params.conversionId) {
            this.conversionId = this.props.match.params.conversionId;
        }
        this.renderMode = SourceSystem.miniToAMS360Id === this.state.conversionTypeId!.toUpperCase() ||
            (!this.conversionId && [Roles.customerAdministratorRoleId, Roles.customerUserRoleId].includes(this.props.currentUser!.role!)) ? 'Mini' : 'Normal';

        if (this.props.isEditing && this.renderMode === 'Mini' && this.state.options) {
            for (const id of Object.keys(this.state.options)) {
                const value = this.state.options[id].value;

                // Set check box checked state based on Conversion Options saved when the Mini Conversion record was created/last saved.
                if (id.toUpperCase() === MiniConstants.conversionOption_CustFileSelectedId && value === '1') {
                    this.state.miniFileUploadType[0] = 'Customers';
                } else if (id.toUpperCase() === MiniConstants.conversionOption_PolFileSelectedId && value === '1') {
                    this.state.miniFileUploadType[1] = 'Policies';
                }

                if (id.toUpperCase() === MiniConstants.conversionOption_CustFileQueuedId) {
                    this.custFileQueued = (value === '1');
                }
            }
        }
        return (
            <React.Fragment>
                <Container>
                    <Form onSubmit={e => e.preventDefault()}>
                        <Row>
                            <Col md={{ size: 10, offset: 1 }}>
                                {this.props.isEditing ?
                                    <h2>Edit Conversion</h2> :
                                    <h2>New Conversion</h2>
                                }
                                <h5>Please provide the following information:</h5>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 5, offset: 1 }}>
                                <FormGroup>
                                    <Label for="clientName">Client Name</Label>
                                    <SecureContainer roles={[
                                        Roles.customerAdministratorRoleId,
                                        Roles.customerUserRoleId
                                    ]}>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="clientName"
                                                id="clientName"
                                                value={this.state.clientsList.length > 0 ? this.state.clientsList[0].description : ""}
                                                readOnly
                                            />
                                        </FormGroup>
                                    </SecureContainer>
                                    <SecureContainer roles={[
                                        Roles.vertaforeInternalRoleId,
                                        Roles.developerAdministrationRoleId,
                                        Roles.administrationRoleId
                                    ]}>
                                        <select
                                            className="custom-select"
                                            id="clientNameSelect"
                                            value={this.state.client.id}
                                            onChange={e => this.handleChange('client.id', e)}
                                            disabled={this.renderMode === 'Mini'}
                                        >
                                            <option key={0} value="">Select Client Name</option>

                                            {this.state.clientsList.map((client) => (
                                                <option
                                                    key={client.id}
                                                    value={client.id}
                                                >
                                                    {client.description}
                                                </option>
                                            ))}
                                        </select>
                                    </SecureContainer>
                                    <div className='validationError'>
                                        <p>{_.get(this.state.errors, 'client.id')}</p>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={{ size: 5 }}>
                                <FormGroup>
                                    <Label for="projectName">Project Name</Label>
                                    <Input
                                        type="text"
                                        maxlength = "50"
                                        name="projectName"
                                        id="projectName"
                                        value={this.state.description}
                                        onChange={e => this.handleChange('description', e)}
                                        disabled={this.renderMode === "Mini" && this.custFileQueued}
                                    />
                                    <div className='validationError'>
                                        <p>{_.get(this.state.errors, 'description')}</p>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {this.renderMode !== 'Mini' &&
                                <SecureContainer roles={[
                                    Roles.vertaforeInternalRoleId,
                                    Roles.developerAdministrationRoleId,
                                    Roles.administrationRoleId
                                ]}>
                                    <Col md={{ size: 5, offset: 1 }}>
                                        <FormGroup>
                                            <Label for="conversionTypesSelect">Parent Conversion(optional)</Label>
                                            {this.state.conversionTypeId !== "" ? <select
                                                className="custom-select"
                                                id="conversionTypesSelect"
                                                value={this.state.parentConversionId || ''}
                                                onChange={e => this.handleChange('parentConversionId', e)}
                                            >
                                                <option key={0} value={''}></option>
                                                {this.state.conversionsList
                                                    .filter(x => x.clientId === this.state.client.id)
                                                    .filter(x => x.conversionTypeId === this.state.conversionTypeId)
                                                    .map((conversion) => (
                                                        <option
                                                            key={conversion.id}
                                                            value={conversion.id}
                                                        >
                                                            {conversion.description}
                                                        </option>
                                                    ))}
                                            </select> :
                                                <Input
                                                    placeholder={"Please Select Source System Type"}
                                                    disabled={true}
                                                >
                                                </Input>}
                                        </FormGroup>
                                    </Col>
                                </SecureContainer>}
                            <Col md={{
                                size: 5, offset: this.renderMode === 'Mini' || this.props.currentUser!.role === Roles.customerAdministratorRoleId || this.props.currentUser!.role === Roles.customerUserRoleId ? 1 : 0
                            }}>
                                <FormGroup>
                                    <Label for="creationDate">Creation Date</Label>
                                    <DatePicker
                                        name="creationDate"
                                        id="creationDate"
                                        className='form-control'
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"   
                                        selected={this.state.createdDate}
                                        onChange={() => { }}
                                        readOnly
                                    />
                                    <div className='validationError'>
                                        <p>{_.get(this.state.errors, 'createdDate')}</p>
                                    </div>
                                </FormGroup>
                            </Col>
                            {this.renderMode === 'Mini' &&
                                <Col md={{ size: 5 }}>
                                    <FormGroup>
                                        <Label for="dateCompleted">Date Completed</Label>
                                        <DatePicker
                                            name="dateCompleted"
                                            id="dateCompleted"
                                            className='form-control'
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"   
                                            selected={this.state.dateCompleted}
                                            onChange={() => { }}
                                            readOnly
                                        />
                                        <div className='validationError'>
                                            <p>{_.get(this.state.errors, 'dateCompleted')}</p>
                                        </div>
                                    </FormGroup>
                                </Col>
                            }
                        </Row>
                        {this.renderMode !== 'Mini' &&
                            <SecureContainer roles={[
                                Roles.vertaforeInternalRoleId,
                                Roles.developerAdministrationRoleId,
                                Roles.administrationRoleId
                            ]}>
                                <Row>
                                    <Col md={{ size: 5, offset: 1 }}>
                                        <FormGroup>
                                            <Label for="goLiveDate">Go-Live Date(optional)</Label>
                                            <DatePicker
                                                name="goLiveDate"
                                                id="goLiveDate"
                                                className='form-control'
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"   
                                                selected={this.state.goLiveDate}
                                                onChange={(date: Date | null) => this.setState({ goLiveDate: date })}
                                            />
                                            <div className='validationError'>
                                                <p>{_.get(this.state.errors, 'goLiveDate')}</p>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={{ size: 5 }}>
                                        <FormGroup>
                                            <Label for="dateCompleted">Date Completed</Label>
                                            <DatePicker
                                                name="dateCompleted"
                                                id="dateCompleted"
                                                className='form-control'
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"   
                                                selected={this.state.dateCompleted}
                                                onChange={() => { }}
                                                readOnly
                                            />
                                            <div className='validationError'>
                                                <p>{_.get(this.state.errors, 'dateCompleted')}</p>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </SecureContainer>}
                        {this.renderMode !== 'Mini' &&
                            <SecureContainer roles={[
                                Roles.vertaforeInternalRoleId,
                                Roles.developerAdministrationRoleId,
                                Roles.administrationRoleId
                            ]}>
                                <Row>
                                    <Col md={{ size: 5, offset: 1 }}>
                                        <FormGroup>
                                            <Label for="netSuiteProjectId">NetSuite Project ID(optional)</Label>
                                            <Input
                                                type="text"
                                                name="netSuiteProjectId"
                                                id="netSuiteProjectId"
                                                value={this.state.netsuiteProject}
                                                onChange={e => this.handleChange('netsuiteProject', e)}
                                                maxLength={20}
                                            />
                                            <div className='validationError'>
                                                <p>{_.get(this.state.errors, 'netsuiteProject')}</p>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={{ size: 5 }}>
                                        <FormGroup>
                                            <Label for="vertaforeId">Vertafore ID(optional)</Label>
                                            <Input
                                                type="text"
                                                name="vertaforeId"
                                                id="vertaforeId"
                                                value={this.state.vertaforeId}
                                                onChange={e => this.handleChange('vertaforeId', e)}
                                                maxLength={20}
                                            />
                                            <div className='validationError'>
                                                <p>{_.get(this.state.errors, 'vertaforeId')}</p>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </SecureContainer>}
                        {this.props.isEditing && <SecureContainer roles={[
                            Roles.developerAdministrationRoleId
                        ]}>
                            <Row>
                                <Col md={{ size: 5, offset: 1 }}>
                                    <FormGroup>
                                        <Label for="configurationSetting">Logging Configuration</Label>
                                        <select
                                            className="custom-select"
                                            id="configurationSetting"
                                            value={this.state.configurationSetting}
                                            onChange={e => this.handleChange('configurationSetting', e)}
                                        >
                                            {this.state.loggingValues.map(lv => {
                                                return <option
                                                    key={lv.id}
                                                    value={lv.loggingValue}
                                                >
                                                    {lv.loggingDescription}
                                                </option>
                                            })}
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </SecureContainer>}
                        <Row>
                            <Col md={{ size: 10, offset: 1 }}>
                                <hr />
                                <Label>Source DB Location:</Label>
                            </Col>
                            <Col md={{ size: 5, offset: 1 }}>
                                <FormGroup>
                                    <Label for="conversionTypesSelect">Source System Type</Label>
                                    <select
                                        className="custom-select"
                                        id="conversionTypesSelect"
                                        value={this.state.conversionTypeId}
                                        onChange={e => this.handleChange('conversionTypeId', e)}
                                        disabled={this.props.isEditing || this.renderMode === 'Mini'}
                                    >
                                        {(this.renderMode !== "Mini") &&
                                            <option key={1} value={''}>Please Select Source System Type</option>
                                        }
                                        {this.state.conversionTypes
                                            .filter((this.renderMode === 'Mini') ?
                                                source => source.description!.includes('Mini') :
                                                source => source.isActive
                                            )
                                            .map((source) => (
                                                <option
                                                    key={source.id}
                                                    value={source.id}
                                                >
                                                    {source.description}
                                                </option>
                                            ))}
                                    </select>
                                    <div className='validationError'>
                                        <p>{_.get(this.state.errors, 'conversionTypeId')}</p>
                                    </div>
                                </FormGroup>
                            </Col>
                            {merge && <Col md={{ size: 3, offset: 1 }}>
                                <FormGroup>
                                    <Label for="sourceSystemCount">Source System Count</Label>
                                    <Input type="number" id="sourceSystemCount" name="sourceSystemCount" min="1" max="5" onChange={e => this.handleChange('sourceSystemCount', e)} value={this.state.sourceSystemCount} readOnly={this.props.isEditing} />
                                </FormGroup>
                            </Col>}
                            {this.renderMode === 'Mini' &&
                                <Col md={{ size: 5 }}>
                                    <Label>File Type to Upload:</Label>
                                    <FormGroup>
                                        <input className='custom-checkbox'
                                            type='checkbox'
                                            name='miniFileUploadType'
                                            id='Customers'
                                            value='Customers'
                                            checked={(this.state.miniFileUploadType[0] === 'Customers')}
                                            onChange={e => this.handleCheckboxChange(e)}
                                            disabled={this.props.isEditing || this.custFileQueued}
                                        />
                                        <Label for="miniFileUploadType">&nbsp;Customers File&nbsp;&nbsp;&nbsp;</Label>
                                        <input className='custom-checkbox'
                                            type='checkbox'
                                            name='miniFileUploadType'
                                            id='Policies'
                                            value='Policies'
                                            checked={(this.state.miniFileUploadType[1] === 'Policies')}
                                            onChange={e => this.handleCheckboxChange(e)}
                                            disabled={this.props.isEditing || this.custFileQueued}
                                        />
                                        <Label for="miniFileUploadType">&nbsp;Policies File</Label>
                                        <div className='validationError'>
                                            <p>{_.get(this.state.errors, 'miniFileUploadType')}</p>
                                        </div>
                                    </FormGroup>
                                </Col>
                            }
                        </Row>
                        <>
                            {(!merge && this.renderMode !== 'Mini') ?
                                <SecureContainer roles={[
                                    Roles.vertaforeInternalRoleId,
                                    Roles.developerAdministrationRoleId,
                                    Roles.administrationRoleId
                                ]}>
                                    <SourceForm
                                        isEditing={this.props.isEditing!}
                                        source={this.state.source}
                                        handleChange={this.handleChange}
                                        serverList={this.state.serverList}
                                        errors={this.state.errors}
                                        get={_.get} />
                                </SecureContainer>
                                :
                                this.renderMode !== 'Mini' &&
                                this.state.sourceSystemArray.map((x: any, i: number) =>
                                    <SecureContainer roles={[
                                        Roles.vertaforeInternalRoleId,
                                        Roles.developerAdministrationRoleId,
                                        Roles.administrationRoleId
                                    ]}>
                                        <MultiSourceForm
                                            key={`multi${i}`}
                                            isEditing={this.props.isEditing!}
                                            source={x}
                                            handleChangeSource={this.handleChangeSource}
                                            serverList={this.state.serverList}
                                            multiple={merge}
                                            number={this.state.sourceSystemCount}
                                            close={this.closeForm}
                                            errors={this.state.errors}
                                            id={i}
                                            get={_.get} />
                                    </SecureContainer>
                                )
                            }
                        </>
                        {this.props.isEditing && !merge && this.renderMode !== 'Mini' &&
                            <SecureContainer roles={[
                                Roles.vertaforeInternalRoleId,
                                Roles.developerAdministrationRoleId,
                                Roles.administrationRoleId
                            ]}>
                                <Row>
                                    <Col md={{ size: 5, offset: 1 }}>
                                        <FormGroup>
                                            <Label for="epicVersion">Epic Version</Label>
                                            <Input
                                                type="text"
                                                name="epicVersion"
                                                id="epicVersion"
                                                value={this.state.epicVersion || ''}
                                                readOnly={true}
                                            />
                                            {!this.state.isCertified && this.state.epicVersion !== null &&
                                                <div className='validationError'>
                                                    <p>Smart Map does not support this Epic Version</p>
                                                </div>}
                                        </FormGroup>
                                    </Col>
                                    <Col md={{ size: 5 }}>
                                        <FormGroup>
                                            <Label for="epicMode">Epic Mode</Label>
                                            <Input
                                                type="text"
                                                name="epicMode"
                                                id="epicMode"
                                                value={this.state.epicMode || ''}
                                                readOnly={true}
                                            />
                                            {!this.state.epicModeCertified && this.state.epicMode !== null &&
                                                <div className='validationError'>
                                                    <p>Smart Map does not support this Epic Mode</p>
                                                </div>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </SecureContainer>
                        }
                        <Row>
                            <Col md={{ size: 10, offset: 1 }}>
                                <hr />
                                <Label>Target DB Location:</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 5, offset: 1 }}>
                                {this.props.isEditing ?
                                    <FormGroup>
                                        <Label for="targetHost">Host</Label>
                                        <Input
                                            type="text"
                                            name="targetHost"
                                            id="targetHost"
                                            value={this.state.target.host}
                                            readOnly={this.props.isEditing}
                                        />
                                    </FormGroup>
                                    : <FormGroup>
                                        <Label for="targetHost">Host</Label>
                                        <select
                                            className="custom-select"
                                            id="targetHost"
                                            value={this.state.target.host}
                                            onChange={e => this.handleChange('target.host', e)}
                                            disabled={this.props.isEditing || this.renderMode === 'Mini'}
                                        >
                                            {(this.renderMode !== "Mini") &&
                                                <option key={1} value={''}>Please Select Target Host</option>
                                            }
                                            {this.state.serverList
                                                .filter((this.renderMode === 'Mini') ?
                                                    server => server.serverType === 2 &&
                                                        this.state.clientsList.length > 0 &&
                                                        server.serverInstanceName! === this.state.clientsList[0].miniTargetHost :
                                                    server => server.serverType === 2
                                                )
                                                .map((server) => (
                                                    <option
                                                        key={server.id}
                                                        value={server.serverInstanceName}
                                                    >
                                                        {server.serverInstanceName}
                                                    </option>
                                                ))}
                                        </select>
                                        <div className='validationError'>
                                            <p>{_.get(this.state.errors, 'target.host')}</p>
                                        </div>
                                    </FormGroup>}
                            </Col>
                            <Col md={{ size: 5 }}>



                                <FormGroup>
                                    <Label>Catalog</Label>
                                    <Input
                                        type="text"
                                        name="targetDbCatalog"
                                        id="targetDbCatalog"
                                        maxLength={50}
                                        value={(this.renderMode === 'Mini' && this.state.clientsList.length > 0 && !this.props.isEditing) ?                                                                                                                    
                                            this.state.clientsList[0].miniTargetCatalog :  
                                            this.state.target.catalog || ''}               
                                        onChange={e => this.handleChange('target.catalog', e)}
                                        readOnly={this.props.isEditing || this.renderMode === 'Mini'}
                                    />
                                    <div className='validationError'>
                                        <p>{_.get(this.state.errors, 'target.catalog')}</p>
                                    </div>
                                </FormGroup> 





                            </Col>
                        </Row>
                        {this.props.isEditing && !merge && this.renderMode !== 'Mini' &&
                            <Row>
                                <Col md={{ size: 5, offset: 1 }}>
                                    <FormGroup>
                                        <Label for="amsVersion">AMS360 Version</Label>
                                        <Input
                                            type="text"
                                            name="amsVersion"
                                            id="amsVersion"
                                            value={this.state.amsVersion || ''}
                                            readOnly={true}
                                        />
                                        {!this.state.amsCertified && this.state.amsVersion !== null &&
                                            <div className='validationError'>
                                                <p>Smart Map does not support this AMS360 Version</p>
                                            </div>}
                                    </FormGroup>
                                </Col>
                            </Row>}
                        {this.props.isEditing && merge && this.renderMode !== 'Mini' && <Row>
                            <Col md={{ size: 5, offset: 1 }}>
                                <FormGroup>
                                    <Label for="version">Version</Label>
                                    <Input
                                        type="text"
                                        name="version"
                                        id="version"
                                        value={this.state.mergeTarget.version || ''}
                                        readOnly={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={{ size: 5 }}>
                                <FormGroup>
                                    <Label for="compatibilityLevel">Compatibility Level</Label>
                                    <Input
                                        type="text"
                                        name="compatibilityLevel"
                                        id="compatibilityLevel"
                                        value={this.state.mergeTarget.compatibilityLevel || ''}
                                        readOnly={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>}
                        {this.props.isEditing &&
                            <Row>
                                <Col md={{ size: 10, offset: 1 }}>
                                    <hr />
                                    <Label>Stage DB Location:</Label>
                                </Col>
                            </Row>}
                        {this.props.isEditing &&
                            <Row>
                                <Col md={{ size: 5, offset: 1 }}>
                                    <FormGroup>
                                        <Label for="stagingHost">Host</Label>
                                        <Input
                                            type="text"
                                            name="stagingHost"
                                            id="stagingHost"
                                            value={this.state.staging.host}
                                            readOnly={this.props.isEditing}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={{ size: 5 }}>
                                    <FormGroup>
                                        <Label>Catalog</Label>
                                        <Input
                                            type="text"
                                            name="stagingDbCatalog"
                                            id="stagingDbCatalog"
                                            value={this.state.staging.catalog || ''}
                                            onChange={e => this.handleChange('staging.catalog', e)}
                                            readOnly={this.props.isEditing || this.renderMode === 'Mini'}
                                        />
                                        <div className='validationError'>
                                            <p>{_.get(this.state.errors, 'staging.catalog')}</p>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>}
                        {this.state.conversionTypeId!.toUpperCase() === SourceSystem.epicToAMS360Id &&
                            <SecureContainer roles={[
                                Roles.vertaforeInternalRoleId,
                                Roles.developerAdministrationRoleId,
                                Roles.administrationRoleId
                            ]}>
                                <>
                                    <Row>
                                        <Col md={{ size: 10, offset: 1 }}>
                                            <hr />
                                            <Label>Image Data:</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{ size: 5, offset: 1 }}>
                                            <FormGroup>
                                                <Label for="imagePath">Image Data Root Path</Label>
                                                <Input
                                                    type="text"
                                                    name="imagePath"
                                                    id="imagePath"
                                                    value={this.state.imagePath}
                                                    maxLength={260}
                                                    onChange={e => this.handleChange('imagePath', e)}
                                                />
                                                {this.state.imagePath !== '' && this.state.initial.imagePath === this.state.imagePath && !this.state.imageReportModal && this.state.imageStatus}
                                            </FormGroup>
                                        </Col>
                                    </Row></>
                            </SecureContainer>}
                    </Form>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }} className="action-col">
                            {
                                this.props.isEditing && this.state.refreshRan && <Button
                                    className='submit-button-orange float-right'
                                    onClick={() => {
                                        merge ?
                                            this.props.history.push(Routes.getMergeMappingLocation(this.props.match.params.conversionId)) :
                                            this.props.history.push(Routes.getMappingLocation(this.props.match.params.conversionId))
                                    }}
                                >Mappings</Button>
                            }
                            <Button
                                className={'submit-button-orange float-right'}
                                onClick={(this.renderMode === 'Mini') ? this.handleSubmitMini : this.handleSubmit}
                                disabled={this.state.buttonLock || (this.renderMode === "Mini" && this.custFileQueued)}
                            >Submit</Button>
                        </Col>
                    </Row>
                </Container>
                <ConfirmationModalNewConversion
                    isOpen={this.state.confirmationModal}
                    message={this.state.description + ' was saved.'}
                    conversionId={this.state.id}
                />
                <TaskConfirmationModal
                    isOpen={this.state.imageReportModal}
                    title={`Run Image Report - ${this.state.imagePath}`}
                    message={`When updating the image file path, image report will need to run.  Please confirm.`}
                    onSubmit={this.runImageReport}
                    onClose={() => this.imageReportModal(true)}
                />
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(
        (state: ApplicationState) => {
            return {
                currentUser: state.authentication && state.authentication.currentUser,
                currentTabName: state.select && state.select.tabName,
            }
        }
    )(promptAgainstState<
            ConversionEditAndOptionsPageProps,
            ConversionEditPageState,
            ConversionDetails
        >(
            ConversionEditPage,
            x => {
                return {
                    client: x.client,
                    description: x.description,
                    goLiveDate: x.goLiveDate,
                    createdDate: x.createdDate,
                    dateCompleted: x.dateCompleted,
                    netsuiteProject: x.netsuiteProject,
                    vertaforeId: x.vertaforeId,
                    parentConversionId: x.parentConversionId,
                    configurationSetting: x.configurationSetting,
                    source: x.source,
                    target: x.target,
                    staging: x.staging
                }
            },
            "edit",
            "Changes you made with the conversion settings will be lost. Do you really want to leave the page?"
        )
    )
);