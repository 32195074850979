import { Reducer } from 'redux'

export type Notification = {
    key?: number,
    type: NotificationType
    message: string
}

export type NotificationType = 'success' | 'error' | 'primary'

export interface NotificationsState {
    items: Notification[]
}

export interface EnqueueNotificationAction {
    type: 'ENQUEUE_NOTIFICATION',
    notification: Notification
}

export interface RemoveNotificationAction {
    type: 'REMOVE_NOTIFICATION',
    key: number
}

export type NotificationActions = EnqueueNotificationAction | RemoveNotificationAction;

export const enqueueNotification = (notification: Notification): NotificationActions => ({
    type: 'ENQUEUE_NOTIFICATION',
    notification: notification
})

export const removeNotification = (key: number): NotificationActions => {
    return {
        type: 'REMOVE_NOTIFICATION',
        key: key
    }
}

export const actionCreators = {
    enqueueNotification: () => (notification: Notification): NotificationActions => ({
        type: 'ENQUEUE_NOTIFICATION',
        notification: notification
    }),
    removeNotification: (key: number): NotificationActions => ({
        type: 'REMOVE_NOTIFICATION',
        key: key
    })
};

const initialState: NotificationsState = {
    items: []
}

export const reducer: Reducer<NotificationsState, NotificationActions> =
    (state = initialState, action: NotificationActions): NotificationsState => {
        switch (action.type) {
            case 'ENQUEUE_NOTIFICATION':
                return {
                    items: [
                        ...state.items, {
                            key: Math.random(),
                            ...action.notification
                        }]
                }
            case 'REMOVE_NOTIFICATION':
                return {
                    items: state.items.filter(x => x.key !== action.key)
                }
            default:
                return state
        }
    }
