export const administrationRoleId = '055e7910-7a0d-ea11-9dd7-3c528246d348';

export const vertaforeInternalRoleId = '1a660917-7a0d-ea11-9dd7-3c528246d348';

export const customerAdministratorRoleId = '1b660917-7a0d-ea11-9dd7-3c528246d348';

export const customerUserRoleId = '287a7ada-5f32-ea11-9ddd-3c528246d348';

export const noAccessRoleId = '55fdf462-3b36-ea11-9ddd-3c528246d348';

export const developerAdministrationRoleId = '46913e4b-c9c2-464b-878e-d15ea392b79c';

