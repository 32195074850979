import * as React from 'react';
import { FormGroup, FormText } from 'reactstrap';
import { ReactDatePickerProps } from 'react-datepicker';

import * as _ from 'lodash';

import './Form.scss';

export class ValidationResultList {
    public results: [string, ValidationResult?][];

    constructor() {
        this.results = [];
    }

    add = (name: string, result?: ValidationResult) => this.results.push([name, result])

    // Returns a value indicating whether the collection doesn't
    // represent any validation errors.
    isValid = (): boolean => !_.some(this.results, r => !!r[1]);
}

export type ValidationResult = {
    message: string,
};

export type ValidationProps<P> = {
    targetId: string,
    validations: ValidationResultList,
    children?: React.ReactNode
} & P;

export class Validation<P = {}, S = {}> extends React.Component<ValidationProps<P>, S> {
    render() {
        const validations = this.props.validations.results.filter(x => {
                const [id] = x;
                return id === this.props.targetId;
        });

        return (
            <FormGroup>
                {this.props.children}
                {validations.map(x => {
                    let [, result] = x
                    let message = result && result.message;

                    return message && <FormText className='validation-error'>{message}</FormText>
                })}
            </FormGroup>
        )
    }
}

export type DataControlProps<P, V> = P & {
    id: string,
    tag: React.FunctionComponent<P> | React.ComponentClass<P> | any
    value: V
    onCommit?: (id: string, value: V) => void
};

// A temporary solution until TypeScript is upgraded to version 3.5.
type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

export abstract class DataControl<P, V, S = {}> extends React.Component<Omit<DataControlProps<P, V>, 'onChange'>, S> {
    abstract extractValue(event: any): V;
    triggerCommitOnChange = (value: V) => {
        this.props.onCommit && this.props.onCommit(this.props.id, value);
    }
    protected doRender(overrides: any = {}): React.ReactNode {
        const { tag, onCommit, ...props } = this.props;
        return React.createElement(this.props.tag, {
            ...props,
            ...overrides,
            onChange: (event: any) => {
                const value = this.extractValue(event);
                this.triggerCommitOnChange(value);
            },
        });
    }
    render() {
        return this.doRender();
    }
}

export type TrackedDataControlState<V> = {
    original: V,
    value: V,
}

export abstract class TrackedDataControl<P, V>
    extends DataControl<P, V, TrackedDataControlState<V>> {
    constructor(props: Readonly<DataControlProps<P, V>>) {
        super(props)
        this.state = {
            original: props.value,
            value: props.value,
        };
    }
    static getDerivedStateFromProps(props: DataControlProps<any, any>, state: TrackedDataControlState<any>) {
        if (props.value !== state.original) {
            return {
                original: props.value,
                value: props.value,
            }
        } else {
            return null;
        }
    }
    triggerCommitOnChange = (value: V) => {
        this.setState({ value: value }, () => {
            if (this.props.onCommit) {
                this.props.onCommit(this.props.id, this.state.value);
            }
        })
    }
    
    triggerCommitOnBlur = () => {
        if (this.props.onCommit && this.props.value !== this.state.value) {
            this.props.onCommit(this.props.id, this.state.value);
        }
    }
    protected doRender(overrides: any = {}): React.ReactNode {
        return super.doRender({
            onBlur: this.triggerCommitOnBlur,
            value: this.state.value,
            ...overrides,
        });
    }
}

export class InputDataControl extends DataControl<React.InputHTMLAttributes<HTMLInputElement>, string | number> {
    extractValue(event: React.ChangeEvent<HTMLInputElement>): string | number {
        return event.target.value;
    }
}

export class TrackedInputDataControl extends TrackedDataControl<React.InputHTMLAttributes<HTMLInputElement>, string | number> {
    extractValue(event: React.ChangeEvent<HTMLInputElement>): string | number {
        return event.target.value;
    }
}

export class DateDataControl extends DataControl<ReactDatePickerProps, string | null> {
    extractValue(date: Date | null): string | null { return serializeDate(date); }
    protected doRender(overrides: any = {}): React.ReactNode {
        return super.doRender({
            className: 'form-control',
            selected: deserializeDate(this.props.value),
            showMonthDropdown: true,
            showYearDropdown: true,           
            dropdownMode: "select",
            value: undefined, /* Apply default rendering of the date. */
            ...overrides,
        });
    }
}

export class TrackedDateDataControl extends TrackedDataControl<ReactDatePickerProps, string | null> {
    extractValue(date: Date | null): string | null { return serializeDateValue(date); }
    protected doRender(overrides: any = {}): React.ReactNode {
        return super.doRender({
            className: 'form-control',
            selected: deserializeDateValue(this.state.value),
            showMonthDropdown: true,
            showYearDropdown: true,       
            dropdownMode: "select",
            value: undefined, /* Apply default rendering of the date. */
            ...overrides,
        });
    }
}

function serializeDate(value: Date | null): string | null {
    if (!value) {
        return null;
    }
    value.setMinutes(value.getMinutes() + value.getTimezoneOffset());
    return value.toISOString();
}

function deserializeDate(value: string | null): Date | null {
    if (!value) {
        return null;
    }
    const result = new Date(Date.parse(value));
    result.setMinutes(result.getMinutes() + result.getTimezoneOffset());
    return result;
}



function serializeDateValue(value: Date | null): string | null {
    if (!value) {
        return null;
    }
    //value.setMinutes(value.getMinutes() + value.getTimezoneOffset());
    return value.toISOString();
}

function deserializeDateValue(value: string | null): Date | null {
    if (!value) {
        return null;
    }
    const result = new Date(Date.parse(value));
    //result.setMinutes(result.getMinutes() + result.getTimezoneOffset());
    return result;
}

export function deriveState(prev: { [key: string]: any }, id: string, value: any): any {
    const parts = id.split('.');
    let derived: any = {};
    let previous = prev;
    let current = derived;
    // Enumerating parts of ID, which must target an object.
    for (let i = 0; i < parts.length - 1; i++) {
        const part = parts[i];
        // Create a nested object...
        const nested = {}
        current[part] = nested;
        current = nested;
        // ...and then populate it with what sits under the 'part' name in
        // the previous context.
        if (previous && typeof previous[part] === 'object') {
            previous = previous[part];
            for (const key in previous) {
                current[key] = previous[key];
            }
        }
    }
    // Setting a value to the top level object.
    current[parts[parts.length - 1]] = value;
    return derived;
}
