import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import StageActionToolbar from '../generic/StageActionToolbar';

import '../../../../../styles/Icons.scss';

interface BusinessOwnerPoliciesStagingPageArgs {
    conversionId: string,
}

type BusinessOwnerPoliciesStagingPageProps = RouteComponentProps<BusinessOwnerPoliciesStagingPageArgs>;


export class BusinessOwnerPoliciesStagingPage extends React.Component<BusinessOwnerPoliciesStagingPageProps>{

    constructor(props: BusinessOwnerPoliciesStagingPageProps) {
        super(props)
        this.state = { }
    }

    public render() {
        const conversionId = this.props.match.params.conversionId;

        return (
            <>
                <StageActionToolbar
                    conversionId={conversionId}
                    sectionId='businessownerpolicies'
                    tierGate='tier4'
                    noMapping={true}
                    filter={''}
                />
                
            </>
        );
    }
}