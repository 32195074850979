import { NotificationType, enqueueNotification } from "../store/Notifications"
import { store } from "AppContext"
import { ApiException } from "../client/ApiClient"

export const sendNotification = (type: NotificationType, message: string, ) => {
    store.dispatch(enqueueNotification({ type: type, message: message }))
}

export const sendError = (error: any) => {
    let message = undefined
    let shouldDisplay = true
    const isJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    if (error instanceof ApiException) {
        const json = isJsonString(error.response);
        if (error.response && json) {
            let response = JSON.parse(error.response)
            if (response.errors !== undefined) {
                if (response.errors.Password !== undefined)
                    if (response.errors.Password[0] === "The Password field is required.")
                        response.Id = "USERNAME_PASSWORD_EMPTY_ERROR";

                if (response.errors.UserName !== undefined)
                    if (response.errors.UserName[0] === "The UserName field is required.") {

                        response.Id = "USERNAME_PASSWORD_EMPTY_ERROR";

                    }
            }
            if (response.Id) {
                switch (response.Id) {
                    case 'GENERAL_ERROR':
                        message = response.Diagnostics.Message ? response.Diagnostics.Message : 'Unexpected server error.'
                        break
                    case 'ACCOUNT_LOCKED_ERROR':
                        message = 'The account is locked.'
                        break
                    case 'ACCOUNT_EXPIRED_ERROR':
                        message = 'The user account has expired.'
                        break
                    case 'SESSION_EXPIRED_ERROR':
                        message = 'The user session has expired.'
                        break
                    case 'INVALID_CREDENTIALS_ERROR':
                        message = 'The provided credentials are invalid.'
                        break
                    case 'MAPPING_ERROR':
                        message = response.Data.ReturnValue
                        break
                    case 'USERNAME_PASSWORD_EMPTY_ERROR':
                        message = 'The provided credentials are invalid.'
                        break
                }
            }
        }
        else {
            message = error.response;
        }

        if (!message) {
            switch (error.status) {
                case 401:
                    message = 'Your session has timed out.'
                    break
                case 412:
                    message = 'Data has been changed. Please reload the page.'
                    break
                case 504:
                    message = 'Your session has timed out.'
                    break
            }
        }
    }

    if (shouldDisplay) {
        message = message || 'Unexpected server error.'
        store.dispatch(enqueueNotification({ type: 'error', message: message }))
    }
}